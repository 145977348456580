import React, { PureComponent } from "react";
import styled from "@emotion/styled";
import convert from "color-convert";
import { splitTime } from "../../../util/index";
import typography from "../../../util/typography";
import { observer } from "mobx-react";
import {
  ExternalServiceDTO,
  BookingDTO,
  BookingClient,
  BookingOffering
} from "sdk/dist/bookings_pb";
import { renderBookingClientStatus, renderGroupBookingFooter } from "../../../util/bookings";
import { toDate } from "../../../util/timestamp";
import { format } from "date-fns";

interface Props {
  openingHours: any;
  startDate: any;
  endDate: any;
  onClick: (e: any, booking: BookingDTO.AsObject) => void;
  booking: BookingDTO.AsObject;
}

@observer
export class CalendarCard extends PureComponent<Props> {
  onCardClick = (e: any) => {
    e.stopPropagation();
    this.props.onClick(e, this.props.booking);
  };

  getStyle = () => {
    const { booking, openingHours, startDate, endDate } = this.props;

    let startDatetime = format(toDate(booking.startDatetime!), "yyyy-mm-dd HH:mm");
    let endDatetime = format(toDate(booking.endDatetime!), "yyyy-mm-dd HH:mm");
    if (startDatetime.localeCompare(startDate) < 0) {
      startDatetime = openingHours.OpensAt;
    }
    if (endDatetime.localeCompare(endDate) > 0) {
      endDatetime = openingHours.ClosesAt;
    }
    if (startDatetime.localeCompare(openingHours.OpensAt) < 0) {
      startDatetime = openingHours.ClosesAt;
    }
    if (endDatetime.localeCompare(openingHours.ClosesAt) > 0) {
      endDatetime = openingHours.ClosesAt;
    }
    const [startHour, startMinute] = splitTime(format(toDate(booking.startDatetime!), "HH:mm"));
    const [endHour, endMinute] = splitTime(format(toDate(booking.endDatetime!), "HH:mm"));

    const durationInMinutes = endHour * 60 + endMinute - (startHour * 60 + startMinute);
    const height = durationInMinutes * 2 - 2;

    const [openingStartHour, openingStartMinute] = splitTime(openingHours.OpensAt);
    const timeSinceOpenInMinutes =
      startHour * 60 + startMinute - (openingStartHour * 60 + openingStartMinute);
    const top = timeSinceOpenInMinutes * 2;

    const topBase = 62;
    return {
      height: height,
      top: topBase + top
    };
  };

  renderCardTitle(offerings: BookingOffering.AsObject[]) {
    const { booking } = this.props;
    const heading = this.getHeading(offerings);

    let thirdParty = "";
    switch (booking.externalService) {
      case ExternalServiceDTO.MINDBODY_SERVICE:
        thirdParty = "Mindbody";
    }

    if (booking.externalSessionId) {
      return (
        <>
          {heading} <em>{thirdParty}</em>
        </>
      );
    }
    return heading;
  }

  getHeading(offerings: BookingOffering.AsObject[]) {
    const { booking } = this.props;
    switch (booking.type) {
      case BookingDTO.Type.BOOKING_SINGLE:
        return booking.clientsList[0].firstName + " " + booking.clientsList[0].lastName;
      case BookingDTO.Type.BOOKING_GROUP:
        return (offerings || []).map((o, i) => (i > 0 ? ", " : "") + o.lineItem!.offering!.name);
      case BookingDTO.Type.BOOKING_TIME_OFF:
        return "On Leave";
      default:
        return "";
    }
  }

  renderCardDate() {
    const { booking } = this.props;
    switch (booking.type) {
      case BookingDTO.Type.BOOKING_TIME_OFF:
        return (
          <React.Fragment>
            {`${format(toDate(booking.startDatetime!), "yyyy-mm-ddTHH:mm")} - `}
            <br />
            {`${format(toDate(booking.endDatetime!), "yyyy-mm-ddTHH:mm")}`}
          </React.Fragment>
        );
      default:
        return `${format(toDate(booking.startDatetime!), "HH:mm")} -
                ${format(toDate(booking.endDatetime!), "HH:mm")}`;
    }
  }

  renderCardBody(offerings: BookingOffering.AsObject[]) {
    const { booking } = this.props;
    switch (booking.type) {
      case BookingDTO.Type.BOOKING_SINGLE:
        return (offerings || []).map((o: any, i: any) => (i > 0 ? ", " : "") + o.Name);
      case BookingDTO.Type.BOOKING_GROUP:
        let approvedClients = booking!.clientsList.filter((x) => {
          return x.status == BookingClient.Status.APPROVED;
        });
        return (
          booking!.provider!.firstName +
          " " +
          booking!.provider!.lastName +
          " " +
          (approvedClients.length +
            (booking.externallyRegisteredCount ? booking.externallyRegisteredCount.value : 0)) +
          "/" +
          booking!.maxClients
        );
      case BookingDTO.Type.BOOKING_TIME_OFF:
        return "On Leave";
      default:
        return "";
    }
  }

  renderCardFooter() {
    const { booking } = this.props;

    switch (booking.type) {
      case BookingDTO.Type.BOOKING_SINGLE:
        return renderBookingClientStatus(booking);
      case BookingDTO.Type.BOOKING_GROUP:
        return renderGroupBookingFooter(booking);
      default:
        return "";
    }
  }

  render() {
    const { startDate, endDate, booking, onClick: _, ...props } = this.props;
    if (booking.status === BookingDTO.Status.BOOKING_CANCELLED) {
      return null;
    }

    const offerings = booking.offeringsList;
    let color = offerings.length <= 0 ? "#00F" : offerings[0].lineItem!.offering!.color;

    if (booking.type === BookingDTO.Type.BOOKING_TIME_OFF) {
      color = "#111";
    }

    const [hue, saturation] = convert.hex.hsl(color);
    const lightness = "97%";
    let lighter = "hsl(" + hue + ", " + saturation + "%, " + lightness + ")";

    // change the color of the booking if clients pending approval
    let awaitingClients = booking!.clientsList.filter((x) => {
      return x.status == BookingClient.Status.CREATED;
    });
    if (awaitingClients.length > 0) {
      (lighter = "lemonchiffon"), (color = "yellow");
    }

    return (
      <Card
        {...props}
        onClick={this.onCardClick}
        style={{
          ...this.getStyle(),
          borderColor: color,
          backgroundColor: lighter
        }}
      >
        {booking && (
          <CardContainer>
            <CardTitle>{this.renderCardTitle(offerings)}</CardTitle>
            <CardText>{this.renderCardDate()}</CardText>
            <CardText>{this.renderCardBody(offerings)}</CardText>
            <CardText>{this.renderCardFooter()}</CardText>
          </CardContainer>
        )}
      </Card>
    );
  }
}

const Card = styled.div`
  position: absolute;
  width: 100%;
  height: 58px;
  padding: 6px 13px 8px;
  border-top: 4px solid transparent;
  position: absolute;
  left: 0;
  top: 62px;
  z-index: 2;
  cursor: pointer;
  overflow: hidden;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardTitle = styled.span`
  color: rgba(44, 46, 60, 1);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.12px;
  line-height: 14px;
  ${typography.elipse};
`;

const CardText = styled.span`
  color: rgba(44, 46, 60, 1);
  font-size: 10px;
  letter-spacing: 0.11px;
  line-height: 10px;
  margin-top: 1px;
  ${typography.elipse};
`;
