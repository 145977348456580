import React, { useEffect, useState } from "react";
import FormHeader from "../../form/FormHeader";
import Section from "../../form/Section";
import SectionsContainer from "../../form/SectionsContainer";
import LoadingButton from "../../LoadingButton";
import FooterContainer from "../../form/FooterContainer";
import styled from "@emotion/styled";
import Select from "../../form/BaseSelect";
import Label from "../../form/Label";
import { Grid } from "@material-ui/core";
import AddIcon from "../../icons/AddIcon";
import { colors, hexToRgba } from "../../../util/consts";
import IconButton from "../../IconButton";
import Minus from "../../icons/Minus";
import { toastStore } from "../../../stores/toast-store";
import { useGrpc } from "../../../hooks/useGrpc";
import { OnPeakHours } from "../../../ts-sdk/dist/location_corporate_membership_setting";
import { useAsync } from "../../../hooks/useAsync";

const mins = ["00", "15", "30", "45"];
const times: any = [];
for (let i = 0; i < 24 * mins.length; i++) {
  const hour = Math.floor(i / mins.length);
  times.push(`${hour < 10 ? "0" : ""}${hour}:${mins[i % mins.length]}:00`);
}

export function CorporateSchedule(props: { locID: string }) {
  const [isSaving, setIsSaving] = useState(false);
  const { services } = useGrpc();
  const [onPeakHoursSchedule, setOnPeakHoursSchedule] = useState<OnPeakHours>({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
  });
    
  const locationFetcher = useAsync(async () => {
    const res = await services.locationService.Get({
      locationId: props.locID
    });
    return res;
  }, [props.locID]);

  const handleSubmit = async () => {
    setIsSaving(true);
      try {
        await services.locationService.UpdateOnPeakHoursSchedule({
            locationId: props.locID,
            onPeakHoursSchedule: onPeakHoursSchedule
        });
        toastStore.success("Schedule is updated.");
    } finally {
        setIsSaving(false);
    }
  };

  const addNewOnPeakSlot = (day: string) => {
    const onPeakHoursScheduleCopy = { ...onPeakHoursSchedule };
    switch (day) {
      case "Monday":
        onPeakHoursScheduleCopy.Monday.push({ Start: "00:00:00", End: "23:45:00" });
        break;
      case "Tuesday":
        onPeakHoursScheduleCopy.Tuesday.push({ Start: "00:00:00", End: "23:45:00" });
        break;
      case "Wednesday":
        onPeakHoursScheduleCopy.Wednesday.push({ Start: "00:00:00", End: "23:45:00" });
        break;
      case "Thursday":
        onPeakHoursScheduleCopy.Thursday.push({ Start: "00:00:00", End: "23:45:00" });
        break;
      case "Friday":
        onPeakHoursScheduleCopy.Friday.push({ Start: "00:00:00", End: "23:45:00" });
        break;
      case "Saturday":
        onPeakHoursScheduleCopy.Saturday.push({ Start: "00:00:00", End: "23:45:00" });
        break;
      case "Sunday":
        onPeakHoursScheduleCopy.Sunday.push({ Start: "00:00:00", End: "23:45:00" });
        break;
      default:
        break;
    }
    setOnPeakHoursSchedule(onPeakHoursScheduleCopy);
  };

  const removeOnPeakSlot = (day: string, index: number) => {
    const onPeakHoursScheduleCopy = { ...onPeakHoursSchedule };
    switch (day) {
      case "Monday":
        onPeakHoursScheduleCopy.Monday.splice(index, 1);
        break;
      case "Tuesday":
        onPeakHoursScheduleCopy.Tuesday.splice(index, 1);
        break;
      case "Wednesday":
        onPeakHoursScheduleCopy.Wednesday.splice(index, 1);
        break;
      case "Thursday":
        onPeakHoursScheduleCopy.Thursday.splice(index, 1);
        break;
      case "Friday":
        onPeakHoursScheduleCopy.Friday.splice(index, 1);
        break;
      case "Saturday":
        onPeakHoursScheduleCopy.Saturday.splice(index, 1);
        break;
      case "Sunday":
        onPeakHoursScheduleCopy.Sunday.splice(index, 1);
        break;
      default:
        break;
    }
    setOnPeakHoursSchedule(onPeakHoursScheduleCopy);
  };

  const updateOnPeakSlot = (day: string, index: number, value: string, isStart: boolean) => {
    const onPeakHoursScheduleCopy = { ...onPeakHoursSchedule };
    switch (day) {
      case "Monday":
        isStart
          ? (onPeakHoursScheduleCopy.Monday[index].Start = value)
          : (onPeakHoursScheduleCopy.Monday[index].End = value);
        break;
      case "Tuesday":
        isStart
          ? (onPeakHoursScheduleCopy.Tuesday[index].Start = value)
          : (onPeakHoursScheduleCopy.Tuesday[index].End = value);
        break;
      case "Wednesday":
        isStart
          ? (onPeakHoursScheduleCopy.Wednesday[index].Start = value)
          : (onPeakHoursScheduleCopy.Wednesday[index].End = value);
        break;
      case "Thursday":
        isStart
          ? (onPeakHoursScheduleCopy.Thursday[index].Start = value)
          : (onPeakHoursScheduleCopy.Thursday[index].End = value);
        break;
      case "Friday":
        isStart
          ? (onPeakHoursScheduleCopy.Friday[index].Start = value)
          : (onPeakHoursScheduleCopy.Friday[index].End = value);
        break;
      case "Saturday":
        isStart
          ? (onPeakHoursScheduleCopy.Saturday[index].Start = value)
          : (onPeakHoursScheduleCopy.Saturday[index].End = value);
        break;
      case "Sunday":
        isStart
          ? (onPeakHoursScheduleCopy.Sunday[index].Start = value)
          : (onPeakHoursScheduleCopy.Sunday[index].End = value);
        break;
      default:
        break;
    }
    setOnPeakHoursSchedule(onPeakHoursScheduleCopy);
  };

  useEffect(() => {
    if (
      locationFetcher.value?.location?.locationCorporateMembershipSetting &&
      locationFetcher.value?.location?.locationCorporateMembershipSetting.onPeakHours &&
      Object.keys(locationFetcher.value?.location?.locationCorporateMembershipSetting.onPeakHours).length > 0
    ) {
      setOnPeakHoursSchedule(locationFetcher.value?.location?.locationCorporateMembershipSetting.onPeakHours);
    }
  }, [locationFetcher.value]);

  return (
    <>
      <FormHeader
        title="Corporate Schedule"
        subtitle="Please fill out the on-peak hours for your location"
      />
      <SectionsContainer>
        <Section>
          <SectionsContainer>
            <DailyOnPeakSchedule
              day="Monday"
              timeFrame={onPeakHoursSchedule.Monday}
              addNewOnPeakSlot={addNewOnPeakSlot}
              removeOnPeakSlot={removeOnPeakSlot}
              updateOnPeakSlot={updateOnPeakSlot}
            />
            <DailyOnPeakSchedule
              day="Tuesday"
              timeFrame={onPeakHoursSchedule.Tuesday}
              addNewOnPeakSlot={addNewOnPeakSlot}
              removeOnPeakSlot={removeOnPeakSlot}
              updateOnPeakSlot={updateOnPeakSlot}
            />
            <DailyOnPeakSchedule
              day="Wednesday"
              timeFrame={onPeakHoursSchedule.Wednesday}
              addNewOnPeakSlot={addNewOnPeakSlot}
              removeOnPeakSlot={removeOnPeakSlot}
              updateOnPeakSlot={updateOnPeakSlot}
            />
            <DailyOnPeakSchedule
              day="Thursday"
              timeFrame={onPeakHoursSchedule.Thursday}
              addNewOnPeakSlot={addNewOnPeakSlot}
              removeOnPeakSlot={removeOnPeakSlot}
              updateOnPeakSlot={updateOnPeakSlot}
            />
            <DailyOnPeakSchedule
              day="Friday"
              timeFrame={onPeakHoursSchedule.Friday}
              addNewOnPeakSlot={addNewOnPeakSlot}
              removeOnPeakSlot={removeOnPeakSlot}
              updateOnPeakSlot={updateOnPeakSlot}
            />
            <DailyOnPeakSchedule
              day="Saturday"
              timeFrame={onPeakHoursSchedule.Saturday}
              addNewOnPeakSlot={addNewOnPeakSlot}
              removeOnPeakSlot={removeOnPeakSlot}
              updateOnPeakSlot={updateOnPeakSlot}
            />
            <DailyOnPeakSchedule
              day="Sunday"
              timeFrame={onPeakHoursSchedule.Sunday}
              addNewOnPeakSlot={addNewOnPeakSlot}
              removeOnPeakSlot={removeOnPeakSlot}
              updateOnPeakSlot={updateOnPeakSlot}
            />
          </SectionsContainer>
          <FooterContainer>
            <LoadingButton
              style={{ width: 150 }}
              loading={isSaving}
              variant="contained"
              color="secondary"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </LoadingButton>
          </FooterContainer>
        </Section>
      </SectionsContainer>
    </>
  );
}

function DailyOnPeakSchedule(props: {
  day: string;
  timeFrame: any[]; // TODO: will change type to DTO type once API is created
  addNewOnPeakSlot: (day: string) => void;
  removeOnPeakSlot: (day: string, index: number) => void;
  updateOnPeakSlot: (day: string, index: number, value: string, isStart: boolean) => void;
}) {
  return (
    <Grid container alignItems="center" spacing={1} style={{ marginBottom: "10px" }}>
      <Grid item xs={6}>
        <b>{props.day}</b>
      </Grid>
      <Grid item xs={6} style={{ display: "flex", justifyContent: "end" }}>
        <Button mini onClick={() => props.addNewOnPeakSlot(props.day)}>
          <AddIcon fill={colors.surface.light} />
        </Button>
      </Grid>
      {props.timeFrame.map((t, i) => (
        <React.Fragment key={i}>
          <Grid item xs={2}>
            <Button mini onClick={() => props.removeOnPeakSlot(props.day, i)}>
              <Minus fill={colors.surface.light} />
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Label>Start: </Label>
          </Grid>
          <Grid item xs={3}>
            <TimeSelect
              id={`${props.day}-open`}
              name={`${props.day}Open`}
              value={t.Start}
              onChange={(e: any) => props.updateOnPeakSlot(props.day, i, e.target.value, true)}
            >
              {times
                .filter((v: any) => !t.End || v.localeCompare(t.End) < 0)
                .map((tv: any) => (
                  <option key={tv} value={tv}>
                    {tv.slice(0, 5)}
                  </option>
                ))}
            </TimeSelect>
          </Grid>
          <Grid item xs={2}>
            <Label>End: </Label>
          </Grid>
          <Grid item xs={3}>
            <TimeSelect
              id={`${props.day}-open`}
              name={`${props.day}Open`}
              value={t.End}
              onChange={(e: any) => props.updateOnPeakSlot(props.day, i, e.target.value, false)}
            >
              {times
                .filter((v: any) => !t.Start || v.localeCompare(t.Start) > 0)
                .map((tv: any) => (
                  <option key={tv} value={tv}>
                    {tv.slice(0, 5)}
                  </option>
                ))}
            </TimeSelect>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}

const TimeSelect = styled(Select)`
  width: 120px;
`;

const Button = styled(IconButton)`
  margin-left: 10px;
  background-color: ${colors.secondary.main} !important;

  &:hover {
    background-color: ${hexToRgba(colors.secondary.main, 0.6)} !important;
  }
`;
