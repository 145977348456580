import { Component } from "react";
import { connect } from "react-redux";

import * as GlobalActions from "./../../redux/features/global/actions";

import { metadata, rpc } from "../../grpc/grpc-legacy";
import { GetOrganisationUserRequest } from "sdk/dist/organisation_user_pb";
import { GetLocationRequest } from "sdk/dist/location_grpc_web_pb"
import { UserUIType } from "sdk/dist/location_user_pb"
import apiService from "./../../redux/services/api";

class SetLocInUserContext extends Component {
  componentDidMount() {
    const { dispatch, userContext, match, auth, history } = this.props;
    dispatch(GlobalActions.setUserContext(userContext.OrgID, match.params.lid));
    this.getUserUIType(userContext.OrgID, auth ? auth.UserID : '');
    this.getLocation(match.params.lid)
  }

  getUserUIType = async (ordId, userID) => {
    const req = new GetOrganisationUserRequest();
    req.setUserid(userID);
    req.setOrganisationid(ordId);
    const res = await rpc.organisationUser.getOrganisationUser(req, metadata());
    let type = UserUIType[res.toObject().type];
    if (type !== "" && type != undefined) {
      localStorage.setItem("UserUIType", type);
    } else {
      localStorage.removeItem("UserUIType");
    }
    return res;
  };



  getLocation = async (locId) =>{
    const req = new GetLocationRequest()
    req.setLocationId(locId)
    const res = await rpc.location.get(req, metadata());
    localStorage.setItem("LocTimeZone",res.toObject().timeZone);
    return res
  }

  render() {
    return null;
  }
}

export default connect((state) => ({
  userContext: state.global.UserContext,
  auth: state.auth
}))(SetLocInUserContext);
