/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { UserType, userTypeFromJSON, userTypeToJSON } from "./user";

export const protobufPackage = "proto";

export enum SubscriptionStatusDTO {
  SUBSCRIPTION_UNKNOWN = 0,
  SUBSCRIPTION_TRIAL = 1,
  SUBSCRIPTION_ACTIVE = 2,
  SUBSCRIPTION_PAYMENTFAILED = 3,
  SUBSCRIPTION_ENDED = 4,
  UNRECOGNIZED = -1,
}

export function subscriptionStatusDTOFromJSON(object: any): SubscriptionStatusDTO {
  switch (object) {
    case 0:
    case "SUBSCRIPTION_UNKNOWN":
      return SubscriptionStatusDTO.SUBSCRIPTION_UNKNOWN;
    case 1:
    case "SUBSCRIPTION_TRIAL":
      return SubscriptionStatusDTO.SUBSCRIPTION_TRIAL;
    case 2:
    case "SUBSCRIPTION_ACTIVE":
      return SubscriptionStatusDTO.SUBSCRIPTION_ACTIVE;
    case 3:
    case "SUBSCRIPTION_PAYMENTFAILED":
      return SubscriptionStatusDTO.SUBSCRIPTION_PAYMENTFAILED;
    case 4:
    case "SUBSCRIPTION_ENDED":
      return SubscriptionStatusDTO.SUBSCRIPTION_ENDED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SubscriptionStatusDTO.UNRECOGNIZED;
  }
}

export function subscriptionStatusDTOToJSON(object: SubscriptionStatusDTO): string {
  switch (object) {
    case SubscriptionStatusDTO.SUBSCRIPTION_UNKNOWN:
      return "SUBSCRIPTION_UNKNOWN";
    case SubscriptionStatusDTO.SUBSCRIPTION_TRIAL:
      return "SUBSCRIPTION_TRIAL";
    case SubscriptionStatusDTO.SUBSCRIPTION_ACTIVE:
      return "SUBSCRIPTION_ACTIVE";
    case SubscriptionStatusDTO.SUBSCRIPTION_PAYMENTFAILED:
      return "SUBSCRIPTION_PAYMENTFAILED";
    case SubscriptionStatusDTO.SUBSCRIPTION_ENDED:
      return "SUBSCRIPTION_ENDED";
    case SubscriptionStatusDTO.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum RolloverSubscriptionTypeDTO {
  SUBSCRIPTION_TYPE_NONE = 0,
  SUBSCRIPTION_TYPE_SOLO = 1,
  SUBSCRIPTION_TYPE_TEAM = 2,
  SUBSCRIPTION_TYPE_MEDIUM = 3,
  SUBSCRIPTION_TYPE_LARGE = 4,
  SUBSCRIPTION_TYPE_CUSTOM = 5,
  SUBSCRIPTION_TYPE_STARTER_KIT = 6,
  SUBSCRIPTION_TYPE_STARTER = 7,
  SUBSCRIPTION_TYPE_BASIC = 8,
  SUBSCRIPTION_TYPE_PRO = 9,
  UNRECOGNIZED = -1,
}

export function rolloverSubscriptionTypeDTOFromJSON(object: any): RolloverSubscriptionTypeDTO {
  switch (object) {
    case 0:
    case "SUBSCRIPTION_TYPE_NONE":
      return RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_NONE;
    case 1:
    case "SUBSCRIPTION_TYPE_SOLO":
      return RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_SOLO;
    case 2:
    case "SUBSCRIPTION_TYPE_TEAM":
      return RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_TEAM;
    case 3:
    case "SUBSCRIPTION_TYPE_MEDIUM":
      return RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_MEDIUM;
    case 4:
    case "SUBSCRIPTION_TYPE_LARGE":
      return RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_LARGE;
    case 5:
    case "SUBSCRIPTION_TYPE_CUSTOM":
      return RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_CUSTOM;
    case 6:
    case "SUBSCRIPTION_TYPE_STARTER_KIT":
      return RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_STARTER_KIT;
    case 7:
    case "SUBSCRIPTION_TYPE_STARTER":
      return RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_STARTER;
    case 8:
    case "SUBSCRIPTION_TYPE_BASIC":
      return RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_BASIC;
    case 9:
    case "SUBSCRIPTION_TYPE_PRO":
      return RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_PRO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RolloverSubscriptionTypeDTO.UNRECOGNIZED;
  }
}

export function rolloverSubscriptionTypeDTOToJSON(object: RolloverSubscriptionTypeDTO): string {
  switch (object) {
    case RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_NONE:
      return "SUBSCRIPTION_TYPE_NONE";
    case RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_SOLO:
      return "SUBSCRIPTION_TYPE_SOLO";
    case RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_TEAM:
      return "SUBSCRIPTION_TYPE_TEAM";
    case RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_MEDIUM:
      return "SUBSCRIPTION_TYPE_MEDIUM";
    case RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_LARGE:
      return "SUBSCRIPTION_TYPE_LARGE";
    case RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_CUSTOM:
      return "SUBSCRIPTION_TYPE_CUSTOM";
    case RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_STARTER_KIT:
      return "SUBSCRIPTION_TYPE_STARTER_KIT";
    case RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_STARTER:
      return "SUBSCRIPTION_TYPE_STARTER";
    case RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_BASIC:
      return "SUBSCRIPTION_TYPE_BASIC";
    case RolloverSubscriptionTypeDTO.SUBSCRIPTION_TYPE_PRO:
      return "SUBSCRIPTION_TYPE_PRO";
    case RolloverSubscriptionTypeDTO.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CorporateMembershipStatusDTO {
  CORPORATE_MEMBERSHIP_STATUS_UNKNOWN = 0,
  NOT_REGISTERED_STATUS = 1,
  FORM_SUBMITTED_STATUS = 2,
  INACTIVE_STATUS = 3,
  ACTIVE_STATUS = 4,
  UNRECOGNIZED = -1,
}

export function corporateMembershipStatusDTOFromJSON(object: any): CorporateMembershipStatusDTO {
  switch (object) {
    case 0:
    case "CORPORATE_MEMBERSHIP_STATUS_UNKNOWN":
      return CorporateMembershipStatusDTO.CORPORATE_MEMBERSHIP_STATUS_UNKNOWN;
    case 1:
    case "NOT_REGISTERED_STATUS":
      return CorporateMembershipStatusDTO.NOT_REGISTERED_STATUS;
    case 2:
    case "FORM_SUBMITTED_STATUS":
      return CorporateMembershipStatusDTO.FORM_SUBMITTED_STATUS;
    case 3:
    case "INACTIVE_STATUS":
      return CorporateMembershipStatusDTO.INACTIVE_STATUS;
    case 4:
    case "ACTIVE_STATUS":
      return CorporateMembershipStatusDTO.ACTIVE_STATUS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CorporateMembershipStatusDTO.UNRECOGNIZED;
  }
}

export function corporateMembershipStatusDTOToJSON(object: CorporateMembershipStatusDTO): string {
  switch (object) {
    case CorporateMembershipStatusDTO.CORPORATE_MEMBERSHIP_STATUS_UNKNOWN:
      return "CORPORATE_MEMBERSHIP_STATUS_UNKNOWN";
    case CorporateMembershipStatusDTO.NOT_REGISTERED_STATUS:
      return "NOT_REGISTERED_STATUS";
    case CorporateMembershipStatusDTO.FORM_SUBMITTED_STATUS:
      return "FORM_SUBMITTED_STATUS";
    case CorporateMembershipStatusDTO.INACTIVE_STATUS:
      return "INACTIVE_STATUS";
    case CorporateMembershipStatusDTO.ACTIVE_STATUS:
      return "ACTIVE_STATUS";
    case CorporateMembershipStatusDTO.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ListOrganisationsRequest {
  statusWrapper: OptionalCorporateMembershipStatusDTO | undefined;
}

export interface ListOrganisationsResponse {
  data: OrganisationDTO[];
}

export interface GetOrganisationRequest {
  organisationId: string;
}

export interface GetOrganisationResponse {
  organisation: OrganisationDTO | undefined;
}

export interface GetOrganisationTypeRequest {
  organisationId: string;
  locationId: string;
}

export interface GetOrganisationTypeResponse {
  type: UserType;
}

export interface UpdateOrgCorporateMembershipStatusReq {
  organisationId: string;
  corporateMembershipStatus: CorporateMembershipStatusDTO;
}

export interface UpdateOrgCorporateMembershipStatusRes {
}

export interface OrganisationDTO {
  id: string;
  rolloverSubscriptionType: RolloverSubscriptionTypeDTO;
  subscriptionStatus: SubscriptionStatusDTO;
  corporateMembershipStatus: CorporateMembershipStatusDTO;
  name: string;
}

export interface OptionalCorporateMembershipStatusDTO {
  status: CorporateMembershipStatusDTO;
}

function createBaseListOrganisationsRequest(): ListOrganisationsRequest {
  return { statusWrapper: undefined };
}

export const ListOrganisationsRequest = {
  encode(message: ListOrganisationsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusWrapper !== undefined) {
      OptionalCorporateMembershipStatusDTO.encode(message.statusWrapper, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOrganisationsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOrganisationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.statusWrapper = OptionalCorporateMembershipStatusDTO.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOrganisationsRequest {
    return {
      statusWrapper: isSet(object.statusWrapper)
        ? OptionalCorporateMembershipStatusDTO.fromJSON(object.statusWrapper)
        : undefined,
    };
  },

  toJSON(message: ListOrganisationsRequest): unknown {
    const obj: any = {};
    if (message.statusWrapper !== undefined) {
      obj.statusWrapper = OptionalCorporateMembershipStatusDTO.toJSON(message.statusWrapper);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListOrganisationsRequest>, I>>(base?: I): ListOrganisationsRequest {
    return ListOrganisationsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListOrganisationsRequest>, I>>(object: I): ListOrganisationsRequest {
    const message = createBaseListOrganisationsRequest();
    message.statusWrapper = (object.statusWrapper !== undefined && object.statusWrapper !== null)
      ? OptionalCorporateMembershipStatusDTO.fromPartial(object.statusWrapper)
      : undefined;
    return message;
  },
};

function createBaseListOrganisationsResponse(): ListOrganisationsResponse {
  return { data: [] };
}

export const ListOrganisationsResponse = {
  encode(message: ListOrganisationsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.data) {
      OrganisationDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOrganisationsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOrganisationsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(OrganisationDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOrganisationsResponse {
    return {
      data: globalThis.Array.isArray(object?.data) ? object.data.map((e: any) => OrganisationDTO.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListOrganisationsResponse): unknown {
    const obj: any = {};
    if (message.data?.length) {
      obj.data = message.data.map((e) => OrganisationDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListOrganisationsResponse>, I>>(base?: I): ListOrganisationsResponse {
    return ListOrganisationsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListOrganisationsResponse>, I>>(object: I): ListOrganisationsResponse {
    const message = createBaseListOrganisationsResponse();
    message.data = object.data?.map((e) => OrganisationDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetOrganisationRequest(): GetOrganisationRequest {
  return { organisationId: "" };
}

export const GetOrganisationRequest = {
  encode(message: GetOrganisationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organisationId !== "") {
      writer.uint32(10).string(message.organisationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrganisationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrganisationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organisationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrganisationRequest {
    return { organisationId: isSet(object.organisationId) ? globalThis.String(object.organisationId) : "" };
  },

  toJSON(message: GetOrganisationRequest): unknown {
    const obj: any = {};
    if (message.organisationId !== "") {
      obj.organisationId = message.organisationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrganisationRequest>, I>>(base?: I): GetOrganisationRequest {
    return GetOrganisationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOrganisationRequest>, I>>(object: I): GetOrganisationRequest {
    const message = createBaseGetOrganisationRequest();
    message.organisationId = object.organisationId ?? "";
    return message;
  },
};

function createBaseGetOrganisationResponse(): GetOrganisationResponse {
  return { organisation: undefined };
}

export const GetOrganisationResponse = {
  encode(message: GetOrganisationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organisation !== undefined) {
      OrganisationDTO.encode(message.organisation, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrganisationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrganisationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organisation = OrganisationDTO.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrganisationResponse {
    return { organisation: isSet(object.organisation) ? OrganisationDTO.fromJSON(object.organisation) : undefined };
  },

  toJSON(message: GetOrganisationResponse): unknown {
    const obj: any = {};
    if (message.organisation !== undefined) {
      obj.organisation = OrganisationDTO.toJSON(message.organisation);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrganisationResponse>, I>>(base?: I): GetOrganisationResponse {
    return GetOrganisationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOrganisationResponse>, I>>(object: I): GetOrganisationResponse {
    const message = createBaseGetOrganisationResponse();
    message.organisation = (object.organisation !== undefined && object.organisation !== null)
      ? OrganisationDTO.fromPartial(object.organisation)
      : undefined;
    return message;
  },
};

function createBaseGetOrganisationTypeRequest(): GetOrganisationTypeRequest {
  return { organisationId: "", locationId: "" };
}

export const GetOrganisationTypeRequest = {
  encode(message: GetOrganisationTypeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organisationId !== "") {
      writer.uint32(10).string(message.organisationId);
    }
    if (message.locationId !== "") {
      writer.uint32(18).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrganisationTypeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrganisationTypeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organisationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrganisationTypeRequest {
    return {
      organisationId: isSet(object.organisationId) ? globalThis.String(object.organisationId) : "",
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
    };
  },

  toJSON(message: GetOrganisationTypeRequest): unknown {
    const obj: any = {};
    if (message.organisationId !== "") {
      obj.organisationId = message.organisationId;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrganisationTypeRequest>, I>>(base?: I): GetOrganisationTypeRequest {
    return GetOrganisationTypeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOrganisationTypeRequest>, I>>(object: I): GetOrganisationTypeRequest {
    const message = createBaseGetOrganisationTypeRequest();
    message.organisationId = object.organisationId ?? "";
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseGetOrganisationTypeResponse(): GetOrganisationTypeResponse {
  return { type: 0 };
}

export const GetOrganisationTypeResponse = {
  encode(message: GetOrganisationTypeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrganisationTypeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrganisationTypeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrganisationTypeResponse {
    return { type: isSet(object.type) ? userTypeFromJSON(object.type) : 0 };
  },

  toJSON(message: GetOrganisationTypeResponse): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = userTypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrganisationTypeResponse>, I>>(base?: I): GetOrganisationTypeResponse {
    return GetOrganisationTypeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetOrganisationTypeResponse>, I>>(object: I): GetOrganisationTypeResponse {
    const message = createBaseGetOrganisationTypeResponse();
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseUpdateOrgCorporateMembershipStatusReq(): UpdateOrgCorporateMembershipStatusReq {
  return { organisationId: "", corporateMembershipStatus: 0 };
}

export const UpdateOrgCorporateMembershipStatusReq = {
  encode(message: UpdateOrgCorporateMembershipStatusReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organisationId !== "") {
      writer.uint32(10).string(message.organisationId);
    }
    if (message.corporateMembershipStatus !== 0) {
      writer.uint32(16).int32(message.corporateMembershipStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOrgCorporateMembershipStatusReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOrgCorporateMembershipStatusReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organisationId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.corporateMembershipStatus = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateOrgCorporateMembershipStatusReq {
    return {
      organisationId: isSet(object.organisationId) ? globalThis.String(object.organisationId) : "",
      corporateMembershipStatus: isSet(object.corporateMembershipStatus)
        ? corporateMembershipStatusDTOFromJSON(object.corporateMembershipStatus)
        : 0,
    };
  },

  toJSON(message: UpdateOrgCorporateMembershipStatusReq): unknown {
    const obj: any = {};
    if (message.organisationId !== "") {
      obj.organisationId = message.organisationId;
    }
    if (message.corporateMembershipStatus !== 0) {
      obj.corporateMembershipStatus = corporateMembershipStatusDTOToJSON(message.corporateMembershipStatus);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateOrgCorporateMembershipStatusReq>, I>>(
    base?: I,
  ): UpdateOrgCorporateMembershipStatusReq {
    return UpdateOrgCorporateMembershipStatusReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateOrgCorporateMembershipStatusReq>, I>>(
    object: I,
  ): UpdateOrgCorporateMembershipStatusReq {
    const message = createBaseUpdateOrgCorporateMembershipStatusReq();
    message.organisationId = object.organisationId ?? "";
    message.corporateMembershipStatus = object.corporateMembershipStatus ?? 0;
    return message;
  },
};

function createBaseUpdateOrgCorporateMembershipStatusRes(): UpdateOrgCorporateMembershipStatusRes {
  return {};
}

export const UpdateOrgCorporateMembershipStatusRes = {
  encode(_: UpdateOrgCorporateMembershipStatusRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOrgCorporateMembershipStatusRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOrgCorporateMembershipStatusRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateOrgCorporateMembershipStatusRes {
    return {};
  },

  toJSON(_: UpdateOrgCorporateMembershipStatusRes): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateOrgCorporateMembershipStatusRes>, I>>(
    base?: I,
  ): UpdateOrgCorporateMembershipStatusRes {
    return UpdateOrgCorporateMembershipStatusRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateOrgCorporateMembershipStatusRes>, I>>(
    _: I,
  ): UpdateOrgCorporateMembershipStatusRes {
    const message = createBaseUpdateOrgCorporateMembershipStatusRes();
    return message;
  },
};

function createBaseOrganisationDTO(): OrganisationDTO {
  return { id: "", rolloverSubscriptionType: 0, subscriptionStatus: 0, corporateMembershipStatus: 0, name: "" };
}

export const OrganisationDTO = {
  encode(message: OrganisationDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.rolloverSubscriptionType !== 0) {
      writer.uint32(16).int32(message.rolloverSubscriptionType);
    }
    if (message.subscriptionStatus !== 0) {
      writer.uint32(24).int32(message.subscriptionStatus);
    }
    if (message.corporateMembershipStatus !== 0) {
      writer.uint32(32).int32(message.corporateMembershipStatus);
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganisationDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganisationDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rolloverSubscriptionType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.subscriptionStatus = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.corporateMembershipStatus = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrganisationDTO {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      rolloverSubscriptionType: isSet(object.rolloverSubscriptionType)
        ? rolloverSubscriptionTypeDTOFromJSON(object.rolloverSubscriptionType)
        : 0,
      subscriptionStatus: isSet(object.subscriptionStatus)
        ? subscriptionStatusDTOFromJSON(object.subscriptionStatus)
        : 0,
      corporateMembershipStatus: isSet(object.corporateMembershipStatus)
        ? corporateMembershipStatusDTOFromJSON(object.corporateMembershipStatus)
        : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: OrganisationDTO): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.rolloverSubscriptionType !== 0) {
      obj.rolloverSubscriptionType = rolloverSubscriptionTypeDTOToJSON(message.rolloverSubscriptionType);
    }
    if (message.subscriptionStatus !== 0) {
      obj.subscriptionStatus = subscriptionStatusDTOToJSON(message.subscriptionStatus);
    }
    if (message.corporateMembershipStatus !== 0) {
      obj.corporateMembershipStatus = corporateMembershipStatusDTOToJSON(message.corporateMembershipStatus);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrganisationDTO>, I>>(base?: I): OrganisationDTO {
    return OrganisationDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrganisationDTO>, I>>(object: I): OrganisationDTO {
    const message = createBaseOrganisationDTO();
    message.id = object.id ?? "";
    message.rolloverSubscriptionType = object.rolloverSubscriptionType ?? 0;
    message.subscriptionStatus = object.subscriptionStatus ?? 0;
    message.corporateMembershipStatus = object.corporateMembershipStatus ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseOptionalCorporateMembershipStatusDTO(): OptionalCorporateMembershipStatusDTO {
  return { status: 0 };
}

export const OptionalCorporateMembershipStatusDTO = {
  encode(message: OptionalCorporateMembershipStatusDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OptionalCorporateMembershipStatusDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptionalCorporateMembershipStatusDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OptionalCorporateMembershipStatusDTO {
    return { status: isSet(object.status) ? corporateMembershipStatusDTOFromJSON(object.status) : 0 };
  },

  toJSON(message: OptionalCorporateMembershipStatusDTO): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = corporateMembershipStatusDTOToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OptionalCorporateMembershipStatusDTO>, I>>(
    base?: I,
  ): OptionalCorporateMembershipStatusDTO {
    return OptionalCorporateMembershipStatusDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OptionalCorporateMembershipStatusDTO>, I>>(
    object: I,
  ): OptionalCorporateMembershipStatusDTO {
    const message = createBaseOptionalCorporateMembershipStatusDTO();
    message.status = object.status ?? 0;
    return message;
  },
};

export interface OrganisationService {
  ListOrganisations(
    request: DeepPartial<ListOrganisationsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListOrganisationsResponse>;
  GetOrganisation(
    request: DeepPartial<GetOrganisationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetOrganisationResponse>;
  /** Get type of the organisation */
  GetOrganisationType(
    request: DeepPartial<GetOrganisationTypeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetOrganisationTypeResponse>;
  UpdateOrgCorporateMembershipStatus(
    request: DeepPartial<UpdateOrgCorporateMembershipStatusReq>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateOrgCorporateMembershipStatusRes>;
}

export class OrganisationServiceClientImpl implements OrganisationService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListOrganisations = this.ListOrganisations.bind(this);
    this.GetOrganisation = this.GetOrganisation.bind(this);
    this.GetOrganisationType = this.GetOrganisationType.bind(this);
    this.UpdateOrgCorporateMembershipStatus = this.UpdateOrgCorporateMembershipStatus.bind(this);
  }

  ListOrganisations(
    request: DeepPartial<ListOrganisationsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListOrganisationsResponse> {
    return this.rpc.unary(
      OrganisationServiceListOrganisationsDesc,
      ListOrganisationsRequest.fromPartial(request),
      metadata,
    );
  }

  GetOrganisation(
    request: DeepPartial<GetOrganisationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetOrganisationResponse> {
    return this.rpc.unary(
      OrganisationServiceGetOrganisationDesc,
      GetOrganisationRequest.fromPartial(request),
      metadata,
    );
  }

  GetOrganisationType(
    request: DeepPartial<GetOrganisationTypeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetOrganisationTypeResponse> {
    return this.rpc.unary(
      OrganisationServiceGetOrganisationTypeDesc,
      GetOrganisationTypeRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateOrgCorporateMembershipStatus(
    request: DeepPartial<UpdateOrgCorporateMembershipStatusReq>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateOrgCorporateMembershipStatusRes> {
    return this.rpc.unary(
      OrganisationServiceUpdateOrgCorporateMembershipStatusDesc,
      UpdateOrgCorporateMembershipStatusReq.fromPartial(request),
      metadata,
    );
  }
}

export const OrganisationServiceDesc = { serviceName: "proto.OrganisationService" };

export const OrganisationServiceListOrganisationsDesc: UnaryMethodDefinitionish = {
  methodName: "ListOrganisations",
  service: OrganisationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListOrganisationsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListOrganisationsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrganisationServiceGetOrganisationDesc: UnaryMethodDefinitionish = {
  methodName: "GetOrganisation",
  service: OrganisationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetOrganisationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetOrganisationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrganisationServiceGetOrganisationTypeDesc: UnaryMethodDefinitionish = {
  methodName: "GetOrganisationType",
  service: OrganisationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetOrganisationTypeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetOrganisationTypeResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrganisationServiceUpdateOrgCorporateMembershipStatusDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateOrgCorporateMembershipStatus",
  service: OrganisationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateOrgCorporateMembershipStatusReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateOrgCorporateMembershipStatusRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
