/* eslint-disable @typescript-eslint/no-explicit-any */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as grpcWeb from "grpc-web";
import { nanoid } from "nanoid";

export interface IMiddleware {
  pre: (
    traceId: string,
    methodDesc: any,
    request: any,
    metadata: BrowserHeaders | undefined
  ) => void;
  post: (traceId: string, methodDesc: any, res: any) => any;
  onError: (traceId: string, methodDesc: any, error: grpcWeb.RpcError) => void;
}

export class GrpcInterceptor implements Rpc {
  constructor(
    private impl: Rpc,
    private middleware: IMiddleware[]
  ) {}

  async unary<T extends UnaryMethodDefinitionishR>(
    methodDesc: T,
    request: any,
    metadata: BrowserHeaders | undefined
  ): Promise<any> {
    const id = nanoid();
    try {
      this.middleware.forEach((m) => {
        m.pre && m.pre(id, methodDesc, request, metadata);
      });

      const res = await this.impl.unary(methodDesc, request, metadata);

      this.middleware.forEach((m) => {
        m.post && m.post(id, methodDesc, res);
      });
      return res;
    } catch (error) {
      this.middleware.forEach((m) => {
        m.onError && m.onError(id, methodDesc, error as grpcWeb.RpcError);
      });
      throw error;
    }
  }
}

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: BrowserHeaders | undefined
  ): Promise<any>;
}
