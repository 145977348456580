/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: accounts_table.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var account_items_pb = require('./account_items_pb.js')

var offerings_pb = require('./offerings_pb.js')

var money_pb = require('./money_pb.js')
const proto = {};
proto.proto = require('./accounts_table_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.AccountTableServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.AccountTableServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetAccountTableRequest,
 *   !proto.proto.ListAccountTableResponse>}
 */
const methodDescriptor_AccountTableService_GetBookings = new grpc.web.MethodDescriptor(
  '/proto.AccountTableService/GetBookings',
  grpc.web.MethodType.UNARY,
  proto.proto.GetAccountTableRequest,
  proto.proto.ListAccountTableResponse,
  /**
   * @param {!proto.proto.GetAccountTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListAccountTableResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetAccountTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListAccountTableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListAccountTableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AccountTableServiceClient.prototype.getBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AccountTableService/GetBookings',
      request,
      metadata || {},
      methodDescriptor_AccountTableService_GetBookings,
      callback);
};


/**
 * @param {!proto.proto.GetAccountTableRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListAccountTableResponse>}
 *     Promise that resolves to the response
 */
proto.proto.AccountTableServicePromiseClient.prototype.getBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AccountTableService/GetBookings',
      request,
      metadata || {},
      methodDescriptor_AccountTableService_GetBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetAccountTableRequest,
 *   !proto.proto.ListAccountTableResponse>}
 */
const methodDescriptor_AccountTableService_GetLyfePaymentsAndBookings = new grpc.web.MethodDescriptor(
  '/proto.AccountTableService/GetLyfePaymentsAndBookings',
  grpc.web.MethodType.UNARY,
  proto.proto.GetAccountTableRequest,
  proto.proto.ListAccountTableResponse,
  /**
   * @param {!proto.proto.GetAccountTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListAccountTableResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetAccountTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListAccountTableResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListAccountTableResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AccountTableServiceClient.prototype.getLyfePaymentsAndBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AccountTableService/GetLyfePaymentsAndBookings',
      request,
      metadata || {},
      methodDescriptor_AccountTableService_GetLyfePaymentsAndBookings,
      callback);
};


/**
 * @param {!proto.proto.GetAccountTableRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListAccountTableResponse>}
 *     Promise that resolves to the response
 */
proto.proto.AccountTableServicePromiseClient.prototype.getLyfePaymentsAndBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AccountTableService/GetLyfePaymentsAndBookings',
      request,
      metadata || {},
      methodDescriptor_AccountTableService_GetLyfePaymentsAndBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetFiltersTableRequest,
 *   !proto.proto.FiltersAccountTable>}
 */
const methodDescriptor_AccountTableService_ListFilters = new grpc.web.MethodDescriptor(
  '/proto.AccountTableService/ListFilters',
  grpc.web.MethodType.UNARY,
  proto.proto.GetFiltersTableRequest,
  proto.proto.FiltersAccountTable,
  /**
   * @param {!proto.proto.GetFiltersTableRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.FiltersAccountTable.deserializeBinary
);


/**
 * @param {!proto.proto.GetFiltersTableRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.FiltersAccountTable)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.FiltersAccountTable>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.AccountTableServiceClient.prototype.listFilters =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.AccountTableService/ListFilters',
      request,
      metadata || {},
      methodDescriptor_AccountTableService_ListFilters,
      callback);
};


/**
 * @param {!proto.proto.GetFiltersTableRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.FiltersAccountTable>}
 *     Promise that resolves to the response
 */
proto.proto.AccountTableServicePromiseClient.prototype.listFilters =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.AccountTableService/ListFilters',
      request,
      metadata || {},
      methodDescriptor_AccountTableService_ListFilters);
};


module.exports = proto.proto;

