import { metadata, rpc } from "../grpc/grpc-legacy";
import { AffiliateCodeRequest } from "sdk/dist/affiliate_code_pb";
import { grpcResponseHandler } from "../util/grpcResponseHandler";

class AffiliateCodeService {
  @grpcResponseHandler()
  async checkAffiliateCode(code: string) {
    const reqAffiliate = new AffiliateCodeRequest();
    reqAffiliate.setAffiliateCode(code.trim());
    const resAffiliate = await rpc.affiliateService.get(reqAffiliate, metadata());
    return resAffiliate.toObject().exists;
  }
}

export const sharedAffiliateCodeService = new AffiliateCodeService();
