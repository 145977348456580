import { ThirdPartyInvoice } from "sdk/dist/third_party_invoices_pb";
import { Money } from "sdk/dist/money_pb";
import money_legacy from "./money_legacy";
import { ISOCurrencyName } from "sdk/dist/currencies_pb";

export function tpiStatusToText(status: ThirdPartyInvoice.Status): string {
  switch (status) {
    case ThirdPartyInvoice.Status.WAITING:
      return "Waiting";
    case ThirdPartyInvoice.Status.SENT:
      return "Sent";
    default:
      return "Unknown";
  }
}

export function tpiTotalCharge(tpi: ThirdPartyInvoice.AsObject): Money.AsObject {
  const currencyCode =
    tpi.details?.lineItemsList &&
    tpi.details.lineItemsList.length > 0 &&
    tpi.details.lineItemsList[0].charge?.amount?.currencyCode
      ? tpi.details.lineItemsList[0].charge?.amount?.currencyCode
      : ISOCurrencyName.XXX;
  // add up all the charges for the items in the invoice
  return money_legacy.add(
    currencyCode,
    ...tpi.details!.lineItemsList.map((item) =>
      money_legacy.add(currencyCode, item.charge!.amount!, item.charge!.tax!)
    )
  );
}
