import { EntityStore } from "./entity-store";
import {
  BookingDTO,
  GetBookingRequest,
  ListBookingByLocationRequest,
  CreateBookingRequest,
  CreateBulkBookingRequest
} from "sdk/dist/bookings_pb";
import { rpc, metadata } from "../grpc/grpc-legacy";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { toastStore } from "./toast-store";
import { timezone } from "../util/timestamp";
class BookingStore extends EntityStore<BookingDTO.AsObject> {
  async load(bookingId: string) {
    const req = new GetBookingRequest();
    req.setId(bookingId);
    try {
      const res = await rpc.bookings.get(req, metadata());
      this.add(res.toObject());
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  async loadByLocation(locationId: string, startDate?: Timestamp, endDate?: Timestamp) {
    const req = new ListBookingByLocationRequest();
    req.setLocationId(locationId);
    req.setBookingStartDate(startDate);
    req.setBookingEndDate(endDate);
    req.setTimezone(timezone());
    try {
      const res = await rpc.bookings.listByLocation(req, metadata());
      res.toObject().bookingsList.map((booking) => this.add(booking));
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  async Create(
    locationId: string,
    maxClients: number,
    providerId: string,
    frequency: CreateBulkBookingRequest.BookingsFrequency,
    daysList: Array<CreateBulkBookingRequest.DaysOfTheWeek>,
    repetitions: number,
    startDatetime: Date,
    endDatetime: Date,
    repetitionEndDate: Date,
    service: string,
    type: BookingDTO.Type
  ) {
    try {
      const req = new CreateBookingRequest();

      const startTimestamp = new Timestamp();
      startTimestamp.setSeconds(new Date(startDatetime.toISOString()).getTime() / 1000);

      const endTimestamp = new Timestamp();
      endTimestamp.setSeconds(new Date(endDatetime.toISOString()).getTime() / 1000);

      req.setLocationId(locationId);
      req.setProviderId(providerId);
      req.setMaxClients(maxClients);

      req.setEndDatetime(endTimestamp);
      req.setStartDatetime(startTimestamp);
      req.setOfferingId(service);
      req.setType(type);
      req.setTimezone(timezone());

      if (frequency != 0) {
        let repititionTimestamp = new Timestamp();
        repititionTimestamp.setSeconds(new Date(repetitionEndDate).getTime() / 1000);

        const bulkReq = new CreateBulkBookingRequest();
        bulkReq.setRepetitionEndDate(repititionTimestamp);
        bulkReq.setFrequency(frequency);
        bulkReq.setDaysList(frequency == 0 ? [] : daysList);
        bulkReq.setBooking(req);
        bulkReq.setRepetitions(repetitions);
        const res = await rpc.bookings.createBulk(bulkReq, metadata());
        let bookingsList = res.toObject().bookingsList;
        for (var index in bookingsList) {
          this.add(bookingsList[index]);
        }
      } else {
        const res = await rpc.bookings.create(req, metadata());
        this.add(res.toObject());
      }
    } catch (err) {
      toastStore.grpcError(err);
    }
  }
}

export const bookingStore = new BookingStore();
