import { rpc, metadata } from "../grpc/grpc-legacy";
import { FiltersAccountTable, GetFiltersTableRequest } from "sdk/dist/accounts_table_pb";
import { observable, action } from "mobx";
import { AccountItem } from "sdk/dist/account_items_pb";
import { Filters } from "react-table";
import { accTableStore } from "./acc_table-store";

class FiltersAccTableStore {
  @observable
  private filters = new FiltersAccountTable().toObject();

  @observable
  private selectedFilters: Filters<AccountItem.AsObject> = [];

  @observable
  private organisationId: string = "";

  @observable
  private locationId: string = "";

  async loadFilters(getAccItemType: AccountItem.Type) {
    const req = new GetFiltersTableRequest();
    req.setGetAccItemType(getAccItemType);
    const res = await rpc.accountTable.listFilters(req, metadata());
    this.filters = res.toObject();
  }

  getAll() {
    return this.filters;
  }

  @action
  setSelectedFilters(filters: Filters<AccountItem.AsObject>) {
    this.selectedFilters = filters;
  }

  getSelectedFilters() {
    return this.selectedFilters;
  }

  @action
  resetTableFilters() {
    this.selectedFilters = [];
  }

  getLocationId() {
    return this.locationId;
  }

  @action
  setLocationId(locationId: string) {
    this.locationId = locationId;
  }

  getOrgId() {
    return this.organisationId;
  }

  @action
  setOrgId(orgId: string) {
    this.organisationId = orgId;
  }
}

export const accTableFilters = new FiltersAccTableStore();
