import { metadata, rpc } from "../grpc/grpc-legacy";
import { CreateMediaRequest } from "sdk/dist/media_pb";
import { readFileBytes } from "../util/files";
import { grpcResponseHandler } from "../util/grpcResponseHandler";

class MediaService {
  @grpcResponseHandler()
  async upload(file: File) {
    const req = new CreateMediaRequest();
    req.setFile(await readFileBytes(file));
    req.setFilename(file.name);
    req.setMime(file.type);

    const media = await rpc.media.create(req, metadata());
    return media.toObject();
  }
}

export const sharedMediaService = new MediaService();
