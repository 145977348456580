import React, { Component } from "react";
import styled from "@emotion/styled";
import { jsx, css } from "@emotion/core";
import DateButton from "./../../../components/routes/InsightDashboard/DateButton";
import DateRangerFilter from "./../../../components/routes/InsightDashboard/DateRangeFilter";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { GetInisghtFilterOptionsRequest } from "sdk/dist/insight_filter_options_pb";
import { ButtonCSS } from "./../../../components/routes/InsightDashboard/DateButton";
import { TextFilter } from "./../../../components/routes/InsightDashboard/TextFilter";
import { Option } from "sdk/dist/insight_filter_options_pb";
import { IFilter } from "./../../../components/routes/InsightDashboard/CustomTypes";
import { rpc, metadata } from "../../../grpc/grpc-legacy";

const buttons = [
  { text: "Today", daysDiff: 0 },
  { text: "Last 7 days", daysDiff: 7 },
  { text: "Last 14 days", daysDiff: 14 },
  { text: "Last 30 days", daysDiff: 30 }
];

interface IProps {
  selectedTab: string;
  organisationID: string;
  iFilter: IFilter;
  updateFilter(newFilter: Partial<IFilter>): void;
  updateStartDateFilter(startDate: Date): void;
  updateEndDateFilter(endDate: Date): void;
  getInsightData(): void;
}

@observer
class FilterForm extends Component<IProps> {
  @observable
  selectedDateButton: number = 0;
  @observable
  locationOptions: Array<Option> = [];
  @observable
  stateOptions: Array<Option> = [];
  @observable
  serviceOptions: Array<Option> = [];
  @observable
  providerOptions: Array<Option> = [];
  @observable
  tagOptions: Array<Option> = [];

  async getOptions() {
    const orgID = this.props.organisationID;
    const req = new GetInisghtFilterOptionsRequest();
    req.setOrgid(orgID);
    const res = await rpc.insightFilterOptions.get(req, metadata());
    this.locationOptions = res.getLocationsList();
    this.serviceOptions = res.getServicesList();
    this.stateOptions = res.getStatesList();
    this.providerOptions = res.getProvidersList();
    this.tagOptions = res.getTagsList();
  }

  componentDidMount() {
    this.getOptions();
    this.props.getInsightData();
  }
  updateClickedDateButton(buttonIndex: number) {
    this.selectedDateButton = buttonIndex;
  }

  render() {
    const { updateFilter, updateStartDateFilter, updateEndDateFilter, iFilter, getInsightData } =
      this.props;
    return (
      <div>
        <OneHalfDiv
          css={css`
            margin-left: 0px;
            position: relative;
          `}
        >
          <label css={labelCss} style={{ position: "absolute" }}>
            {" "}
            Viewing date range{" "}
          </label>
          <label css={labelCss}> &nbsp; </label>
          <div
            css={css`
              position: relative;
              display: flex;
            `}
          >
            <DateRangerFilter
              dateValue={iFilter.dateStart}
              onUpdateFilter={(selectedDate) => updateStartDateFilter(selectedDate)}
            ></DateRangerFilter>
            <DateRangerFilter
              dateValue={iFilter.dateEnd}
              onUpdateFilter={(selectedDate) => updateEndDateFilter(selectedDate)}
            ></DateRangerFilter>
          </div>
        </OneHalfDiv>

        <OneHalfDiv>
          <label css={labelCss}> View specific date range </label>
          {/* use the index of an static array as the key temporarily */}
          {buttons.map((i, index) => (
            <DateButton
              buttonText={i.text}
              key={index}
              onUpdateFilter={(newFilter) => updateFilter(newFilter)}
              dateDiff={i.daysDiff}
              onUpdateClickedButton={(buttonIndex) => this.updateClickedDateButton(buttonIndex)}
              buttonIndex={index}
              isSelect={this.selectedDateButton === index ? true : false}
            ></DateButton>
          ))}
        </OneHalfDiv>

        <OneQuarterDiv
          css={css`
            margin-left: 0px;
          `}
        >
          <TextFilter
            label="Location"
            options={this.locationOptions}
            onUpdateFilter={(newFilter) => {
              updateFilter(newFilter);
            }}
          ></TextFilter>
        </OneQuarterDiv>
        <OneQuarterDiv>
          <TextFilter
            label="Service"
            options={this.serviceOptions}
            onUpdateFilter={(newFilter) => {
              updateFilter(newFilter);
            }}
          ></TextFilter>
        </OneQuarterDiv>
        <OneQuarterDiv>
          <TextFilter
            label="Provider"
            options={this.providerOptions}
            onUpdateFilter={(newFilter) => {
              updateFilter(newFilter);
            }}
          ></TextFilter>
        </OneQuarterDiv>
        <OneQuarterDiv>
          <TextFilter
            label="Tag"
            options={this.tagOptions}
            onUpdateFilter={(newFilter) => {
              updateFilter(newFilter);
            }}
          ></TextFilter>
        </OneQuarterDiv>

        <OneQuarterDiv
          css={css`
            margin-left: 0px;
          `}
        >
          <button
            css={ButtonCSS}
            id={"insight_search_button"}
            onClick={(e: any) => {
              e.preventDefault();
              getInsightData();
            }}
          >
            Search{" "}
          </button>
        </OneQuarterDiv>
      </div>
    );
  }
}

export default FilterForm;

const OneHalfDiv = styled.div`
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
  margin-bottom: 20px;
  @media (max-width: 30em) {
    margin-bottom: 0px;
    width: 100%;
    margin-left: 0px;
    margin-top: 1rem;
  }
`;
const OneQuarterDiv = styled.div`
    width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
    margin-bottom: 20px;
    @media (max-width: 30em) {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
}
`;

const OneFifthDiv = styled.div`
  width: calc(20% - 24px);
  float: left;
  margin-left: 20px;
  margin-bottom: 20px;
  @media (max-width: 30em) {
    width: 100%;
    margin-left: 0px;
  }
`;

const labelCss = css`
  display: block;
  font-weight: 700;
  margin-bottom: 8px;
`;
