/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";
import { LocationCorporateMembershipSetting } from "./location_corporate_membership_setting";
import { Media } from "./media";
import { Money } from "./money";

export const protobufPackage = "proto";

export interface Coordinates {
  latitude: string;
  longitude: string;
}

export interface LocationSchedule {
  day: string;
  opensAt: string;
  closesAt: string;
}

export interface Search {
  /** offering id */
  id: string;
  /** taxonomy value in DB */
  categoryName: string;
  /** same as service or product name */
  offeringName: string;
  offeringDurationMins: number;
  offeringImage:
    | Media
    | undefined;
  /** distance in kms from location passed in coord to location address */
  distanceKms: string;
  cost: Money | undefined;
  providerId: string;
  providerName: string;
  orgName: string;
  orgBanner: Media | undefined;
  coordinates: Coordinates | undefined;
  country: string;
  place: string;
  state: string;
  postCode: string;
  locId: string;
  locName: string;
  locSchedule: LocationSchedule[];
  bookingId: string;
  bookingStatus: string;
  bookingType: string;
  bookingStartDatetime: Date | undefined;
  bookingEndDatetime: Date | undefined;
  offeringLevel: string;
  locationCorporateMembershipSetting: LocationCorporateMembershipSetting | undefined;
}

export interface SearchQueryRequest {
  /** Can search org name, off name, org name and provider name */
  query: string;
  /** Postcode number or place (suburb/city) or state is accepted */
  place: string;
  level: SearchQueryRequest_CategoryLevel;
  duration: SearchQueryRequest_DurationService;
  minCostPerService: number;
  maxCostPerService: number;
  queryDate: Date | undefined;
  coordinates: Coordinates | undefined;
  keyword: string[];
  serviceType: SearchQueryRequest_ServiceType;
}

export enum SearchQueryRequest_CategoryLevel {
  NONE = 0,
  BEGINNER = 1,
  INTERMEDIATE = 2,
  ADVANCED = 3,
  UNRECOGNIZED = -1,
}

export function searchQueryRequest_CategoryLevelFromJSON(object: any): SearchQueryRequest_CategoryLevel {
  switch (object) {
    case 0:
    case "NONE":
      return SearchQueryRequest_CategoryLevel.NONE;
    case 1:
    case "BEGINNER":
      return SearchQueryRequest_CategoryLevel.BEGINNER;
    case 2:
    case "INTERMEDIATE":
      return SearchQueryRequest_CategoryLevel.INTERMEDIATE;
    case 3:
    case "ADVANCED":
      return SearchQueryRequest_CategoryLevel.ADVANCED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SearchQueryRequest_CategoryLevel.UNRECOGNIZED;
  }
}

export function searchQueryRequest_CategoryLevelToJSON(object: SearchQueryRequest_CategoryLevel): string {
  switch (object) {
    case SearchQueryRequest_CategoryLevel.NONE:
      return "NONE";
    case SearchQueryRequest_CategoryLevel.BEGINNER:
      return "BEGINNER";
    case SearchQueryRequest_CategoryLevel.INTERMEDIATE:
      return "INTERMEDIATE";
    case SearchQueryRequest_CategoryLevel.ADVANCED:
      return "ADVANCED";
    case SearchQueryRequest_CategoryLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SearchQueryRequest_DurationService {
  ALL = 0,
  UNDER_30_MIN = 1,
  BETWEEN_30_TO_60_MIN = 2,
  MORE_THAN_60_MIN = 3,
  UNRECOGNIZED = -1,
}

export function searchQueryRequest_DurationServiceFromJSON(object: any): SearchQueryRequest_DurationService {
  switch (object) {
    case 0:
    case "ALL":
      return SearchQueryRequest_DurationService.ALL;
    case 1:
    case "UNDER_30_MIN":
      return SearchQueryRequest_DurationService.UNDER_30_MIN;
    case 2:
    case "BETWEEN_30_TO_60_MIN":
      return SearchQueryRequest_DurationService.BETWEEN_30_TO_60_MIN;
    case 3:
    case "MORE_THAN_60_MIN":
      return SearchQueryRequest_DurationService.MORE_THAN_60_MIN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SearchQueryRequest_DurationService.UNRECOGNIZED;
  }
}

export function searchQueryRequest_DurationServiceToJSON(object: SearchQueryRequest_DurationService): string {
  switch (object) {
    case SearchQueryRequest_DurationService.ALL:
      return "ALL";
    case SearchQueryRequest_DurationService.UNDER_30_MIN:
      return "UNDER_30_MIN";
    case SearchQueryRequest_DurationService.BETWEEN_30_TO_60_MIN:
      return "BETWEEN_30_TO_60_MIN";
    case SearchQueryRequest_DurationService.MORE_THAN_60_MIN:
      return "MORE_THAN_60_MIN";
    case SearchQueryRequest_DurationService.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SearchQueryRequest_ServiceType {
  ALL_SERVICES = 0,
  PUBLIC = 1,
  CORPORATE = 2,
  UNRECOGNIZED = -1,
}

export function searchQueryRequest_ServiceTypeFromJSON(object: any): SearchQueryRequest_ServiceType {
  switch (object) {
    case 0:
    case "ALL_SERVICES":
      return SearchQueryRequest_ServiceType.ALL_SERVICES;
    case 1:
    case "PUBLIC":
      return SearchQueryRequest_ServiceType.PUBLIC;
    case 2:
    case "CORPORATE":
      return SearchQueryRequest_ServiceType.CORPORATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SearchQueryRequest_ServiceType.UNRECOGNIZED;
  }
}

export function searchQueryRequest_ServiceTypeToJSON(object: SearchQueryRequest_ServiceType): string {
  switch (object) {
    case SearchQueryRequest_ServiceType.ALL_SERVICES:
      return "ALL_SERVICES";
    case SearchQueryRequest_ServiceType.PUBLIC:
      return "PUBLIC";
    case SearchQueryRequest_ServiceType.CORPORATE:
      return "CORPORATE";
    case SearchQueryRequest_ServiceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SearchQueryResponse {
  searchResult: Search[];
}

function createBaseCoordinates(): Coordinates {
  return { latitude: "", longitude: "" };
}

export const Coordinates = {
  encode(message: Coordinates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.latitude !== "") {
      writer.uint32(10).string(message.latitude);
    }
    if (message.longitude !== "") {
      writer.uint32(18).string(message.longitude);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Coordinates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoordinates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.latitude = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.longitude = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Coordinates {
    return {
      latitude: isSet(object.latitude) ? globalThis.String(object.latitude) : "",
      longitude: isSet(object.longitude) ? globalThis.String(object.longitude) : "",
    };
  },

  toJSON(message: Coordinates): unknown {
    const obj: any = {};
    if (message.latitude !== "") {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== "") {
      obj.longitude = message.longitude;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Coordinates>, I>>(base?: I): Coordinates {
    return Coordinates.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Coordinates>, I>>(object: I): Coordinates {
    const message = createBaseCoordinates();
    message.latitude = object.latitude ?? "";
    message.longitude = object.longitude ?? "";
    return message;
  },
};

function createBaseLocationSchedule(): LocationSchedule {
  return { day: "", opensAt: "", closesAt: "" };
}

export const LocationSchedule = {
  encode(message: LocationSchedule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.day !== "") {
      writer.uint32(10).string(message.day);
    }
    if (message.opensAt !== "") {
      writer.uint32(18).string(message.opensAt);
    }
    if (message.closesAt !== "") {
      writer.uint32(26).string(message.closesAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocationSchedule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocationSchedule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.day = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.opensAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.closesAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LocationSchedule {
    return {
      day: isSet(object.day) ? globalThis.String(object.day) : "",
      opensAt: isSet(object.opensAt) ? globalThis.String(object.opensAt) : "",
      closesAt: isSet(object.closesAt) ? globalThis.String(object.closesAt) : "",
    };
  },

  toJSON(message: LocationSchedule): unknown {
    const obj: any = {};
    if (message.day !== "") {
      obj.day = message.day;
    }
    if (message.opensAt !== "") {
      obj.opensAt = message.opensAt;
    }
    if (message.closesAt !== "") {
      obj.closesAt = message.closesAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LocationSchedule>, I>>(base?: I): LocationSchedule {
    return LocationSchedule.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LocationSchedule>, I>>(object: I): LocationSchedule {
    const message = createBaseLocationSchedule();
    message.day = object.day ?? "";
    message.opensAt = object.opensAt ?? "";
    message.closesAt = object.closesAt ?? "";
    return message;
  },
};

function createBaseSearch(): Search {
  return {
    id: "",
    categoryName: "",
    offeringName: "",
    offeringDurationMins: 0,
    offeringImage: undefined,
    distanceKms: "",
    cost: undefined,
    providerId: "",
    providerName: "",
    orgName: "",
    orgBanner: undefined,
    coordinates: undefined,
    country: "",
    place: "",
    state: "",
    postCode: "",
    locId: "",
    locName: "",
    locSchedule: [],
    bookingId: "",
    bookingStatus: "",
    bookingType: "",
    bookingStartDatetime: undefined,
    bookingEndDatetime: undefined,
    offeringLevel: "",
    locationCorporateMembershipSetting: undefined,
  };
}

export const Search = {
  encode(message: Search, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.categoryName !== "") {
      writer.uint32(18).string(message.categoryName);
    }
    if (message.offeringName !== "") {
      writer.uint32(26).string(message.offeringName);
    }
    if (message.offeringDurationMins !== 0) {
      writer.uint32(32).int32(message.offeringDurationMins);
    }
    if (message.offeringImage !== undefined) {
      Media.encode(message.offeringImage, writer.uint32(42).fork()).ldelim();
    }
    if (message.distanceKms !== "") {
      writer.uint32(50).string(message.distanceKms);
    }
    if (message.cost !== undefined) {
      Money.encode(message.cost, writer.uint32(58).fork()).ldelim();
    }
    if (message.providerId !== "") {
      writer.uint32(66).string(message.providerId);
    }
    if (message.providerName !== "") {
      writer.uint32(74).string(message.providerName);
    }
    if (message.orgName !== "") {
      writer.uint32(82).string(message.orgName);
    }
    if (message.orgBanner !== undefined) {
      Media.encode(message.orgBanner, writer.uint32(90).fork()).ldelim();
    }
    if (message.coordinates !== undefined) {
      Coordinates.encode(message.coordinates, writer.uint32(98).fork()).ldelim();
    }
    if (message.country !== "") {
      writer.uint32(106).string(message.country);
    }
    if (message.place !== "") {
      writer.uint32(114).string(message.place);
    }
    if (message.state !== "") {
      writer.uint32(122).string(message.state);
    }
    if (message.postCode !== "") {
      writer.uint32(130).string(message.postCode);
    }
    if (message.locId !== "") {
      writer.uint32(138).string(message.locId);
    }
    if (message.locName !== "") {
      writer.uint32(146).string(message.locName);
    }
    for (const v of message.locSchedule) {
      LocationSchedule.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    if (message.bookingId !== "") {
      writer.uint32(162).string(message.bookingId);
    }
    if (message.bookingStatus !== "") {
      writer.uint32(170).string(message.bookingStatus);
    }
    if (message.bookingType !== "") {
      writer.uint32(178).string(message.bookingType);
    }
    if (message.bookingStartDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.bookingStartDatetime), writer.uint32(186).fork()).ldelim();
    }
    if (message.bookingEndDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.bookingEndDatetime), writer.uint32(194).fork()).ldelim();
    }
    if (message.offeringLevel !== "") {
      writer.uint32(202).string(message.offeringLevel);
    }
    if (message.locationCorporateMembershipSetting !== undefined) {
      LocationCorporateMembershipSetting.encode(message.locationCorporateMembershipSetting, writer.uint32(210).fork())
        .ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Search {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.categoryName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.offeringName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.offeringDurationMins = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.offeringImage = Media.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.distanceKms = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.cost = Money.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.providerId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.orgName = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.orgBanner = Media.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.coordinates = Coordinates.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.country = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.place = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.state = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.postCode = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.locId = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.locName = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.locSchedule.push(LocationSchedule.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.bookingStatus = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.bookingType = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.bookingStartDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.bookingEndDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.offeringLevel = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.locationCorporateMembershipSetting = LocationCorporateMembershipSetting.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Search {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      categoryName: isSet(object.categoryName) ? globalThis.String(object.categoryName) : "",
      offeringName: isSet(object.offeringName) ? globalThis.String(object.offeringName) : "",
      offeringDurationMins: isSet(object.offeringDurationMins) ? globalThis.Number(object.offeringDurationMins) : 0,
      offeringImage: isSet(object.offeringImage) ? Media.fromJSON(object.offeringImage) : undefined,
      distanceKms: isSet(object.distanceKms) ? globalThis.String(object.distanceKms) : "",
      cost: isSet(object.cost) ? Money.fromJSON(object.cost) : undefined,
      providerId: isSet(object.providerId) ? globalThis.String(object.providerId) : "",
      providerName: isSet(object.providerName) ? globalThis.String(object.providerName) : "",
      orgName: isSet(object.orgName) ? globalThis.String(object.orgName) : "",
      orgBanner: isSet(object.orgBanner) ? Media.fromJSON(object.orgBanner) : undefined,
      coordinates: isSet(object.coordinates) ? Coordinates.fromJSON(object.coordinates) : undefined,
      country: isSet(object.country) ? globalThis.String(object.country) : "",
      place: isSet(object.place) ? globalThis.String(object.place) : "",
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      postCode: isSet(object.postCode) ? globalThis.String(object.postCode) : "",
      locId: isSet(object.locId) ? globalThis.String(object.locId) : "",
      locName: isSet(object.locName) ? globalThis.String(object.locName) : "",
      locSchedule: globalThis.Array.isArray(object?.locSchedule)
        ? object.locSchedule.map((e: any) => LocationSchedule.fromJSON(e))
        : [],
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      bookingStatus: isSet(object.bookingStatus) ? globalThis.String(object.bookingStatus) : "",
      bookingType: isSet(object.bookingType) ? globalThis.String(object.bookingType) : "",
      bookingStartDatetime: isSet(object.bookingStartDatetime)
        ? fromJsonTimestamp(object.bookingStartDatetime)
        : undefined,
      bookingEndDatetime: isSet(object.bookingEndDatetime) ? fromJsonTimestamp(object.bookingEndDatetime) : undefined,
      offeringLevel: isSet(object.offeringLevel) ? globalThis.String(object.offeringLevel) : "",
      locationCorporateMembershipSetting: isSet(object.locationCorporateMembershipSetting)
        ? LocationCorporateMembershipSetting.fromJSON(object.locationCorporateMembershipSetting)
        : undefined,
    };
  },

  toJSON(message: Search): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.categoryName !== "") {
      obj.categoryName = message.categoryName;
    }
    if (message.offeringName !== "") {
      obj.offeringName = message.offeringName;
    }
    if (message.offeringDurationMins !== 0) {
      obj.offeringDurationMins = Math.round(message.offeringDurationMins);
    }
    if (message.offeringImage !== undefined) {
      obj.offeringImage = Media.toJSON(message.offeringImage);
    }
    if (message.distanceKms !== "") {
      obj.distanceKms = message.distanceKms;
    }
    if (message.cost !== undefined) {
      obj.cost = Money.toJSON(message.cost);
    }
    if (message.providerId !== "") {
      obj.providerId = message.providerId;
    }
    if (message.providerName !== "") {
      obj.providerName = message.providerName;
    }
    if (message.orgName !== "") {
      obj.orgName = message.orgName;
    }
    if (message.orgBanner !== undefined) {
      obj.orgBanner = Media.toJSON(message.orgBanner);
    }
    if (message.coordinates !== undefined) {
      obj.coordinates = Coordinates.toJSON(message.coordinates);
    }
    if (message.country !== "") {
      obj.country = message.country;
    }
    if (message.place !== "") {
      obj.place = message.place;
    }
    if (message.state !== "") {
      obj.state = message.state;
    }
    if (message.postCode !== "") {
      obj.postCode = message.postCode;
    }
    if (message.locId !== "") {
      obj.locId = message.locId;
    }
    if (message.locName !== "") {
      obj.locName = message.locName;
    }
    if (message.locSchedule?.length) {
      obj.locSchedule = message.locSchedule.map((e) => LocationSchedule.toJSON(e));
    }
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.bookingStatus !== "") {
      obj.bookingStatus = message.bookingStatus;
    }
    if (message.bookingType !== "") {
      obj.bookingType = message.bookingType;
    }
    if (message.bookingStartDatetime !== undefined) {
      obj.bookingStartDatetime = message.bookingStartDatetime.toISOString();
    }
    if (message.bookingEndDatetime !== undefined) {
      obj.bookingEndDatetime = message.bookingEndDatetime.toISOString();
    }
    if (message.offeringLevel !== "") {
      obj.offeringLevel = message.offeringLevel;
    }
    if (message.locationCorporateMembershipSetting !== undefined) {
      obj.locationCorporateMembershipSetting = LocationCorporateMembershipSetting.toJSON(
        message.locationCorporateMembershipSetting,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Search>, I>>(base?: I): Search {
    return Search.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Search>, I>>(object: I): Search {
    const message = createBaseSearch();
    message.id = object.id ?? "";
    message.categoryName = object.categoryName ?? "";
    message.offeringName = object.offeringName ?? "";
    message.offeringDurationMins = object.offeringDurationMins ?? 0;
    message.offeringImage = (object.offeringImage !== undefined && object.offeringImage !== null)
      ? Media.fromPartial(object.offeringImage)
      : undefined;
    message.distanceKms = object.distanceKms ?? "";
    message.cost = (object.cost !== undefined && object.cost !== null) ? Money.fromPartial(object.cost) : undefined;
    message.providerId = object.providerId ?? "";
    message.providerName = object.providerName ?? "";
    message.orgName = object.orgName ?? "";
    message.orgBanner = (object.orgBanner !== undefined && object.orgBanner !== null)
      ? Media.fromPartial(object.orgBanner)
      : undefined;
    message.coordinates = (object.coordinates !== undefined && object.coordinates !== null)
      ? Coordinates.fromPartial(object.coordinates)
      : undefined;
    message.country = object.country ?? "";
    message.place = object.place ?? "";
    message.state = object.state ?? "";
    message.postCode = object.postCode ?? "";
    message.locId = object.locId ?? "";
    message.locName = object.locName ?? "";
    message.locSchedule = object.locSchedule?.map((e) => LocationSchedule.fromPartial(e)) || [];
    message.bookingId = object.bookingId ?? "";
    message.bookingStatus = object.bookingStatus ?? "";
    message.bookingType = object.bookingType ?? "";
    message.bookingStartDatetime = object.bookingStartDatetime ?? undefined;
    message.bookingEndDatetime = object.bookingEndDatetime ?? undefined;
    message.offeringLevel = object.offeringLevel ?? "";
    message.locationCorporateMembershipSetting =
      (object.locationCorporateMembershipSetting !== undefined && object.locationCorporateMembershipSetting !== null)
        ? LocationCorporateMembershipSetting.fromPartial(object.locationCorporateMembershipSetting)
        : undefined;
    return message;
  },
};

function createBaseSearchQueryRequest(): SearchQueryRequest {
  return {
    query: "",
    place: "",
    level: 0,
    duration: 0,
    minCostPerService: 0,
    maxCostPerService: 0,
    queryDate: undefined,
    coordinates: undefined,
    keyword: [],
    serviceType: 0,
  };
}

export const SearchQueryRequest = {
  encode(message: SearchQueryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.query !== "") {
      writer.uint32(10).string(message.query);
    }
    if (message.place !== "") {
      writer.uint32(18).string(message.place);
    }
    if (message.level !== 0) {
      writer.uint32(24).int32(message.level);
    }
    if (message.duration !== 0) {
      writer.uint32(32).int32(message.duration);
    }
    if (message.minCostPerService !== 0) {
      writer.uint32(40).int32(message.minCostPerService);
    }
    if (message.maxCostPerService !== 0) {
      writer.uint32(48).int32(message.maxCostPerService);
    }
    if (message.queryDate !== undefined) {
      Timestamp.encode(toTimestamp(message.queryDate), writer.uint32(58).fork()).ldelim();
    }
    if (message.coordinates !== undefined) {
      Coordinates.encode(message.coordinates, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.keyword) {
      writer.uint32(74).string(v!);
    }
    if (message.serviceType !== 0) {
      writer.uint32(80).int32(message.serviceType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchQueryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchQueryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.query = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.place = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.level = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.duration = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.minCostPerService = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.maxCostPerService = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.queryDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.coordinates = Coordinates.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.keyword.push(reader.string());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.serviceType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchQueryRequest {
    return {
      query: isSet(object.query) ? globalThis.String(object.query) : "",
      place: isSet(object.place) ? globalThis.String(object.place) : "",
      level: isSet(object.level) ? searchQueryRequest_CategoryLevelFromJSON(object.level) : 0,
      duration: isSet(object.duration) ? searchQueryRequest_DurationServiceFromJSON(object.duration) : 0,
      minCostPerService: isSet(object.minCostPerService) ? globalThis.Number(object.minCostPerService) : 0,
      maxCostPerService: isSet(object.maxCostPerService) ? globalThis.Number(object.maxCostPerService) : 0,
      queryDate: isSet(object.queryDate) ? fromJsonTimestamp(object.queryDate) : undefined,
      coordinates: isSet(object.coordinates) ? Coordinates.fromJSON(object.coordinates) : undefined,
      keyword: globalThis.Array.isArray(object?.keyword) ? object.keyword.map((e: any) => globalThis.String(e)) : [],
      serviceType: isSet(object.serviceType) ? searchQueryRequest_ServiceTypeFromJSON(object.serviceType) : 0,
    };
  },

  toJSON(message: SearchQueryRequest): unknown {
    const obj: any = {};
    if (message.query !== "") {
      obj.query = message.query;
    }
    if (message.place !== "") {
      obj.place = message.place;
    }
    if (message.level !== 0) {
      obj.level = searchQueryRequest_CategoryLevelToJSON(message.level);
    }
    if (message.duration !== 0) {
      obj.duration = searchQueryRequest_DurationServiceToJSON(message.duration);
    }
    if (message.minCostPerService !== 0) {
      obj.minCostPerService = Math.round(message.minCostPerService);
    }
    if (message.maxCostPerService !== 0) {
      obj.maxCostPerService = Math.round(message.maxCostPerService);
    }
    if (message.queryDate !== undefined) {
      obj.queryDate = message.queryDate.toISOString();
    }
    if (message.coordinates !== undefined) {
      obj.coordinates = Coordinates.toJSON(message.coordinates);
    }
    if (message.keyword?.length) {
      obj.keyword = message.keyword;
    }
    if (message.serviceType !== 0) {
      obj.serviceType = searchQueryRequest_ServiceTypeToJSON(message.serviceType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchQueryRequest>, I>>(base?: I): SearchQueryRequest {
    return SearchQueryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchQueryRequest>, I>>(object: I): SearchQueryRequest {
    const message = createBaseSearchQueryRequest();
    message.query = object.query ?? "";
    message.place = object.place ?? "";
    message.level = object.level ?? 0;
    message.duration = object.duration ?? 0;
    message.minCostPerService = object.minCostPerService ?? 0;
    message.maxCostPerService = object.maxCostPerService ?? 0;
    message.queryDate = object.queryDate ?? undefined;
    message.coordinates = (object.coordinates !== undefined && object.coordinates !== null)
      ? Coordinates.fromPartial(object.coordinates)
      : undefined;
    message.keyword = object.keyword?.map((e) => e) || [];
    message.serviceType = object.serviceType ?? 0;
    return message;
  },
};

function createBaseSearchQueryResponse(): SearchQueryResponse {
  return { searchResult: [] };
}

export const SearchQueryResponse = {
  encode(message: SearchQueryResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.searchResult) {
      Search.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchQueryResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchQueryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.searchResult.push(Search.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchQueryResponse {
    return {
      searchResult: globalThis.Array.isArray(object?.searchResult)
        ? object.searchResult.map((e: any) => Search.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SearchQueryResponse): unknown {
    const obj: any = {};
    if (message.searchResult?.length) {
      obj.searchResult = message.searchResult.map((e) => Search.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchQueryResponse>, I>>(base?: I): SearchQueryResponse {
    return SearchQueryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchQueryResponse>, I>>(object: I): SearchQueryResponse {
    const message = createBaseSearchQueryResponse();
    message.searchResult = object.searchResult?.map((e) => Search.fromPartial(e)) || [];
    return message;
  },
};

export interface SearchService {
  SearchQuery(request: DeepPartial<SearchQueryRequest>, metadata?: grpc.Metadata): Promise<SearchQueryResponse>;
}

export class SearchServiceClientImpl implements SearchService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.SearchQuery = this.SearchQuery.bind(this);
  }

  SearchQuery(request: DeepPartial<SearchQueryRequest>, metadata?: grpc.Metadata): Promise<SearchQueryResponse> {
    return this.rpc.unary(SearchServiceSearchQueryDesc, SearchQueryRequest.fromPartial(request), metadata);
  }
}

export const SearchServiceDesc = { serviceName: "proto.SearchService" };

export const SearchServiceSearchQueryDesc: UnaryMethodDefinitionish = {
  methodName: "SearchQuery",
  service: SearchServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchQueryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchQueryResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
