/* eslint-disable @typescript-eslint/no-explicit-any */
import * as grpcWeb from "grpc-web";
import { IMiddleware } from "./GrpcInterceptor";
import { Logger } from "../util/Logger";

const logger = new Logger("LoggingMiddleware");

export class LoggingMiddleware implements IMiddleware {
  private startTimes: Map<string, number> = new Map();

  onError(id: string, methodDesc: any, error: grpcWeb.RpcError) {
    logger.error(
      "GRPC Error",
      `${methodDesc.service.serviceName}.${methodDesc.methodName}`,
      {
        context: id
      },
      error
    );
  }
  pre(id: string, methodDesc: any, request: any, metadata: grpcWeb.Metadata | undefined) {
    this.startTimes.set(id, performance.now());
    logger.info("GRPC Request", `${methodDesc.service.serviceName}.${methodDesc.methodName}`, {
      context: id,
      request: request,
      metadata: metadata
    });
  }
  post(id: string, methodDesc: any, res: any) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const elapsed = performance.now() - this.startTimes.get(id)!;
    this.startTimes.delete(id);

    logger.info("GRPC Response", `${methodDesc.service.serviceName}.${methodDesc.methodName}`, {
      context: id,
      response: res,
      duration: `${elapsed.toFixed(2)}ms`
    });
  }
}
