import React from "react";
import { BookingDTO, BookingClient } from "sdk/dist/bookings_pb";
import { LeftFooterText } from "../../SplitDialog";
import ButtonBase from "./../../../../components/ButtonBase";
import styled from "@emotion/styled";

interface Props {
  booking: BookingDTO.AsObject;
  onClick: (client: BookingClient.AsObject) => void;
}

export class GroupBreakdown extends React.Component<Props> {
  render() {
    const { booking, onClick } = this.props;
    return (
      <>
        {booking.clientsList
          .filter(
            (client) =>
              client.status !== BookingClient.Status.CANCELLED &&
              client.status !== BookingClient.Status.REJECTED
          )
          .map((client) => (
            <LeftFooterText key={client.clientId} fontWeight={400} style={{ height: "30px" }}>
              {client.firstName + " " + client.lastName}
            </LeftFooterText>
          ))}
      </>
    );
  }
}

export const ButtonGroupClient = styled(ButtonBase)`
  background-color: transparent;
  display: flex;
  height: 50px;
  width: 100%;
  cursor: pointer;
  padding: 5px;

  &:hover:enabled {
    background-color: #0000001c;
  }
`;
