import React from "react";
import SearchInput from "./form/SearchInput";
import { rpc, metadata } from "../grpc/grpc-legacy";
import { ListOfferingsRequest, Offering } from "sdk/dist/offerings_pb";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { durationToText } from "./../util/duration";
import { throttle } from "lodash";
import money_legacy from "../util/money_legacy";

interface Props {
  locationId: string;
  onSelect: (offering: Offering.AsObject | undefined) => void;
}

@observer
export class LocationOfferingSelector extends React.Component<Props> {
  @observable
  items = new Array<Offering.AsObject>();

  async componentDidMount() {
    await this.load();
  }

  private load = throttle(async (search: string = "") => {
    const req = new ListOfferingsRequest();
    req.setLocationId(this.props.locationId);
    req.setTypeFilter(Offering.Type.SERVICE); // only search for services
    req.setSearchFilter(search);
    const res = await rpc.offerings.list(req, metadata());
    this.items = res.toObject().offeringsList;
  }, 200);

  private label(item: Offering.AsObject) {
    return `${item.name} - ${money_legacy.chargeToText(item.fee!)} (${durationToText(
      item.duration
    )}) ${item.level ? " - " + item.level : ""}`;
  }

  render() {
    return (
      <SearchInput
        name="service"
        placeholder="Select a provided service..."
        hasClearButton
        items={this.items.map((item) => ({
          label: this.label(item),
          value: item
        }))}
        setFieldValue={(_, value) => {
          this.props.onSelect(value);
        }}
        onInputValueChange={(value) => this.load(value)}
      />
    );
  }
}
