/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Category } from "./category";
import { Duration } from "./google/protobuf/duration";
import { Media } from "./media";
import { Charge } from "./money";

export const protobufPackage = "proto";

export enum FundType {
  FUND_TYPE_UNSPECIFIED = 0,
  CREDIT_CARD = 1,
  MEDICARE_STANDARD = 2,
  MEDICARE_BULK = 3,
  DVA_STANDARD = 4,
  DVA_ALLIED = 5,
  HICAPS = 6,
  THIRD_PARTY_INVOICE = 7,
  UNRECOGNIZED = -1,
}

export function fundTypeFromJSON(object: any): FundType {
  switch (object) {
    case 0:
    case "FUND_TYPE_UNSPECIFIED":
      return FundType.FUND_TYPE_UNSPECIFIED;
    case 1:
    case "CREDIT_CARD":
      return FundType.CREDIT_CARD;
    case 2:
    case "MEDICARE_STANDARD":
      return FundType.MEDICARE_STANDARD;
    case 3:
    case "MEDICARE_BULK":
      return FundType.MEDICARE_BULK;
    case 4:
    case "DVA_STANDARD":
      return FundType.DVA_STANDARD;
    case 5:
    case "DVA_ALLIED":
      return FundType.DVA_ALLIED;
    case 6:
    case "HICAPS":
      return FundType.HICAPS;
    case 7:
    case "THIRD_PARTY_INVOICE":
      return FundType.THIRD_PARTY_INVOICE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FundType.UNRECOGNIZED;
  }
}

export function fundTypeToJSON(object: FundType): string {
  switch (object) {
    case FundType.FUND_TYPE_UNSPECIFIED:
      return "FUND_TYPE_UNSPECIFIED";
    case FundType.CREDIT_CARD:
      return "CREDIT_CARD";
    case FundType.MEDICARE_STANDARD:
      return "MEDICARE_STANDARD";
    case FundType.MEDICARE_BULK:
      return "MEDICARE_BULK";
    case FundType.DVA_STANDARD:
      return "DVA_STANDARD";
    case FundType.DVA_ALLIED:
      return "DVA_ALLIED";
    case FundType.HICAPS:
      return "HICAPS";
    case FundType.THIRD_PARTY_INVOICE:
      return "THIRD_PARTY_INVOICE";
    case FundType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Offering {
  id: string;
  name: string;
  description: string;
  fee: Charge | undefined;
  type: Offering_Type;
  duration: Duration | undefined;
  enabledFunds: FundType[];
  locationId: string;
  isTelehealth: boolean;
  taxRate: string;
  image: Media | undefined;
  Level: string;
  category: Category[];
  color: string;
}

export enum Offering_Type {
  TYPE_UNSPECIFIED = 0,
  PRODUCT = 1,
  SERVICE = 2,
  UNRECOGNIZED = -1,
}

export function offering_TypeFromJSON(object: any): Offering_Type {
  switch (object) {
    case 0:
    case "TYPE_UNSPECIFIED":
      return Offering_Type.TYPE_UNSPECIFIED;
    case 1:
    case "PRODUCT":
      return Offering_Type.PRODUCT;
    case 2:
    case "SERVICE":
      return Offering_Type.SERVICE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Offering_Type.UNRECOGNIZED;
  }
}

export function offering_TypeToJSON(object: Offering_Type): string {
  switch (object) {
    case Offering_Type.TYPE_UNSPECIFIED:
      return "TYPE_UNSPECIFIED";
    case Offering_Type.PRODUCT:
      return "PRODUCT";
    case Offering_Type.SERVICE:
      return "SERVICE";
    case Offering_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ListOfferingsRequest {
  locationId: string;
  searchFilter: string;
  typeFilter: Offering_Type;
}

export interface ListOfferingsResponse {
  offerings: Offering[];
}

function createBaseOffering(): Offering {
  return {
    id: "",
    name: "",
    description: "",
    fee: undefined,
    type: 0,
    duration: undefined,
    enabledFunds: [],
    locationId: "",
    isTelehealth: false,
    taxRate: "",
    image: undefined,
    Level: "",
    category: [],
    color: "",
  };
}

export const Offering = {
  encode(message: Offering, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.fee !== undefined) {
      Charge.encode(message.fee, writer.uint32(34).fork()).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.duration !== undefined) {
      Duration.encode(message.duration, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(58).fork();
    for (const v of message.enabledFunds) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.locationId !== "") {
      writer.uint32(66).string(message.locationId);
    }
    if (message.isTelehealth === true) {
      writer.uint32(72).bool(message.isTelehealth);
    }
    if (message.taxRate !== "") {
      writer.uint32(82).string(message.taxRate);
    }
    if (message.image !== undefined) {
      Media.encode(message.image, writer.uint32(90).fork()).ldelim();
    }
    if (message.Level !== "") {
      writer.uint32(98).string(message.Level);
    }
    for (const v of message.category) {
      Category.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.color !== "") {
      writer.uint32(114).string(message.color);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Offering {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOffering();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fee = Charge.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.duration = Duration.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag === 56) {
            message.enabledFunds.push(reader.int32() as any);

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.enabledFunds.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isTelehealth = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.taxRate = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.image = Media.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.Level = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.category.push(Category.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.color = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Offering {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      fee: isSet(object.fee) ? Charge.fromJSON(object.fee) : undefined,
      type: isSet(object.type) ? offering_TypeFromJSON(object.type) : 0,
      duration: isSet(object.duration) ? Duration.fromJSON(object.duration) : undefined,
      enabledFunds: globalThis.Array.isArray(object?.enabledFunds)
        ? object.enabledFunds.map((e: any) => fundTypeFromJSON(e))
        : [],
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      isTelehealth: isSet(object.isTelehealth) ? globalThis.Boolean(object.isTelehealth) : false,
      taxRate: isSet(object.taxRate) ? globalThis.String(object.taxRate) : "",
      image: isSet(object.image) ? Media.fromJSON(object.image) : undefined,
      Level: isSet(object.Level) ? globalThis.String(object.Level) : "",
      category: globalThis.Array.isArray(object?.category) ? object.category.map((e: any) => Category.fromJSON(e)) : [],
      color: isSet(object.color) ? globalThis.String(object.color) : "",
    };
  },

  toJSON(message: Offering): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.fee !== undefined) {
      obj.fee = Charge.toJSON(message.fee);
    }
    if (message.type !== 0) {
      obj.type = offering_TypeToJSON(message.type);
    }
    if (message.duration !== undefined) {
      obj.duration = Duration.toJSON(message.duration);
    }
    if (message.enabledFunds?.length) {
      obj.enabledFunds = message.enabledFunds.map((e) => fundTypeToJSON(e));
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.isTelehealth === true) {
      obj.isTelehealth = message.isTelehealth;
    }
    if (message.taxRate !== "") {
      obj.taxRate = message.taxRate;
    }
    if (message.image !== undefined) {
      obj.image = Media.toJSON(message.image);
    }
    if (message.Level !== "") {
      obj.Level = message.Level;
    }
    if (message.category?.length) {
      obj.category = message.category.map((e) => Category.toJSON(e));
    }
    if (message.color !== "") {
      obj.color = message.color;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Offering>, I>>(base?: I): Offering {
    return Offering.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Offering>, I>>(object: I): Offering {
    const message = createBaseOffering();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.fee = (object.fee !== undefined && object.fee !== null) ? Charge.fromPartial(object.fee) : undefined;
    message.type = object.type ?? 0;
    message.duration = (object.duration !== undefined && object.duration !== null)
      ? Duration.fromPartial(object.duration)
      : undefined;
    message.enabledFunds = object.enabledFunds?.map((e) => e) || [];
    message.locationId = object.locationId ?? "";
    message.isTelehealth = object.isTelehealth ?? false;
    message.taxRate = object.taxRate ?? "";
    message.image = (object.image !== undefined && object.image !== null) ? Media.fromPartial(object.image) : undefined;
    message.Level = object.Level ?? "";
    message.category = object.category?.map((e) => Category.fromPartial(e)) || [];
    message.color = object.color ?? "";
    return message;
  },
};

function createBaseListOfferingsRequest(): ListOfferingsRequest {
  return { locationId: "", searchFilter: "", typeFilter: 0 };
}

export const ListOfferingsRequest = {
  encode(message: ListOfferingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    if (message.searchFilter !== "") {
      writer.uint32(18).string(message.searchFilter);
    }
    if (message.typeFilter !== 0) {
      writer.uint32(24).int32(message.typeFilter);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOfferingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOfferingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.searchFilter = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.typeFilter = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOfferingsRequest {
    return {
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      searchFilter: isSet(object.searchFilter) ? globalThis.String(object.searchFilter) : "",
      typeFilter: isSet(object.typeFilter) ? offering_TypeFromJSON(object.typeFilter) : 0,
    };
  },

  toJSON(message: ListOfferingsRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.searchFilter !== "") {
      obj.searchFilter = message.searchFilter;
    }
    if (message.typeFilter !== 0) {
      obj.typeFilter = offering_TypeToJSON(message.typeFilter);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListOfferingsRequest>, I>>(base?: I): ListOfferingsRequest {
    return ListOfferingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListOfferingsRequest>, I>>(object: I): ListOfferingsRequest {
    const message = createBaseListOfferingsRequest();
    message.locationId = object.locationId ?? "";
    message.searchFilter = object.searchFilter ?? "";
    message.typeFilter = object.typeFilter ?? 0;
    return message;
  },
};

function createBaseListOfferingsResponse(): ListOfferingsResponse {
  return { offerings: [] };
}

export const ListOfferingsResponse = {
  encode(message: ListOfferingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.offerings) {
      Offering.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOfferingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOfferingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offerings.push(Offering.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOfferingsResponse {
    return {
      offerings: globalThis.Array.isArray(object?.offerings)
        ? object.offerings.map((e: any) => Offering.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListOfferingsResponse): unknown {
    const obj: any = {};
    if (message.offerings?.length) {
      obj.offerings = message.offerings.map((e) => Offering.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListOfferingsResponse>, I>>(base?: I): ListOfferingsResponse {
    return ListOfferingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListOfferingsResponse>, I>>(object: I): ListOfferingsResponse {
    const message = createBaseListOfferingsResponse();
    message.offerings = object.offerings?.map((e) => Offering.fromPartial(e)) || [];
    return message;
  },
};

export interface Offerings {
  List(request: DeepPartial<ListOfferingsRequest>, metadata?: grpc.Metadata): Promise<ListOfferingsResponse>;
}

export class OfferingsClientImpl implements Offerings {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.List = this.List.bind(this);
  }

  List(request: DeepPartial<ListOfferingsRequest>, metadata?: grpc.Metadata): Promise<ListOfferingsResponse> {
    return this.rpc.unary(OfferingsListDesc, ListOfferingsRequest.fromPartial(request), metadata);
  }
}

export const OfferingsDesc = { serviceName: "proto.Offerings" };

export const OfferingsListDesc: UnaryMethodDefinitionish = {
  methodName: "List",
  service: OfferingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListOfferingsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListOfferingsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
