import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import * as grpcWeb from "grpc-web";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useAsync } from "../../../hooks/useAsync";
import { CreditCard } from "sdk/dist/credit_cards_pb";
import { sharedClientCardService } from "../../../services/ClientCardService";
import { sharedOrganizationCardService } from "../../../services/OrganizationCardService";
import { sharedUserCardService } from "../../../services/UserCardService";
import { stripeStore } from "../../../stores/stripe-store";
import { toastStore } from "../../../stores/toast-store";
import { ActivityIndicator } from "../../../ui-kit/ActivityIndicator";
import { AsyncButton } from "../../../ui-kit/AsyncButton";
import { Container } from "../../../ui-kit/Container";
import { FlexBox, FlexItem } from "../../../ui-kit/FlexBox";
import { StripePaymentCard } from "../../../ui-kit/StripePaymentCard";
import { Typography } from "../../../ui-kit/Typography";
import { toDate } from "../../../util/timestamp";
import { Label } from "../../elements/Label";
import { TabContainer, TabHeader } from "../../elements/Tabs";
import { NoCards } from "./NoCards";
import { PaymentCard } from "./PaymentCard";

interface IProps {
  ownerId: string;
  ownerType: CreditCard.OwnerType;
  required?: boolean;
  useFormHeaderComp?: boolean;
}

export const ManageCreditCard = observer((props: IProps) => {
  const [adding, setAdding] = useState(false);
  const [stripeObj, setStripeObj] = useState<Stripe | null>(null);

  useEffect(() => {
    (async () => {
      const res = await stripeStore.getPKInstance();
      setStripeObj(res);
    })();
  }, []);

  const paymentCardsFetcher = useAsync(async () => {
    const cards: CreditCard.AsObject[] = [];
    switch (ownerType) {
      case CreditCard.OwnerType.CLIENT:
        const clientCards = await sharedClientCardService.getCards(props.ownerId);
        cards.push(...clientCards);
        break;
      case CreditCard.OwnerType.ORGANISATION:
        const orgCards = await sharedOrganizationCardService.getCards(props.ownerId);
        cards.push(...orgCards);
        break;
      case CreditCard.OwnerType.USER:
        const userCards = await sharedUserCardService.getCards(props.ownerId);
        cards.push(...userCards);
        break;
      default:
        throw toastStore.grpcError({
          code: 3,
          message: `Can't list credit card(s), owner type ${ownerTypeToText(
            ownerType
          )} unimplemented`
        } as grpcWeb.RpcError);
    }

    return cards.sort((a: CreditCard.AsObject, b: CreditCard.AsObject) => {
      if (a.lastUsedDate && b.lastUsedDate) {
        return getTime(toDate(a.lastUsedDate!)) - getTime(toDate(b.lastUsedDate!));
      }
      return 0;
    });
  });

  const getTime = (date?: Date) => {
    return date != null ? date.getTime() : 0;
  };

  const setPrimary = async (card: CreditCard.AsObject) => {
    switch (props.ownerType) {
      case CreditCard.OwnerType.CLIENT:
        await sharedClientCardService.setPrimaryCard(card.id);
        break;
      case CreditCard.OwnerType.ORGANISATION:
        await sharedOrganizationCardService.setPrimaryCard(card.id);
        break;
      case CreditCard.OwnerType.USER:
        await sharedUserCardService.setPrimaryCard(card.id);
        break;
      default:
        toastStore.grpcError({
          code: 3,
          message: `Can't set default credit card(s), owner type ${ownerTypeToText(
            props.ownerType
          )} unimplemented`
        } as grpcWeb.RpcError);
    }
    await paymentCardsFetcher.refresh();
  };

  const removeCard = async (card: CreditCard.AsObject) => {
    switch (props.ownerType) {
      case CreditCard.OwnerType.CLIENT:
        await sharedClientCardService.deleteCard(card.id);
        break;
      case CreditCard.OwnerType.ORGANISATION:
        await sharedOrganizationCardService.deleteCard(card.id);
        break;
      case CreditCard.OwnerType.USER:
        await sharedUserCardService.deleteCard(card.id);
        break;
      default:
        throw toastStore.grpcError({
          code: 3,
          message: `Can't remove credit card(s), owner type ${ownerTypeToText(
            props.ownerType
          )} unimplemented`
        } as grpcWeb.RpcError);
    }
    await paymentCardsFetcher.refresh();
  };

  const createNewCard = async (token: string) => {
    switch (props.ownerType) {
      case CreditCard.OwnerType.CLIENT:
        await sharedClientCardService.create(token, props.ownerId);
        break;
      case CreditCard.OwnerType.ORGANISATION:
        await sharedOrganizationCardService.create(token, props.ownerId);
        break;
      case CreditCard.OwnerType.USER:
        await sharedUserCardService.create(token, props.ownerId);
        break;
      default:
        throw toastStore.grpcError({
          code: 3,
          message: `Can't create credit card(s), owner type ${ownerTypeToText(
            props.ownerType
          )} unimplemented`
        } as grpcWeb.RpcError);
    }
    setAdding(false);
    await paymentCardsFetcher.refresh();
  };

  const { ownerType, useFormHeaderComp, required } = props;

  if (paymentCardsFetcher.loading) {
    return <ActivityIndicator color="primary" />;
  }

  return (
    <TabContainer>
      <Container noPadding>
        <FlexBox direction="col" justifyContent="between" spacing={[0, 8]}>
          <FlexItem>
            <FlexBox direction="col" fullHeight spacing={[0, 10]}>
              <FlexItem>
                {useFormHeaderComp ? (
                  <>
                    <Typography.H2 color="primary" noPadding>
                      {headerTitle(ownerType)}
                    </Typography.H2>
                    <Typography.Label color="secondary">
                      Please modify your primary payment methods below
                    </Typography.Label>
                  </>
                ) : (
                  <TabHeader>
                    <Label required={required}>{headerTitle(ownerType)}</Label>
                  </TabHeader>
                )}
              </FlexItem>
              {paymentCardsFetcher.value && !paymentCardsFetcher.value.length && !adding && (
                <NoCards />
              )}
              <FlexItem>
                <FlexBox direction="col" spacing={[0, 2]}>
                  {paymentCardsFetcher.value &&
                    paymentCardsFetcher.value
                      .filter((card) => card.isPrimary)
                      .map((card, key) => (
                        <FlexItem key={key}>
                          <PaymentCard
                            key={card.id}
                            card={card}
                            setPrimaryCard={() => {
                              if (card && !card.isPrimary) setPrimary(card);
                            }}
                            onRemove={() => removeCard(card)}
                            isUserCC={
                              card.ownerType === CreditCard.OwnerType.USER &&
                              ownerType !== CreditCard.OwnerType.USER
                            }
                          />
                        </FlexItem>
                      ))}
                  {paymentCardsFetcher.value &&
                    paymentCardsFetcher.value
                      .filter((card) => !card.isPrimary)
                      .map((card, key) => (
                        <FlexItem key={key}>
                          <PaymentCard
                            displayTitle={key === 0}
                            key={card.id}
                            card={card}
                            setPrimaryCard={() => {
                              if (card && !card.isPrimary) setPrimary(card);
                            }}
                            onRemove={() => removeCard(card)}
                            isUserCC={
                              card.ownerType === CreditCard.OwnerType.USER &&
                              ownerType !== CreditCard.OwnerType.USER
                            }
                          />
                        </FlexItem>
                      ))}
                </FlexBox>
              </FlexItem>

              {adding && (
                <FlexItem fullWidth>
                  <Elements stripe={stripeObj}>
                    <StripePaymentCard
                      onSubmit={(token) => createNewCard(token.id)}
                      onCancel={() => setAdding(false)}
                      isAddingNewCard={adding}
                    />
                  </Elements>
                </FlexItem>
              )}
            </FlexBox>
          </FlexItem>

          {!adding && (
            <FlexItem>
              <AsyncButton.PrimaryGradient
                onClick={() => setAdding(true)}
                type="submit"
                rounded="full"
                size="md"
                fullWidth
              >
                ADD NEW CARD
              </AsyncButton.PrimaryGradient>
            </FlexItem>
          )}
        </FlexBox>
      </Container>
    </TabContainer>
  );
});

const headerTitle = (ownerType: CreditCard.OwnerType) => {
  switch (ownerType) {
    case CreditCard.OwnerType.CLIENT:
      return "Client's Cards";
    case CreditCard.OwnerType.ORGANISATION:
      return "Organisation's Cards";
    case CreditCard.OwnerType.USER:
      return "User's Cards";
    default:
      return "Credit Cards";
  }
};

export function ownerTypeToText(ownerType: CreditCard.OwnerType): string {
  switch (ownerType) {
    case CreditCard.OwnerType.CLIENT:
      return "CLIENT";
    case CreditCard.OwnerType.LOCATION:
      return "LOCATION";
    case CreditCard.OwnerType.ORGANISATION:
      return "ORGANISATION";
    case CreditCard.OwnerType.PROVIDER:
      return "PROVIDER";
    case CreditCard.OwnerType.USER:
      return "USER";
    default:
      return "UNSPECIFIED";
  }
}
