import React, { Component } from "react";
import {
  ApiToken,
  AuthApplication,
  GetListTokenUserRequest,
  ApplicationType,
  SetActiveTokenRequest
} from "sdk/dist/applications_and_tokens_pb";
import Section from "../../../form/Section";
import { observer } from "mobx-react";
import { observable, toJS, computed } from "mobx";
import { HeaderContainer, HeaderButton, Container } from "../ContactDetails";
import styled from "styled-components";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";
import { rpc, metadata } from "../../../../grpc/grpc-legacy";
import { colors } from "../../../../util/consts";
import { NewApp } from "./NewApp";
import Delete from "../../../../components/icons/Delete";
import { toastStore } from "../../../../stores/toast-store";
import { LightTooltip } from "../../../../components/elements/MaterialTooltip";

interface Props {
  orgUser: any;
}

@observer
export class IntegratedApps extends Component<Props> {
  @observable
  private editing = false;

  @observable
  private authApplications = Array<AuthApplication.AsObject>();

  @observable
  private listApiTokens = Array<ApiToken.AsObject>();

  handleEditClick = () => {
    this.editing = !this.editing;
  };

  onDelete = async (apiToken: ApiToken.AsObject) => {
    try {
      const { orgUser } = this.props;
      const req = new SetActiveTokenRequest();
      req.setType(apiToken.type);
      req.setOrgId(orgUser.Org.ID);
      req.setUserId(orgUser.User.ID);
      req.setToken(apiToken.token);
      req.setIsActive(false);

      await rpc.apiTokenService.setActive(req, metadata());
      // try to remove it from list
      let indexToRemove = -1;
      indexToRemove = this.listApiTokens.indexOf(apiToken);
      if (indexToRemove >= 0) {
        this.listApiTokens.splice(indexToRemove, 1);
        toastStore.success("Token deleted successfully");
      }
    } catch (err) {
      toastStore.grpcError(err);
    }
  };

  async updateAppsAndTokens(orgId: string, userId: string) {
    // Get all authorized integration applications
    this.authApplications = (
      await rpc.authApplicationService.getAll(new Empty(), metadata())
    ).toObject().applicationList;

    // For each app search the api tokens for the provider
    this.authApplications.forEach(async (app) => {
      const reqApiTokens = new GetListTokenUserRequest();
      reqApiTokens.setType(app.type);
      reqApiTokens.setOrgId(orgId);
      reqApiTokens.setUserId(userId);
      reqApiTokens.setOnlyActive(true);
      const appApiTokens = (
        await rpc.apiTokenService.getListTokenUser(reqApiTokens, metadata())
      ).toObject().apiTokenList;

      // Add active token of each app to the list of token for this provider
      appApiTokens.forEach((token) => {
        this.listApiTokens.push(token);
      });
      // If not tokens, auto open create form
      if (this.listApiTokens.length === 0) {
        this.editing = true;
      } else {
        this.editing = false;
      }
    });
  }

  async componentDidMount() {
    const { orgUser } = this.props;
    try {
      await this.updateAppsAndTokens(orgUser.Org.ID, orgUser.User.ID);
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  render() {
    const { orgUser } = this.props;
    return (
      <>
        <HeaderContainer>
          Integrations
          {this.editing && <HeaderButton onClick={this.handleEditClick}>Cancel</HeaderButton>}
          {!this.editing && <HeaderButton onClick={this.handleEditClick}>Edit</HeaderButton>}
        </HeaderContainer>
        <Container>
          {this.listApiTokens.length > 0 && (
            <Section>
              <SingleRowContainer>
                <ApiLabel flexBasis={30}>Application</ApiLabel>
                <ApiLabel flexBasis={55}>Token</ApiLabel>
                <ApiLabel flexBasis={15}>Status</ApiLabel>
              </SingleRowContainer>
            </Section>
          )}
          {this.listApiTokens.map((apiToken) => (
            <Section key={apiToken.type}>
              <SingleRowContainer>
                <ApiLabel flexBasis={30} regularText>
                  <LightTooltip
                    disableFocusListener
                    disableTouchListener
                    title={
                      this.authApplications.filter((app) => app.type === apiToken.type)[0]
                        .description
                    }
                    placement="left"
                  >
                    <p>{ApiTypeToInternal(apiToken.type)}</p>
                  </LightTooltip>
                </ApiLabel>
                <ApiLabel
                  flexBasis={55}
                  regularText
                  style={{ wordBreak: "break-all", paddingRight: "20px" }}
                >
                  {apiToken.token}
                </ApiLabel>
                <ApiLabel flexBasis={this.editing ? 10 : 15} regularText>
                  {apiToken.isActive ? "Active   " : "Inactive   "}
                </ApiLabel>
                {this.editing && (
                  <Delete
                    size={"small"}
                    cursor={"pointer"}
                    onClick={() => {
                      this.onDelete(apiToken);
                    }}
                  />
                )}
              </SingleRowContainer>
            </Section>
          ))}
          {this.editing && (
            <NewApp
              orgId={orgUser.Org.ID}
              userId={orgUser.User.ID}
              authApplications={this.authApplications}
              handleEditClick={this.handleEditClick}
              listApiTokens={this.listApiTokens}
            />
          )}
        </Container>
      </>
    );
  }
}

export const ApiTypeToInternal = (type: ApplicationType): string => {
  switch (type) {
    case ApplicationType.PHYSITRACK_LYFE:
      return "Physitrack Lyfe";
    case ApplicationType.PHYSITRACK_API:
      return "Physitrack API";
    default:
      return "Unknown";
  }
};

const SingleRowContainer = styled.div`
  display: flex;
`;

const ApiLabel = styled.label<{ regularText: boolean; flexBasis: number }>`
  flex-basis: ${({ flexBasis }) => {
    if (flexBasis) {
      return flexBasis;
    } else {
      return 0;
    }
  }}%;
  margin-bottom: 6px;
  color: ${colors.surfaceText.highEmphasis};
  font-size: ${({ regularText }) => {
    if (regularText) {
      return "12.8px";
    } else {
      return "15.8px";
    }
  }};
  letter-spacing: 0.15px;
  line-height: 24px;
  opacity: ${({ regularText }) => {
    if (regularText) {
      return "0.6";
    } else {
      return "1";
    }
  }};
`;
