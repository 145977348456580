import React, { Component } from "react";

import PagesContainer, { PageGroup, Page } from "./../../../components/elements/PagesContainer";

import General from "./General";
import Market from "./Market";
import Sched from "./Sched";
import IFrame from "./IFrame";
import Fund_AUClaiming from "./Fund_AUClaiming";
import { MindBodyIntegration } from "./integrations/MindBody";
import { FeatureFlag } from "../../feature-flag/FeatureFlag";

import ResourceFetch from "./../../../components/elements/ResourceFetch";
import ResourceRender from "./../../../components/elements/ResourceRender";

import ResourcePageError from "./../../../components/elements/ResourcePageError";
import { AppConfig } from "../../../AppConfig";
import { CorporateSchedule } from "./CorporateSchedule";
import { CorporateMembershipStatus } from "../../../util/consts";
import { GoogleCalendarIntegration } from "./integrations/GoogleCalendar";

export default class LocEditAccount extends Component {
  renderContent = React.memo(({ resource: loc }) => (
    <PagesContainer key={loc.ID} sidePanel={true} queryp="page" extraProps={{ loc }}>
      <PageGroup label="Basics">
        <Page name="general" label="General" component={General} />
        <Page name="market" label="Marketing" component={Market} />
        <Page name="sched" label="Schedule" component={Sched} />
        <Page name="iframe" label="IFrame" component={IFrame} />
      </PageGroup>
      {AppConfig.BOOKLYFE_SERVER_INSTANCE_COUNTRY !== "US" ? (
        <PageGroup label="Funds">
          <Page name="aufundclaiming" label="Medicare/DVA" component={Fund_AUClaiming} />
        </PageGroup>
      ) : (
        ""
      )}
      {!FeatureFlag.IsProduction &&
        loc.Org.CorporateMembershipStatus === CorporateMembershipStatus.ActiveStatus && (
        <PageGroup label="Integrations">
          <Page
            name="integrations"
            label="Mindbody Integration"
            component={() => <MindBodyIntegration locationId={loc.ID} />}
          />
          <Page
            name="googleIntegration"
            label="Google Integrations"
            component={() => <GoogleCalendarIntegration locationId={loc.ID} />}
          />

        </PageGroup>
      )}
      {!FeatureFlag.IsProduction &&
        loc.Org.CorporateMembershipStatus === CorporateMembershipStatus.ActiveStatus && (
          <PageGroup label="Corporate Wellness Program">
            <Page
              name="corporateSchedule"
              label="Schedule"
              component={() => <CorporateSchedule locID={loc.ID} />}
            />
          </PageGroup>
        )}
    </PagesContainer>
  ));

  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <ResourceFetch
          type="Location"
          ids={{ ID: match.params.lid }}
          extraData={{ Context: "Admin" }}
          force
        />
        <ResourceRender
          type="Location"
          ids={{ ID: match.params.lid }}
          denorm={true}
          compSuccess={this.renderContent}
          compError={ResourcePageError}
        />
      </React.Fragment>
    );
  }
}
