import { metadata, rpc } from "../grpc/grpc-legacy";
import { GetSupportedCountriesListRequest } from "sdk/dist/country_pb";

class CountryService {
  async getSupportedCountriesList() {
    const res = await rpc.country.getSupportedCountriesList(
      new GetSupportedCountriesListRequest(),
      metadata()
    );
    return res.toObject().countriesList;
  }
}

export const sharedCountryService = new CountryService();
