import React, { Component } from "react";
import styled from "@emotion/styled";
import { withFormik } from "formik";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// import apiService from "./../../../redux/services/api";
import * as ResourceActions from "../../../redux/features/resources/thunkactions";

import { parseFormError } from "../../../util/form";

// utils
import { colors } from "../../../util/consts";
import typography from "../../../util/typography";

// components
import Input from "../../form/Input";
import CheckRadioBox from "../../form/CheckRadioBox";
import Error from "../../form/Error";
import Form from "../../form/Form";
import Section from "../../form/Section";
import Button from "../../Button";
import Label from "../../form/Label";
import LoadingButton from "../../LoadingButton";

// icons
import LoadingIcon from "../../icons/Loading";

import { RolesContainer } from "../../elements/AccordianElements";

// grpc
import { rpc, metadata } from "../../../grpc/grpc-legacy";
import {
  GetOrganisationUserRequest,
  UpsertOrganisationUserRequest
} from "sdk/dist/organisation_user_pb";
import { UserUIType } from "sdk/dist/location_user_pb";
import { toastStore } from "../../../stores/toast-store";

interface Props {
  data: any;
  values: Values;
  errors: any;
  status: boolean;
  touched: any;
  orgUser: any;
  setStatus: (func: any) => void;
  handleChange: () => void;
  handleBlur: () => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
}

interface Values {
  Type: string;
  Loading: boolean;
}

class OrgRoles extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  handleEditClick = (e: any) => {
    this.props.setStatus(true);
  };

  async getType() {
    try {
      const req = new GetOrganisationUserRequest();
      req.setUserid(this.props.orgUser.User.ID);
      req.setOrganisationid(this.props.orgUser.Org.ID);
      const res = await rpc.organisationUser.getOrganisationUser(req, metadata());
      this.props.setStatus(true);
      this.props.values.Type = String(UserUIType[res.toObject().type]);
      this.props.values.Loading = true;
      this.props.setStatus(false);
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  render() {
    const {
      values,
      status,
      touched,
      errors,
      handleChange,
      handleBlur,
      isSubmitting,
      handleSubmit
    } = this.props;
    if (!values.Loading) {
      this.getType();
    }
    return (
      <Form onSubmit={handleSubmit}>
        <HeaderContainer>
          Organisation Types
          {!isSubmitting && status && <HeaderButton type="submit">Save</HeaderButton>}
          {!isSubmitting && !status && (
            <HeaderButton onClick={this.handleEditClick}>Edit</HeaderButton>
          )}
          {isSubmitting && <LoadingIcon color="#2c2e3c" size="small" />}
        </HeaderContainer>

        <Container>
          <Section>
            <React.Fragment>
              <RolesContainer>
                <CheckRadioBox
                  id={`addstaff-Type-FITNESS`}
                  name="Type"
                  value={String(UserUIType.FITNESS)}
                  type="radio"
                  disabled={!status}
                  checked={values.Type == String(UserUIType.FITNESS)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Type && errors.Type}
                >
                  Fitness
                </CheckRadioBox>
                {process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "AU" ? (
                  <CheckRadioBox
                    id={`addstaff-Type-HEALTHCARE`}
                    name="Type"
                    value={String(UserUIType.HEALTHCARE)}
                    type="radio"
                    disabled={!status}
                    checked={values.Type == String(UserUIType.HEALTHCARE)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.Type && errors.Type}
                  >
                    Healthcare
                  </CheckRadioBox>
                ) : null}
              </RolesContainer>
              {!!errors.Type && touched.Type && <Error>{errors.Type}</Error>}
            </React.Fragment>
          </Section>
        </Container>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ orgUser }: any) => ({
    Type: UserUIType.FITNESS
  }),
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, setStatus, setFieldError, props }) => {
    const { orgUser, dispatch } = props;
    try {
      const req = new UpsertOrganisationUserRequest();
      req.setUserid(props.orgUser.User.ID);
      req.setOrganisationid(props.orgUser.Org.ID);
      req.setType(values.Type);
      const res = await rpc.organisationUser.upsertOrganisationUser(req, metadata());
      if (props.auth.UserID == props.orgUser.User.ID) {
        localStorage.setItem("UserUIType", String(values.Type));
      }
      setStatus(false);
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  }
});

export default compose(
  connect((state: any) => ({
    auth: state.auth
  })),
  withToastManager,
  formikEnhancer
)(OrgRoles as any);

export const Container = styled.div`
  border-radius: 4px;
  margin: 8px 0 30px;
  background-color: ${colors.surface.light};
  padding: 24px 32px 30px;
`;

export const HeaderContainer = styled.div`
  /* margin-top: 30px; */
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderButton = styled(Button)`
  padding: 0;
`;

export const DetailInput = styled(Input)`
  ${typography.body2};
  letter-spacing; 0.19px;
  line-height: 18px;
  opacity: 0.6;
`;
