import React, { Component } from "react";
import { NoteAccordian } from "./NoteAccordian";
import LargeDottedButton from "./../../../../components/LargeDottedButton";
import { TabContainer, TabHeader } from "../index";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { BookingDTO, BookingClient } from "sdk/dist/bookings_pb";
import { rpc, metadata } from "../../../../grpc/grpc-legacy";
import { ListNotesRequest, Note, ListNotesResponse } from "sdk/dist/notes_pb";
import { string } from "yup";

interface Props {
  booking?: BookingDTO.AsObject;
  client: BookingClient.AsObject;
  clientID: string;
}

@observer
export class ClientNotes extends Component<Props> {
  @observable adding = false;

  state = {
    listNotes: new Array<Note.AsObject>(),
    bookingId: string,
    clientId: string
  };

  async componentDidMount() {
    if (this.props.booking) {
      this.refreshListNotes(this.props.booking.id, this.props.clientID);
    } else {
      this.refreshListNotes("", this.props.clientID);
    }
  }

  async refreshListNotes(bookingId: string, clientId: string) {
    var req = new ListNotesRequest();
    req.setBookingId(bookingId);
    req.setClientId(clientId);
    const res = await rpc.notes.get(req, metadata());
    this.setState({ listNotes: res.toObject().listnotesList });
  }

  addCard = () => {
    this.adding = true;
  };

  closeNew = () => {
    this.adding = false;
  };

  render() {
    const { client, booking } = this.props;
    return (
      <TabContainer>
        <TabHeader>Notes</TabHeader>
        {this.adding ? (
          <NoteAccordian
            client={client}
            booking={booking}
            clientID={this.props.clientID}
            closeNew={this.closeNew}
            refreshNotes={(bookingId: string, clientId: string) =>
              this.refreshListNotes(bookingId, clientId)
            }
          />
        ) : (
          <LargeDottedButton onClick={this.addCard}>ADD NEW NOTE</LargeDottedButton>
        )}
        {(!this.adding || this.state.listNotes.length > 0) &&
          this.state.listNotes.map((note) => (
            <NoteAccordian
              key={note.id}
              note={note}
              client={client}
              clientID={this.props.clientID}
              booking={booking}
              closeNew={this.closeNew}
              refreshNotes={(bookingId: string, clientId: string) =>
                this.refreshListNotes(bookingId, clientId)
              }
            />
          ))}
      </TabContainer>
    );
  }
}
