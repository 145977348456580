import React, { PureComponent } from "react";
import { ClientNotes } from "../ClientActionDialog/Notes/ClientNotes";
import { BookingDTO, BookingClient } from "sdk/dist/bookings_pb";

interface Props {
  booking: BookingDTO.AsObject;
  client: BookingClient.AsObject;
}

export class Notes extends PureComponent<Props> {
  render() {
    const { booking, client } = this.props;
    return (
      // All notes of a client are shared across all bookings
      <ClientNotes client={client} clientID={client.clientId} />
    );
  }
}
