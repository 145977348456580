import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import Error from "./../../components/form/Error";
import { createForm, updateArrayItem, removeArrayItem, addArrayItem } from "./../../forms/forms";
import {
  ThirdPartyInvoice,
  ThirdPartyInvoiceDetails,
  ThirdPartyInvoiceLineItem,
  CreateThirdPartyInvoiceRequest,
  TaxType
} from "sdk/dist/third_party_invoices_pb";
import { LoadingIndicator } from "./../../util/loading";
import {
  AccordianContainer,
  AccordianForm,
  ButtonContainer,
  ButtonHead,
  NewHeader
} from "../elements/AccordianElements";
import { CloseButton } from "../elements/CloseButton";
import Input from "../form/Input";
import Label from "../form/Label";
import Section from "../form/Section";
import LoadingButton from "../LoadingButton";
import SearchInput from "../form/SearchInput";
import { BookingDTO, BookingClient } from "sdk/dist/bookings_pb";
import { rpc, metadata } from "../../grpc/grpc-legacy";
import * as Yup from "yup";
import { validABN } from "./../../util";
import { lineItemSchema, NewLineItemForm, emptyTpiLineItem } from "./NewLineItemForm";
import LargeDottedButton from "../LargeDottedButton";
import {
  HistoryThirdParty,
  ListHistoryLocationThirdPartyRequest,
  CreateHistoryThirdPartyRequest,
  ListHistoryClientThirdPartyRequest
} from "sdk/dist/history_third_parties_pb";
import { FundType } from "sdk/dist/offerings_pb";
import { thirdPartyInvStore } from "./../../stores/third_party_inv-store";
import { accItemStore } from "./../../stores/acc_item-store";
import { ThirdPartyInvoiceTemplate } from "sdk/dist/third_party_invoice_templates_pb";
import { Charge, Money } from "sdk/dist/money_pb";
import { ISOCurrencyName } from "sdk/dist/currencies_pb";
import money_legacy from "../../util/money_legacy";

interface Props {
  booking: BookingDTO.AsObject;
  client: BookingClient.AsObject;
  template?: ThirdPartyInvoiceTemplate.AsObject;
  onRequestClose: () => void;
  dfltCcy: ISOCurrencyName;
}

export const tpiDetailsSchema = Yup.object<ThirdPartyInvoiceDetails.AsObject>({
  companyName: Yup.string().required("The entities name is required"),
  companyAbn: Yup.string().default("").test("ABN Check", "Invalid ABN Number", validABN),
  companyEmailAddress: Yup.string(),
  providerId: Yup.string().default(""),
  claimId: Yup.string().default(""),
  lineItemsList: Yup.array().of(lineItemSchema).min(1, "At least one item is required")
});

@observer
export class NewThirdPartyForm extends React.Component<Props> {
  @observable
  private history = new Array<HistoryThirdParty.AsObject>();

  @observable
  private indicator = new LoadingIndicator();

  async componentDidMount() {
    const { booking, client } = this.props;
    if (client.preferredPaymentType === FundType.THIRD_PARTY_INVOICE) {
      const req = new ListHistoryClientThirdPartyRequest();
      req.setLocationId(booking.locationId);
      req.setClientId(client.clientId);
      const res = await rpc.historyThirdParties.listHistoryClient(req, metadata());

      if (res.toObject().itemsList.length > 0) {
        this.history = res.toObject().itemsList;
      } else {
        const req = new ListHistoryLocationThirdPartyRequest();
        req.setLocationId(booking.locationId);
        const res = await rpc.historyThirdParties.listHistoryLocation(req, metadata());
        this.history = res.toObject().itemsList;
      }
    } else {
      const req = new ListHistoryLocationThirdPartyRequest();
      req.setLocationId(booking.locationId);
      const res = await rpc.historyThirdParties.listHistoryLocation(req, metadata());
      this.history = res.toObject().itemsList;
    }
  }

  private onSubmit = async (details: ThirdPartyInvoiceDetails.AsObject) => {
    const { booking, client, onRequestClose } = this.props;
    try {
      // Create ThirdPartyInvoice
      await this.indicator.while(async () => {
        const reqTPI = new CreateThirdPartyInvoiceRequest();
        reqTPI.setBookingId(booking.id);
        reqTPI.setClientId(client.clientId);
        const detailsMsg = new ThirdPartyInvoiceDetails();
        detailsMsg.setClaimId(details.claimId);
        detailsMsg.setCompanyAbn(details.companyAbn ? details.companyAbn.replace(/\s+/g, "") : "");
        detailsMsg.setCompanyEmailAddress(details.companyEmailAddress);
        detailsMsg.setCompanyName(details.companyName);
        detailsMsg.setProviderId(details.providerId);
        detailsMsg.setLineItemsList(
          details.lineItemsList.map((item) => {
            const itemMsg = new ThirdPartyInvoiceLineItem();
            itemMsg.setCode(item.code);
            itemMsg.setDescription(item.description);
            itemMsg.setCharge(money_legacy.chargeToProto(item.charge!));
            itemMsg.setTaxType(item.taxType);
            return itemMsg;
          })
        );
        reqTPI.setDetails(detailsMsg);
        const res = await rpc.thirdPartyInvoices.create(reqTPI, metadata());
        res.toObject().invoicesList.map((inv: ThirdPartyInvoice.AsObject) => {
          thirdPartyInvStore.add(inv);
          accItemStore.add(inv.accountItem!);
        });
      });
      onRequestClose();
      // Create Third Party History
      const reqHistory = new CreateHistoryThirdPartyRequest();
      reqHistory.setAbn(details.companyAbn ? details.companyAbn.replace(/\s+/g, "") : "");
      reqHistory.setEmail(details.companyEmailAddress);
      reqHistory.setName(details.companyName);
      reqHistory.setLocationId(booking.locationId);
      if (client.preferredPaymentType === FundType.THIRD_PARTY_INVOICE) {
        reqHistory.setClientId(client!.clientId);
      }
      await rpc.historyThirdParties.create(reqHistory, metadata());
    } catch (err) {
      onRequestClose();
    }
  };

  initialLineItem(): Array<ThirdPartyInvoiceLineItem.AsObject> {
    const { booking } = this.props;
    const initialLineItems = new Array<ThirdPartyInvoiceLineItem.AsObject>();
    booking.offeringsList
      .filter((inv) => inv.lineItem!.fundType == FundType.THIRD_PARTY_INVOICE)
      .map((item) => {
        initialLineItems.push({
          code: "",
          description: item.lineItem!.offering!.name,
          charge: item.lineItem!.offering!.fee,
          taxType: TaxType.INCLUDED
        });
      });
    return initialLineItems;
  }

  initialLineItemWithTemplate(): Array<ThirdPartyInvoiceLineItem.AsObject> {
    const { template } = this.props;
    const initialLineItems = new Array<ThirdPartyInvoiceLineItem.AsObject>();

    if (template && template.itemsList) {
      for (var i = 0; i < template.itemsList.length; i++) {
        const tempItem = template.itemsList[i];
        const charge = new Charge();
        const amount = new Money();
        const tax = new Money();

        if (tempItem.fee) {
          amount.setCurrencyCode(tempItem.fee.currencyCode);
          amount.setNanos(tempItem.fee.nanos);
          amount.setUnits(tempItem.fee.units);
        }
        charge.setAmount(amount);
        charge.setTax(tax);

        let lineItem = {
          code: tempItem.code,
          description: tempItem.description,
          charge: charge.toObject(),
          taxType: tempItem.taxType
        };
        initialLineItems.push(lineItem);
      }
    }
    return initialLineItems;
  }

  render() {
    const { client, template } = this.props;
    const Form = createForm<ThirdPartyInvoiceDetails.AsObject>();
    let initial: Partial<ThirdPartyInvoiceDetails.AsObject> =
      new ThirdPartyInvoiceDetails().toObject();
    if (client.preferredPaymentType === FundType.THIRD_PARTY_INVOICE && this.history.length > 0) {
      initial = {
        lineItemsList: this.initialLineItem(),
        providerId: this.props.booking.provider!.providerId,
        companyAbn: this.history[0].abn,
        companyEmailAddress: this.history[0].email,
        companyName: this.history[0].name
      };
    } else {
      if (template) {
        initial = {
          lineItemsList: this.initialLineItemWithTemplate(),
          providerId: template.providerId,
          companyAbn: template.entityAbn,
          companyEmailAddress: template.entityEmailAddress,
          companyName: template.entityName
        };
      } else {
        initial = {
          lineItemsList: this.initialLineItem(),
          providerId: this.props.booking.provider!.providerId
        };
      }
    }

    return (
      <AccordianContainer>
        {this.props.onRequestClose && <CloseButton onClick={this.props.onRequestClose} />}
        <NewHeader>
          <ButtonHead>New Third Party Invoice</ButtonHead>
        </NewHeader>
        {this.history.length >= 0 && (
          <Form
            initial={initial}
            schema={tpiDetailsSchema}
            onSubmit={this.onSubmit}
            component={AccordianForm}
          >
            {({ fields, updateField, errors }) => (
              <>
                <Section>
                  <Label>Entity Name</Label>
                  <SearchInput
                    items={this.history.map((tpi) => ({ value: tpi, label: tpi.name }))}
                    inputValue={fields.companyName || ""}
                    allowFreeText={true}
                    onInputValueChange={(value) => updateField({ companyName: value })}
                    setFieldValue={(_: any, value: HistoryThirdParty.AsObject) => {
                      updateField({
                        companyName: value.name,
                        companyAbn: value.abn,
                        companyEmailAddress: value.email
                      });
                    }}
                    placeholder="Enter the name of the entity you wish to invoice"
                  />
                  {!!errors.companyName && <Error>{errors.companyName}</Error>}
                </Section>
                <Section>
                  <Label>Entity ABN</Label>
                  <SearchInput
                    items={this.history.map((tpi) => ({ value: tpi, label: tpi.abn }))}
                    inputValue={fields.companyAbn || ""}
                    allowFreeText={true}
                    onInputValueChange={(value) => updateField({ companyAbn: value })}
                    setFieldValue={(_: any, value: HistoryThirdParty.AsObject) => {
                      updateField({
                        companyName: value.name,
                        companyAbn: value.abn,
                        companyEmailAddress: value.email
                      });
                    }}
                    placeholder="Enter the entity's ABN (optional)"
                  />
                  {!!errors.companyAbn && <Error>{errors.companyAbn}</Error>}
                </Section>
                <Section>
                  <Label>Entity Email Address</Label>
                  <SearchInput
                    items={this.history.map((tpi) => ({ value: tpi, label: tpi.email }))}
                    inputValue={fields.companyEmailAddress || ""}
                    allowFreeText={true}
                    onInputValueChange={(value) => updateField({ companyEmailAddress: value })}
                    setFieldValue={(_: any, value: HistoryThirdParty.AsObject) => {
                      updateField({
                        companyName: value.name,
                        companyAbn: value.abn,
                        companyEmailAddress: value.email
                      });
                    }}
                    placeholder="Enter the entity's Email Address (optional)"
                  />
                  {!!errors.companyEmailAddress && <Error>{errors.companyEmailAddress}</Error>}
                </Section>
                <Section>
                  <Label>Provider ID</Label>
                  <Input
                    type="text"
                    placeholder="Enter the ID of the provider (optional)"
                    value={fields.providerId || ""}
                    onChange={(event: any) =>
                      updateField({ providerId: event.currentTarget.value })
                    }
                  />

                  {!!errors.providerId && <Error>{errors.providerId}</Error>}
                </Section>
                <Section>
                  <Label>Claim or ID number</Label>
                  <Input
                    type="text"
                    placeholder="Enter the ID of the claim (optional)"
                    value={fields.claimId}
                    onChange={(event: any) => {
                      updateField({ claimId: event.currentTarget.value });
                    }}
                  />
                  {!!errors.claimId && <Error>{errors.claimId}</Error>}
                </Section>
                <Section>
                  <Label>Line Items</Label>
                  {fields.lineItemsList &&
                    fields.lineItemsList.map((lineItem: ThirdPartyInvoiceLineItem.AsObject, i) => (
                      <div key={i}>
                        <NewLineItemForm
                          key={i + "_lineitem"}
                          initial={lineItem}
                          onChange={(event: any) => {
                            updateField({
                              lineItemsList: updateArrayItem(fields.lineItemsList, i, event)
                            });
                          }}
                          onRequestDelete={
                            fields.lineItemsList && fields.lineItemsList.length === 1
                              ? undefined
                              : () =>
                                  updateField({
                                    lineItemsList: removeArrayItem(fields.lineItemsList, i)
                                  })
                          }
                          dfltCcy={this.props.dfltCcy}
                        />
                        <Error key={i + "_code"}>{errors["lineItemsList[" + i + "].code"]}</Error>
                        <Error key={i + "_description"}>
                          {errors["lineItemsList[" + i + "].description"]}
                        </Error>
                        <Error key={i + "_charge"}>
                          {errors["lineItemsList[" + i + "].charge"]}
                        </Error>
                      </div>
                    ))}

                  <LargeDottedButton
                    type="button"
                    onClick={() =>
                      updateField({
                        lineItemsList: addArrayItem(
                          fields.lineItemsList,
                          emptyTpiLineItem(this.props.dfltCcy)
                        )
                      })
                    }
                  >
                    ADD NEW ITEM
                  </LargeDottedButton>
                  {errors.lineItemsList && <Error>{errors.lineItemsList}</Error>}
                </Section>
                <ButtonContainer>
                  <LoadingButton
                    style={{ minWidth: 150 }}
                    loading={this.indicator.isLoading()}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                </ButtonContainer>
              </>
            )}
          </Form>
        )}
      </AccordianContainer>
    );
  }
}
