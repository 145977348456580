/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { BookingDTO } from "./bookings";
import { Timestamp } from "./google/protobuf/timestamp";
import { LocationCorporateMembershipSetting } from "./location_corporate_membership_setting";
import { Media } from "./media";

export const protobufPackage = "proto";

export enum UserUIType {
  HEALTHCARE = 0,
  FITNESS = 1,
  UNRECOGNIZED = -1,
}

export function userUITypeFromJSON(object: any): UserUIType {
  switch (object) {
    case 0:
    case "HEALTHCARE":
      return UserUIType.HEALTHCARE;
    case 1:
    case "FITNESS":
      return UserUIType.FITNESS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserUIType.UNRECOGNIZED;
  }
}

export function userUITypeToJSON(object: UserUIType): string {
  switch (object) {
    case UserUIType.HEALTHCARE:
      return "HEALTHCARE";
    case UserUIType.FITNESS:
      return "FITNESS";
    case UserUIType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface LocationUserDTO {
  id: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  locationId: string;
  offering: ProviderOfferingDTO[];
}

export interface ProviderOfferingDTO {
  offeringId: string;
  name: string;
  day: ProviderOfferingDay[];
  duration: number;
  fee: string;
  image: Media | undefined;
  description: string;
  locationCorporateMembershipSetting: LocationCorporateMembershipSetting | undefined;
}

export interface ProviderOfferingDay {
  date: Date | undefined;
  futureBookings: BookingDTO[];
}

export interface GetLocationUserRequest {
  userId: string;
  locationId: string;
}

export interface GetLocationUserResponse {
  userId: string;
  locationId: string;
  type: string;
}

export interface UpsertLocationUserRequest {
  userId: string;
  locationId: string;
  type: UserUIType;
}

export interface UpsertLocationUserResponse {
  userId: string;
  locationId: string;
  type: UserUIType;
}

function createBaseLocationUserDTO(): LocationUserDTO {
  return { id: "", firstName: "", lastName: "", isActive: false, locationId: "", offering: [] };
}

export const LocationUserDTO = {
  encode(message: LocationUserDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.firstName !== "") {
      writer.uint32(18).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(26).string(message.lastName);
    }
    if (message.isActive === true) {
      writer.uint32(32).bool(message.isActive);
    }
    if (message.locationId !== "") {
      writer.uint32(42).string(message.locationId);
    }
    for (const v of message.offering) {
      ProviderOfferingDTO.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocationUserDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocationUserDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isActive = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.offering.push(ProviderOfferingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LocationUserDTO {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      isActive: isSet(object.isActive) ? globalThis.Boolean(object.isActive) : false,
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      offering: globalThis.Array.isArray(object?.offering)
        ? object.offering.map((e: any) => ProviderOfferingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LocationUserDTO): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.isActive === true) {
      obj.isActive = message.isActive;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.offering?.length) {
      obj.offering = message.offering.map((e) => ProviderOfferingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LocationUserDTO>, I>>(base?: I): LocationUserDTO {
    return LocationUserDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LocationUserDTO>, I>>(object: I): LocationUserDTO {
    const message = createBaseLocationUserDTO();
    message.id = object.id ?? "";
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.isActive = object.isActive ?? false;
    message.locationId = object.locationId ?? "";
    message.offering = object.offering?.map((e) => ProviderOfferingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProviderOfferingDTO(): ProviderOfferingDTO {
  return {
    offeringId: "",
    name: "",
    day: [],
    duration: 0,
    fee: "",
    image: undefined,
    description: "",
    locationCorporateMembershipSetting: undefined,
  };
}

export const ProviderOfferingDTO = {
  encode(message: ProviderOfferingDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.offeringId !== "") {
      writer.uint32(10).string(message.offeringId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.day) {
      ProviderOfferingDay.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.duration !== 0) {
      writer.uint32(32).int32(message.duration);
    }
    if (message.fee !== "") {
      writer.uint32(42).string(message.fee);
    }
    if (message.image !== undefined) {
      Media.encode(message.image, writer.uint32(50).fork()).ldelim();
    }
    if (message.description !== "") {
      writer.uint32(58).string(message.description);
    }
    if (message.locationCorporateMembershipSetting !== undefined) {
      LocationCorporateMembershipSetting.encode(message.locationCorporateMembershipSetting, writer.uint32(66).fork())
        .ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProviderOfferingDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProviderOfferingDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offeringId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.day.push(ProviderOfferingDay.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.duration = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fee = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.image = Media.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.description = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.locationCorporateMembershipSetting = LocationCorporateMembershipSetting.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProviderOfferingDTO {
    return {
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      day: globalThis.Array.isArray(object?.day) ? object.day.map((e: any) => ProviderOfferingDay.fromJSON(e)) : [],
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      fee: isSet(object.fee) ? globalThis.String(object.fee) : "",
      image: isSet(object.image) ? Media.fromJSON(object.image) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      locationCorporateMembershipSetting: isSet(object.locationCorporateMembershipSetting)
        ? LocationCorporateMembershipSetting.fromJSON(object.locationCorporateMembershipSetting)
        : undefined,
    };
  },

  toJSON(message: ProviderOfferingDTO): unknown {
    const obj: any = {};
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.day?.length) {
      obj.day = message.day.map((e) => ProviderOfferingDay.toJSON(e));
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.fee !== "") {
      obj.fee = message.fee;
    }
    if (message.image !== undefined) {
      obj.image = Media.toJSON(message.image);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.locationCorporateMembershipSetting !== undefined) {
      obj.locationCorporateMembershipSetting = LocationCorporateMembershipSetting.toJSON(
        message.locationCorporateMembershipSetting,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProviderOfferingDTO>, I>>(base?: I): ProviderOfferingDTO {
    return ProviderOfferingDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProviderOfferingDTO>, I>>(object: I): ProviderOfferingDTO {
    const message = createBaseProviderOfferingDTO();
    message.offeringId = object.offeringId ?? "";
    message.name = object.name ?? "";
    message.day = object.day?.map((e) => ProviderOfferingDay.fromPartial(e)) || [];
    message.duration = object.duration ?? 0;
    message.fee = object.fee ?? "";
    message.image = (object.image !== undefined && object.image !== null) ? Media.fromPartial(object.image) : undefined;
    message.description = object.description ?? "";
    message.locationCorporateMembershipSetting =
      (object.locationCorporateMembershipSetting !== undefined && object.locationCorporateMembershipSetting !== null)
        ? LocationCorporateMembershipSetting.fromPartial(object.locationCorporateMembershipSetting)
        : undefined;
    return message;
  },
};

function createBaseProviderOfferingDay(): ProviderOfferingDay {
  return { date: undefined, futureBookings: [] };
}

export const ProviderOfferingDay = {
  encode(message: ProviderOfferingDay, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.futureBookings) {
      BookingDTO.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProviderOfferingDay {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProviderOfferingDay();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.futureBookings.push(BookingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProviderOfferingDay {
    return {
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      futureBookings: globalThis.Array.isArray(object?.futureBookings)
        ? object.futureBookings.map((e: any) => BookingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProviderOfferingDay): unknown {
    const obj: any = {};
    if (message.date !== undefined) {
      obj.date = message.date.toISOString();
    }
    if (message.futureBookings?.length) {
      obj.futureBookings = message.futureBookings.map((e) => BookingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProviderOfferingDay>, I>>(base?: I): ProviderOfferingDay {
    return ProviderOfferingDay.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProviderOfferingDay>, I>>(object: I): ProviderOfferingDay {
    const message = createBaseProviderOfferingDay();
    message.date = object.date ?? undefined;
    message.futureBookings = object.futureBookings?.map((e) => BookingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetLocationUserRequest(): GetLocationUserRequest {
  return { userId: "", locationId: "" };
}

export const GetLocationUserRequest = {
  encode(message: GetLocationUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.locationId !== "") {
      writer.uint32(18).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLocationUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLocationUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLocationUserRequest {
    return {
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
    };
  },

  toJSON(message: GetLocationUserRequest): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLocationUserRequest>, I>>(base?: I): GetLocationUserRequest {
    return GetLocationUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLocationUserRequest>, I>>(object: I): GetLocationUserRequest {
    const message = createBaseGetLocationUserRequest();
    message.userId = object.userId ?? "";
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseGetLocationUserResponse(): GetLocationUserResponse {
  return { userId: "", locationId: "", type: "" };
}

export const GetLocationUserResponse = {
  encode(message: GetLocationUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.locationId !== "") {
      writer.uint32(18).string(message.locationId);
    }
    if (message.type !== "") {
      writer.uint32(26).string(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLocationUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLocationUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLocationUserResponse {
    return {
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
    };
  },

  toJSON(message: GetLocationUserResponse): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLocationUserResponse>, I>>(base?: I): GetLocationUserResponse {
    return GetLocationUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLocationUserResponse>, I>>(object: I): GetLocationUserResponse {
    const message = createBaseGetLocationUserResponse();
    message.userId = object.userId ?? "";
    message.locationId = object.locationId ?? "";
    message.type = object.type ?? "";
    return message;
  },
};

function createBaseUpsertLocationUserRequest(): UpsertLocationUserRequest {
  return { userId: "", locationId: "", type: 0 };
}

export const UpsertLocationUserRequest = {
  encode(message: UpsertLocationUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.locationId !== "") {
      writer.uint32(18).string(message.locationId);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpsertLocationUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpsertLocationUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpsertLocationUserRequest {
    return {
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      type: isSet(object.type) ? userUITypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: UpsertLocationUserRequest): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.type !== 0) {
      obj.type = userUITypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpsertLocationUserRequest>, I>>(base?: I): UpsertLocationUserRequest {
    return UpsertLocationUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpsertLocationUserRequest>, I>>(object: I): UpsertLocationUserRequest {
    const message = createBaseUpsertLocationUserRequest();
    message.userId = object.userId ?? "";
    message.locationId = object.locationId ?? "";
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseUpsertLocationUserResponse(): UpsertLocationUserResponse {
  return { userId: "", locationId: "", type: 0 };
}

export const UpsertLocationUserResponse = {
  encode(message: UpsertLocationUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.locationId !== "") {
      writer.uint32(18).string(message.locationId);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpsertLocationUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpsertLocationUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpsertLocationUserResponse {
    return {
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      type: isSet(object.type) ? userUITypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: UpsertLocationUserResponse): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.type !== 0) {
      obj.type = userUITypeToJSON(message.type);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpsertLocationUserResponse>, I>>(base?: I): UpsertLocationUserResponse {
    return UpsertLocationUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpsertLocationUserResponse>, I>>(object: I): UpsertLocationUserResponse {
    const message = createBaseUpsertLocationUserResponse();
    message.userId = object.userId ?? "";
    message.locationId = object.locationId ?? "";
    message.type = object.type ?? 0;
    return message;
  },
};

export interface LocationUser {
  GetLocationUser(
    request: DeepPartial<GetLocationUserRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetLocationUserResponse>;
  UpsertLocationUser(
    request: DeepPartial<UpsertLocationUserRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpsertLocationUserResponse>;
}

export class LocationUserClientImpl implements LocationUser {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetLocationUser = this.GetLocationUser.bind(this);
    this.UpsertLocationUser = this.UpsertLocationUser.bind(this);
  }

  GetLocationUser(
    request: DeepPartial<GetLocationUserRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetLocationUserResponse> {
    return this.rpc.unary(LocationUserGetLocationUserDesc, GetLocationUserRequest.fromPartial(request), metadata);
  }

  UpsertLocationUser(
    request: DeepPartial<UpsertLocationUserRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpsertLocationUserResponse> {
    return this.rpc.unary(LocationUserUpsertLocationUserDesc, UpsertLocationUserRequest.fromPartial(request), metadata);
  }
}

export const LocationUserDesc = { serviceName: "proto.LocationUser" };

export const LocationUserGetLocationUserDesc: UnaryMethodDefinitionish = {
  methodName: "GetLocationUser",
  service: LocationUserDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetLocationUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetLocationUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const LocationUserUpsertLocationUserDesc: UnaryMethodDefinitionish = {
  methodName: "UpsertLocationUser",
  service: LocationUserDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpsertLocationUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpsertLocationUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
