/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "proto";

export interface LocationCorporateMembershipSetting {
  id: string;
  locationId: string;
  onPeakDiscountPercentage: number;
  offPeakDiscountPercentage: number;
  onPeakHours: OnPeakHours | undefined;
}

export interface OnPeakHours {
  Monday: OnPeakHoursTimeFrame[];
  Tuesday: OnPeakHoursTimeFrame[];
  Wednesday: OnPeakHoursTimeFrame[];
  Thursday: OnPeakHoursTimeFrame[];
  Friday: OnPeakHoursTimeFrame[];
  Saturday: OnPeakHoursTimeFrame[];
  Sunday: OnPeakHoursTimeFrame[];
}

export interface OnPeakHoursTimeFrame {
  Start: string;
  End: string;
}

function createBaseLocationCorporateMembershipSetting(): LocationCorporateMembershipSetting {
  return { id: "", locationId: "", onPeakDiscountPercentage: 0, offPeakDiscountPercentage: 0, onPeakHours: undefined };
}

export const LocationCorporateMembershipSetting = {
  encode(message: LocationCorporateMembershipSetting, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.locationId !== "") {
      writer.uint32(18).string(message.locationId);
    }
    if (message.onPeakDiscountPercentage !== 0) {
      writer.uint32(24).int32(message.onPeakDiscountPercentage);
    }
    if (message.offPeakDiscountPercentage !== 0) {
      writer.uint32(32).int32(message.offPeakDiscountPercentage);
    }
    if (message.onPeakHours !== undefined) {
      OnPeakHours.encode(message.onPeakHours, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocationCorporateMembershipSetting {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocationCorporateMembershipSetting();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.onPeakDiscountPercentage = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.offPeakDiscountPercentage = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.onPeakHours = OnPeakHours.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LocationCorporateMembershipSetting {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      onPeakDiscountPercentage: isSet(object.onPeakDiscountPercentage)
        ? globalThis.Number(object.onPeakDiscountPercentage)
        : 0,
      offPeakDiscountPercentage: isSet(object.offPeakDiscountPercentage)
        ? globalThis.Number(object.offPeakDiscountPercentage)
        : 0,
      onPeakHours: isSet(object.onPeakHours) ? OnPeakHours.fromJSON(object.onPeakHours) : undefined,
    };
  },

  toJSON(message: LocationCorporateMembershipSetting): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.onPeakDiscountPercentage !== 0) {
      obj.onPeakDiscountPercentage = Math.round(message.onPeakDiscountPercentage);
    }
    if (message.offPeakDiscountPercentage !== 0) {
      obj.offPeakDiscountPercentage = Math.round(message.offPeakDiscountPercentage);
    }
    if (message.onPeakHours !== undefined) {
      obj.onPeakHours = OnPeakHours.toJSON(message.onPeakHours);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LocationCorporateMembershipSetting>, I>>(
    base?: I,
  ): LocationCorporateMembershipSetting {
    return LocationCorporateMembershipSetting.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LocationCorporateMembershipSetting>, I>>(
    object: I,
  ): LocationCorporateMembershipSetting {
    const message = createBaseLocationCorporateMembershipSetting();
    message.id = object.id ?? "";
    message.locationId = object.locationId ?? "";
    message.onPeakDiscountPercentage = object.onPeakDiscountPercentage ?? 0;
    message.offPeakDiscountPercentage = object.offPeakDiscountPercentage ?? 0;
    message.onPeakHours = (object.onPeakHours !== undefined && object.onPeakHours !== null)
      ? OnPeakHours.fromPartial(object.onPeakHours)
      : undefined;
    return message;
  },
};

function createBaseOnPeakHours(): OnPeakHours {
  return { Monday: [], Tuesday: [], Wednesday: [], Thursday: [], Friday: [], Saturday: [], Sunday: [] };
}

export const OnPeakHours = {
  encode(message: OnPeakHours, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.Monday) {
      OnPeakHoursTimeFrame.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Tuesday) {
      OnPeakHoursTimeFrame.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.Wednesday) {
      OnPeakHoursTimeFrame.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.Thursday) {
      OnPeakHoursTimeFrame.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.Friday) {
      OnPeakHoursTimeFrame.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.Saturday) {
      OnPeakHoursTimeFrame.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.Sunday) {
      OnPeakHoursTimeFrame.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OnPeakHours {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOnPeakHours();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.Monday.push(OnPeakHoursTimeFrame.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.Tuesday.push(OnPeakHoursTimeFrame.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.Wednesday.push(OnPeakHoursTimeFrame.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.Thursday.push(OnPeakHoursTimeFrame.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.Friday.push(OnPeakHoursTimeFrame.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.Saturday.push(OnPeakHoursTimeFrame.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.Sunday.push(OnPeakHoursTimeFrame.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OnPeakHours {
    return {
      Monday: globalThis.Array.isArray(object?.Monday)
        ? object.Monday.map((e: any) => OnPeakHoursTimeFrame.fromJSON(e))
        : [],
      Tuesday: globalThis.Array.isArray(object?.Tuesday)
        ? object.Tuesday.map((e: any) => OnPeakHoursTimeFrame.fromJSON(e))
        : [],
      Wednesday: globalThis.Array.isArray(object?.Wednesday)
        ? object.Wednesday.map((e: any) => OnPeakHoursTimeFrame.fromJSON(e))
        : [],
      Thursday: globalThis.Array.isArray(object?.Thursday)
        ? object.Thursday.map((e: any) => OnPeakHoursTimeFrame.fromJSON(e))
        : [],
      Friday: globalThis.Array.isArray(object?.Friday)
        ? object.Friday.map((e: any) => OnPeakHoursTimeFrame.fromJSON(e))
        : [],
      Saturday: globalThis.Array.isArray(object?.Saturday)
        ? object.Saturday.map((e: any) => OnPeakHoursTimeFrame.fromJSON(e))
        : [],
      Sunday: globalThis.Array.isArray(object?.Sunday)
        ? object.Sunday.map((e: any) => OnPeakHoursTimeFrame.fromJSON(e))
        : [],
    };
  },

  toJSON(message: OnPeakHours): unknown {
    const obj: any = {};
    if (message.Monday?.length) {
      obj.Monday = message.Monday.map((e) => OnPeakHoursTimeFrame.toJSON(e));
    }
    if (message.Tuesday?.length) {
      obj.Tuesday = message.Tuesday.map((e) => OnPeakHoursTimeFrame.toJSON(e));
    }
    if (message.Wednesday?.length) {
      obj.Wednesday = message.Wednesday.map((e) => OnPeakHoursTimeFrame.toJSON(e));
    }
    if (message.Thursday?.length) {
      obj.Thursday = message.Thursday.map((e) => OnPeakHoursTimeFrame.toJSON(e));
    }
    if (message.Friday?.length) {
      obj.Friday = message.Friday.map((e) => OnPeakHoursTimeFrame.toJSON(e));
    }
    if (message.Saturday?.length) {
      obj.Saturday = message.Saturday.map((e) => OnPeakHoursTimeFrame.toJSON(e));
    }
    if (message.Sunday?.length) {
      obj.Sunday = message.Sunday.map((e) => OnPeakHoursTimeFrame.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OnPeakHours>, I>>(base?: I): OnPeakHours {
    return OnPeakHours.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OnPeakHours>, I>>(object: I): OnPeakHours {
    const message = createBaseOnPeakHours();
    message.Monday = object.Monday?.map((e) => OnPeakHoursTimeFrame.fromPartial(e)) || [];
    message.Tuesday = object.Tuesday?.map((e) => OnPeakHoursTimeFrame.fromPartial(e)) || [];
    message.Wednesday = object.Wednesday?.map((e) => OnPeakHoursTimeFrame.fromPartial(e)) || [];
    message.Thursday = object.Thursday?.map((e) => OnPeakHoursTimeFrame.fromPartial(e)) || [];
    message.Friday = object.Friday?.map((e) => OnPeakHoursTimeFrame.fromPartial(e)) || [];
    message.Saturday = object.Saturday?.map((e) => OnPeakHoursTimeFrame.fromPartial(e)) || [];
    message.Sunday = object.Sunday?.map((e) => OnPeakHoursTimeFrame.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOnPeakHoursTimeFrame(): OnPeakHoursTimeFrame {
  return { Start: "", End: "" };
}

export const OnPeakHoursTimeFrame = {
  encode(message: OnPeakHoursTimeFrame, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Start !== "") {
      writer.uint32(10).string(message.Start);
    }
    if (message.End !== "") {
      writer.uint32(18).string(message.End);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OnPeakHoursTimeFrame {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOnPeakHoursTimeFrame();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.Start = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.End = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OnPeakHoursTimeFrame {
    return {
      Start: isSet(object.Start) ? globalThis.String(object.Start) : "",
      End: isSet(object.End) ? globalThis.String(object.End) : "",
    };
  },

  toJSON(message: OnPeakHoursTimeFrame): unknown {
    const obj: any = {};
    if (message.Start !== "") {
      obj.Start = message.Start;
    }
    if (message.End !== "") {
      obj.End = message.End;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OnPeakHoursTimeFrame>, I>>(base?: I): OnPeakHoursTimeFrame {
    return OnPeakHoursTimeFrame.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OnPeakHoursTimeFrame>, I>>(object: I): OnPeakHoursTimeFrame {
    const message = createBaseOnPeakHoursTimeFrame();
    message.Start = object.Start ?? "";
    message.End = object.End ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
