import styled from "@emotion/styled";
import { format } from "date-fns";

import { hexToRgba, colors } from "../../util/consts";
import typography from "../../util/typography";
import { taxonomyCategoryStore } from "../../mobx/stores/TaxonomyCategoryStore";
import { IBooking, ILocation } from "../../IRestApiResponse";
import { useGrpc } from "../../hooks/useGrpc";
import { useAsync } from "../../hooks/useAsync";
import { BookingClient_Status } from "../../ts-sdk/dist/bookings";

interface Props {
  booking: IBooking;
  location: ILocation;
}

export const ConsumerAptDialog = (props: Props) => {
  const { services } = useGrpc();

  const StartDateTime = new Date(props.booking.start_datetime);
  const offerings =
    props.booking.Type === "Single"
      ? props.booking.Clients[0].Offerings
      : props.booking.GroupOfferings;

  const FullProvider =
    props.location &&
    props.location.Providers &&
    props.location.Providers.find((p) => p.User && p.User.ID === props.booking.Provider.ID);

  const bookingClientFetcher = useAsync(async () => {
    const bookingClient = await services.bookingClientsService.Get({ bookingId: props.booking.ID });
    return bookingClient;
  });

  return (
    <Container>
      <Content>
        <Header>
          <HeaderTitle>{format(StartDateTime, "EEEE 'at' hh:mmaaaaa")}m</HeaderTitle>
          <HeaderSubtitle style={{ marginBottom: 4 }}>
            {format(StartDateTime, "dd MMMM yyyy")}
          </HeaderSubtitle>
          <HeaderSubSubtitle
            style={props.booking.Status === "Cancelled" ? { color: "#f00f51" } : {}}
          >
            {bookingClientFetcher.value && BookingClient_Status[bookingClientFetcher.value.status]}
          </HeaderSubSubtitle>
        </Header>
        <Section>
          <div>
            <SectionHeading1>{props.location.Name}</SectionHeading1>
            <SectionBody>{props.location.Address.Raw}</SectionBody>
            <SectionBody>{props.location.Phone}</SectionBody>
          </div>
        </Section>
        <Section>
          <div>
            <SectionHeading2>
              {props.booking.Provider.FirstName} {props.booking.Provider.LastName}
              {props.booking.Type === "Group" && " - Group Booking"}
            </SectionHeading2>
            <SectionBody>
              {FullProvider &&
                FullProvider.ProviderCategories &&
                FullProvider.ProviderCategories.map((c, i) => {
                  const category = taxonomyCategoryStore.getCategory(c);
                  return (i > 0 ? ", " : "") + category.Value;
                })}
            </SectionBody>
          </div>
        </Section>
        <Section>
          {(offerings || []).map((o, i) => (
            <SectionSection key={i}>
              <div>
                <SectionHeading2>{o.Offering.Name}</SectionHeading2>
                <SectionBody>{o.Offering.ServiceDuration} mins</SectionBody>
              </div>
              {props.booking.Status !== "Created" && props.booking.Status !== "Cancelled" && (
                <SectionHeading2 style={{ opacity: 0.6 }}>
                  ${(o.Offering.Fee / 100).toFixed(2)}
                </SectionHeading2>
              )}
            </SectionSection>
          ))}
        </Section>
        {props.booking.Status !== "Created" && props.booking.Status !== "Cancelled" && (
          <>
            <Section>
              <SectionSection>
                <div>
                  <SectionHeading2>Subtotal</SectionHeading2>
                </div>
                <SectionHeading2>
                  ${(props.booking.SubtotalCharge / 100).toFixed(2)}
                </SectionHeading2>
              </SectionSection>
              <SectionSection>
                <div>
                  <SectionHeading2>GST (incl)</SectionHeading2>
                </div>
                <SectionHeading2>${(props.booking.SubtotalTax / 100).toFixed(2)}</SectionHeading2>
              </SectionSection>

              <SectionSection>
                <div>
                  <SectionHeading2>Transaction Fee (1.20%)</SectionHeading2>
                </div>
                <SectionHeading2>
                  ${(props.booking.LyfeCreditCardFee / 100).toFixed(2)}
                </SectionHeading2>
              </SectionSection>

              <SectionSection>
                <div>
                  <SectionHeading2 style={{ fontWeight: 600 }}>Total</SectionHeading2>
                </div>
                <SectionHeading2 style={{ fontWeight: 600 }}>
                  ${(props.booking.TotalCharge / 100).toFixed(2)}
                </SectionHeading2>
              </SectionSection>
            </Section>
          </>
        )}
        <Section>
          <div>
            <SectionHeading2>Cancelation policy</SectionHeading2>
            <SectionBody>
              Please note cancellations within 24 hours of the appointment may incur a cancellation
              fee.
            </SectionBody>
          </div>
        </Section>
      </Content>
    </Container>
  );
};

export default ConsumerAptDialog;

const Container = styled.div`
  padding: 10px;
`;

const Content = styled.div``;

const Header = styled.div`
  margin-bottom: 42px;
`;

const HeaderTitle = styled.div`
  ${typography.heading5};
  font-weight: 600;
`;

const HeaderSubtitle = styled.div`
  ${typography.body2};
  font-weight: 600;
  letter-spacing: 0.09px;
  color: ${colors.surfaceText.medEmphasis};
`;

const HeaderSubSubtitle = styled.div`
  ${typography.caption};
  color: ${colors.secondary.main};
`;

const Section = styled.div`
  padding-bottom: 18px;
  border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
  margin: 16px 0;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SectionSection = styled.div`
  display: flex;
  align-itmes: center;
  justify-content: space-between;

  margin: 12px 0;

  &first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SectionHeading1 = styled.div`
  ${typography.subtitle1};
  font-weight: 600;
  letter-spacing: 0.29px;
  line-height: 20px;
`;

const SectionHeading2 = styled.div`
  ${typography.subtitle2};
  letter-spacing: 0.25px;
  line-height: 20px;
`;

const SectionBody = styled.div`
  ${typography.body2};
  letter-spacing: 0.21px;
  line-height: 20px;
  color: ${hexToRgba(colors.primary.main, 0.6)};
`;
