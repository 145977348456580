import { withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import * as Yup from "yup";
import { metadata, rpc } from "../../../grpc/grpc-legacy";
import apiService, { APIError, HTTPMethod } from '../../../redux/services/api';
import { AffiliateCodeRequest } from 'sdk/dist/affiliate_code_pb';
import { getAuthenticatedUser } from "./../../../components/elements/AuthGetUser";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import Form from "./../../../components/form/Form";
import FormHeader from "./../../../components/form/FormHeader";
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import Section from "./../../../components/form/Section";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import LoadingButton from "./../../../components/LoadingButton";
import * as ResourceActions from "./../../../redux/features/resources/thunkactions";
import { CountrySelect } from "./../../../ui-kit/form/CountrySelect";
import { LocationSearchInput } from "./../../../ui-kit/form/LocationSearchInput";
import { findPlaceDetails, getAddressResourceFromPlaceDetails } from "./../../../util/address";
import { parseFormError } from "./../../../util/form";


class OrgCreateAccount_General extends Component {
  componentWillUnmount() {
    this.props.setData({ ...this.props.values });
  }

  handleValidateCode = async(value) => {
    const { setFieldValue, setFieldError } = this.props;
    const name ='Affiliate';
    const text = value.currentTarget.value
    setFieldValue(name, text, true);
    try{
    const reqAffiliate = new AffiliateCodeRequest();

    reqAffiliate.setAffiliateCode(text.trim());
    const resAffiliate = await rpc.affiliateService.get(reqAffiliate, metadata());
    const affiliateCodeExists = resAffiliate.toObject().exists;
    if (!affiliateCodeExists){
      setFieldError(name, "Not a valid code");
    }
    } catch {
      setFieldError(name, "Not a valid code");
    }
  }

  render() {
    const {
      values,
      errors,
      touched,
      dirty,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;
    return (
      <Form
        onSubmit={
          isSubmitting
            ? (e) => {
              e.preventDefault();
              return false;
            }
            : handleSubmit
        }
      >
        <FormHeader
          title="Business Information"
          subtitle="Please fill out your business details."
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="createorg-name">Business Name</Label>
            <Input
              id="createorg-name"
              name="Name"
              type="text"
              placeholder="Enter business name"
              autoFocus={true}
              value={values.Name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Name && errors.Name}
            />
            {!!errors.Name && touched.Name && <Error>{errors.Name}</Error>}
          </Section>
          <Section>
            <Label htmlFor="createorg-email">Contact Email</Label>
            <Input
              id="createorg-email"
              name="ContactEmail"
              type="text"
              placeholder="some@example.com"
              value={values.ContactEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.ContactEmail && errors.ContactEmail}
            />
            {!!errors.ContactEmail && touched.ContactEmail && <Error>{errors.ContactEmail}</Error>}
          </Section>
          <Section>
            <Label htmlFor="createorg-phone">Contact Phone</Label>
            <Input
              id="createorg-phone"
              name="ContactPhone"
              type="text"
              placeholder= {process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "AU" ? "03 1234 3210" : "406 750 9999"}
              value={values.ContactPhone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.ContactPhone && errors.ContactPhone}
            />
            {!!errors.ContactPhone && touched.ContactPhone && <Error>{errors.ContactPhone}</Error>}
          </Section>
          <Section>
                <Label htmlFor="createorg-Country">Country</Label>
                <CountrySelect
                  id="createorg-Country"
                  name="Country"
                  value={values.Country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {!!errors.Country && touched.Country && <Error>{errors.Country}</Error>}
              </Section>
          <Section>
            <Label htmlFor="createorg-address">Business Address</Label>
            <LocationSearchInput
              id="createorg-address"
              name="Address"
              type="text"
              placeholder="Enter business address"
              value={values.Address}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.Address && errors.Address}
              countryCodes={values.Country}
            />
            {!!errors.Address && touched.Address && <Error>{errors.Address}</Error>}
          </Section>
          <Section>
            <Label htmlFor="createorg-affiliate">Affiliate code</Label>
            <Input
              id="createorg-affiliate"
              name="Affiliate"
              type="text"
              placeholder="Enter affiliate code"
              value={values.Affiliate}
              onChange={this.handleValidateCode}
              error={touched.Affiliate && errors.Affiliate}
            />
            {!!errors.Affiliate && <Error>{errors.Affiliate}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <LoadingButton
            style={{ width: 150 }}
            loading={isSubmitting}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={!dirty && !pageData.Name}
          >
            Continue
          </LoadingButton>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData, authUser }) => ({
    Name: pageData.Name || "",
    ContactEmail: pageData.ContactEmail || authUser.Email || "",
    ContactPhone: pageData.ContactPhone || authUser.MobileNumber || "",
    Address: pageData.Address || "",
    Affiliate: pageData.Affiliate || "",
    isLoggedIn: true,
    Country: process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY
  }),
  validationSchema: Yup.object().shape({
    Name: Yup.string().required("Name is required."),
    ContactEmail: Yup.string()
      .email("Email is invalid.")
      .required("Email is required."),
    ContactPhone: Yup.string().required("Phone is required."),
    Address: Yup.string().required("Address is required"),
    Affiliate: Yup.string().required("")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      // Find and create the address.
      let addrID;
      if (props.pageData.Address !== values.Address) {
        const prDet = await findPlaceDetails(values.Address, "Address");
        const prAddRes = {
          OwnerType: "Organisation",
          ...getAddressResourceFromPlaceDetails(prDet, values.Address)
        };
        const prRes = await props.dispatch(ResourceActions.action(prAddRes, "Create", {}));
        addrID = prRes.Payload.ID;
      } else {
        addrID = props.pageData.AddressID;
      }
    const reqAffiliate = new AffiliateCodeRequest();
    reqAffiliate.setAffiliateCode(values.Affiliate.trim());
    const resAffiliate = await rpc.affiliateService.get(reqAffiliate, metadata());
    const affiliateCodeExists = resAffiliate.toObject().exists;
    if (!affiliateCodeExists){
      setFieldError("Affiliate", "Not a valid code");
      throw apiService.errorProcess(
        new APIError(HTTPMethod.Get, 'affiliateService Grpc',
        {
          Status: `Error`,
          StatusCode: 400,
          Errors: [
            {UserMessage: "Not a valid code"}
          ]
        }
      ));
    }

      props.setPage("desc", {
        Name: values.Name,
        ContactEmail: values.ContactEmail,
        ContactPhone: values.ContactPhone,
        Address: values.Address,
        AddressID: addrID,
        Affiliate: values.Affiliate,
        Country: values.Country
      });

    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }
    setSubmitting(false);
  },
  displayName: "OrgCreateAccountGeneral"
});

export default compose(
  connect((state) => ({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  formikEnhancer
)(OrgCreateAccount_General);
