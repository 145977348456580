/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: tag.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.proto = require('./tag_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.TagServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.TagServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListTagRequest,
 *   !proto.proto.Tags>}
 */
const methodDescriptor_TagService_List = new grpc.web.MethodDescriptor(
  '/proto.TagService/List',
  grpc.web.MethodType.UNARY,
  proto.proto.ListTagRequest,
  proto.proto.Tags,
  /**
   * @param {!proto.proto.ListTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Tags.deserializeBinary
);


/**
 * @param {!proto.proto.ListTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.Tags)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Tags>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/List',
      request,
      metadata || {},
      methodDescriptor_TagService_List,
      callback);
};


/**
 * @param {!proto.proto.ListTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Tags>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/List',
      request,
      metadata || {},
      methodDescriptor_TagService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateTagRequest,
 *   !proto.proto.Tag>}
 */
const methodDescriptor_TagService_Create = new grpc.web.MethodDescriptor(
  '/proto.TagService/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateTagRequest,
  proto.proto.Tag,
  /**
   * @param {!proto.proto.CreateTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Tag.deserializeBinary
);


/**
 * @param {!proto.proto.CreateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.Tag)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Tag>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/Create',
      request,
      metadata || {},
      methodDescriptor_TagService_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Tag>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/Create',
      request,
      metadata || {},
      methodDescriptor_TagService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteTagRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TagService_Delete = new grpc.web.MethodDescriptor(
  '/proto.TagService/Delete',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteTagRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.DeleteTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/Delete',
      request,
      metadata || {},
      methodDescriptor_TagService_Delete,
      callback);
};


/**
 * @param {!proto.proto.DeleteTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/Delete',
      request,
      metadata || {},
      methodDescriptor_TagService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListClientTagsRequest,
 *   !proto.proto.ClientTags>}
 */
const methodDescriptor_TagService_ListClientTags = new grpc.web.MethodDescriptor(
  '/proto.TagService/ListClientTags',
  grpc.web.MethodType.UNARY,
  proto.proto.ListClientTagsRequest,
  proto.proto.ClientTags,
  /**
   * @param {!proto.proto.ListClientTagsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ClientTags.deserializeBinary
);


/**
 * @param {!proto.proto.ListClientTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ClientTags)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ClientTags>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.listClientTags =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/ListClientTags',
      request,
      metadata || {},
      methodDescriptor_TagService_ListClientTags,
      callback);
};


/**
 * @param {!proto.proto.ListClientTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ClientTags>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.listClientTags =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/ListClientTags',
      request,
      metadata || {},
      methodDescriptor_TagService_ListClientTags);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AddTagToClientRequest,
 *   !proto.proto.TagClientPair>}
 */
const methodDescriptor_TagService_AddTagToClient = new grpc.web.MethodDescriptor(
  '/proto.TagService/AddTagToClient',
  grpc.web.MethodType.UNARY,
  proto.proto.AddTagToClientRequest,
  proto.proto.TagClientPair,
  /**
   * @param {!proto.proto.AddTagToClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.TagClientPair.deserializeBinary
);


/**
 * @param {!proto.proto.AddTagToClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.TagClientPair)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.TagClientPair>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.addTagToClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/AddTagToClient',
      request,
      metadata || {},
      methodDescriptor_TagService_AddTagToClient,
      callback);
};


/**
 * @param {!proto.proto.AddTagToClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.TagClientPair>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.addTagToClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/AddTagToClient',
      request,
      metadata || {},
      methodDescriptor_TagService_AddTagToClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RemoveTagFromClientRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TagService_RemovetagFromClient = new grpc.web.MethodDescriptor(
  '/proto.TagService/RemovetagFromClient',
  grpc.web.MethodType.UNARY,
  proto.proto.RemoveTagFromClientRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.RemoveTagFromClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.proto.RemoveTagFromClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.removetagFromClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/RemovetagFromClient',
      request,
      metadata || {},
      methodDescriptor_TagService_RemovetagFromClient,
      callback);
};


/**
 * @param {!proto.proto.RemoveTagFromClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.removetagFromClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/RemovetagFromClient',
      request,
      metadata || {},
      methodDescriptor_TagService_RemovetagFromClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.MultiTagClientRequest,
 *   !proto.proto.MultiTagClientResponse>}
 */
const methodDescriptor_TagService_AddMultiTagsClient = new grpc.web.MethodDescriptor(
  '/proto.TagService/AddMultiTagsClient',
  grpc.web.MethodType.UNARY,
  proto.proto.MultiTagClientRequest,
  proto.proto.MultiTagClientResponse,
  /**
   * @param {!proto.proto.MultiTagClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.MultiTagClientResponse.deserializeBinary
);


/**
 * @param {!proto.proto.MultiTagClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.MultiTagClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.MultiTagClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.addMultiTagsClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/AddMultiTagsClient',
      request,
      metadata || {},
      methodDescriptor_TagService_AddMultiTagsClient,
      callback);
};


/**
 * @param {!proto.proto.MultiTagClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.MultiTagClientResponse>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.addMultiTagsClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/AddMultiTagsClient',
      request,
      metadata || {},
      methodDescriptor_TagService_AddMultiTagsClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ReplaceClientTagsResquest,
 *   !proto.proto.ClientTags>}
 */
const methodDescriptor_TagService_ReplaceClientTags = new grpc.web.MethodDescriptor(
  '/proto.TagService/ReplaceClientTags',
  grpc.web.MethodType.UNARY,
  proto.proto.ReplaceClientTagsResquest,
  proto.proto.ClientTags,
  /**
   * @param {!proto.proto.ReplaceClientTagsResquest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ClientTags.deserializeBinary
);


/**
 * @param {!proto.proto.ReplaceClientTagsResquest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ClientTags)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ClientTags>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.replaceClientTags =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/ReplaceClientTags',
      request,
      metadata || {},
      methodDescriptor_TagService_ReplaceClientTags,
      callback);
};


/**
 * @param {!proto.proto.ReplaceClientTagsResquest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ClientTags>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.replaceClientTags =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/ReplaceClientTags',
      request,
      metadata || {},
      methodDescriptor_TagService_ReplaceClientTags);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListReferralTagsRequest,
 *   !proto.proto.ReferralTags>}
 */
const methodDescriptor_TagService_ListReferralTags = new grpc.web.MethodDescriptor(
  '/proto.TagService/ListReferralTags',
  grpc.web.MethodType.UNARY,
  proto.proto.ListReferralTagsRequest,
  proto.proto.ReferralTags,
  /**
   * @param {!proto.proto.ListReferralTagsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ReferralTags.deserializeBinary
);


/**
 * @param {!proto.proto.ListReferralTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ReferralTags)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ReferralTags>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.listReferralTags =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/ListReferralTags',
      request,
      metadata || {},
      methodDescriptor_TagService_ListReferralTags,
      callback);
};


/**
 * @param {!proto.proto.ListReferralTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ReferralTags>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.listReferralTags =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/ListReferralTags',
      request,
      metadata || {},
      methodDescriptor_TagService_ListReferralTags);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AddTagToReferralRequest,
 *   !proto.proto.TagReferralPair>}
 */
const methodDescriptor_TagService_AddTagToReferral = new grpc.web.MethodDescriptor(
  '/proto.TagService/AddTagToReferral',
  grpc.web.MethodType.UNARY,
  proto.proto.AddTagToReferralRequest,
  proto.proto.TagReferralPair,
  /**
   * @param {!proto.proto.AddTagToReferralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.TagReferralPair.deserializeBinary
);


/**
 * @param {!proto.proto.AddTagToReferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.TagReferralPair)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.TagReferralPair>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.addTagToReferral =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/AddTagToReferral',
      request,
      metadata || {},
      methodDescriptor_TagService_AddTagToReferral,
      callback);
};


/**
 * @param {!proto.proto.AddTagToReferralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.TagReferralPair>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.addTagToReferral =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/AddTagToReferral',
      request,
      metadata || {},
      methodDescriptor_TagService_AddTagToReferral);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RemoveTagFromReferralRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TagService_RemoveRagFromReferral = new grpc.web.MethodDescriptor(
  '/proto.TagService/RemoveRagFromReferral',
  grpc.web.MethodType.UNARY,
  proto.proto.RemoveTagFromReferralRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.RemoveTagFromReferralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.proto.RemoveTagFromReferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.removeRagFromReferral =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/RemoveRagFromReferral',
      request,
      metadata || {},
      methodDescriptor_TagService_RemoveRagFromReferral,
      callback);
};


/**
 * @param {!proto.proto.RemoveTagFromReferralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.removeRagFromReferral =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/RemoveRagFromReferral',
      request,
      metadata || {},
      methodDescriptor_TagService_RemoveRagFromReferral);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AddMultiTagsToReferralRequest,
 *   !proto.proto.ReferralTags>}
 */
const methodDescriptor_TagService_AddMultiTagsToReferral = new grpc.web.MethodDescriptor(
  '/proto.TagService/AddMultiTagsToReferral',
  grpc.web.MethodType.UNARY,
  proto.proto.AddMultiTagsToReferralRequest,
  proto.proto.ReferralTags,
  /**
   * @param {!proto.proto.AddMultiTagsToReferralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ReferralTags.deserializeBinary
);


/**
 * @param {!proto.proto.AddMultiTagsToReferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ReferralTags)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ReferralTags>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.addMultiTagsToReferral =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/AddMultiTagsToReferral',
      request,
      metadata || {},
      methodDescriptor_TagService_AddMultiTagsToReferral,
      callback);
};


/**
 * @param {!proto.proto.AddMultiTagsToReferralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ReferralTags>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.addMultiTagsToReferral =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/AddMultiTagsToReferral',
      request,
      metadata || {},
      methodDescriptor_TagService_AddMultiTagsToReferral);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ReplaceReferralTagsRequest,
 *   !proto.proto.ReferralTags>}
 */
const methodDescriptor_TagService_ReplaceReferralTags = new grpc.web.MethodDescriptor(
  '/proto.TagService/ReplaceReferralTags',
  grpc.web.MethodType.UNARY,
  proto.proto.ReplaceReferralTagsRequest,
  proto.proto.ReferralTags,
  /**
   * @param {!proto.proto.ReplaceReferralTagsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ReferralTags.deserializeBinary
);


/**
 * @param {!proto.proto.ReplaceReferralTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ReferralTags)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ReferralTags>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.TagServiceClient.prototype.replaceReferralTags =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.TagService/ReplaceReferralTags',
      request,
      metadata || {},
      methodDescriptor_TagService_ReplaceReferralTags,
      callback);
};


/**
 * @param {!proto.proto.ReplaceReferralTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ReferralTags>}
 *     Promise that resolves to the response
 */
proto.proto.TagServicePromiseClient.prototype.replaceReferralTags =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.TagService/ReplaceReferralTags',
      request,
      metadata || {},
      methodDescriptor_TagService_ReplaceReferralTags);
};


module.exports = proto.proto;

