import moment from "moment";
import React, { useEffect, useState } from "react";
import { metadata, rpc } from "../../../grpc/grpc-legacy";
import { BookingSearchFiltersRequest, BookingSearchFiltersResponse } from "sdk/dist/location_pb";
import { sharedLocationService } from "../../../services/LocationService";
import { toastStore } from "../../../stores/toast-store";
import { DatePicker } from "../../../ui-kit/DatePicker";
import { Dropdown } from "../../../ui-kit/Dropdown";
import { FlexBox, FlexItem } from "../../../ui-kit/FlexBox";
import { Stack } from "../../../ui-kit/Stack";
import { Typography } from "../../../ui-kit/Typography";
import { ChevronLeftIcon } from "../../../ui-kit/icons/ChevronLeftIcon";
import { ChevronRightIcon } from "../../../ui-kit/icons/ChevronRightIcon";
import { timezone } from "../../../util/timestamp";
import ClassCard from "./ClassCard";
import { useGrpc } from "../../../hooks/useGrpc";
import { SearchQueryRequest_CategoryLevel } from "../../../ts-sdk/dist/search";
import { BookingSearchResponse } from "../../../ts-sdk/dist/location";

interface IProps {
  locationId: string;
  serviceID: string;
  isFiltersVisible: boolean;
  subjectId?: string;
}

const NUMBER_OF_DAYS = 7;

const ServicesBlock = (props: IProps) => {
  const { services } = useGrpc();
  const [filterDate, setFilterDate] = useState(moment().format("yyyy-MM-DD"));
  const [selectedLevel, setSelectedLevel] = useState<SearchQueryRequest_CategoryLevel>(
    SearchQueryRequest_CategoryLevel.NONE
  );
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedService, setSelectedService] = useState(
    props.serviceID !== "none" ? props.serviceID : ""
  );
  const [searchResults, setSearchResults] = useState<BookingSearchResponse>();
  const [filters, setFilters] = useState<BookingSearchFiltersResponse.AsObject>();

  useEffect(() => {
    fetchFilters();
    fetchFirstBooking();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedProvider, selectedLevel, filterDate, selectedService]);

  // Snap to the day of first upcoming booking
  async function fetchFirstBooking() {
    try {
      const response = await sharedLocationService.getLocUpcomingBookings(props.locationId);
      if (response.length > 0) {
        setFilterDate(response[0].StartDateTimeTZ.split("T")[0]);
      }
    } catch (error) {
      toastStore.error(error);
    }
  }

  // Gets the list of bookings based on filters
  async function fetchData() {
    try {
      var tempDate = new Date();
      var currentDate = moment(tempDate, "YYYY-MM-DD");

      // get timestamp from date
      console.log("timezone()", timezone());
      let date = moment(filterDate, "yyyy-MM-DD").tz(timezone());

      // if filter date is today, then add current time
      if (
        moment(filterDate, "YYYY-MM-DD").format("YYYY-MM-DD") === currentDate.format("YYYY-MM-DD")
      ) {
        // current time
        var currentTime = ` ${tempDate.getHours()}:${tempDate.getMinutes()}:${tempDate.getSeconds()}`;
        // get timestamp from date
        date = moment(filterDate + currentTime, "yyyy-MM-DD hh:mm:ss").tz(timezone());
      }

      const tz = timezone();

      const res = await services.locationBookingService.BookingSearch({
        locationId: props.locationId,
        offeringId: selectedService,
        providerId: selectedProvider,
        numberOfDays: NUMBER_OF_DAYS,
        level: selectedLevel,
        searchDatetime: date.toDate(),
        timezone: tz
      });
      setSearchResults(res);
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  async function fetchFilters() {
    try {
      const filtersReq = new BookingSearchFiltersRequest();
      filtersReq.setLocationId(props.locationId);
      const filtersRes = await rpc.locationBookings.bookingSearchFilters(filtersReq, metadata());
      setFilters(filtersRes.toObject());

      if (props.subjectId) {
        const provider = filtersRes
          .toObject()
          .providerList.filter((a) => a.subjectId === props.subjectId)[0];
        setSelectedProvider(provider ? provider.providerId : "");
      }
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  // calculates day offset used for pagination
  let dayOffset: number;
  if (moment(filterDate, "yyyy-MM-DD").isSame(moment(), "day")) {
    dayOffset = 0;
  } else {
    dayOffset = moment(filterDate, "yyyy-MM-DD").diff(moment(), "days") + 1;
  }

  // loops through all the providers bookings and services attached to the booking to display correctly
  const renderClassCard = () => {
    if (searchResults && filters) {
      let cards = [];
      for (let provider of searchResults.provider) {
        for (let offering of provider.offering) {
          cards.push(
            <ClassCard
              key={`${provider.id}-${offering.offeringId}`}
              fetchData={fetchData}
              service={offering}
              provider={provider}
              searchResults={searchResults}
              filterDate={filterDate}
            />
          );
        }
      }
      if (cards.length == 0) {
        cards.push(
          <Typography.Body>There are no appointments available on these days</Typography.Body>
        );
      }
      return cards;
    }
  };

  return (
    <div id="bio" className="p-0 sm:4/5 mx-auto">
      {searchResults ? (
        <Stack span={1} gap={[0, 6]} direction="row">
          {props.isFiltersVisible && (
            <Stack span={1} gap={[0, 3]} direction="row">
              <div>
                <Dropdown
                  label="Trainer"
                  value={selectedProvider}
                  onChange={(v) => (v ? setSelectedProvider(v) : setSelectedProvider(""))}
                  options={
                    filters && filters.providerList && filters.providerList.length
                      ? filters.providerList.map((provider) => ({
                          label: provider.firstName + " " + provider.lastName,
                          value: provider.providerId,
                          disabled: !provider.hasBooking
                        }))
                      : []
                  }
                />
              </div>
              <div>
                <Dropdown
                  label="Service"
                  value={selectedService}
                  onChange={(v) => setSelectedService(v ? v : "")}
                  options={
                    filters && filters.offeringList && filters.offeringList.length
                      ? filters.offeringList.map((offering) => ({
                          value: offering.offeringId,
                          label: offering.name,
                          disabled: !offering.hasBooking
                        }))
                      : []
                  }
                />
              </div>
              <div>
                <Dropdown
                  label="Difficulty"
                  value={selectedLevel}
                  onChange={(v) =>
                    v === undefined
                      ? setSelectedLevel(SearchQueryRequest_CategoryLevel.NONE)
                      : setSelectedLevel(v)
                  }
                  options={Object.keys(SearchQueryRequest_CategoryLevel).map((level, index) => {
                    let abled = true;
                    if (filters) {
                      abled = filters!.availableLevelList.includes(level) || index == 0;
                    }
                    return {
                      value: index,
                      label: level == "NONE" ? "Any" : level,
                      disabled: !abled
                    };
                  })}
                />
              </div>
              <div>
                <DatePicker
                  label="Date"
                  placeholder=""
                  value={filterDate}
                  onChange={(v) => {
                    if (moment(v, "yyyy-MM-DD").isBefore(moment(), "days")) {
                      toastStore.error("Please select a date equal or greater than today");
                    } else if (v) {
                      setFilterDate(v);
                    }
                  }}
                />
              </div>
            </Stack>
          )}

          <div>
            <FlexBox direction="row" justifyContent="between" alignItems="center">
              <FlexItem>
                <Typography.H3 color="primary">Results</Typography.H3>
              </FlexItem>

              <FlexItem>
                <FlexBox alignItems="center">
                  {!!dayOffset && (
                    <FlexItem>
                      <div
                        onClick={() =>
                          setFilterDate(
                            moment()
                              .add(dayOffset - NUMBER_OF_DAYS, "days")
                              .format("yyyy-MM-DD")
                          )
                        }
                      >
                        <ChevronLeftIcon />
                      </div>
                    </FlexItem>
                  )}

                  <FlexItem>
                    <Typography.Label color="primary">
                      {moment().add(dayOffset, "days").format("D MMM") +
                        " - " +
                        moment()
                          .add(dayOffset + NUMBER_OF_DAYS - 1, "days")
                          .format("D MMM")}
                    </Typography.Label>
                  </FlexItem>

                  <FlexItem>
                    <div
                      onClick={() =>
                        setFilterDate(
                          moment()
                            .add(dayOffset + NUMBER_OF_DAYS, "days")
                            .format("yyyy-MM-DD")
                        )
                      }
                    >
                      <ChevronRightIcon />
                    </div>
                  </FlexItem>
                </FlexBox>
              </FlexItem>
            </FlexBox>
            {renderClassCard()}
          </div>
        </Stack>
      ) : (
        "No classess currently available"
      )}
    </div>
  );
};

export default ServicesBlock;
