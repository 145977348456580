/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "proto";

export enum CorporateMembershipStatusEnumDTO {
  ACTIVE = 0,
  INACTIVE = 1,
  PAUSED = 2,
  UNRECOGNIZED = -1,
}

export function corporateMembershipStatusEnumDTOFromJSON(object: any): CorporateMembershipStatusEnumDTO {
  switch (object) {
    case 0:
    case "ACTIVE":
      return CorporateMembershipStatusEnumDTO.ACTIVE;
    case 1:
    case "INACTIVE":
      return CorporateMembershipStatusEnumDTO.INACTIVE;
    case 2:
    case "PAUSED":
      return CorporateMembershipStatusEnumDTO.PAUSED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CorporateMembershipStatusEnumDTO.UNRECOGNIZED;
  }
}

export function corporateMembershipStatusEnumDTOToJSON(object: CorporateMembershipStatusEnumDTO): string {
  switch (object) {
    case CorporateMembershipStatusEnumDTO.ACTIVE:
      return "ACTIVE";
    case CorporateMembershipStatusEnumDTO.INACTIVE:
      return "INACTIVE";
    case CorporateMembershipStatusEnumDTO.PAUSED:
      return "PAUSED";
    case CorporateMembershipStatusEnumDTO.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AddCorporateMembershipRequest {
  name: string;
  creditPerStaffPerMonth: number;
  unallocatedCredit: number;
}

export interface AddCorporateMembershipResponse {
}

export interface GetCorporateMembershipRequest {
  id: string;
}

export interface GetCorporateMembershipResponse {
  data: CorporateMembershipDTO | undefined;
}

export interface UpdateCorporateMembershipRequest {
  id: string;
  status: CorporateMembershipStatusEnumDTO;
}

export interface UpdateCorporateMembershipResponse {
  data: CorporateMembershipDTO | undefined;
}

export interface UpdateCorporateMembershipDetailsRequest {
  id: string;
  name: string;
  creditPerStaffPerMonth: number;
  unallocatedCredit: number;
}

export interface UpdateCorporateMembershipDetailsResponse {
}

export interface UpdateCorporateMembershipStatusRequest {
  id: string;
  status: CorporateMembershipStatusEnumDTO;
}

export interface UpdateCorporateMembershipStatusResponse {
}

export interface ListCorporateMembershipsRequest {
}

export interface ListCorporateMembershipsResponse {
  data: CorporateMembershipPreviewDTO[];
}

export interface GetMonthlyReviewRequest {
  startDate: Date | undefined;
  endDate: Date | undefined;
  previousMonthStartDate: Date | undefined;
  previousMonthEndDate: Date | undefined;
}

export interface GetMonthlyReviewResponse {
  totalCreditSpent: number;
  totalCreditSpentPercentage: number;
  sessionsBooked: number;
  sessionsBookedPercentage: number;
  activeStaff: number;
  activeStaffPercentage: number;
}

export interface AddSeedingDataRequest {
}

export interface AddSeedingDataResponse {
}

export interface CorporateMembershipDTO {
  id: string;
  name: string;
  status: CorporateMembershipStatusEnumDTO;
  totalStaff: number;
  creditPerStaffPerMonth: number;
  unallocatedCredit: number;
  allocatedCredit: number;
  totalSpendLastThirtyDays: number;
  averageStaffSpendLastThirtyDays: number;
  renewalDate: Date | undefined;
}

export interface CorporateMembershipPreviewDTO {
  id: string;
  name: string;
  status: CorporateMembershipStatusEnumDTO;
  totalStaff: number;
  creditPerStaffPerMonth: number;
  remainingCredit: number;
}

function createBaseAddCorporateMembershipRequest(): AddCorporateMembershipRequest {
  return { name: "", creditPerStaffPerMonth: 0, unallocatedCredit: 0 };
}

export const AddCorporateMembershipRequest = {
  encode(message: AddCorporateMembershipRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.creditPerStaffPerMonth !== 0) {
      writer.uint32(17).double(message.creditPerStaffPerMonth);
    }
    if (message.unallocatedCredit !== 0) {
      writer.uint32(25).double(message.unallocatedCredit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddCorporateMembershipRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddCorporateMembershipRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.creditPerStaffPerMonth = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.unallocatedCredit = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddCorporateMembershipRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      creditPerStaffPerMonth: isSet(object.creditPerStaffPerMonth)
        ? globalThis.Number(object.creditPerStaffPerMonth)
        : 0,
      unallocatedCredit: isSet(object.unallocatedCredit) ? globalThis.Number(object.unallocatedCredit) : 0,
    };
  },

  toJSON(message: AddCorporateMembershipRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.creditPerStaffPerMonth !== 0) {
      obj.creditPerStaffPerMonth = message.creditPerStaffPerMonth;
    }
    if (message.unallocatedCredit !== 0) {
      obj.unallocatedCredit = message.unallocatedCredit;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddCorporateMembershipRequest>, I>>(base?: I): AddCorporateMembershipRequest {
    return AddCorporateMembershipRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddCorporateMembershipRequest>, I>>(
    object: I,
  ): AddCorporateMembershipRequest {
    const message = createBaseAddCorporateMembershipRequest();
    message.name = object.name ?? "";
    message.creditPerStaffPerMonth = object.creditPerStaffPerMonth ?? 0;
    message.unallocatedCredit = object.unallocatedCredit ?? 0;
    return message;
  },
};

function createBaseAddCorporateMembershipResponse(): AddCorporateMembershipResponse {
  return {};
}

export const AddCorporateMembershipResponse = {
  encode(_: AddCorporateMembershipResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddCorporateMembershipResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddCorporateMembershipResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AddCorporateMembershipResponse {
    return {};
  },

  toJSON(_: AddCorporateMembershipResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AddCorporateMembershipResponse>, I>>(base?: I): AddCorporateMembershipResponse {
    return AddCorporateMembershipResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddCorporateMembershipResponse>, I>>(_: I): AddCorporateMembershipResponse {
    const message = createBaseAddCorporateMembershipResponse();
    return message;
  },
};

function createBaseGetCorporateMembershipRequest(): GetCorporateMembershipRequest {
  return { id: "" };
}

export const GetCorporateMembershipRequest = {
  encode(message: GetCorporateMembershipRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCorporateMembershipRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCorporateMembershipRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCorporateMembershipRequest {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: GetCorporateMembershipRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCorporateMembershipRequest>, I>>(base?: I): GetCorporateMembershipRequest {
    return GetCorporateMembershipRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCorporateMembershipRequest>, I>>(
    object: I,
  ): GetCorporateMembershipRequest {
    const message = createBaseGetCorporateMembershipRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseGetCorporateMembershipResponse(): GetCorporateMembershipResponse {
  return { data: undefined };
}

export const GetCorporateMembershipResponse = {
  encode(message: GetCorporateMembershipResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data !== undefined) {
      CorporateMembershipDTO.encode(message.data, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCorporateMembershipResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCorporateMembershipResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = CorporateMembershipDTO.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCorporateMembershipResponse {
    return { data: isSet(object.data) ? CorporateMembershipDTO.fromJSON(object.data) : undefined };
  },

  toJSON(message: GetCorporateMembershipResponse): unknown {
    const obj: any = {};
    if (message.data !== undefined) {
      obj.data = CorporateMembershipDTO.toJSON(message.data);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCorporateMembershipResponse>, I>>(base?: I): GetCorporateMembershipResponse {
    return GetCorporateMembershipResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCorporateMembershipResponse>, I>>(
    object: I,
  ): GetCorporateMembershipResponse {
    const message = createBaseGetCorporateMembershipResponse();
    message.data = (object.data !== undefined && object.data !== null)
      ? CorporateMembershipDTO.fromPartial(object.data)
      : undefined;
    return message;
  },
};

function createBaseUpdateCorporateMembershipRequest(): UpdateCorporateMembershipRequest {
  return { id: "", status: 0 };
}

export const UpdateCorporateMembershipRequest = {
  encode(message: UpdateCorporateMembershipRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCorporateMembershipRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCorporateMembershipRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCorporateMembershipRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      status: isSet(object.status) ? corporateMembershipStatusEnumDTOFromJSON(object.status) : 0,
    };
  },

  toJSON(message: UpdateCorporateMembershipRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.status !== 0) {
      obj.status = corporateMembershipStatusEnumDTOToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCorporateMembershipRequest>, I>>(
    base?: I,
  ): UpdateCorporateMembershipRequest {
    return UpdateCorporateMembershipRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCorporateMembershipRequest>, I>>(
    object: I,
  ): UpdateCorporateMembershipRequest {
    const message = createBaseUpdateCorporateMembershipRequest();
    message.id = object.id ?? "";
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseUpdateCorporateMembershipResponse(): UpdateCorporateMembershipResponse {
  return { data: undefined };
}

export const UpdateCorporateMembershipResponse = {
  encode(message: UpdateCorporateMembershipResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data !== undefined) {
      CorporateMembershipDTO.encode(message.data, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCorporateMembershipResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCorporateMembershipResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = CorporateMembershipDTO.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCorporateMembershipResponse {
    return { data: isSet(object.data) ? CorporateMembershipDTO.fromJSON(object.data) : undefined };
  },

  toJSON(message: UpdateCorporateMembershipResponse): unknown {
    const obj: any = {};
    if (message.data !== undefined) {
      obj.data = CorporateMembershipDTO.toJSON(message.data);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCorporateMembershipResponse>, I>>(
    base?: I,
  ): UpdateCorporateMembershipResponse {
    return UpdateCorporateMembershipResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCorporateMembershipResponse>, I>>(
    object: I,
  ): UpdateCorporateMembershipResponse {
    const message = createBaseUpdateCorporateMembershipResponse();
    message.data = (object.data !== undefined && object.data !== null)
      ? CorporateMembershipDTO.fromPartial(object.data)
      : undefined;
    return message;
  },
};

function createBaseUpdateCorporateMembershipDetailsRequest(): UpdateCorporateMembershipDetailsRequest {
  return { id: "", name: "", creditPerStaffPerMonth: 0, unallocatedCredit: 0 };
}

export const UpdateCorporateMembershipDetailsRequest = {
  encode(message: UpdateCorporateMembershipDetailsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.creditPerStaffPerMonth !== 0) {
      writer.uint32(25).double(message.creditPerStaffPerMonth);
    }
    if (message.unallocatedCredit !== 0) {
      writer.uint32(33).double(message.unallocatedCredit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCorporateMembershipDetailsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCorporateMembershipDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.creditPerStaffPerMonth = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.unallocatedCredit = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCorporateMembershipDetailsRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      creditPerStaffPerMonth: isSet(object.creditPerStaffPerMonth)
        ? globalThis.Number(object.creditPerStaffPerMonth)
        : 0,
      unallocatedCredit: isSet(object.unallocatedCredit) ? globalThis.Number(object.unallocatedCredit) : 0,
    };
  },

  toJSON(message: UpdateCorporateMembershipDetailsRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.creditPerStaffPerMonth !== 0) {
      obj.creditPerStaffPerMonth = message.creditPerStaffPerMonth;
    }
    if (message.unallocatedCredit !== 0) {
      obj.unallocatedCredit = message.unallocatedCredit;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCorporateMembershipDetailsRequest>, I>>(
    base?: I,
  ): UpdateCorporateMembershipDetailsRequest {
    return UpdateCorporateMembershipDetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCorporateMembershipDetailsRequest>, I>>(
    object: I,
  ): UpdateCorporateMembershipDetailsRequest {
    const message = createBaseUpdateCorporateMembershipDetailsRequest();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.creditPerStaffPerMonth = object.creditPerStaffPerMonth ?? 0;
    message.unallocatedCredit = object.unallocatedCredit ?? 0;
    return message;
  },
};

function createBaseUpdateCorporateMembershipDetailsResponse(): UpdateCorporateMembershipDetailsResponse {
  return {};
}

export const UpdateCorporateMembershipDetailsResponse = {
  encode(_: UpdateCorporateMembershipDetailsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCorporateMembershipDetailsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCorporateMembershipDetailsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateCorporateMembershipDetailsResponse {
    return {};
  },

  toJSON(_: UpdateCorporateMembershipDetailsResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCorporateMembershipDetailsResponse>, I>>(
    base?: I,
  ): UpdateCorporateMembershipDetailsResponse {
    return UpdateCorporateMembershipDetailsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCorporateMembershipDetailsResponse>, I>>(
    _: I,
  ): UpdateCorporateMembershipDetailsResponse {
    const message = createBaseUpdateCorporateMembershipDetailsResponse();
    return message;
  },
};

function createBaseUpdateCorporateMembershipStatusRequest(): UpdateCorporateMembershipStatusRequest {
  return { id: "", status: 0 };
}

export const UpdateCorporateMembershipStatusRequest = {
  encode(message: UpdateCorporateMembershipStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCorporateMembershipStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCorporateMembershipStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCorporateMembershipStatusRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      status: isSet(object.status) ? corporateMembershipStatusEnumDTOFromJSON(object.status) : 0,
    };
  },

  toJSON(message: UpdateCorporateMembershipStatusRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.status !== 0) {
      obj.status = corporateMembershipStatusEnumDTOToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCorporateMembershipStatusRequest>, I>>(
    base?: I,
  ): UpdateCorporateMembershipStatusRequest {
    return UpdateCorporateMembershipStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCorporateMembershipStatusRequest>, I>>(
    object: I,
  ): UpdateCorporateMembershipStatusRequest {
    const message = createBaseUpdateCorporateMembershipStatusRequest();
    message.id = object.id ?? "";
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseUpdateCorporateMembershipStatusResponse(): UpdateCorporateMembershipStatusResponse {
  return {};
}

export const UpdateCorporateMembershipStatusResponse = {
  encode(_: UpdateCorporateMembershipStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCorporateMembershipStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCorporateMembershipStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateCorporateMembershipStatusResponse {
    return {};
  },

  toJSON(_: UpdateCorporateMembershipStatusResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCorporateMembershipStatusResponse>, I>>(
    base?: I,
  ): UpdateCorporateMembershipStatusResponse {
    return UpdateCorporateMembershipStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCorporateMembershipStatusResponse>, I>>(
    _: I,
  ): UpdateCorporateMembershipStatusResponse {
    const message = createBaseUpdateCorporateMembershipStatusResponse();
    return message;
  },
};

function createBaseListCorporateMembershipsRequest(): ListCorporateMembershipsRequest {
  return {};
}

export const ListCorporateMembershipsRequest = {
  encode(_: ListCorporateMembershipsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCorporateMembershipsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCorporateMembershipsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListCorporateMembershipsRequest {
    return {};
  },

  toJSON(_: ListCorporateMembershipsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCorporateMembershipsRequest>, I>>(base?: I): ListCorporateMembershipsRequest {
    return ListCorporateMembershipsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCorporateMembershipsRequest>, I>>(_: I): ListCorporateMembershipsRequest {
    const message = createBaseListCorporateMembershipsRequest();
    return message;
  },
};

function createBaseListCorporateMembershipsResponse(): ListCorporateMembershipsResponse {
  return { data: [] };
}

export const ListCorporateMembershipsResponse = {
  encode(message: ListCorporateMembershipsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.data) {
      CorporateMembershipPreviewDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCorporateMembershipsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCorporateMembershipsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(CorporateMembershipPreviewDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCorporateMembershipsResponse {
    return {
      data: globalThis.Array.isArray(object?.data)
        ? object.data.map((e: any) => CorporateMembershipPreviewDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListCorporateMembershipsResponse): unknown {
    const obj: any = {};
    if (message.data?.length) {
      obj.data = message.data.map((e) => CorporateMembershipPreviewDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCorporateMembershipsResponse>, I>>(
    base?: I,
  ): ListCorporateMembershipsResponse {
    return ListCorporateMembershipsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCorporateMembershipsResponse>, I>>(
    object: I,
  ): ListCorporateMembershipsResponse {
    const message = createBaseListCorporateMembershipsResponse();
    message.data = object.data?.map((e) => CorporateMembershipPreviewDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetMonthlyReviewRequest(): GetMonthlyReviewRequest {
  return {
    startDate: undefined,
    endDate: undefined,
    previousMonthStartDate: undefined,
    previousMonthEndDate: undefined,
  };
}

export const GetMonthlyReviewRequest = {
  encode(message: GetMonthlyReviewRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(18).fork()).ldelim();
    }
    if (message.previousMonthStartDate !== undefined) {
      Timestamp.encode(toTimestamp(message.previousMonthStartDate), writer.uint32(26).fork()).ldelim();
    }
    if (message.previousMonthEndDate !== undefined) {
      Timestamp.encode(toTimestamp(message.previousMonthEndDate), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMonthlyReviewRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMonthlyReviewRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.previousMonthStartDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.previousMonthEndDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMonthlyReviewRequest {
    return {
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? fromJsonTimestamp(object.endDate) : undefined,
      previousMonthStartDate: isSet(object.previousMonthStartDate)
        ? fromJsonTimestamp(object.previousMonthStartDate)
        : undefined,
      previousMonthEndDate: isSet(object.previousMonthEndDate)
        ? fromJsonTimestamp(object.previousMonthEndDate)
        : undefined,
    };
  },

  toJSON(message: GetMonthlyReviewRequest): unknown {
    const obj: any = {};
    if (message.startDate !== undefined) {
      obj.startDate = message.startDate.toISOString();
    }
    if (message.endDate !== undefined) {
      obj.endDate = message.endDate.toISOString();
    }
    if (message.previousMonthStartDate !== undefined) {
      obj.previousMonthStartDate = message.previousMonthStartDate.toISOString();
    }
    if (message.previousMonthEndDate !== undefined) {
      obj.previousMonthEndDate = message.previousMonthEndDate.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMonthlyReviewRequest>, I>>(base?: I): GetMonthlyReviewRequest {
    return GetMonthlyReviewRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMonthlyReviewRequest>, I>>(object: I): GetMonthlyReviewRequest {
    const message = createBaseGetMonthlyReviewRequest();
    message.startDate = object.startDate ?? undefined;
    message.endDate = object.endDate ?? undefined;
    message.previousMonthStartDate = object.previousMonthStartDate ?? undefined;
    message.previousMonthEndDate = object.previousMonthEndDate ?? undefined;
    return message;
  },
};

function createBaseGetMonthlyReviewResponse(): GetMonthlyReviewResponse {
  return {
    totalCreditSpent: 0,
    totalCreditSpentPercentage: 0,
    sessionsBooked: 0,
    sessionsBookedPercentage: 0,
    activeStaff: 0,
    activeStaffPercentage: 0,
  };
}

export const GetMonthlyReviewResponse = {
  encode(message: GetMonthlyReviewResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.totalCreditSpent !== 0) {
      writer.uint32(9).double(message.totalCreditSpent);
    }
    if (message.totalCreditSpentPercentage !== 0) {
      writer.uint32(17).double(message.totalCreditSpentPercentage);
    }
    if (message.sessionsBooked !== 0) {
      writer.uint32(25).double(message.sessionsBooked);
    }
    if (message.sessionsBookedPercentage !== 0) {
      writer.uint32(33).double(message.sessionsBookedPercentage);
    }
    if (message.activeStaff !== 0) {
      writer.uint32(41).double(message.activeStaff);
    }
    if (message.activeStaffPercentage !== 0) {
      writer.uint32(49).double(message.activeStaffPercentage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMonthlyReviewResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMonthlyReviewResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.totalCreditSpent = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.totalCreditSpentPercentage = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.sessionsBooked = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.sessionsBookedPercentage = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.activeStaff = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.activeStaffPercentage = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMonthlyReviewResponse {
    return {
      totalCreditSpent: isSet(object.totalCreditSpent) ? globalThis.Number(object.totalCreditSpent) : 0,
      totalCreditSpentPercentage: isSet(object.totalCreditSpentPercentage)
        ? globalThis.Number(object.totalCreditSpentPercentage)
        : 0,
      sessionsBooked: isSet(object.sessionsBooked) ? globalThis.Number(object.sessionsBooked) : 0,
      sessionsBookedPercentage: isSet(object.sessionsBookedPercentage)
        ? globalThis.Number(object.sessionsBookedPercentage)
        : 0,
      activeStaff: isSet(object.activeStaff) ? globalThis.Number(object.activeStaff) : 0,
      activeStaffPercentage: isSet(object.activeStaffPercentage) ? globalThis.Number(object.activeStaffPercentage) : 0,
    };
  },

  toJSON(message: GetMonthlyReviewResponse): unknown {
    const obj: any = {};
    if (message.totalCreditSpent !== 0) {
      obj.totalCreditSpent = message.totalCreditSpent;
    }
    if (message.totalCreditSpentPercentage !== 0) {
      obj.totalCreditSpentPercentage = message.totalCreditSpentPercentage;
    }
    if (message.sessionsBooked !== 0) {
      obj.sessionsBooked = message.sessionsBooked;
    }
    if (message.sessionsBookedPercentage !== 0) {
      obj.sessionsBookedPercentage = message.sessionsBookedPercentage;
    }
    if (message.activeStaff !== 0) {
      obj.activeStaff = message.activeStaff;
    }
    if (message.activeStaffPercentage !== 0) {
      obj.activeStaffPercentage = message.activeStaffPercentage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMonthlyReviewResponse>, I>>(base?: I): GetMonthlyReviewResponse {
    return GetMonthlyReviewResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMonthlyReviewResponse>, I>>(object: I): GetMonthlyReviewResponse {
    const message = createBaseGetMonthlyReviewResponse();
    message.totalCreditSpent = object.totalCreditSpent ?? 0;
    message.totalCreditSpentPercentage = object.totalCreditSpentPercentage ?? 0;
    message.sessionsBooked = object.sessionsBooked ?? 0;
    message.sessionsBookedPercentage = object.sessionsBookedPercentage ?? 0;
    message.activeStaff = object.activeStaff ?? 0;
    message.activeStaffPercentage = object.activeStaffPercentage ?? 0;
    return message;
  },
};

function createBaseAddSeedingDataRequest(): AddSeedingDataRequest {
  return {};
}

export const AddSeedingDataRequest = {
  encode(_: AddSeedingDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddSeedingDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddSeedingDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AddSeedingDataRequest {
    return {};
  },

  toJSON(_: AddSeedingDataRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AddSeedingDataRequest>, I>>(base?: I): AddSeedingDataRequest {
    return AddSeedingDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddSeedingDataRequest>, I>>(_: I): AddSeedingDataRequest {
    const message = createBaseAddSeedingDataRequest();
    return message;
  },
};

function createBaseAddSeedingDataResponse(): AddSeedingDataResponse {
  return {};
}

export const AddSeedingDataResponse = {
  encode(_: AddSeedingDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddSeedingDataResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddSeedingDataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AddSeedingDataResponse {
    return {};
  },

  toJSON(_: AddSeedingDataResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AddSeedingDataResponse>, I>>(base?: I): AddSeedingDataResponse {
    return AddSeedingDataResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddSeedingDataResponse>, I>>(_: I): AddSeedingDataResponse {
    const message = createBaseAddSeedingDataResponse();
    return message;
  },
};

function createBaseCorporateMembershipDTO(): CorporateMembershipDTO {
  return {
    id: "",
    name: "",
    status: 0,
    totalStaff: 0,
    creditPerStaffPerMonth: 0,
    unallocatedCredit: 0,
    allocatedCredit: 0,
    totalSpendLastThirtyDays: 0,
    averageStaffSpendLastThirtyDays: 0,
    renewalDate: undefined,
  };
}

export const CorporateMembershipDTO = {
  encode(message: CorporateMembershipDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.totalStaff !== 0) {
      writer.uint32(40).int32(message.totalStaff);
    }
    if (message.creditPerStaffPerMonth !== 0) {
      writer.uint32(49).double(message.creditPerStaffPerMonth);
    }
    if (message.unallocatedCredit !== 0) {
      writer.uint32(57).double(message.unallocatedCredit);
    }
    if (message.allocatedCredit !== 0) {
      writer.uint32(65).double(message.allocatedCredit);
    }
    if (message.totalSpendLastThirtyDays !== 0) {
      writer.uint32(73).double(message.totalSpendLastThirtyDays);
    }
    if (message.averageStaffSpendLastThirtyDays !== 0) {
      writer.uint32(81).double(message.averageStaffSpendLastThirtyDays);
    }
    if (message.renewalDate !== undefined) {
      Timestamp.encode(toTimestamp(message.renewalDate), writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CorporateMembershipDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCorporateMembershipDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.totalStaff = reader.int32();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.creditPerStaffPerMonth = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.unallocatedCredit = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.allocatedCredit = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.totalSpendLastThirtyDays = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.averageStaffSpendLastThirtyDays = reader.double();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.renewalDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CorporateMembershipDTO {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      status: isSet(object.status) ? corporateMembershipStatusEnumDTOFromJSON(object.status) : 0,
      totalStaff: isSet(object.totalStaff) ? globalThis.Number(object.totalStaff) : 0,
      creditPerStaffPerMonth: isSet(object.creditPerStaffPerMonth)
        ? globalThis.Number(object.creditPerStaffPerMonth)
        : 0,
      unallocatedCredit: isSet(object.unallocatedCredit) ? globalThis.Number(object.unallocatedCredit) : 0,
      allocatedCredit: isSet(object.allocatedCredit) ? globalThis.Number(object.allocatedCredit) : 0,
      totalSpendLastThirtyDays: isSet(object.totalSpendLastThirtyDays)
        ? globalThis.Number(object.totalSpendLastThirtyDays)
        : 0,
      averageStaffSpendLastThirtyDays: isSet(object.averageStaffSpendLastThirtyDays)
        ? globalThis.Number(object.averageStaffSpendLastThirtyDays)
        : 0,
      renewalDate: isSet(object.renewalDate) ? fromJsonTimestamp(object.renewalDate) : undefined,
    };
  },

  toJSON(message: CorporateMembershipDTO): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.status !== 0) {
      obj.status = corporateMembershipStatusEnumDTOToJSON(message.status);
    }
    if (message.totalStaff !== 0) {
      obj.totalStaff = Math.round(message.totalStaff);
    }
    if (message.creditPerStaffPerMonth !== 0) {
      obj.creditPerStaffPerMonth = message.creditPerStaffPerMonth;
    }
    if (message.unallocatedCredit !== 0) {
      obj.unallocatedCredit = message.unallocatedCredit;
    }
    if (message.allocatedCredit !== 0) {
      obj.allocatedCredit = message.allocatedCredit;
    }
    if (message.totalSpendLastThirtyDays !== 0) {
      obj.totalSpendLastThirtyDays = message.totalSpendLastThirtyDays;
    }
    if (message.averageStaffSpendLastThirtyDays !== 0) {
      obj.averageStaffSpendLastThirtyDays = message.averageStaffSpendLastThirtyDays;
    }
    if (message.renewalDate !== undefined) {
      obj.renewalDate = message.renewalDate.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CorporateMembershipDTO>, I>>(base?: I): CorporateMembershipDTO {
    return CorporateMembershipDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CorporateMembershipDTO>, I>>(object: I): CorporateMembershipDTO {
    const message = createBaseCorporateMembershipDTO();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.status = object.status ?? 0;
    message.totalStaff = object.totalStaff ?? 0;
    message.creditPerStaffPerMonth = object.creditPerStaffPerMonth ?? 0;
    message.unallocatedCredit = object.unallocatedCredit ?? 0;
    message.allocatedCredit = object.allocatedCredit ?? 0;
    message.totalSpendLastThirtyDays = object.totalSpendLastThirtyDays ?? 0;
    message.averageStaffSpendLastThirtyDays = object.averageStaffSpendLastThirtyDays ?? 0;
    message.renewalDate = object.renewalDate ?? undefined;
    return message;
  },
};

function createBaseCorporateMembershipPreviewDTO(): CorporateMembershipPreviewDTO {
  return { id: "", name: "", status: 0, totalStaff: 0, creditPerStaffPerMonth: 0, remainingCredit: 0 };
}

export const CorporateMembershipPreviewDTO = {
  encode(message: CorporateMembershipPreviewDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.totalStaff !== 0) {
      writer.uint32(40).int32(message.totalStaff);
    }
    if (message.creditPerStaffPerMonth !== 0) {
      writer.uint32(49).double(message.creditPerStaffPerMonth);
    }
    if (message.remainingCredit !== 0) {
      writer.uint32(57).double(message.remainingCredit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CorporateMembershipPreviewDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCorporateMembershipPreviewDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.totalStaff = reader.int32();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.creditPerStaffPerMonth = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.remainingCredit = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CorporateMembershipPreviewDTO {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      status: isSet(object.status) ? corporateMembershipStatusEnumDTOFromJSON(object.status) : 0,
      totalStaff: isSet(object.totalStaff) ? globalThis.Number(object.totalStaff) : 0,
      creditPerStaffPerMonth: isSet(object.creditPerStaffPerMonth)
        ? globalThis.Number(object.creditPerStaffPerMonth)
        : 0,
      remainingCredit: isSet(object.remainingCredit) ? globalThis.Number(object.remainingCredit) : 0,
    };
  },

  toJSON(message: CorporateMembershipPreviewDTO): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.status !== 0) {
      obj.status = corporateMembershipStatusEnumDTOToJSON(message.status);
    }
    if (message.totalStaff !== 0) {
      obj.totalStaff = Math.round(message.totalStaff);
    }
    if (message.creditPerStaffPerMonth !== 0) {
      obj.creditPerStaffPerMonth = message.creditPerStaffPerMonth;
    }
    if (message.remainingCredit !== 0) {
      obj.remainingCredit = message.remainingCredit;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CorporateMembershipPreviewDTO>, I>>(base?: I): CorporateMembershipPreviewDTO {
    return CorporateMembershipPreviewDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CorporateMembershipPreviewDTO>, I>>(
    object: I,
  ): CorporateMembershipPreviewDTO {
    const message = createBaseCorporateMembershipPreviewDTO();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.status = object.status ?? 0;
    message.totalStaff = object.totalStaff ?? 0;
    message.creditPerStaffPerMonth = object.creditPerStaffPerMonth ?? 0;
    message.remainingCredit = object.remainingCredit ?? 0;
    return message;
  },
};

export interface CorporateMembershipsService {
  AddCorporateMembership(
    request: DeepPartial<AddCorporateMembershipRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddCorporateMembershipResponse>;
  GetCorporateMembership(
    request: DeepPartial<GetCorporateMembershipRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCorporateMembershipResponse>;
  UpdateCorporateMembership(
    request: DeepPartial<UpdateCorporateMembershipRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateCorporateMembershipResponse>;
  UpdateCorporateMembershipDetails(
    request: DeepPartial<UpdateCorporateMembershipDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateCorporateMembershipDetailsResponse>;
  UpdateCorporateMembershipStatus(
    request: DeepPartial<UpdateCorporateMembershipStatusRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateCorporateMembershipStatusResponse>;
  ListCorporateMemberships(
    request: DeepPartial<ListCorporateMembershipsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCorporateMembershipsResponse>;
  GetMonthlyReview(
    request: DeepPartial<GetMonthlyReviewRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetMonthlyReviewResponse>;
  /** Temporary API to seed data into staging */
  AddSeedingData(
    request: DeepPartial<AddSeedingDataRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddSeedingDataResponse>;
}

export class CorporateMembershipsServiceClientImpl implements CorporateMembershipsService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.AddCorporateMembership = this.AddCorporateMembership.bind(this);
    this.GetCorporateMembership = this.GetCorporateMembership.bind(this);
    this.UpdateCorporateMembership = this.UpdateCorporateMembership.bind(this);
    this.UpdateCorporateMembershipDetails = this.UpdateCorporateMembershipDetails.bind(this);
    this.UpdateCorporateMembershipStatus = this.UpdateCorporateMembershipStatus.bind(this);
    this.ListCorporateMemberships = this.ListCorporateMemberships.bind(this);
    this.GetMonthlyReview = this.GetMonthlyReview.bind(this);
    this.AddSeedingData = this.AddSeedingData.bind(this);
  }

  AddCorporateMembership(
    request: DeepPartial<AddCorporateMembershipRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddCorporateMembershipResponse> {
    return this.rpc.unary(
      CorporateMembershipsServiceAddCorporateMembershipDesc,
      AddCorporateMembershipRequest.fromPartial(request),
      metadata,
    );
  }

  GetCorporateMembership(
    request: DeepPartial<GetCorporateMembershipRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCorporateMembershipResponse> {
    return this.rpc.unary(
      CorporateMembershipsServiceGetCorporateMembershipDesc,
      GetCorporateMembershipRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateCorporateMembership(
    request: DeepPartial<UpdateCorporateMembershipRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateCorporateMembershipResponse> {
    return this.rpc.unary(
      CorporateMembershipsServiceUpdateCorporateMembershipDesc,
      UpdateCorporateMembershipRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateCorporateMembershipDetails(
    request: DeepPartial<UpdateCorporateMembershipDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateCorporateMembershipDetailsResponse> {
    return this.rpc.unary(
      CorporateMembershipsServiceUpdateCorporateMembershipDetailsDesc,
      UpdateCorporateMembershipDetailsRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateCorporateMembershipStatus(
    request: DeepPartial<UpdateCorporateMembershipStatusRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateCorporateMembershipStatusResponse> {
    return this.rpc.unary(
      CorporateMembershipsServiceUpdateCorporateMembershipStatusDesc,
      UpdateCorporateMembershipStatusRequest.fromPartial(request),
      metadata,
    );
  }

  ListCorporateMemberships(
    request: DeepPartial<ListCorporateMembershipsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCorporateMembershipsResponse> {
    return this.rpc.unary(
      CorporateMembershipsServiceListCorporateMembershipsDesc,
      ListCorporateMembershipsRequest.fromPartial(request),
      metadata,
    );
  }

  GetMonthlyReview(
    request: DeepPartial<GetMonthlyReviewRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetMonthlyReviewResponse> {
    return this.rpc.unary(
      CorporateMembershipsServiceGetMonthlyReviewDesc,
      GetMonthlyReviewRequest.fromPartial(request),
      metadata,
    );
  }

  AddSeedingData(
    request: DeepPartial<AddSeedingDataRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddSeedingDataResponse> {
    return this.rpc.unary(
      CorporateMembershipsServiceAddSeedingDataDesc,
      AddSeedingDataRequest.fromPartial(request),
      metadata,
    );
  }
}

export const CorporateMembershipsServiceDesc = { serviceName: "proto.CorporateMembershipsService" };

export const CorporateMembershipsServiceAddCorporateMembershipDesc: UnaryMethodDefinitionish = {
  methodName: "AddCorporateMembership",
  service: CorporateMembershipsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddCorporateMembershipRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddCorporateMembershipResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateMembershipsServiceGetCorporateMembershipDesc: UnaryMethodDefinitionish = {
  methodName: "GetCorporateMembership",
  service: CorporateMembershipsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCorporateMembershipRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCorporateMembershipResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateMembershipsServiceUpdateCorporateMembershipDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateCorporateMembership",
  service: CorporateMembershipsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateCorporateMembershipRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateCorporateMembershipResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateMembershipsServiceUpdateCorporateMembershipDetailsDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateCorporateMembershipDetails",
  service: CorporateMembershipsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateCorporateMembershipDetailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateCorporateMembershipDetailsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateMembershipsServiceUpdateCorporateMembershipStatusDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateCorporateMembershipStatus",
  service: CorporateMembershipsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateCorporateMembershipStatusRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateCorporateMembershipStatusResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateMembershipsServiceListCorporateMembershipsDesc: UnaryMethodDefinitionish = {
  methodName: "ListCorporateMemberships",
  service: CorporateMembershipsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListCorporateMembershipsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListCorporateMembershipsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateMembershipsServiceGetMonthlyReviewDesc: UnaryMethodDefinitionish = {
  methodName: "GetMonthlyReview",
  service: CorporateMembershipsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetMonthlyReviewRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetMonthlyReviewResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateMembershipsServiceAddSeedingDataDesc: UnaryMethodDefinitionish = {
  methodName: "AddSeedingData",
  service: CorporateMembershipsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddSeedingDataRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddSeedingDataResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
