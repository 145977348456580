import { useGoogleLogin } from "@react-oauth/google";
import { FlexBox, FlexItem } from "../../../../ui-kit/FlexBox";
import FormHeader from "../../../form/FormHeader";
import Label from "../../../form/Label";
import { AsyncButton } from "../../../../ui-kit/AsyncButton";
import { Container } from "../../../../ui-kit/Container";
import Section from "../../../form/Section";
import { useState } from "react";

interface IProps {
  locationId: string;
}

const scopes = [
  "email",
  "profile",
  "openid",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/calendar"
];

export function GoogleCalendarIntegration(props: IProps) {
  // TODO: set these two fields from API
  const [lastSyncStatus, setLastSyncStatus] = useState<string>("N/A");
  const [lastSyncAt, setLastSyncAt] = useState<string>("N/A");

  const authCodeLogin = useGoogleLogin({
    // TODO: pass the code to the APi server
    onSuccess: (tokenResponse) => console.log(tokenResponse),
    flow: "auth-code",
    ux_mode: "popup",
    scope: scopes.join(" ")
  });

  return (
    <Container>
      <Section>
        <FlexBox direction="col" spacing={[0, 10]} justifyContent="between" fullHeight>
          <FlexItem>
            <FormHeader
              title="Google Calendar"
              subtitle="Please Click the button below to authorize the Google Calendar Integration"
            />
          </FlexItem>
          <FlexItem>
            <AsyncButton
              variant="primary"
              rounded="full"
              size="md"
              fullWidth={false}
              onClick={() => {
                authCodeLogin();
              }}
            >
              Google Oauth2
            </AsyncButton>
          </FlexItem>
        </FlexBox>
      </Section>
      <Section>
        <FlexBox direction="row" justifyContent="between" fullHeight>
          <FlexItem>
            <Label htmlFor="last-sync-at">Last Sync at: {lastSyncStatus}</Label>
          </FlexItem>
          <FlexItem>
            <Label htmlFor="last-sync-status">Last Sync status: {lastSyncAt} </Label>
          </FlexItem>
        </FlexBox>
      </Section>
    </Container>
  );
}
