import * as ReactToast from "react-toast-notifications";
import { observable } from 'mobx';
import * as grpcWeb from "grpc-web";

interface Toast {
  message: string;
  appearance: ReactToast.ToastAppearance;
}

class ToastStore {

  @observable
  private provider?: ReactToast.ToastManager;

  success(message: string) {
    this.present({ message, appearance: 'success' });
  }

  error(message: string) {
    this.present({ message, appearance: 'error' });
  }

  setProvider(provider?: ReactToast.ToastManager) {
    this.provider = provider;
  }

  grpcError(err: grpcWeb.RpcError) {
    const code = err.code ? "code ("+err.code+"). ": "";
    let userMessage = ""
    // invalid argument is the grpc error we are using to identify it's an error we want the user to see
    if (err.code === grpcWeb.StatusCode.INTERNAL ) {
      userMessage = code + err.message + ". Please try again later. If the problem persists, contact support.";
    } else {
      userMessage = code + err.message;
    }
    this.present({ message: userMessage, appearance: 'error' });
  }

  private present(toast: Toast) {
    if (this.provider) {
      this.provider.add(toast.message, {
        appearance: toast.appearance,
      });
    }
  }

}

export const toastStore = new ToastStore();
