import React, { Component, createContext, useContext } from "react";
import { connect } from "react-redux";
import { ToastManager, withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import { User } from "sdk/dist/user_pb";
import { getAuthenticatedUser } from "../components/elements/AuthGetUser";

interface IReduxContext {
  user?: User.AsObject;
  toastManager: ToastManager;
}

const ReduxContext = createContext<IReduxContext>({} as any);

export const ReduxHooksProvider = (props: { children: React.ReactNode }) => {
  return <ReduxComposition>{props.children}</ReduxComposition>;
};

export const useReduxContext = () => {
  const context = useContext(ReduxContext);
  return context;
};

// Take injected props from HOC and set them in the context provider
class HooksMiddleware extends Component<IReduxContext> {
  render() {
    return (
      <ReduxContext.Provider
        value={{ user: this.props.user, toastManager: this.props.toastManager }}
      >
        {this.props.children}
      </ReduxContext.Provider>
    );
  }
}

// HOC with prop injection
const ReduxComposition = compose<IReduxContext, {}>(
  connect((state) => ({ user: getAuthenticatedUser(state) })),
  withToastManager
)(HooksMiddleware);
