import { metadata, rpc } from "../grpc/grpc-legacy";
import { UserUIType } from "sdk/dist/location_user_pb";
import {
  GetOrganizationUserListRequest,
  UpsertOrganisationUserRequest
} from "sdk/dist/organisation_user_pb";
import { grpcResponseHandler } from "../util/grpcResponseHandler";

class OrganizationUserService {
  @grpcResponseHandler()
  async listOfUsers(id: string) {
    const req = new GetOrganizationUserListRequest();
    req.setOrgId(id);

    const res = await rpc.organisationUser.getOrganizationUserList(req, metadata());
    return res.toObject();
  }

  // API is used to set the UserUITypes i.e, Fitness or Healthcare
  @grpcResponseHandler()
  async setUserType(orgId: string, userId: string, type: UserUIType) {
    const req = new UpsertOrganisationUserRequest();
    req.setOrganisationid(orgId);
    req.setUserid(userId);
    req.setType(type);

    const res = await rpc.organisationUser.upsertOrganisationUser(req, metadata());
    return res.toObject();
  }
}

export const sharedOrganizationUserService = new OrganizationUserService();
