import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import * as fns from "date-fns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

// import apiService from "./../../../redux/services/api";
import { useDispatch } from "react-redux";
import * as ReduxDialogs from "./../../../redux/features/dialogs";

// utils
import { colors } from "./../../../util/consts";
import typography from "./../../../util/typography";

// components
import Button from "./../../../components/Button";
import DayTimeRangePickerInput from "./../../../components/form/DayTimeRangePickerInput";
import Error from "./../../../components/form/Error";
import Form from "./../../../components/form/Form";
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import Section from "./../../../components/form/Section";

// icons
import LoadingIcon from "./../../../components/icons/Loading";

import { LocationProviderSelector } from "../../../components/LocationProviderSelector";
import { metadata, rpc } from "../../../grpc/grpc-legacy";
import { BookingDTO, Provider, UpdateBookingRequest } from "sdk/dist/bookings_pb";
import { bookingStore } from "../../../stores/booking-store";
import { toastStore } from "../../../stores/toast-store";
import { dateToTimestamp } from "../../../util/time";
import { timezone, toDate } from "../../../util/timestamp";
import { TabContainer } from "../../elements/Tabs";

export const DetailInput = styled(Input)`
  ${typography.body2};
  letter-spacing; 0.19px;
  line-height: 18px;
  opacity: 0.6;
`;

interface Props {
  booking: BookingDTO.AsObject;
}

export const AppDetails: React.FC<Props> = (props: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState(false);
  const mayEdit = props.booking.status === BookingDTO.Status.BOOKING_CREATED;
  // Format datetimes
  const startDateConv = fns.parse(
    fns.format(toDate(props.booking.startDatetime!), "yyyy-MM-dd"),
    "yyyy-MM-dd",
    new Date()
  );
  const startTimeConv = fns.format(toDate(props.booking.startDatetime!), "HH:mm:ss");
  const endTimeConv = fns.format(toDate(props.booking.endDatetime!), "HH:mm:ss");
  const [startDate, setStartDate] = useState(startDateConv);
  const [startTime, setStartTime] = useState(startTimeConv);
  const [endTime, setEndTime] = useState(endTimeConv);
  const [maxGroupSize, setMaxGroupSize] = useState(props.booking.maxClients);
  const [providerName, setProviderName] = useState(
    `${props.booking.provider!.firstName} ${props.booking.provider!.lastName}`
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const tmpStartTime = startTime.split(":");
    const tmpEndTime = endTime.split(":");
    setValue(
      "StartDatetime",
      fns.format(
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          parseInt(tmpStartTime[0]),
          parseInt(tmpStartTime[1]),
          parseInt(tmpStartTime[2])
        ),
        "yyyy-MM-dd HH:mm:ss"
      )
    );
    setValue(
      "EndDatetime",
      fns.format(
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          parseInt(tmpEndTime[0]),
          parseInt(tmpEndTime[1]),
          parseInt(tmpEndTime[2])
        ),
        "yyyy-MM-dd HH:mm:ss"
      )
    );
    return () => {};
  }, [startDate, startTime, endTime, providerName, maxGroupSize]);
  const schema = Yup.object().shape({
    ProviderId: Yup.string().required("A provider is required"),
    StartDatetime: Yup.string().required("Start datetime is required"),
    EndDatetime: Yup.string().required("End datetime is required"),
    MaxGroupSize: Yup.number()
      .min(
        Math.max(props.booking.clientsList.length, 1),
        props.booking.clientsList.length < 1
          ? "Must be one or more."
          : "Must not be less than the current number of clients."
      )
      .max(100, "Must be less than one hundred.")
      .required("Max Group Size is required")
  });

  type FormSchema = {
    ProviderId: string;
    StartDatetime: string;
    EndDatetime: string;
    MaxGroupSize: number;
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<FormSchema>({
    resolver: yupResolver(schema)
  });

  const handleEditClick = (e: any) => {
    // initialize form values
    setValue("ProviderId", props.booking.provider!.id);
    setValue(
      "StartDatetime",
      fns.format(toDate(props.booking.startDatetime!), "yyyy-MM-dd HH:mm:ss")
    );
    setValue("EndDatetime", fns.format(toDate(props.booking.endDatetime!), "yyyy-MM-dd HH:mm:ss"));
    setValue("MaxGroupSize", props.booking.maxClients);
    setStatus(true);
  };

  const handleDateChange = (e: any) => {
    switch (e.target.name) {
      case "Date":
        setStartDate(fns.parse(fns.format(e.target.value, "yyyy-MM-dd"), "yyyy-MM-dd", new Date()));
        break;
      case "StartTime":
        setStartTime(e.target.value);
        break;
      case "EndTime":
        setEndTime(e.target.value);
        break;
    }
  };

  const onSubmit = handleSubmit(async (values: FormSchema) => {
    const { booking } = props;
    try {
      const bookingReq = new UpdateBookingRequest();
      bookingReq.setId(booking.id);
      bookingReq.setProviderId(values.ProviderId);
      bookingReq.setMaxGroupSize(values.MaxGroupSize);
      bookingReq.setStartDatetime(
        dateToTimestamp(fns.parse(values.StartDatetime, "yyyy-MM-dd HH:mm:ss", new Date()))
      );
      bookingReq.setEndDatetime(
        dateToTimestamp(fns.parse(values.EndDatetime, "yyyy-MM-dd HH:mm:ss", new Date()))
      );
      const tz = timezone();
      bookingReq.setTimezone(tz);
      const openActionDialogMsg = async (msg: any, checkMsg: any) => {
        await dispatch(
          ReduxDialogs.openAction(
            msg,
            checkMsg,
            null,
            async (check: boolean, yay: boolean, notify: boolean) => {
              if (check && yay) {
                bookingReq.setNotifyClient(notify);
                const bookRes = await rpc.bookings.update(bookingReq, metadata());
                bookingStore.add(bookRes.toObject());
              }
              setStatus(false);
            },
            "Yes",
            "No"
          )
        );
      };
      const previousStartDatetime = `${fns.format(startDateConv, "yyyy-MM-dd")} ${startTimeConv}`;
      const previousEndDatetime = `${fns.format(startDateConv, "yyyy-MM-dd")} ${endTimeConv}`;
      const newStartDatetime = values.StartDatetime;
      const newEndDatetime = values.EndDatetime;

      if (previousStartDatetime !== newStartDatetime || previousEndDatetime !== newEndDatetime) {
        await openActionDialogMsg(
          "Are you sure you wish to reschedule this booking?",
          "Notify the client(s)?"
        );
      } else if (values.ProviderId !== booking.provider!.id) {
        await openActionDialogMsg(
          "Are you sure you wish to change the provider for this booking?",
          "Notify the client(s)?"
        );
      } else {
        const bookRes = await rpc.bookings.update(bookingReq, metadata());
        bookingStore.add(bookRes.toObject());
        setStatus(false);
      }
    } catch (err) {
      toastStore.grpcError(err);
    }
    setIsSubmitting(false);
  });
  return (
    <TabContainer style={{ marginBottom: "0px" }}>
      <Form onSubmit={onSubmit}>
        <HeaderContainer>
          Appointment Details
          {!isSubmitting && status && <HeaderButton type="submit">Save</HeaderButton>}
          {!isSubmitting && !status && mayEdit && (
            <HeaderButton onClick={handleEditClick}>Edit</HeaderButton>
          )}
          {isSubmitting && <LoadingIcon color="#2c2e3c" size="small" />}
        </HeaderContainer>

        <Container>
          <Section>
            <Label htmlFor="newbook-datetime">Date &amp; Time</Label>
            <DayTimeRangePickerInput
              id="newbook-datetime"
              interval={5}
              placeholder="Select the time"
              dayName="Date"
              dayValue={startDate}
              startName="StartTime"
              startValue={startTime}
              endName="EndTime"
              endValue={endTime}
              onChange={handleDateChange}
              startDisabled={!status}
              endDisabled={!status}
              regularText
            />
            <input {...register("StartDatetime")} hidden />
            {errors.StartDatetime && <Error>{errors.StartDatetime.message}</Error>}
            <input {...register("EndDatetime")} hidden />
            {errors.EndDatetime && <Error>{errors.EndDatetime.message}</Error>}
          </Section>
          <Section>
            <Label htmlFor="newbook-provider">Provider</Label>
            <LocationProviderSelector
              locationId={props.booking.locationId}
              onSelect={(e: Provider.AsObject | undefined) => {
                if (e) {
                  setValue("ProviderId", e.id);
                  setProviderName(`${e.firstName} ${e.lastName}`);
                } else {
                  setValue("ProviderId", "");
                  setProviderName("");
                }
              }}
              setProvName={setProviderName}
              provName={providerName}
              disabled={!status}
            />
            <input {...register("ProviderId")} hidden />
            {errors.ProviderId && <Error>{errors.ProviderId.message}</Error>}
          </Section>
          {props.booking.type === BookingDTO.Type.BOOKING_GROUP && (
            <React.Fragment>
              <Section>
                <Label htmlFor="editbook-maxgroupsize">Maximum Group Size</Label>
                <Input
                  id="offering-maxgroupsize"
                  {...register("MaxGroupSize")}
                  type="number"
                  min={1}
                  max={100}
                  placeholder="Max group size"
                  value={maxGroupSize}
                  onChange={(e: any) => setMaxGroupSize(e.currentTarget.value)}
                  disabled={!status}
                  regularText
                />
                {errors.MaxGroupSize && <Error>{errors.MaxGroupSize.message}</Error>}
              </Section>
            </React.Fragment>
          )}
        </Container>
      </Form>
    </TabContainer>
  );
};

export const Container = styled.div`
  margin: 8px 0 30px;
  border-radius: 4px;
  background-color: ${colors.surface.light};
  padding: 24px 32px 30px;
`;

export const HeaderContainer = styled.div`
  color: #2c2e3c;
  font-family: "Open Sans";
  font-size: 15.74px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderButton = styled(Button)`
  padding: 0;
  opacity: 0.3;
`;
