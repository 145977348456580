import { metadata, rpc } from "../grpc/grpc-legacy";
import {
  CreateCreditCardRequest,
  CreditCard,
  DeleteCreditCardRequest,
  ListCreditCardsRequest,
  SetPrimaryCardRequest
} from "sdk/dist/credit_cards_pb";
import { grpcResponseHandler } from "../util/grpcResponseHandler";

class ClientCardService {
  @grpcResponseHandler({ successMessage: "Credit card added successfully" })
  async create(token: string, id: string) {
    const req = new CreateCreditCardRequest();
    req.setOwner(id);
    req.setToken(token);
    req.setOwnerType(CreditCard.OwnerType.CLIENT);

    return (await rpc.clientCards.create(req, metadata())).toObject();
  }

  @grpcResponseHandler({ successMessage: "new credit card successfully set as primary" })
  async setPrimaryCard(id: string) {
    const req = new SetPrimaryCardRequest();
    req.setCardId(id);
    req.setOwnerType(CreditCard.OwnerType.CLIENT);

    return (await rpc.clientCards.setPrimaryCard(req, metadata())).toObject();
  }

  @grpcResponseHandler({ successMessage: "credit card removed successfully" })
  async deleteCard(id: string) {
    const req = new DeleteCreditCardRequest();
    req.setCardId(id);
    req.setOwnerType(CreditCard.OwnerType.CLIENT);

    return (await rpc.clientCards.delete(req, metadata())).toObject();
  }

  @grpcResponseHandler()
  async getCards(id: string) {
    const req = new ListCreditCardsRequest();
    req.setOwner(id);
    req.setOwnerType(CreditCard.OwnerType.CLIENT);

    return (await rpc.clientCards.list(req, metadata())).toObject().cardsList;
  }
}

export const sharedClientCardService = new ClientCardService();
