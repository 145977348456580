/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: bookings.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var offerings_pb = require('./offerings_pb.js')

var money_pb = require('./money_pb.js')

var user_pb = require('./user_pb.js')

var physitrack_pb = require('./physitrack_pb.js')
const proto = {};
proto.proto = require('./bookings_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ProcessBookingRequest,
 *   !proto.proto.ProcessBookingResponse>}
 */
const methodDescriptor_Bookings_ProcessBooking = new grpc.web.MethodDescriptor(
  '/proto.Bookings/ProcessBooking',
  grpc.web.MethodType.UNARY,
  proto.proto.ProcessBookingRequest,
  proto.proto.ProcessBookingResponse,
  /**
   * @param {!proto.proto.ProcessBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProcessBookingResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ProcessBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ProcessBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ProcessBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.processBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/ProcessBooking',
      request,
      metadata || {},
      methodDescriptor_Bookings_ProcessBooking,
      callback);
};


/**
 * @param {!proto.proto.ProcessBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ProcessBookingResponse>}
 *     Promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.processBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/ProcessBooking',
      request,
      metadata || {},
      methodDescriptor_Bookings_ProcessBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetBookingRequest,
 *   !proto.proto.BookingDTO>}
 */
const methodDescriptor_Bookings_Get = new grpc.web.MethodDescriptor(
  '/proto.Bookings/Get',
  grpc.web.MethodType.UNARY,
  proto.proto.GetBookingRequest,
  proto.proto.BookingDTO,
  /**
   * @param {!proto.proto.GetBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingDTO.deserializeBinary
);


/**
 * @param {!proto.proto.GetBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingDTO)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingDTO>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/Get',
      request,
      metadata || {},
      methodDescriptor_Bookings_Get,
      callback);
};


/**
 * @param {!proto.proto.GetBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingDTO>}
 *     Promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/Get',
      request,
      metadata || {},
      methodDescriptor_Bookings_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateBookingRequest,
 *   !proto.proto.BookingDTO>}
 */
const methodDescriptor_Bookings_Create = new grpc.web.MethodDescriptor(
  '/proto.Bookings/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateBookingRequest,
  proto.proto.BookingDTO,
  /**
   * @param {!proto.proto.CreateBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingDTO.deserializeBinary
);


/**
 * @param {!proto.proto.CreateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingDTO)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingDTO>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/Create',
      request,
      metadata || {},
      methodDescriptor_Bookings_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingDTO>}
 *     Promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/Create',
      request,
      metadata || {},
      methodDescriptor_Bookings_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateBulkBookingRequest,
 *   !proto.proto.CreateBulkBookingResponse>}
 */
const methodDescriptor_Bookings_CreateBulk = new grpc.web.MethodDescriptor(
  '/proto.Bookings/CreateBulk',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateBulkBookingRequest,
  proto.proto.CreateBulkBookingResponse,
  /**
   * @param {!proto.proto.CreateBulkBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateBulkBookingResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateBulkBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateBulkBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateBulkBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.createBulk =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/CreateBulk',
      request,
      metadata || {},
      methodDescriptor_Bookings_CreateBulk,
      callback);
};


/**
 * @param {!proto.proto.CreateBulkBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateBulkBookingResponse>}
 *     Promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.createBulk =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/CreateBulk',
      request,
      metadata || {},
      methodDescriptor_Bookings_CreateBulk);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CancelBookingClientRequest,
 *   !proto.proto.CancelBulkBookingResponse>}
 */
const methodDescriptor_Bookings_CancelBulk = new grpc.web.MethodDescriptor(
  '/proto.Bookings/CancelBulk',
  grpc.web.MethodType.UNARY,
  proto.proto.CancelBookingClientRequest,
  proto.proto.CancelBulkBookingResponse,
  /**
   * @param {!proto.proto.CancelBookingClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CancelBulkBookingResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CancelBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CancelBulkBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CancelBulkBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.cancelBulk =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/CancelBulk',
      request,
      metadata || {},
      methodDescriptor_Bookings_CancelBulk,
      callback);
};


/**
 * @param {!proto.proto.CancelBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CancelBulkBookingResponse>}
 *     Promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.cancelBulk =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/CancelBulk',
      request,
      metadata || {},
      methodDescriptor_Bookings_CancelBulk);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListBookingByLocationRequest,
 *   !proto.proto.ListBookingsResponse>}
 */
const methodDescriptor_Bookings_ListByLocation = new grpc.web.MethodDescriptor(
  '/proto.Bookings/ListByLocation',
  grpc.web.MethodType.UNARY,
  proto.proto.ListBookingByLocationRequest,
  proto.proto.ListBookingsResponse,
  /**
   * @param {!proto.proto.ListBookingByLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListBookingByLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.listByLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/ListByLocation',
      request,
      metadata || {},
      methodDescriptor_Bookings_ListByLocation,
      callback);
};


/**
 * @param {!proto.proto.ListBookingByLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.listByLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/ListByLocation',
      request,
      metadata || {},
      methodDescriptor_Bookings_ListByLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListThirdPartyBookingsRequest,
 *   !proto.proto.ListThirdPartyBookingsResponse>}
 */
const methodDescriptor_Bookings_ListThirdPartyBookings = new grpc.web.MethodDescriptor(
  '/proto.Bookings/ListThirdPartyBookings',
  grpc.web.MethodType.UNARY,
  proto.proto.ListThirdPartyBookingsRequest,
  proto.proto.ListThirdPartyBookingsResponse,
  /**
   * @param {!proto.proto.ListThirdPartyBookingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListThirdPartyBookingsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListThirdPartyBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListThirdPartyBookingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListThirdPartyBookingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.listThirdPartyBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/ListThirdPartyBookings',
      request,
      metadata || {},
      methodDescriptor_Bookings_ListThirdPartyBookings,
      callback);
};


/**
 * @param {!proto.proto.ListThirdPartyBookingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListThirdPartyBookingsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.listThirdPartyBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/ListThirdPartyBookings',
      request,
      metadata || {},
      methodDescriptor_Bookings_ListThirdPartyBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateBookingRequest,
 *   !proto.proto.BookingDTO>}
 */
const methodDescriptor_Bookings_Update = new grpc.web.MethodDescriptor(
  '/proto.Bookings/Update',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateBookingRequest,
  proto.proto.BookingDTO,
  /**
   * @param {!proto.proto.UpdateBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingDTO.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingDTO)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingDTO>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingsClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.Bookings/Update',
      request,
      metadata || {},
      methodDescriptor_Bookings_Update,
      callback);
};


/**
 * @param {!proto.proto.UpdateBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingDTO>}
 *     Promise that resolves to the response
 */
proto.proto.BookingsPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.Bookings/Update',
      request,
      metadata || {},
      methodDescriptor_Bookings_Update);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingClientsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingClientsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetBookingClientRequest,
 *   !proto.proto.BookingClient>}
 */
const methodDescriptor_BookingClients_Get = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Get',
  grpc.web.MethodType.UNARY,
  proto.proto.GetBookingClientRequest,
  proto.proto.BookingClient,
  /**
   * @param {!proto.proto.GetBookingClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingClient.deserializeBinary
);


/**
 * @param {!proto.proto.GetBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingClient)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingClient>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Get',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Get,
      callback);
};


/**
 * @param {!proto.proto.GetBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingClient>}
 *     Promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Get',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateBookingClientRequest,
 *   !proto.proto.BookingClient>}
 */
const methodDescriptor_BookingClients_Create = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateBookingClientRequest,
  proto.proto.BookingClient,
  /**
   * @param {!proto.proto.CreateBookingClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingClient.deserializeBinary
);


/**
 * @param {!proto.proto.CreateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingClient)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingClient>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Create',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingClient>}
 *     Promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Create',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateBookingClientRequest,
 *   !proto.proto.BookingClient>}
 */
const methodDescriptor_BookingClients_Update = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Update',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateBookingClientRequest,
  proto.proto.BookingClient,
  /**
   * @param {!proto.proto.UpdateBookingClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingClient.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingClient)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingClient>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Update',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Update,
      callback);
};


/**
 * @param {!proto.proto.UpdateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingClient>}
 *     Promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Update',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.PartialUpdateBookingClientRequest,
 *   !proto.proto.BookingClient>}
 */
const methodDescriptor_BookingClients_PartialUpdate = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/PartialUpdate',
  grpc.web.MethodType.UNARY,
  proto.proto.PartialUpdateBookingClientRequest,
  proto.proto.BookingClient,
  /**
   * @param {!proto.proto.PartialUpdateBookingClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingClient.deserializeBinary
);


/**
 * @param {!proto.proto.PartialUpdateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingClient)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingClient>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.partialUpdate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/PartialUpdate',
      request,
      metadata || {},
      methodDescriptor_BookingClients_PartialUpdate,
      callback);
};


/**
 * @param {!proto.proto.PartialUpdateBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingClient>}
 *     Promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.partialUpdate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/PartialUpdate',
      request,
      metadata || {},
      methodDescriptor_BookingClients_PartialUpdate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteBookingClientRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_BookingClients_Delete = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Delete',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteBookingClientRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.proto.DeleteBookingClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Delete',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Delete,
      callback);
};


/**
 * @param {!proto.proto.DeleteBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Delete',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CancellationChargeClientRequest,
 *   !proto.proto.ProcessBookingCancellationResponse>}
 */
const methodDescriptor_BookingClients_CancellationCharge = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/CancellationCharge',
  grpc.web.MethodType.UNARY,
  proto.proto.CancellationChargeClientRequest,
  proto.proto.ProcessBookingCancellationResponse,
  /**
   * @param {!proto.proto.CancellationChargeClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ProcessBookingCancellationResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CancellationChargeClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ProcessBookingCancellationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ProcessBookingCancellationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.cancellationCharge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/CancellationCharge',
      request,
      metadata || {},
      methodDescriptor_BookingClients_CancellationCharge,
      callback);
};


/**
 * @param {!proto.proto.CancellationChargeClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ProcessBookingCancellationResponse>}
 *     Promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.cancellationCharge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/CancellationCharge',
      request,
      metadata || {},
      methodDescriptor_BookingClients_CancellationCharge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CancelBookingClientRequest,
 *   !proto.proto.BookingDTO>}
 */
const methodDescriptor_BookingClients_Cancel = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Cancel',
  grpc.web.MethodType.UNARY,
  proto.proto.CancelBookingClientRequest,
  proto.proto.BookingDTO,
  /**
   * @param {!proto.proto.CancelBookingClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingDTO.deserializeBinary
);


/**
 * @param {!proto.proto.CancelBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingDTO)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingDTO>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.cancel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Cancel',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Cancel,
      callback);
};


/**
 * @param {!proto.proto.CancelBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingDTO>}
 *     Promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.cancel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Cancel',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Cancel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ApproveBookingClientRequest,
 *   !proto.proto.BookingDTO>}
 */
const methodDescriptor_BookingClients_Approve = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Approve',
  grpc.web.MethodType.UNARY,
  proto.proto.ApproveBookingClientRequest,
  proto.proto.BookingDTO,
  /**
   * @param {!proto.proto.ApproveBookingClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingDTO.deserializeBinary
);


/**
 * @param {!proto.proto.ApproveBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingDTO)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingDTO>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.approve =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Approve',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Approve,
      callback);
};


/**
 * @param {!proto.proto.ApproveBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingDTO>}
 *     Promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.approve =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Approve',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Approve);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RejectBookingClientRequest,
 *   !proto.proto.BookingDTO>}
 */
const methodDescriptor_BookingClients_Reject = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/Reject',
  grpc.web.MethodType.UNARY,
  proto.proto.RejectBookingClientRequest,
  proto.proto.BookingDTO,
  /**
   * @param {!proto.proto.RejectBookingClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingDTO.deserializeBinary
);


/**
 * @param {!proto.proto.RejectBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingDTO)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingDTO>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.reject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/Reject',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Reject,
      callback);
};


/**
 * @param {!proto.proto.RejectBookingClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingDTO>}
 *     Promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.reject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/Reject',
      request,
      metadata || {},
      methodDescriptor_BookingClients_Reject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetLatestReferralRequest,
 *   !proto.proto.GetLatestReferralResponse>}
 */
const methodDescriptor_BookingClients_GetLatestReferral = new grpc.web.MethodDescriptor(
  '/proto.BookingClients/GetLatestReferral',
  grpc.web.MethodType.UNARY,
  proto.proto.GetLatestReferralRequest,
  proto.proto.GetLatestReferralResponse,
  /**
   * @param {!proto.proto.GetLatestReferralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetLatestReferralResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetLatestReferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GetLatestReferralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetLatestReferralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingClientsClient.prototype.getLatestReferral =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingClients/GetLatestReferral',
      request,
      metadata || {},
      methodDescriptor_BookingClients_GetLatestReferral,
      callback);
};


/**
 * @param {!proto.proto.GetLatestReferralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetLatestReferralResponse>}
 *     Promise that resolves to the response
 */
proto.proto.BookingClientsPromiseClient.prototype.getLatestReferral =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingClients/GetLatestReferral',
      request,
      metadata || {},
      methodDescriptor_BookingClients_GetLatestReferral);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UserBookingsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UserBookingsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UserBookingRequest,
 *   !proto.proto.UserBookingResponse>}
 */
const methodDescriptor_UserBookings_Create = new grpc.web.MethodDescriptor(
  '/proto.UserBookings/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.UserBookingRequest,
  proto.proto.UserBookingResponse,
  /**
   * @param {!proto.proto.UserBookingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UserBookingResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UserBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UserBookingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UserBookingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserBookingsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserBookings/Create',
      request,
      metadata || {},
      methodDescriptor_UserBookings_Create,
      callback);
};


/**
 * @param {!proto.proto.UserBookingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UserBookingResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserBookingsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserBookings/Create',
      request,
      metadata || {},
      methodDescriptor_UserBookings_Create);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingOfferingsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.BookingOfferingsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateBookingOfferingRequest,
 *   !proto.proto.BookingOffering>}
 */
const methodDescriptor_BookingOfferings_Create = new grpc.web.MethodDescriptor(
  '/proto.BookingOfferings/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateBookingOfferingRequest,
  proto.proto.BookingOffering,
  /**
   * @param {!proto.proto.CreateBookingOfferingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingOffering.deserializeBinary
);


/**
 * @param {!proto.proto.CreateBookingOfferingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingOffering)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingOffering>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.BookingOfferingsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.BookingOfferings/Create',
      request,
      metadata || {},
      methodDescriptor_BookingOfferings_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateBookingOfferingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingOffering>}
 *     Promise that resolves to the response
 */
proto.proto.BookingOfferingsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.BookingOfferings/Create',
      request,
      metadata || {},
      methodDescriptor_BookingOfferings_Create);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ClientOfferingsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ClientOfferingsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateClientOfferingRequest,
 *   !proto.proto.ClientOffering>}
 */
const methodDescriptor_ClientOfferings_Create = new grpc.web.MethodDescriptor(
  '/proto.ClientOfferings/Create',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateClientOfferingRequest,
  proto.proto.ClientOffering,
  /**
   * @param {!proto.proto.CreateClientOfferingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ClientOffering.deserializeBinary
);


/**
 * @param {!proto.proto.CreateClientOfferingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ClientOffering)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ClientOffering>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ClientOfferingsClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ClientOfferings/Create',
      request,
      metadata || {},
      methodDescriptor_ClientOfferings_Create,
      callback);
};


/**
 * @param {!proto.proto.CreateClientOfferingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ClientOffering>}
 *     Promise that resolves to the response
 */
proto.proto.ClientOfferingsPromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ClientOfferings/Create',
      request,
      metadata || {},
      methodDescriptor_ClientOfferings_Create);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.LineItemsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.LineItemsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateLineItemRequest,
 *   !proto.proto.BookingDTO>}
 */
const methodDescriptor_LineItems_Update = new grpc.web.MethodDescriptor(
  '/proto.LineItems/Update',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateLineItemRequest,
  proto.proto.BookingDTO,
  /**
   * @param {!proto.proto.UpdateLineItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingDTO.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateLineItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingDTO)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingDTO>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LineItemsClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LineItems/Update',
      request,
      metadata || {},
      methodDescriptor_LineItems_Update,
      callback);
};


/**
 * @param {!proto.proto.UpdateLineItemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingDTO>}
 *     Promise that resolves to the response
 */
proto.proto.LineItemsPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LineItems/Update',
      request,
      metadata || {},
      methodDescriptor_LineItems_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteLineItemRequest,
 *   !proto.proto.BookingDTO>}
 */
const methodDescriptor_LineItems_Delete = new grpc.web.MethodDescriptor(
  '/proto.LineItems/Delete',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteLineItemRequest,
  proto.proto.BookingDTO,
  /**
   * @param {!proto.proto.DeleteLineItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingDTO.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteLineItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingDTO)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingDTO>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LineItemsClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LineItems/Delete',
      request,
      metadata || {},
      methodDescriptor_LineItems_Delete,
      callback);
};


/**
 * @param {!proto.proto.DeleteLineItemRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingDTO>}
 *     Promise that resolves to the response
 */
proto.proto.LineItemsPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LineItems/Delete',
      request,
      metadata || {},
      methodDescriptor_LineItems_Delete);
};


module.exports = proto.proto;

