import { Listbox, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment } from "react";
import { FlexBox, FlexItem } from "./FlexBox";
import { ErrorMessage } from "./form/ErrorMessage";
import { CheckIcon } from "./icons/CheckIcon";
import { ChevronDownIcon } from "./icons/ChevronDownIcon";
import { Typography } from "./Typography";

export interface IOption<T> {
  label: string;
  value: T;
  disabled?: boolean;
  icon?: React.ReactNode;
}

interface IProps<T> {
  value?: T;
  onChange: (v?: T) => void;
  options: IOption<T>[];
  disabled?: boolean;
  label?: string;
  subLabel?: string;
  required?: boolean;
  error?: string;
  fullWidth?: boolean;
}

export function Dropdown<T>(props: IProps<T>) {
  return (
    <div className={["mb-4", "sm:mb-6", props.fullWidth ? 'w-full' : ''].join(" ")}>
      <Listbox value={props.value} onChange={props.onChange} disabled={props.disabled}>
        {({ open }) => (
          <FlexBox spacing={[0, 1]} direction="col">
            <FlexItem>
              {props.label && (
                <Listbox.Label>
                  <Typography.Body noPadding color="primary">
                    {props.label} {props.required && "*"}
                  </Typography.Body>
                </Listbox.Label>
              )}
              {props.subLabel && (
                <Listbox.Label>
                  <Typography.Label noPadding color="secondary">
                    {props.subLabel}
                  </Typography.Label>
                </Listbox.Label>
              )}
            </FlexItem>
            <FlexItem>
              <div className="relative">
                <Listbox.Button
                  className={classNames(
                    "relative w-full bg-white border border-secondary-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm h-10 sm:h-12 md:h-14",
                    props.error
                      ? "ring-danger border-danger focus:bg-white"
                      : "focus:ring-primary-500 focus:border-primary-900 focus:bg-white",
                    props.disabled && "opacity-30 cursor-not-allowed"
                  )}
                  disabled={props.disabled}
                >
                  <span className="flex items-center h-6">
                    <Typography.Body noPadding>
                      {props.options.filter((o) => o.value === props.value)[0]
                        ? props.options.filter((o) => o.value === props.value)[0].label
                        : ""}
                    </Typography.Body>
                  </span>
                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronDownIcon color={props.error ? "danger" : "primary"} />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    static
                  >
                    {props.options.map((option, key) => (
                      <Listbox.Option
                        key={key}
                        className={({ active }) =>
                          classNames(
                            option.disabled && "cursor-not-allowed opacity-30",
                            active ? "bg-primary-100" : "text-secondary-900",
                            "relative py-2 pl-10 pr-4"
                          )
                        }
                        disabled={option.disabled}
                        value={option.value}
                      >
                        {({ selected }) => (
                          <>
                            <Typography.Label noPadding weight={selected ? "bold" : "normal"}>
                              {option.label}
                            </Typography.Label>
                            {option.icon}

                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-900">
                                <CheckIcon />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </FlexItem>
            {props.error && (
              <FlexItem>
                <ErrorMessage messages={[props.error]} />
              </FlexItem>
            )}
          </FlexBox>
        )}
      </Listbox>
    </div>
  );
}
