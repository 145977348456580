// source: search.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var money_pb = require('./money_pb.js');
goog.object.extend(proto, money_pb);
var media_pb = require('./media_pb.js');
goog.object.extend(proto, media_pb);
var location_corporate_membership_setting_pb = require('./location_corporate_membership_setting_pb.js');
goog.object.extend(proto, location_corporate_membership_setting_pb);
goog.exportSymbol('proto.proto.Coordinates', null, global);
goog.exportSymbol('proto.proto.LocationSchedule', null, global);
goog.exportSymbol('proto.proto.Search', null, global);
goog.exportSymbol('proto.proto.SearchQueryRequest', null, global);
goog.exportSymbol('proto.proto.SearchQueryRequest.CategoryLevel', null, global);
goog.exportSymbol('proto.proto.SearchQueryRequest.DurationService', null, global);
goog.exportSymbol('proto.proto.SearchQueryRequest.ServiceType', null, global);
goog.exportSymbol('proto.proto.SearchQueryResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Coordinates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Coordinates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Coordinates.displayName = 'proto.proto.Coordinates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LocationSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LocationSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LocationSchedule.displayName = 'proto.proto.LocationSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Search = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.Search.repeatedFields_, null);
};
goog.inherits(proto.proto.Search, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Search.displayName = 'proto.proto.Search';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SearchQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SearchQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.SearchQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SearchQueryRequest.displayName = 'proto.proto.SearchQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SearchQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SearchQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.SearchQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SearchQueryResponse.displayName = 'proto.proto.SearchQueryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Coordinates.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Coordinates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Coordinates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Coordinates.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFieldWithDefault(msg, 1, ""),
    longitude: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Coordinates}
 */
proto.proto.Coordinates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Coordinates;
  return proto.proto.Coordinates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Coordinates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Coordinates}
 */
proto.proto.Coordinates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Coordinates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Coordinates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Coordinates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Coordinates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string latitude = 1;
 * @return {string}
 */
proto.proto.Coordinates.prototype.getLatitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Coordinates} returns this
 */
proto.proto.Coordinates.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string longitude = 2;
 * @return {string}
 */
proto.proto.Coordinates.prototype.getLongitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Coordinates} returns this
 */
proto.proto.Coordinates.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LocationSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LocationSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LocationSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LocationSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, ""),
    opensAt: jspb.Message.getFieldWithDefault(msg, 2, ""),
    closesAt: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LocationSchedule}
 */
proto.proto.LocationSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LocationSchedule;
  return proto.proto.LocationSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LocationSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LocationSchedule}
 */
proto.proto.LocationSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpensAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosesAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LocationSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LocationSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LocationSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LocationSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpensAt();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClosesAt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string day = 1;
 * @return {string}
 */
proto.proto.LocationSchedule.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LocationSchedule} returns this
 */
proto.proto.LocationSchedule.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string opens_at = 2;
 * @return {string}
 */
proto.proto.LocationSchedule.prototype.getOpensAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LocationSchedule} returns this
 */
proto.proto.LocationSchedule.prototype.setOpensAt = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string closes_at = 3;
 * @return {string}
 */
proto.proto.LocationSchedule.prototype.getClosesAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.LocationSchedule} returns this
 */
proto.proto.LocationSchedule.prototype.setClosesAt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.Search.repeatedFields_ = [19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Search.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Search.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Search} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Search.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offeringName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    offeringDurationMins: jspb.Message.getFieldWithDefault(msg, 4, 0),
    offeringImage: (f = msg.getOfferingImage()) && media_pb.Media.toObject(includeInstance, f),
    distanceKms: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cost: (f = msg.getCost()) && money_pb.Money.toObject(includeInstance, f),
    providerId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    providerName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    orgName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    orgBanner: (f = msg.getOrgBanner()) && media_pb.Media.toObject(includeInstance, f),
    coordinates: (f = msg.getCoordinates()) && proto.proto.Coordinates.toObject(includeInstance, f),
    country: jspb.Message.getFieldWithDefault(msg, 13, ""),
    place: jspb.Message.getFieldWithDefault(msg, 14, ""),
    state: jspb.Message.getFieldWithDefault(msg, 15, ""),
    postCode: jspb.Message.getFieldWithDefault(msg, 16, ""),
    locId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    locName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    locScheduleList: jspb.Message.toObjectList(msg.getLocScheduleList(),
    proto.proto.LocationSchedule.toObject, includeInstance),
    bookingId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    bookingStatus: jspb.Message.getFieldWithDefault(msg, 21, ""),
    bookingType: jspb.Message.getFieldWithDefault(msg, 22, ""),
    bookingStartDatetime: (f = msg.getBookingStartDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    bookingEndDatetime: (f = msg.getBookingEndDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    offeringLevel: jspb.Message.getFieldWithDefault(msg, 25, ""),
    locationCorporateMembershipSetting: (f = msg.getLocationCorporateMembershipSetting()) && location_corporate_membership_setting_pb.LocationCorporateMembershipSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Search}
 */
proto.proto.Search.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Search;
  return proto.proto.Search.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Search} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Search}
 */
proto.proto.Search.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferingName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOfferingDurationMins(value);
      break;
    case 5:
      var value = new media_pb.Media;
      reader.readMessage(value,media_pb.Media.deserializeBinaryFromReader);
      msg.setOfferingImage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistanceKms(value);
      break;
    case 7:
      var value = new money_pb.Money;
      reader.readMessage(value,money_pb.Money.deserializeBinaryFromReader);
      msg.setCost(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgName(value);
      break;
    case 11:
      var value = new media_pb.Media;
      reader.readMessage(value,media_pb.Media.deserializeBinaryFromReader);
      msg.setOrgBanner(value);
      break;
    case 12:
      var value = new proto.proto.Coordinates;
      reader.readMessage(value,proto.proto.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlace(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostCode(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocName(value);
      break;
    case 19:
      var value = new proto.proto.LocationSchedule;
      reader.readMessage(value,proto.proto.LocationSchedule.deserializeBinaryFromReader);
      msg.addLocSchedule(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingStatus(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingType(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBookingStartDatetime(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBookingEndDatetime(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferingLevel(value);
      break;
    case 26:
      var value = new location_corporate_membership_setting_pb.LocationCorporateMembershipSetting;
      reader.readMessage(value,location_corporate_membership_setting_pb.LocationCorporateMembershipSetting.deserializeBinaryFromReader);
      msg.setLocationCorporateMembershipSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Search.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Search.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Search} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Search.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOfferingName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOfferingDurationMins();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOfferingImage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      media_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getDistanceKms();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCost();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getProviderId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProviderName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrgName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOrgBanner();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      media_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.proto.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPlace();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPostCode();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLocId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLocName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLocScheduleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.proto.LocationSchedule.serializeBinaryToWriter
    );
  }
  f = message.getBookingId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getBookingStatus();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getBookingType();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getBookingStartDatetime();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBookingEndDatetime();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOfferingLevel();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getLocationCorporateMembershipSetting();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      location_corporate_membership_setting_pb.LocationCorporateMembershipSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.proto.Search.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_name = 2;
 * @return {string}
 */
proto.proto.Search.prototype.getCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setCategoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string offering_name = 3;
 * @return {string}
 */
proto.proto.Search.prototype.getOfferingName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setOfferingName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 offering_duration_mins = 4;
 * @return {number}
 */
proto.proto.Search.prototype.getOfferingDurationMins = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setOfferingDurationMins = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Media offering_image = 5;
 * @return {?proto.proto.Media}
 */
proto.proto.Search.prototype.getOfferingImage = function() {
  return /** @type{?proto.proto.Media} */ (
    jspb.Message.getWrapperField(this, media_pb.Media, 5));
};


/**
 * @param {?proto.proto.Media|undefined} value
 * @return {!proto.proto.Search} returns this
*/
proto.proto.Search.prototype.setOfferingImage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.clearOfferingImage = function() {
  return this.setOfferingImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Search.prototype.hasOfferingImage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string distance_kms = 6;
 * @return {string}
 */
proto.proto.Search.prototype.getDistanceKms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setDistanceKms = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Money cost = 7;
 * @return {?proto.proto.Money}
 */
proto.proto.Search.prototype.getCost = function() {
  return /** @type{?proto.proto.Money} */ (
    jspb.Message.getWrapperField(this, money_pb.Money, 7));
};


/**
 * @param {?proto.proto.Money|undefined} value
 * @return {!proto.proto.Search} returns this
*/
proto.proto.Search.prototype.setCost = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.clearCost = function() {
  return this.setCost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Search.prototype.hasCost = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string provider_id = 8;
 * @return {string}
 */
proto.proto.Search.prototype.getProviderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string provider_name = 9;
 * @return {string}
 */
proto.proto.Search.prototype.getProviderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setProviderName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string org_name = 10;
 * @return {string}
 */
proto.proto.Search.prototype.getOrgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setOrgName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Media org_banner = 11;
 * @return {?proto.proto.Media}
 */
proto.proto.Search.prototype.getOrgBanner = function() {
  return /** @type{?proto.proto.Media} */ (
    jspb.Message.getWrapperField(this, media_pb.Media, 11));
};


/**
 * @param {?proto.proto.Media|undefined} value
 * @return {!proto.proto.Search} returns this
*/
proto.proto.Search.prototype.setOrgBanner = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.clearOrgBanner = function() {
  return this.setOrgBanner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Search.prototype.hasOrgBanner = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Coordinates coordinates = 12;
 * @return {?proto.proto.Coordinates}
 */
proto.proto.Search.prototype.getCoordinates = function() {
  return /** @type{?proto.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.proto.Coordinates, 12));
};


/**
 * @param {?proto.proto.Coordinates|undefined} value
 * @return {!proto.proto.Search} returns this
*/
proto.proto.Search.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Search.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string country = 13;
 * @return {string}
 */
proto.proto.Search.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string place = 14;
 * @return {string}
 */
proto.proto.Search.prototype.getPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string state = 15;
 * @return {string}
 */
proto.proto.Search.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string post_code = 16;
 * @return {string}
 */
proto.proto.Search.prototype.getPostCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setPostCode = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string loc_id = 17;
 * @return {string}
 */
proto.proto.Search.prototype.getLocId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setLocId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string loc_name = 18;
 * @return {string}
 */
proto.proto.Search.prototype.getLocName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setLocName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated LocationSchedule loc_schedule = 19;
 * @return {!Array<!proto.proto.LocationSchedule>}
 */
proto.proto.Search.prototype.getLocScheduleList = function() {
  return /** @type{!Array<!proto.proto.LocationSchedule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.LocationSchedule, 19));
};


/**
 * @param {!Array<!proto.proto.LocationSchedule>} value
 * @return {!proto.proto.Search} returns this
*/
proto.proto.Search.prototype.setLocScheduleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.proto.LocationSchedule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.LocationSchedule}
 */
proto.proto.Search.prototype.addLocSchedule = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.proto.LocationSchedule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.clearLocScheduleList = function() {
  return this.setLocScheduleList([]);
};


/**
 * optional string booking_id = 20;
 * @return {string}
 */
proto.proto.Search.prototype.getBookingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setBookingId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string booking_status = 21;
 * @return {string}
 */
proto.proto.Search.prototype.getBookingStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setBookingStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string booking_type = 22;
 * @return {string}
 */
proto.proto.Search.prototype.getBookingType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setBookingType = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional google.protobuf.Timestamp booking_start_datetime = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Search.prototype.getBookingStartDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Search} returns this
*/
proto.proto.Search.prototype.setBookingStartDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.clearBookingStartDatetime = function() {
  return this.setBookingStartDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Search.prototype.hasBookingStartDatetime = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.Timestamp booking_end_datetime = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Search.prototype.getBookingEndDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Search} returns this
*/
proto.proto.Search.prototype.setBookingEndDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.clearBookingEndDatetime = function() {
  return this.setBookingEndDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Search.prototype.hasBookingEndDatetime = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string offering_level = 25;
 * @return {string}
 */
proto.proto.Search.prototype.getOfferingLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.setOfferingLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional LocationCorporateMembershipSetting location_corporate_membership_setting = 26;
 * @return {?proto.proto.LocationCorporateMembershipSetting}
 */
proto.proto.Search.prototype.getLocationCorporateMembershipSetting = function() {
  return /** @type{?proto.proto.LocationCorporateMembershipSetting} */ (
    jspb.Message.getWrapperField(this, location_corporate_membership_setting_pb.LocationCorporateMembershipSetting, 26));
};


/**
 * @param {?proto.proto.LocationCorporateMembershipSetting|undefined} value
 * @return {!proto.proto.Search} returns this
*/
proto.proto.Search.prototype.setLocationCorporateMembershipSetting = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Search} returns this
 */
proto.proto.Search.prototype.clearLocationCorporateMembershipSetting = function() {
  return this.setLocationCorporateMembershipSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Search.prototype.hasLocationCorporateMembershipSetting = function() {
  return jspb.Message.getField(this, 26) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SearchQueryRequest.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SearchQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SearchQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SearchQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SearchQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    place: jspb.Message.getFieldWithDefault(msg, 2, ""),
    level: jspb.Message.getFieldWithDefault(msg, 3, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minCostPerService: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxCostPerService: jspb.Message.getFieldWithDefault(msg, 6, 0),
    queryDate: (f = msg.getQueryDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    coordinates: (f = msg.getCoordinates()) && proto.proto.Coordinates.toObject(includeInstance, f),
    keywordList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    serviceType: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SearchQueryRequest}
 */
proto.proto.SearchQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SearchQueryRequest;
  return proto.proto.SearchQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SearchQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SearchQueryRequest}
 */
proto.proto.SearchQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlace(value);
      break;
    case 3:
      var value = /** @type {!proto.proto.SearchQueryRequest.CategoryLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 4:
      var value = /** @type {!proto.proto.SearchQueryRequest.DurationService} */ (reader.readEnum());
      msg.setDuration(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinCostPerService(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxCostPerService(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQueryDate(value);
      break;
    case 8:
      var value = new proto.proto.Coordinates;
      reader.readMessage(value,proto.proto.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeyword(value);
      break;
    case 10:
      var value = /** @type {!proto.proto.SearchQueryRequest.ServiceType} */ (reader.readEnum());
      msg.setServiceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SearchQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SearchQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SearchQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SearchQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMinCostPerService();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMaxCostPerService();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getQueryDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.proto.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getKeywordList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getServiceType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.proto.SearchQueryRequest.CategoryLevel = {
  NONE: 0,
  BEGINNER: 1,
  INTERMEDIATE: 2,
  ADVANCED: 3
};

/**
 * @enum {number}
 */
proto.proto.SearchQueryRequest.DurationService = {
  ALL: 0,
  UNDER_30_MIN: 1,
  BETWEEN_30_TO_60_MIN: 2,
  MORE_THAN_60_MIN: 3
};

/**
 * @enum {number}
 */
proto.proto.SearchQueryRequest.ServiceType = {
  ALL_SERVICES: 0,
  PUBLIC: 1,
  CORPORATE: 2
};

/**
 * optional string query = 1;
 * @return {string}
 */
proto.proto.SearchQueryRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string place = 2;
 * @return {string}
 */
proto.proto.SearchQueryRequest.prototype.getPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.setPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CategoryLevel level = 3;
 * @return {!proto.proto.SearchQueryRequest.CategoryLevel}
 */
proto.proto.SearchQueryRequest.prototype.getLevel = function() {
  return /** @type {!proto.proto.SearchQueryRequest.CategoryLevel} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.proto.SearchQueryRequest.CategoryLevel} value
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional DurationService duration = 4;
 * @return {!proto.proto.SearchQueryRequest.DurationService}
 */
proto.proto.SearchQueryRequest.prototype.getDuration = function() {
  return /** @type {!proto.proto.SearchQueryRequest.DurationService} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.proto.SearchQueryRequest.DurationService} value
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int32 min_cost_per_service = 5;
 * @return {number}
 */
proto.proto.SearchQueryRequest.prototype.getMinCostPerService = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.setMinCostPerService = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 max_cost_per_service = 6;
 * @return {number}
 */
proto.proto.SearchQueryRequest.prototype.getMaxCostPerService = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.setMaxCostPerService = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp query_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.SearchQueryRequest.prototype.getQueryDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.SearchQueryRequest} returns this
*/
proto.proto.SearchQueryRequest.prototype.setQueryDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.clearQueryDate = function() {
  return this.setQueryDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SearchQueryRequest.prototype.hasQueryDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Coordinates coordinates = 8;
 * @return {?proto.proto.Coordinates}
 */
proto.proto.SearchQueryRequest.prototype.getCoordinates = function() {
  return /** @type{?proto.proto.Coordinates} */ (
    jspb.Message.getWrapperField(this, proto.proto.Coordinates, 8));
};


/**
 * @param {?proto.proto.Coordinates|undefined} value
 * @return {!proto.proto.SearchQueryRequest} returns this
*/
proto.proto.SearchQueryRequest.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SearchQueryRequest.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string keyword = 9;
 * @return {!Array<string>}
 */
proto.proto.SearchQueryRequest.prototype.getKeywordList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.setKeywordList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.addKeyword = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.clearKeywordList = function() {
  return this.setKeywordList([]);
};


/**
 * optional ServiceType service_type = 10;
 * @return {!proto.proto.SearchQueryRequest.ServiceType}
 */
proto.proto.SearchQueryRequest.prototype.getServiceType = function() {
  return /** @type {!proto.proto.SearchQueryRequest.ServiceType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.proto.SearchQueryRequest.ServiceType} value
 * @return {!proto.proto.SearchQueryRequest} returns this
 */
proto.proto.SearchQueryRequest.prototype.setServiceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SearchQueryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SearchQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SearchQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SearchQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SearchQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchResultList: jspb.Message.toObjectList(msg.getSearchResultList(),
    proto.proto.Search.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SearchQueryResponse}
 */
proto.proto.SearchQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SearchQueryResponse;
  return proto.proto.SearchQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SearchQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SearchQueryResponse}
 */
proto.proto.SearchQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Search;
      reader.readMessage(value,proto.proto.Search.deserializeBinaryFromReader);
      msg.addSearchResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SearchQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SearchQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SearchQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SearchQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Search.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Search search_result = 1;
 * @return {!Array<!proto.proto.Search>}
 */
proto.proto.SearchQueryResponse.prototype.getSearchResultList = function() {
  return /** @type{!Array<!proto.proto.Search>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Search, 1));
};


/**
 * @param {!Array<!proto.proto.Search>} value
 * @return {!proto.proto.SearchQueryResponse} returns this
*/
proto.proto.SearchQueryResponse.prototype.setSearchResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Search=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Search}
 */
proto.proto.SearchQueryResponse.prototype.addSearchResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Search, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SearchQueryResponse} returns this
 */
proto.proto.SearchQueryResponse.prototype.clearSearchResultList = function() {
  return this.setSearchResultList([]);
};


goog.object.extend(exports, proto.proto);
