/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: organisation.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var user_pb = require('./user_pb.js')
const proto = {};
proto.proto = require('./organisation_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.OrganisationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.OrganisationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListOrganisationsRequest,
 *   !proto.proto.ListOrganisationsResponse>}
 */
const methodDescriptor_OrganisationService_ListOrganisations = new grpc.web.MethodDescriptor(
  '/proto.OrganisationService/ListOrganisations',
  grpc.web.MethodType.UNARY,
  proto.proto.ListOrganisationsRequest,
  proto.proto.ListOrganisationsResponse,
  /**
   * @param {!proto.proto.ListOrganisationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListOrganisationsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListOrganisationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListOrganisationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListOrganisationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.OrganisationServiceClient.prototype.listOrganisations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.OrganisationService/ListOrganisations',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_ListOrganisations,
      callback);
};


/**
 * @param {!proto.proto.ListOrganisationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListOrganisationsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.OrganisationServicePromiseClient.prototype.listOrganisations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.OrganisationService/ListOrganisations',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_ListOrganisations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetOrganisationRequest,
 *   !proto.proto.GetOrganisationResponse>}
 */
const methodDescriptor_OrganisationService_GetOrganisation = new grpc.web.MethodDescriptor(
  '/proto.OrganisationService/GetOrganisation',
  grpc.web.MethodType.UNARY,
  proto.proto.GetOrganisationRequest,
  proto.proto.GetOrganisationResponse,
  /**
   * @param {!proto.proto.GetOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GetOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.OrganisationServiceClient.prototype.getOrganisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.OrganisationService/GetOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_GetOrganisation,
      callback);
};


/**
 * @param {!proto.proto.GetOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.proto.OrganisationServicePromiseClient.prototype.getOrganisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.OrganisationService/GetOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_GetOrganisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetOrganisationTypeRequest,
 *   !proto.proto.GetOrganisationTypeResponse>}
 */
const methodDescriptor_OrganisationService_GetOrganisationType = new grpc.web.MethodDescriptor(
  '/proto.OrganisationService/GetOrganisationType',
  grpc.web.MethodType.UNARY,
  proto.proto.GetOrganisationTypeRequest,
  proto.proto.GetOrganisationTypeResponse,
  /**
   * @param {!proto.proto.GetOrganisationTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetOrganisationTypeResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetOrganisationTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GetOrganisationTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetOrganisationTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.OrganisationServiceClient.prototype.getOrganisationType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.OrganisationService/GetOrganisationType',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_GetOrganisationType,
      callback);
};


/**
 * @param {!proto.proto.GetOrganisationTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetOrganisationTypeResponse>}
 *     Promise that resolves to the response
 */
proto.proto.OrganisationServicePromiseClient.prototype.getOrganisationType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.OrganisationService/GetOrganisationType',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_GetOrganisationType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateOrgCorporateMembershipStatusReq,
 *   !proto.proto.UpdateOrgCorporateMembershipStatusRes>}
 */
const methodDescriptor_OrganisationService_UpdateOrgCorporateMembershipStatus = new grpc.web.MethodDescriptor(
  '/proto.OrganisationService/UpdateOrgCorporateMembershipStatus',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateOrgCorporateMembershipStatusReq,
  proto.proto.UpdateOrgCorporateMembershipStatusRes,
  /**
   * @param {!proto.proto.UpdateOrgCorporateMembershipStatusReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateOrgCorporateMembershipStatusRes.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateOrgCorporateMembershipStatusReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateOrgCorporateMembershipStatusRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateOrgCorporateMembershipStatusRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.OrganisationServiceClient.prototype.updateOrgCorporateMembershipStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.OrganisationService/UpdateOrgCorporateMembershipStatus',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_UpdateOrgCorporateMembershipStatus,
      callback);
};


/**
 * @param {!proto.proto.UpdateOrgCorporateMembershipStatusReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateOrgCorporateMembershipStatusRes>}
 *     Promise that resolves to the response
 */
proto.proto.OrganisationServicePromiseClient.prototype.updateOrgCorporateMembershipStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.OrganisationService/UpdateOrgCorporateMembershipStatus',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_UpdateOrgCorporateMembershipStatus);
};


module.exports = proto.proto;

