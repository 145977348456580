/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";
import { StringValue } from "./google/protobuf/wrappers";

export const protobufPackage = "proto";

export enum CreditRecordTypeEnumDTO {
  CORPORATE_USER_TO_CORPORATE = 0,
  CORPORATE_TO_CORPORATE_USER = 1,
  CORPORATE_USER_PURCHASE = 2,
  CORPORATE_RENEWAL = 3,
  CORPORATE_USER_REFUND = 4,
  UNRECOGNIZED = -1,
}

export function creditRecordTypeEnumDTOFromJSON(object: any): CreditRecordTypeEnumDTO {
  switch (object) {
    case 0:
    case "CORPORATE_USER_TO_CORPORATE":
      return CreditRecordTypeEnumDTO.CORPORATE_USER_TO_CORPORATE;
    case 1:
    case "CORPORATE_TO_CORPORATE_USER":
      return CreditRecordTypeEnumDTO.CORPORATE_TO_CORPORATE_USER;
    case 2:
    case "CORPORATE_USER_PURCHASE":
      return CreditRecordTypeEnumDTO.CORPORATE_USER_PURCHASE;
    case 3:
    case "CORPORATE_RENEWAL":
      return CreditRecordTypeEnumDTO.CORPORATE_RENEWAL;
    case 4:
    case "CORPORATE_USER_REFUND":
      return CreditRecordTypeEnumDTO.CORPORATE_USER_REFUND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CreditRecordTypeEnumDTO.UNRECOGNIZED;
  }
}

export function creditRecordTypeEnumDTOToJSON(object: CreditRecordTypeEnumDTO): string {
  switch (object) {
    case CreditRecordTypeEnumDTO.CORPORATE_USER_TO_CORPORATE:
      return "CORPORATE_USER_TO_CORPORATE";
    case CreditRecordTypeEnumDTO.CORPORATE_TO_CORPORATE_USER:
      return "CORPORATE_TO_CORPORATE_USER";
    case CreditRecordTypeEnumDTO.CORPORATE_USER_PURCHASE:
      return "CORPORATE_USER_PURCHASE";
    case CreditRecordTypeEnumDTO.CORPORATE_RENEWAL:
      return "CORPORATE_RENEWAL";
    case CreditRecordTypeEnumDTO.CORPORATE_USER_REFUND:
      return "CORPORATE_USER_REFUND";
    case CreditRecordTypeEnumDTO.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ListCreditRecordsRequest {
  corporateMembershipId: string;
}

export interface ListCreditRecordsResponse {
  data: CreditRecordDTO[];
}

export interface ListDailyCreditUsageRequest {
  corporateMembershipId: string;
}

export interface ListDailyCreditUsageResponse {
  data: DailyCreditUsageDTO[];
}

export interface ListUserMonthlyCreditUsageRequest {
  corporateMembershipId: string;
  corporateUserId: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  allStaff: boolean;
}

export interface ListUserMonthlyCreditUsageResponse {
  data: CorporateUserDailyCreditRecordDTO[];
}

export interface ListCorporateMonthlyCreditUsageRequest {
  corporateMembershipId: string;
  corporateUserId: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export interface ListCorporateMonthlyCreditUsageResponse {
  data: CorporateDailyCreditRecordDTO[];
}

export interface DailyCreditUsageDTO {
  date: Date | undefined;
  totalSpent: number;
  averageSpent: number;
  corporateAmountRemaining: number;
}

export interface CreditRecordDTO {
  date: Date | undefined;
  amount: number;
  corporateUserEmail: string;
  recordType: CreditRecordTypeEnumDTO;
  corporateUserAmountRemaining: number;
  corporateAmountRemaining: number;
}

export interface CorporateDailyCreditRecordDTO {
  date: Date | undefined;
  amount: number;
  allocatedTotal: number;
  unallocatedTotal: number;
  type: CreditRecordTypeEnumDTO;
  corporateUserName: string | undefined;
  corporateName: string;
  description: string;
}

export interface CorporateUserDailyCreditRecordDTO {
  date: Date | undefined;
  creditsUsed: number;
  personalFundsUsed: number;
  creditsDeposited: number;
  creditsBalance: number;
  event: CreditRecordTypeEnumDTO;
  corporateUserName: string;
  corporateName: string;
  description: string;
}

function createBaseListCreditRecordsRequest(): ListCreditRecordsRequest {
  return { corporateMembershipId: "" };
}

export const ListCreditRecordsRequest = {
  encode(message: ListCreditRecordsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateMembershipId !== "") {
      writer.uint32(10).string(message.corporateMembershipId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCreditRecordsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCreditRecordsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateMembershipId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCreditRecordsRequest {
    return {
      corporateMembershipId: isSet(object.corporateMembershipId) ? globalThis.String(object.corporateMembershipId) : "",
    };
  },

  toJSON(message: ListCreditRecordsRequest): unknown {
    const obj: any = {};
    if (message.corporateMembershipId !== "") {
      obj.corporateMembershipId = message.corporateMembershipId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCreditRecordsRequest>, I>>(base?: I): ListCreditRecordsRequest {
    return ListCreditRecordsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCreditRecordsRequest>, I>>(object: I): ListCreditRecordsRequest {
    const message = createBaseListCreditRecordsRequest();
    message.corporateMembershipId = object.corporateMembershipId ?? "";
    return message;
  },
};

function createBaseListCreditRecordsResponse(): ListCreditRecordsResponse {
  return { data: [] };
}

export const ListCreditRecordsResponse = {
  encode(message: ListCreditRecordsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.data) {
      CreditRecordDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCreditRecordsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCreditRecordsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(CreditRecordDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCreditRecordsResponse {
    return {
      data: globalThis.Array.isArray(object?.data) ? object.data.map((e: any) => CreditRecordDTO.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListCreditRecordsResponse): unknown {
    const obj: any = {};
    if (message.data?.length) {
      obj.data = message.data.map((e) => CreditRecordDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCreditRecordsResponse>, I>>(base?: I): ListCreditRecordsResponse {
    return ListCreditRecordsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCreditRecordsResponse>, I>>(object: I): ListCreditRecordsResponse {
    const message = createBaseListCreditRecordsResponse();
    message.data = object.data?.map((e) => CreditRecordDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListDailyCreditUsageRequest(): ListDailyCreditUsageRequest {
  return { corporateMembershipId: "" };
}

export const ListDailyCreditUsageRequest = {
  encode(message: ListDailyCreditUsageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateMembershipId !== "") {
      writer.uint32(10).string(message.corporateMembershipId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDailyCreditUsageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDailyCreditUsageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateMembershipId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDailyCreditUsageRequest {
    return {
      corporateMembershipId: isSet(object.corporateMembershipId) ? globalThis.String(object.corporateMembershipId) : "",
    };
  },

  toJSON(message: ListDailyCreditUsageRequest): unknown {
    const obj: any = {};
    if (message.corporateMembershipId !== "") {
      obj.corporateMembershipId = message.corporateMembershipId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListDailyCreditUsageRequest>, I>>(base?: I): ListDailyCreditUsageRequest {
    return ListDailyCreditUsageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListDailyCreditUsageRequest>, I>>(object: I): ListDailyCreditUsageRequest {
    const message = createBaseListDailyCreditUsageRequest();
    message.corporateMembershipId = object.corporateMembershipId ?? "";
    return message;
  },
};

function createBaseListDailyCreditUsageResponse(): ListDailyCreditUsageResponse {
  return { data: [] };
}

export const ListDailyCreditUsageResponse = {
  encode(message: ListDailyCreditUsageResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.data) {
      DailyCreditUsageDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDailyCreditUsageResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDailyCreditUsageResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(DailyCreditUsageDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDailyCreditUsageResponse {
    return {
      data: globalThis.Array.isArray(object?.data) ? object.data.map((e: any) => DailyCreditUsageDTO.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListDailyCreditUsageResponse): unknown {
    const obj: any = {};
    if (message.data?.length) {
      obj.data = message.data.map((e) => DailyCreditUsageDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListDailyCreditUsageResponse>, I>>(base?: I): ListDailyCreditUsageResponse {
    return ListDailyCreditUsageResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListDailyCreditUsageResponse>, I>>(object: I): ListDailyCreditUsageResponse {
    const message = createBaseListDailyCreditUsageResponse();
    message.data = object.data?.map((e) => DailyCreditUsageDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListUserMonthlyCreditUsageRequest(): ListUserMonthlyCreditUsageRequest {
  return { corporateMembershipId: "", corporateUserId: "", startDate: undefined, endDate: undefined, allStaff: false };
}

export const ListUserMonthlyCreditUsageRequest = {
  encode(message: ListUserMonthlyCreditUsageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateMembershipId !== "") {
      writer.uint32(10).string(message.corporateMembershipId);
    }
    if (message.corporateUserId !== "") {
      writer.uint32(18).string(message.corporateUserId);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(26).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(34).fork()).ldelim();
    }
    if (message.allStaff === true) {
      writer.uint32(40).bool(message.allStaff);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUserMonthlyCreditUsageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUserMonthlyCreditUsageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateMembershipId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.corporateUserId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.allStaff = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListUserMonthlyCreditUsageRequest {
    return {
      corporateMembershipId: isSet(object.corporateMembershipId) ? globalThis.String(object.corporateMembershipId) : "",
      corporateUserId: isSet(object.corporateUserId) ? globalThis.String(object.corporateUserId) : "",
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? fromJsonTimestamp(object.endDate) : undefined,
      allStaff: isSet(object.allStaff) ? globalThis.Boolean(object.allStaff) : false,
    };
  },

  toJSON(message: ListUserMonthlyCreditUsageRequest): unknown {
    const obj: any = {};
    if (message.corporateMembershipId !== "") {
      obj.corporateMembershipId = message.corporateMembershipId;
    }
    if (message.corporateUserId !== "") {
      obj.corporateUserId = message.corporateUserId;
    }
    if (message.startDate !== undefined) {
      obj.startDate = message.startDate.toISOString();
    }
    if (message.endDate !== undefined) {
      obj.endDate = message.endDate.toISOString();
    }
    if (message.allStaff === true) {
      obj.allStaff = message.allStaff;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListUserMonthlyCreditUsageRequest>, I>>(
    base?: I,
  ): ListUserMonthlyCreditUsageRequest {
    return ListUserMonthlyCreditUsageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListUserMonthlyCreditUsageRequest>, I>>(
    object: I,
  ): ListUserMonthlyCreditUsageRequest {
    const message = createBaseListUserMonthlyCreditUsageRequest();
    message.corporateMembershipId = object.corporateMembershipId ?? "";
    message.corporateUserId = object.corporateUserId ?? "";
    message.startDate = object.startDate ?? undefined;
    message.endDate = object.endDate ?? undefined;
    message.allStaff = object.allStaff ?? false;
    return message;
  },
};

function createBaseListUserMonthlyCreditUsageResponse(): ListUserMonthlyCreditUsageResponse {
  return { data: [] };
}

export const ListUserMonthlyCreditUsageResponse = {
  encode(message: ListUserMonthlyCreditUsageResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.data) {
      CorporateUserDailyCreditRecordDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListUserMonthlyCreditUsageResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListUserMonthlyCreditUsageResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(CorporateUserDailyCreditRecordDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListUserMonthlyCreditUsageResponse {
    return {
      data: globalThis.Array.isArray(object?.data)
        ? object.data.map((e: any) => CorporateUserDailyCreditRecordDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListUserMonthlyCreditUsageResponse): unknown {
    const obj: any = {};
    if (message.data?.length) {
      obj.data = message.data.map((e) => CorporateUserDailyCreditRecordDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListUserMonthlyCreditUsageResponse>, I>>(
    base?: I,
  ): ListUserMonthlyCreditUsageResponse {
    return ListUserMonthlyCreditUsageResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListUserMonthlyCreditUsageResponse>, I>>(
    object: I,
  ): ListUserMonthlyCreditUsageResponse {
    const message = createBaseListUserMonthlyCreditUsageResponse();
    message.data = object.data?.map((e) => CorporateUserDailyCreditRecordDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListCorporateMonthlyCreditUsageRequest(): ListCorporateMonthlyCreditUsageRequest {
  return { corporateMembershipId: "", corporateUserId: "", startDate: undefined, endDate: undefined };
}

export const ListCorporateMonthlyCreditUsageRequest = {
  encode(message: ListCorporateMonthlyCreditUsageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateMembershipId !== "") {
      writer.uint32(10).string(message.corporateMembershipId);
    }
    if (message.corporateUserId !== "") {
      writer.uint32(18).string(message.corporateUserId);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(26).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCorporateMonthlyCreditUsageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCorporateMonthlyCreditUsageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateMembershipId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.corporateUserId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCorporateMonthlyCreditUsageRequest {
    return {
      corporateMembershipId: isSet(object.corporateMembershipId) ? globalThis.String(object.corporateMembershipId) : "",
      corporateUserId: isSet(object.corporateUserId) ? globalThis.String(object.corporateUserId) : "",
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? fromJsonTimestamp(object.endDate) : undefined,
    };
  },

  toJSON(message: ListCorporateMonthlyCreditUsageRequest): unknown {
    const obj: any = {};
    if (message.corporateMembershipId !== "") {
      obj.corporateMembershipId = message.corporateMembershipId;
    }
    if (message.corporateUserId !== "") {
      obj.corporateUserId = message.corporateUserId;
    }
    if (message.startDate !== undefined) {
      obj.startDate = message.startDate.toISOString();
    }
    if (message.endDate !== undefined) {
      obj.endDate = message.endDate.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCorporateMonthlyCreditUsageRequest>, I>>(
    base?: I,
  ): ListCorporateMonthlyCreditUsageRequest {
    return ListCorporateMonthlyCreditUsageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCorporateMonthlyCreditUsageRequest>, I>>(
    object: I,
  ): ListCorporateMonthlyCreditUsageRequest {
    const message = createBaseListCorporateMonthlyCreditUsageRequest();
    message.corporateMembershipId = object.corporateMembershipId ?? "";
    message.corporateUserId = object.corporateUserId ?? "";
    message.startDate = object.startDate ?? undefined;
    message.endDate = object.endDate ?? undefined;
    return message;
  },
};

function createBaseListCorporateMonthlyCreditUsageResponse(): ListCorporateMonthlyCreditUsageResponse {
  return { data: [] };
}

export const ListCorporateMonthlyCreditUsageResponse = {
  encode(message: ListCorporateMonthlyCreditUsageResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.data) {
      CorporateDailyCreditRecordDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCorporateMonthlyCreditUsageResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCorporateMonthlyCreditUsageResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(CorporateDailyCreditRecordDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCorporateMonthlyCreditUsageResponse {
    return {
      data: globalThis.Array.isArray(object?.data)
        ? object.data.map((e: any) => CorporateDailyCreditRecordDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListCorporateMonthlyCreditUsageResponse): unknown {
    const obj: any = {};
    if (message.data?.length) {
      obj.data = message.data.map((e) => CorporateDailyCreditRecordDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCorporateMonthlyCreditUsageResponse>, I>>(
    base?: I,
  ): ListCorporateMonthlyCreditUsageResponse {
    return ListCorporateMonthlyCreditUsageResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCorporateMonthlyCreditUsageResponse>, I>>(
    object: I,
  ): ListCorporateMonthlyCreditUsageResponse {
    const message = createBaseListCorporateMonthlyCreditUsageResponse();
    message.data = object.data?.map((e) => CorporateDailyCreditRecordDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDailyCreditUsageDTO(): DailyCreditUsageDTO {
  return { date: undefined, totalSpent: 0, averageSpent: 0, corporateAmountRemaining: 0 };
}

export const DailyCreditUsageDTO = {
  encode(message: DailyCreditUsageDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(10).fork()).ldelim();
    }
    if (message.totalSpent !== 0) {
      writer.uint32(17).double(message.totalSpent);
    }
    if (message.averageSpent !== 0) {
      writer.uint32(25).double(message.averageSpent);
    }
    if (message.corporateAmountRemaining !== 0) {
      writer.uint32(33).double(message.corporateAmountRemaining);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DailyCreditUsageDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDailyCreditUsageDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.totalSpent = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.averageSpent = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.corporateAmountRemaining = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DailyCreditUsageDTO {
    return {
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      totalSpent: isSet(object.totalSpent) ? globalThis.Number(object.totalSpent) : 0,
      averageSpent: isSet(object.averageSpent) ? globalThis.Number(object.averageSpent) : 0,
      corporateAmountRemaining: isSet(object.corporateAmountRemaining)
        ? globalThis.Number(object.corporateAmountRemaining)
        : 0,
    };
  },

  toJSON(message: DailyCreditUsageDTO): unknown {
    const obj: any = {};
    if (message.date !== undefined) {
      obj.date = message.date.toISOString();
    }
    if (message.totalSpent !== 0) {
      obj.totalSpent = message.totalSpent;
    }
    if (message.averageSpent !== 0) {
      obj.averageSpent = message.averageSpent;
    }
    if (message.corporateAmountRemaining !== 0) {
      obj.corporateAmountRemaining = message.corporateAmountRemaining;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DailyCreditUsageDTO>, I>>(base?: I): DailyCreditUsageDTO {
    return DailyCreditUsageDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DailyCreditUsageDTO>, I>>(object: I): DailyCreditUsageDTO {
    const message = createBaseDailyCreditUsageDTO();
    message.date = object.date ?? undefined;
    message.totalSpent = object.totalSpent ?? 0;
    message.averageSpent = object.averageSpent ?? 0;
    message.corporateAmountRemaining = object.corporateAmountRemaining ?? 0;
    return message;
  },
};

function createBaseCreditRecordDTO(): CreditRecordDTO {
  return {
    date: undefined,
    amount: 0,
    corporateUserEmail: "",
    recordType: 0,
    corporateUserAmountRemaining: 0,
    corporateAmountRemaining: 0,
  };
}

export const CreditRecordDTO = {
  encode(message: CreditRecordDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(10).fork()).ldelim();
    }
    if (message.amount !== 0) {
      writer.uint32(17).double(message.amount);
    }
    if (message.corporateUserEmail !== "") {
      writer.uint32(26).string(message.corporateUserEmail);
    }
    if (message.recordType !== 0) {
      writer.uint32(32).int32(message.recordType);
    }
    if (message.corporateUserAmountRemaining !== 0) {
      writer.uint32(41).double(message.corporateUserAmountRemaining);
    }
    if (message.corporateAmountRemaining !== 0) {
      writer.uint32(49).double(message.corporateAmountRemaining);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreditRecordDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreditRecordDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.amount = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.corporateUserEmail = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.recordType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.corporateUserAmountRemaining = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.corporateAmountRemaining = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreditRecordDTO {
    return {
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      amount: isSet(object.amount) ? globalThis.Number(object.amount) : 0,
      corporateUserEmail: isSet(object.corporateUserEmail) ? globalThis.String(object.corporateUserEmail) : "",
      recordType: isSet(object.recordType) ? creditRecordTypeEnumDTOFromJSON(object.recordType) : 0,
      corporateUserAmountRemaining: isSet(object.corporateUserAmountRemaining)
        ? globalThis.Number(object.corporateUserAmountRemaining)
        : 0,
      corporateAmountRemaining: isSet(object.corporateAmountRemaining)
        ? globalThis.Number(object.corporateAmountRemaining)
        : 0,
    };
  },

  toJSON(message: CreditRecordDTO): unknown {
    const obj: any = {};
    if (message.date !== undefined) {
      obj.date = message.date.toISOString();
    }
    if (message.amount !== 0) {
      obj.amount = message.amount;
    }
    if (message.corporateUserEmail !== "") {
      obj.corporateUserEmail = message.corporateUserEmail;
    }
    if (message.recordType !== 0) {
      obj.recordType = creditRecordTypeEnumDTOToJSON(message.recordType);
    }
    if (message.corporateUserAmountRemaining !== 0) {
      obj.corporateUserAmountRemaining = message.corporateUserAmountRemaining;
    }
    if (message.corporateAmountRemaining !== 0) {
      obj.corporateAmountRemaining = message.corporateAmountRemaining;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreditRecordDTO>, I>>(base?: I): CreditRecordDTO {
    return CreditRecordDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreditRecordDTO>, I>>(object: I): CreditRecordDTO {
    const message = createBaseCreditRecordDTO();
    message.date = object.date ?? undefined;
    message.amount = object.amount ?? 0;
    message.corporateUserEmail = object.corporateUserEmail ?? "";
    message.recordType = object.recordType ?? 0;
    message.corporateUserAmountRemaining = object.corporateUserAmountRemaining ?? 0;
    message.corporateAmountRemaining = object.corporateAmountRemaining ?? 0;
    return message;
  },
};

function createBaseCorporateDailyCreditRecordDTO(): CorporateDailyCreditRecordDTO {
  return {
    date: undefined,
    amount: 0,
    allocatedTotal: 0,
    unallocatedTotal: 0,
    type: 0,
    corporateUserName: undefined,
    corporateName: "",
    description: "",
  };
}

export const CorporateDailyCreditRecordDTO = {
  encode(message: CorporateDailyCreditRecordDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(10).fork()).ldelim();
    }
    if (message.amount !== 0) {
      writer.uint32(17).double(message.amount);
    }
    if (message.allocatedTotal !== 0) {
      writer.uint32(25).double(message.allocatedTotal);
    }
    if (message.unallocatedTotal !== 0) {
      writer.uint32(33).double(message.unallocatedTotal);
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.corporateUserName !== undefined) {
      StringValue.encode({ value: message.corporateUserName! }, writer.uint32(50).fork()).ldelim();
    }
    if (message.corporateName !== "") {
      writer.uint32(58).string(message.corporateName);
    }
    if (message.description !== "") {
      writer.uint32(66).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CorporateDailyCreditRecordDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCorporateDailyCreditRecordDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.amount = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.allocatedTotal = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.unallocatedTotal = reader.double();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.corporateUserName = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.corporateName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CorporateDailyCreditRecordDTO {
    return {
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      amount: isSet(object.amount) ? globalThis.Number(object.amount) : 0,
      allocatedTotal: isSet(object.allocatedTotal) ? globalThis.Number(object.allocatedTotal) : 0,
      unallocatedTotal: isSet(object.unallocatedTotal) ? globalThis.Number(object.unallocatedTotal) : 0,
      type: isSet(object.type) ? creditRecordTypeEnumDTOFromJSON(object.type) : 0,
      corporateUserName: isSet(object.corporateUserName) ? String(object.corporateUserName) : undefined,
      corporateName: isSet(object.corporateName) ? globalThis.String(object.corporateName) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: CorporateDailyCreditRecordDTO): unknown {
    const obj: any = {};
    if (message.date !== undefined) {
      obj.date = message.date.toISOString();
    }
    if (message.amount !== 0) {
      obj.amount = message.amount;
    }
    if (message.allocatedTotal !== 0) {
      obj.allocatedTotal = message.allocatedTotal;
    }
    if (message.unallocatedTotal !== 0) {
      obj.unallocatedTotal = message.unallocatedTotal;
    }
    if (message.type !== 0) {
      obj.type = creditRecordTypeEnumDTOToJSON(message.type);
    }
    if (message.corporateUserName !== undefined) {
      obj.corporateUserName = message.corporateUserName;
    }
    if (message.corporateName !== "") {
      obj.corporateName = message.corporateName;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CorporateDailyCreditRecordDTO>, I>>(base?: I): CorporateDailyCreditRecordDTO {
    return CorporateDailyCreditRecordDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CorporateDailyCreditRecordDTO>, I>>(
    object: I,
  ): CorporateDailyCreditRecordDTO {
    const message = createBaseCorporateDailyCreditRecordDTO();
    message.date = object.date ?? undefined;
    message.amount = object.amount ?? 0;
    message.allocatedTotal = object.allocatedTotal ?? 0;
    message.unallocatedTotal = object.unallocatedTotal ?? 0;
    message.type = object.type ?? 0;
    message.corporateUserName = object.corporateUserName ?? undefined;
    message.corporateName = object.corporateName ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseCorporateUserDailyCreditRecordDTO(): CorporateUserDailyCreditRecordDTO {
  return {
    date: undefined,
    creditsUsed: 0,
    personalFundsUsed: 0,
    creditsDeposited: 0,
    creditsBalance: 0,
    event: 0,
    corporateUserName: "",
    corporateName: "",
    description: "",
  };
}

export const CorporateUserDailyCreditRecordDTO = {
  encode(message: CorporateUserDailyCreditRecordDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(10).fork()).ldelim();
    }
    if (message.creditsUsed !== 0) {
      writer.uint32(17).double(message.creditsUsed);
    }
    if (message.personalFundsUsed !== 0) {
      writer.uint32(25).double(message.personalFundsUsed);
    }
    if (message.creditsDeposited !== 0) {
      writer.uint32(33).double(message.creditsDeposited);
    }
    if (message.creditsBalance !== 0) {
      writer.uint32(41).double(message.creditsBalance);
    }
    if (message.event !== 0) {
      writer.uint32(48).int32(message.event);
    }
    if (message.corporateUserName !== "") {
      writer.uint32(58).string(message.corporateUserName);
    }
    if (message.corporateName !== "") {
      writer.uint32(66).string(message.corporateName);
    }
    if (message.description !== "") {
      writer.uint32(74).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CorporateUserDailyCreditRecordDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCorporateUserDailyCreditRecordDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.creditsUsed = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.personalFundsUsed = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.creditsDeposited = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.creditsBalance = reader.double();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.event = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.corporateUserName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.corporateName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CorporateUserDailyCreditRecordDTO {
    return {
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      creditsUsed: isSet(object.creditsUsed) ? globalThis.Number(object.creditsUsed) : 0,
      personalFundsUsed: isSet(object.personalFundsUsed) ? globalThis.Number(object.personalFundsUsed) : 0,
      creditsDeposited: isSet(object.creditsDeposited) ? globalThis.Number(object.creditsDeposited) : 0,
      creditsBalance: isSet(object.creditsBalance) ? globalThis.Number(object.creditsBalance) : 0,
      event: isSet(object.event) ? creditRecordTypeEnumDTOFromJSON(object.event) : 0,
      corporateUserName: isSet(object.corporateUserName) ? globalThis.String(object.corporateUserName) : "",
      corporateName: isSet(object.corporateName) ? globalThis.String(object.corporateName) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: CorporateUserDailyCreditRecordDTO): unknown {
    const obj: any = {};
    if (message.date !== undefined) {
      obj.date = message.date.toISOString();
    }
    if (message.creditsUsed !== 0) {
      obj.creditsUsed = message.creditsUsed;
    }
    if (message.personalFundsUsed !== 0) {
      obj.personalFundsUsed = message.personalFundsUsed;
    }
    if (message.creditsDeposited !== 0) {
      obj.creditsDeposited = message.creditsDeposited;
    }
    if (message.creditsBalance !== 0) {
      obj.creditsBalance = message.creditsBalance;
    }
    if (message.event !== 0) {
      obj.event = creditRecordTypeEnumDTOToJSON(message.event);
    }
    if (message.corporateUserName !== "") {
      obj.corporateUserName = message.corporateUserName;
    }
    if (message.corporateName !== "") {
      obj.corporateName = message.corporateName;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CorporateUserDailyCreditRecordDTO>, I>>(
    base?: I,
  ): CorporateUserDailyCreditRecordDTO {
    return CorporateUserDailyCreditRecordDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CorporateUserDailyCreditRecordDTO>, I>>(
    object: I,
  ): CorporateUserDailyCreditRecordDTO {
    const message = createBaseCorporateUserDailyCreditRecordDTO();
    message.date = object.date ?? undefined;
    message.creditsUsed = object.creditsUsed ?? 0;
    message.personalFundsUsed = object.personalFundsUsed ?? 0;
    message.creditsDeposited = object.creditsDeposited ?? 0;
    message.creditsBalance = object.creditsBalance ?? 0;
    message.event = object.event ?? 0;
    message.corporateUserName = object.corporateUserName ?? "";
    message.corporateName = object.corporateName ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

export interface CorporateCreditRecordsService {
  ListDailyCreditUsage(
    request: DeepPartial<ListDailyCreditUsageRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListDailyCreditUsageResponse>;
  ListCreditRecords(
    request: DeepPartial<ListCreditRecordsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCreditRecordsResponse>;
  ListUserMonthlyCreditUsage(
    request: DeepPartial<ListUserMonthlyCreditUsageRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListUserMonthlyCreditUsageResponse>;
  ListCorporateMonthlyCreditUsage(
    request: DeepPartial<ListCorporateMonthlyCreditUsageRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCorporateMonthlyCreditUsageResponse>;
}

export class CorporateCreditRecordsServiceClientImpl implements CorporateCreditRecordsService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListDailyCreditUsage = this.ListDailyCreditUsage.bind(this);
    this.ListCreditRecords = this.ListCreditRecords.bind(this);
    this.ListUserMonthlyCreditUsage = this.ListUserMonthlyCreditUsage.bind(this);
    this.ListCorporateMonthlyCreditUsage = this.ListCorporateMonthlyCreditUsage.bind(this);
  }

  ListDailyCreditUsage(
    request: DeepPartial<ListDailyCreditUsageRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListDailyCreditUsageResponse> {
    return this.rpc.unary(
      CorporateCreditRecordsServiceListDailyCreditUsageDesc,
      ListDailyCreditUsageRequest.fromPartial(request),
      metadata,
    );
  }

  ListCreditRecords(
    request: DeepPartial<ListCreditRecordsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCreditRecordsResponse> {
    return this.rpc.unary(
      CorporateCreditRecordsServiceListCreditRecordsDesc,
      ListCreditRecordsRequest.fromPartial(request),
      metadata,
    );
  }

  ListUserMonthlyCreditUsage(
    request: DeepPartial<ListUserMonthlyCreditUsageRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListUserMonthlyCreditUsageResponse> {
    return this.rpc.unary(
      CorporateCreditRecordsServiceListUserMonthlyCreditUsageDesc,
      ListUserMonthlyCreditUsageRequest.fromPartial(request),
      metadata,
    );
  }

  ListCorporateMonthlyCreditUsage(
    request: DeepPartial<ListCorporateMonthlyCreditUsageRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCorporateMonthlyCreditUsageResponse> {
    return this.rpc.unary(
      CorporateCreditRecordsServiceListCorporateMonthlyCreditUsageDesc,
      ListCorporateMonthlyCreditUsageRequest.fromPartial(request),
      metadata,
    );
  }
}

export const CorporateCreditRecordsServiceDesc = { serviceName: "proto.CorporateCreditRecordsService" };

export const CorporateCreditRecordsServiceListDailyCreditUsageDesc: UnaryMethodDefinitionish = {
  methodName: "ListDailyCreditUsage",
  service: CorporateCreditRecordsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListDailyCreditUsageRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListDailyCreditUsageResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateCreditRecordsServiceListCreditRecordsDesc: UnaryMethodDefinitionish = {
  methodName: "ListCreditRecords",
  service: CorporateCreditRecordsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListCreditRecordsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListCreditRecordsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateCreditRecordsServiceListUserMonthlyCreditUsageDesc: UnaryMethodDefinitionish = {
  methodName: "ListUserMonthlyCreditUsage",
  service: CorporateCreditRecordsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListUserMonthlyCreditUsageRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListUserMonthlyCreditUsageResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateCreditRecordsServiceListCorporateMonthlyCreditUsageDesc: UnaryMethodDefinitionish = {
  methodName: "ListCorporateMonthlyCreditUsage",
  service: CorporateCreditRecordsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListCorporateMonthlyCreditUsageRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListCorporateMonthlyCreditUsageResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
