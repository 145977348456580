/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Provider } from "./bookings";
import { ISOCurrencyName, iSOCurrencyNameFromJSON, iSOCurrencyNameToJSON } from "./currencies";
import { Timestamp } from "./google/protobuf/timestamp";
import { LocationCorporateMembershipSetting, OnPeakHours } from "./location_corporate_membership_setting";
import { LocationUserDTO } from "./location_user";
import { Media } from "./media";
import {
  SearchQueryRequest_CategoryLevel,
  searchQueryRequest_CategoryLevelFromJSON,
  searchQueryRequest_CategoryLevelToJSON,
} from "./search";
import { User } from "./user";

export const protobufPackage = "proto";

export interface Location {
  banner: string;
  id: string;
  name: string;
  org: string;
  phone: string;
  status: string;
  scheduleMedia: Media | undefined;
  timeZone: string;
  locationCorporateMembershipSetting: LocationCorporateMembershipSetting | undefined;
}

export interface UpdateOnPeakHoursScheduleReq {
  locationId: string;
  onPeakHoursSchedule: OnPeakHours | undefined;
}

export interface UpdateOnPeakHoursScheduleRes {
}

export interface GetLocationRequest {
  locationId: string;
}

export interface GetLocationResponse {
  locationId: string;
  timeZone: string;
  location: Location | undefined;
}

export interface UpdateLocationRequest {
  location: Location | undefined;
}

export interface UpdateLocationResponse {
  location: Location | undefined;
}

export interface BookingSearchFiltersRequest {
  locationId: string;
}

export interface LocationUserSearchFilters {
  providerId: string;
  firstName: string;
  lastName: string;
  hasBooking: boolean;
  subjectId: string;
}

export interface OfferingSearchFilters {
  offeringId: string;
  name: string;
  hasBooking: boolean;
}

export interface BookingSearchFiltersResponse {
  provider: LocationUserSearchFilters[];
  offering: OfferingSearchFilters[];
  availableLevel: string[];
}

export interface BookingSearchRequest {
  locationId: string;
  offeringId: string;
  providerId: string;
  level: SearchQueryRequest_CategoryLevel;
  searchDatetime: Date | undefined;
  numberOfDays: number;
  /** accepted timezones from tz database https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  timezone: string;
}

export interface BookingSearchResponse {
  provider: LocationUserDTO[];
  user: User | undefined;
  currency: ISOCurrencyName;
  address: string;
}

export interface GetLocationProviderRequest {
  locationId: string;
  providerId: string;
  nameSearch: string;
}

export interface GetLocationProviderResponse {
  providers: Provider[];
}

function createBaseLocation(): Location {
  return {
    banner: "",
    id: "",
    name: "",
    org: "",
    phone: "",
    status: "",
    scheduleMedia: undefined,
    timeZone: "",
    locationCorporateMembershipSetting: undefined,
  };
}

export const Location = {
  encode(message: Location, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.banner !== "") {
      writer.uint32(10).string(message.banner);
    }
    if (message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.org !== "") {
      writer.uint32(34).string(message.org);
    }
    if (message.phone !== "") {
      writer.uint32(42).string(message.phone);
    }
    if (message.status !== "") {
      writer.uint32(50).string(message.status);
    }
    if (message.scheduleMedia !== undefined) {
      Media.encode(message.scheduleMedia, writer.uint32(58).fork()).ldelim();
    }
    if (message.timeZone !== "") {
      writer.uint32(66).string(message.timeZone);
    }
    if (message.locationCorporateMembershipSetting !== undefined) {
      LocationCorporateMembershipSetting.encode(message.locationCorporateMembershipSetting, writer.uint32(210).fork())
        .ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Location {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.banner = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.org = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.status = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.scheduleMedia = Media.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.locationCorporateMembershipSetting = LocationCorporateMembershipSetting.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Location {
    return {
      banner: isSet(object.banner) ? globalThis.String(object.banner) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      org: isSet(object.org) ? globalThis.String(object.org) : "",
      phone: isSet(object.phone) ? globalThis.String(object.phone) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      scheduleMedia: isSet(object.scheduleMedia) ? Media.fromJSON(object.scheduleMedia) : undefined,
      timeZone: isSet(object.timeZone) ? globalThis.String(object.timeZone) : "",
      locationCorporateMembershipSetting: isSet(object.locationCorporateMembershipSetting)
        ? LocationCorporateMembershipSetting.fromJSON(object.locationCorporateMembershipSetting)
        : undefined,
    };
  },

  toJSON(message: Location): unknown {
    const obj: any = {};
    if (message.banner !== "") {
      obj.banner = message.banner;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.org !== "") {
      obj.org = message.org;
    }
    if (message.phone !== "") {
      obj.phone = message.phone;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.scheduleMedia !== undefined) {
      obj.scheduleMedia = Media.toJSON(message.scheduleMedia);
    }
    if (message.timeZone !== "") {
      obj.timeZone = message.timeZone;
    }
    if (message.locationCorporateMembershipSetting !== undefined) {
      obj.locationCorporateMembershipSetting = LocationCorporateMembershipSetting.toJSON(
        message.locationCorporateMembershipSetting,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Location>, I>>(base?: I): Location {
    return Location.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Location>, I>>(object: I): Location {
    const message = createBaseLocation();
    message.banner = object.banner ?? "";
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.org = object.org ?? "";
    message.phone = object.phone ?? "";
    message.status = object.status ?? "";
    message.scheduleMedia = (object.scheduleMedia !== undefined && object.scheduleMedia !== null)
      ? Media.fromPartial(object.scheduleMedia)
      : undefined;
    message.timeZone = object.timeZone ?? "";
    message.locationCorporateMembershipSetting =
      (object.locationCorporateMembershipSetting !== undefined && object.locationCorporateMembershipSetting !== null)
        ? LocationCorporateMembershipSetting.fromPartial(object.locationCorporateMembershipSetting)
        : undefined;
    return message;
  },
};

function createBaseUpdateOnPeakHoursScheduleReq(): UpdateOnPeakHoursScheduleReq {
  return { locationId: "", onPeakHoursSchedule: undefined };
}

export const UpdateOnPeakHoursScheduleReq = {
  encode(message: UpdateOnPeakHoursScheduleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    if (message.onPeakHoursSchedule !== undefined) {
      OnPeakHours.encode(message.onPeakHoursSchedule, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnPeakHoursScheduleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOnPeakHoursScheduleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.onPeakHoursSchedule = OnPeakHours.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateOnPeakHoursScheduleReq {
    return {
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      onPeakHoursSchedule: isSet(object.onPeakHoursSchedule)
        ? OnPeakHours.fromJSON(object.onPeakHoursSchedule)
        : undefined,
    };
  },

  toJSON(message: UpdateOnPeakHoursScheduleReq): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.onPeakHoursSchedule !== undefined) {
      obj.onPeakHoursSchedule = OnPeakHours.toJSON(message.onPeakHoursSchedule);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateOnPeakHoursScheduleReq>, I>>(base?: I): UpdateOnPeakHoursScheduleReq {
    return UpdateOnPeakHoursScheduleReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateOnPeakHoursScheduleReq>, I>>(object: I): UpdateOnPeakHoursScheduleReq {
    const message = createBaseUpdateOnPeakHoursScheduleReq();
    message.locationId = object.locationId ?? "";
    message.onPeakHoursSchedule = (object.onPeakHoursSchedule !== undefined && object.onPeakHoursSchedule !== null)
      ? OnPeakHours.fromPartial(object.onPeakHoursSchedule)
      : undefined;
    return message;
  },
};

function createBaseUpdateOnPeakHoursScheduleRes(): UpdateOnPeakHoursScheduleRes {
  return {};
}

export const UpdateOnPeakHoursScheduleRes = {
  encode(_: UpdateOnPeakHoursScheduleRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateOnPeakHoursScheduleRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateOnPeakHoursScheduleRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateOnPeakHoursScheduleRes {
    return {};
  },

  toJSON(_: UpdateOnPeakHoursScheduleRes): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateOnPeakHoursScheduleRes>, I>>(base?: I): UpdateOnPeakHoursScheduleRes {
    return UpdateOnPeakHoursScheduleRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateOnPeakHoursScheduleRes>, I>>(_: I): UpdateOnPeakHoursScheduleRes {
    const message = createBaseUpdateOnPeakHoursScheduleRes();
    return message;
  },
};

function createBaseGetLocationRequest(): GetLocationRequest {
  return { locationId: "" };
}

export const GetLocationRequest = {
  encode(message: GetLocationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLocationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLocationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLocationRequest {
    return { locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "" };
  },

  toJSON(message: GetLocationRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLocationRequest>, I>>(base?: I): GetLocationRequest {
    return GetLocationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLocationRequest>, I>>(object: I): GetLocationRequest {
    const message = createBaseGetLocationRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseGetLocationResponse(): GetLocationResponse {
  return { locationId: "", timeZone: "", location: undefined };
}

export const GetLocationResponse = {
  encode(message: GetLocationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    if (message.timeZone !== "") {
      writer.uint32(18).string(message.timeZone);
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLocationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLocationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLocationResponse {
    return {
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      timeZone: isSet(object.timeZone) ? globalThis.String(object.timeZone) : "",
      location: isSet(object.location) ? Location.fromJSON(object.location) : undefined,
    };
  },

  toJSON(message: GetLocationResponse): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.timeZone !== "") {
      obj.timeZone = message.timeZone;
    }
    if (message.location !== undefined) {
      obj.location = Location.toJSON(message.location);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLocationResponse>, I>>(base?: I): GetLocationResponse {
    return GetLocationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLocationResponse>, I>>(object: I): GetLocationResponse {
    const message = createBaseGetLocationResponse();
    message.locationId = object.locationId ?? "";
    message.timeZone = object.timeZone ?? "";
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    return message;
  },
};

function createBaseUpdateLocationRequest(): UpdateLocationRequest {
  return { location: undefined };
}

export const UpdateLocationRequest = {
  encode(message: UpdateLocationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLocationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLocationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateLocationRequest {
    return { location: isSet(object.location) ? Location.fromJSON(object.location) : undefined };
  },

  toJSON(message: UpdateLocationRequest): unknown {
    const obj: any = {};
    if (message.location !== undefined) {
      obj.location = Location.toJSON(message.location);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateLocationRequest>, I>>(base?: I): UpdateLocationRequest {
    return UpdateLocationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLocationRequest>, I>>(object: I): UpdateLocationRequest {
    const message = createBaseUpdateLocationRequest();
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    return message;
  },
};

function createBaseUpdateLocationResponse(): UpdateLocationResponse {
  return { location: undefined };
}

export const UpdateLocationResponse = {
  encode(message: UpdateLocationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLocationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLocationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateLocationResponse {
    return { location: isSet(object.location) ? Location.fromJSON(object.location) : undefined };
  },

  toJSON(message: UpdateLocationResponse): unknown {
    const obj: any = {};
    if (message.location !== undefined) {
      obj.location = Location.toJSON(message.location);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateLocationResponse>, I>>(base?: I): UpdateLocationResponse {
    return UpdateLocationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLocationResponse>, I>>(object: I): UpdateLocationResponse {
    const message = createBaseUpdateLocationResponse();
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    return message;
  },
};

function createBaseBookingSearchFiltersRequest(): BookingSearchFiltersRequest {
  return { locationId: "" };
}

export const BookingSearchFiltersRequest = {
  encode(message: BookingSearchFiltersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookingSearchFiltersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBookingSearchFiltersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BookingSearchFiltersRequest {
    return { locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "" };
  },

  toJSON(message: BookingSearchFiltersRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BookingSearchFiltersRequest>, I>>(base?: I): BookingSearchFiltersRequest {
    return BookingSearchFiltersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BookingSearchFiltersRequest>, I>>(object: I): BookingSearchFiltersRequest {
    const message = createBaseBookingSearchFiltersRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseLocationUserSearchFilters(): LocationUserSearchFilters {
  return { providerId: "", firstName: "", lastName: "", hasBooking: false, subjectId: "" };
}

export const LocationUserSearchFilters = {
  encode(message: LocationUserSearchFilters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.providerId !== "") {
      writer.uint32(10).string(message.providerId);
    }
    if (message.firstName !== "") {
      writer.uint32(18).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(26).string(message.lastName);
    }
    if (message.hasBooking === true) {
      writer.uint32(32).bool(message.hasBooking);
    }
    if (message.subjectId !== "") {
      writer.uint32(42).string(message.subjectId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocationUserSearchFilters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocationUserSearchFilters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.providerId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hasBooking = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.subjectId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LocationUserSearchFilters {
    return {
      providerId: isSet(object.providerId) ? globalThis.String(object.providerId) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      hasBooking: isSet(object.hasBooking) ? globalThis.Boolean(object.hasBooking) : false,
      subjectId: isSet(object.subjectId) ? globalThis.String(object.subjectId) : "",
    };
  },

  toJSON(message: LocationUserSearchFilters): unknown {
    const obj: any = {};
    if (message.providerId !== "") {
      obj.providerId = message.providerId;
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.hasBooking === true) {
      obj.hasBooking = message.hasBooking;
    }
    if (message.subjectId !== "") {
      obj.subjectId = message.subjectId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LocationUserSearchFilters>, I>>(base?: I): LocationUserSearchFilters {
    return LocationUserSearchFilters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LocationUserSearchFilters>, I>>(object: I): LocationUserSearchFilters {
    const message = createBaseLocationUserSearchFilters();
    message.providerId = object.providerId ?? "";
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.hasBooking = object.hasBooking ?? false;
    message.subjectId = object.subjectId ?? "";
    return message;
  },
};

function createBaseOfferingSearchFilters(): OfferingSearchFilters {
  return { offeringId: "", name: "", hasBooking: false };
}

export const OfferingSearchFilters = {
  encode(message: OfferingSearchFilters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.offeringId !== "") {
      writer.uint32(10).string(message.offeringId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.hasBooking === true) {
      writer.uint32(24).bool(message.hasBooking);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OfferingSearchFilters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOfferingSearchFilters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offeringId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasBooking = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OfferingSearchFilters {
    return {
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      hasBooking: isSet(object.hasBooking) ? globalThis.Boolean(object.hasBooking) : false,
    };
  },

  toJSON(message: OfferingSearchFilters): unknown {
    const obj: any = {};
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.hasBooking === true) {
      obj.hasBooking = message.hasBooking;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OfferingSearchFilters>, I>>(base?: I): OfferingSearchFilters {
    return OfferingSearchFilters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OfferingSearchFilters>, I>>(object: I): OfferingSearchFilters {
    const message = createBaseOfferingSearchFilters();
    message.offeringId = object.offeringId ?? "";
    message.name = object.name ?? "";
    message.hasBooking = object.hasBooking ?? false;
    return message;
  },
};

function createBaseBookingSearchFiltersResponse(): BookingSearchFiltersResponse {
  return { provider: [], offering: [], availableLevel: [] };
}

export const BookingSearchFiltersResponse = {
  encode(message: BookingSearchFiltersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.provider) {
      LocationUserSearchFilters.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.offering) {
      OfferingSearchFilters.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.availableLevel) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookingSearchFiltersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBookingSearchFiltersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.provider.push(LocationUserSearchFilters.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.offering.push(OfferingSearchFilters.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.availableLevel.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BookingSearchFiltersResponse {
    return {
      provider: globalThis.Array.isArray(object?.provider)
        ? object.provider.map((e: any) => LocationUserSearchFilters.fromJSON(e))
        : [],
      offering: globalThis.Array.isArray(object?.offering)
        ? object.offering.map((e: any) => OfferingSearchFilters.fromJSON(e))
        : [],
      availableLevel: globalThis.Array.isArray(object?.availableLevel)
        ? object.availableLevel.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: BookingSearchFiltersResponse): unknown {
    const obj: any = {};
    if (message.provider?.length) {
      obj.provider = message.provider.map((e) => LocationUserSearchFilters.toJSON(e));
    }
    if (message.offering?.length) {
      obj.offering = message.offering.map((e) => OfferingSearchFilters.toJSON(e));
    }
    if (message.availableLevel?.length) {
      obj.availableLevel = message.availableLevel;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BookingSearchFiltersResponse>, I>>(base?: I): BookingSearchFiltersResponse {
    return BookingSearchFiltersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BookingSearchFiltersResponse>, I>>(object: I): BookingSearchFiltersResponse {
    const message = createBaseBookingSearchFiltersResponse();
    message.provider = object.provider?.map((e) => LocationUserSearchFilters.fromPartial(e)) || [];
    message.offering = object.offering?.map((e) => OfferingSearchFilters.fromPartial(e)) || [];
    message.availableLevel = object.availableLevel?.map((e) => e) || [];
    return message;
  },
};

function createBaseBookingSearchRequest(): BookingSearchRequest {
  return {
    locationId: "",
    offeringId: "",
    providerId: "",
    level: 0,
    searchDatetime: undefined,
    numberOfDays: 0,
    timezone: "",
  };
}

export const BookingSearchRequest = {
  encode(message: BookingSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    if (message.offeringId !== "") {
      writer.uint32(18).string(message.offeringId);
    }
    if (message.providerId !== "") {
      writer.uint32(26).string(message.providerId);
    }
    if (message.level !== 0) {
      writer.uint32(32).int32(message.level);
    }
    if (message.searchDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.searchDatetime), writer.uint32(42).fork()).ldelim();
    }
    if (message.numberOfDays !== 0) {
      writer.uint32(48).int32(message.numberOfDays);
    }
    if (message.timezone !== "") {
      writer.uint32(58).string(message.timezone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookingSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBookingSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.offeringId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.providerId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.level = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.searchDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.numberOfDays = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.timezone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BookingSearchRequest {
    return {
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
      providerId: isSet(object.providerId) ? globalThis.String(object.providerId) : "",
      level: isSet(object.level) ? searchQueryRequest_CategoryLevelFromJSON(object.level) : 0,
      searchDatetime: isSet(object.searchDatetime) ? fromJsonTimestamp(object.searchDatetime) : undefined,
      numberOfDays: isSet(object.numberOfDays) ? globalThis.Number(object.numberOfDays) : 0,
      timezone: isSet(object.timezone) ? globalThis.String(object.timezone) : "",
    };
  },

  toJSON(message: BookingSearchRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    if (message.providerId !== "") {
      obj.providerId = message.providerId;
    }
    if (message.level !== 0) {
      obj.level = searchQueryRequest_CategoryLevelToJSON(message.level);
    }
    if (message.searchDatetime !== undefined) {
      obj.searchDatetime = message.searchDatetime.toISOString();
    }
    if (message.numberOfDays !== 0) {
      obj.numberOfDays = Math.round(message.numberOfDays);
    }
    if (message.timezone !== "") {
      obj.timezone = message.timezone;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BookingSearchRequest>, I>>(base?: I): BookingSearchRequest {
    return BookingSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BookingSearchRequest>, I>>(object: I): BookingSearchRequest {
    const message = createBaseBookingSearchRequest();
    message.locationId = object.locationId ?? "";
    message.offeringId = object.offeringId ?? "";
    message.providerId = object.providerId ?? "";
    message.level = object.level ?? 0;
    message.searchDatetime = object.searchDatetime ?? undefined;
    message.numberOfDays = object.numberOfDays ?? 0;
    message.timezone = object.timezone ?? "";
    return message;
  },
};

function createBaseBookingSearchResponse(): BookingSearchResponse {
  return { provider: [], user: undefined, currency: 0, address: "" };
}

export const BookingSearchResponse = {
  encode(message: BookingSearchResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.provider) {
      LocationUserDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).ldelim();
    }
    if (message.currency !== 0) {
      writer.uint32(24).int32(message.currency);
    }
    if (message.address !== "") {
      writer.uint32(34).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookingSearchResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBookingSearchResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.provider.push(LocationUserDTO.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BookingSearchResponse {
    return {
      provider: globalThis.Array.isArray(object?.provider)
        ? object.provider.map((e: any) => LocationUserDTO.fromJSON(e))
        : [],
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      currency: isSet(object.currency) ? iSOCurrencyNameFromJSON(object.currency) : 0,
      address: isSet(object.address) ? globalThis.String(object.address) : "",
    };
  },

  toJSON(message: BookingSearchResponse): unknown {
    const obj: any = {};
    if (message.provider?.length) {
      obj.provider = message.provider.map((e) => LocationUserDTO.toJSON(e));
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.currency !== 0) {
      obj.currency = iSOCurrencyNameToJSON(message.currency);
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BookingSearchResponse>, I>>(base?: I): BookingSearchResponse {
    return BookingSearchResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BookingSearchResponse>, I>>(object: I): BookingSearchResponse {
    const message = createBaseBookingSearchResponse();
    message.provider = object.provider?.map((e) => LocationUserDTO.fromPartial(e)) || [];
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.currency = object.currency ?? 0;
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetLocationProviderRequest(): GetLocationProviderRequest {
  return { locationId: "", providerId: "", nameSearch: "" };
}

export const GetLocationProviderRequest = {
  encode(message: GetLocationProviderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    if (message.providerId !== "") {
      writer.uint32(18).string(message.providerId);
    }
    if (message.nameSearch !== "") {
      writer.uint32(26).string(message.nameSearch);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLocationProviderRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLocationProviderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.providerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nameSearch = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLocationProviderRequest {
    return {
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      providerId: isSet(object.providerId) ? globalThis.String(object.providerId) : "",
      nameSearch: isSet(object.nameSearch) ? globalThis.String(object.nameSearch) : "",
    };
  },

  toJSON(message: GetLocationProviderRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.providerId !== "") {
      obj.providerId = message.providerId;
    }
    if (message.nameSearch !== "") {
      obj.nameSearch = message.nameSearch;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLocationProviderRequest>, I>>(base?: I): GetLocationProviderRequest {
    return GetLocationProviderRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLocationProviderRequest>, I>>(object: I): GetLocationProviderRequest {
    const message = createBaseGetLocationProviderRequest();
    message.locationId = object.locationId ?? "";
    message.providerId = object.providerId ?? "";
    message.nameSearch = object.nameSearch ?? "";
    return message;
  },
};

function createBaseGetLocationProviderResponse(): GetLocationProviderResponse {
  return { providers: [] };
}

export const GetLocationProviderResponse = {
  encode(message: GetLocationProviderResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.providers) {
      Provider.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLocationProviderResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLocationProviderResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.providers.push(Provider.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLocationProviderResponse {
    return {
      providers: globalThis.Array.isArray(object?.providers)
        ? object.providers.map((e: any) => Provider.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetLocationProviderResponse): unknown {
    const obj: any = {};
    if (message.providers?.length) {
      obj.providers = message.providers.map((e) => Provider.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLocationProviderResponse>, I>>(base?: I): GetLocationProviderResponse {
    return GetLocationProviderResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLocationProviderResponse>, I>>(object: I): GetLocationProviderResponse {
    const message = createBaseGetLocationProviderResponse();
    message.providers = object.providers?.map((e) => Provider.fromPartial(e)) || [];
    return message;
  },
};

export interface LocationService {
  Get(request: DeepPartial<GetLocationRequest>, metadata?: grpc.Metadata): Promise<GetLocationResponse>;
  Update(request: DeepPartial<UpdateLocationRequest>, metadata?: grpc.Metadata): Promise<UpdateLocationResponse>;
  GetProviders(
    request: DeepPartial<GetLocationProviderRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetLocationProviderResponse>;
  UpdateOnPeakHoursSchedule(
    request: DeepPartial<UpdateOnPeakHoursScheduleReq>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateOnPeakHoursScheduleRes>;
}

export class LocationServiceClientImpl implements LocationService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Get = this.Get.bind(this);
    this.Update = this.Update.bind(this);
    this.GetProviders = this.GetProviders.bind(this);
    this.UpdateOnPeakHoursSchedule = this.UpdateOnPeakHoursSchedule.bind(this);
  }

  Get(request: DeepPartial<GetLocationRequest>, metadata?: grpc.Metadata): Promise<GetLocationResponse> {
    return this.rpc.unary(LocationServiceGetDesc, GetLocationRequest.fromPartial(request), metadata);
  }

  Update(request: DeepPartial<UpdateLocationRequest>, metadata?: grpc.Metadata): Promise<UpdateLocationResponse> {
    return this.rpc.unary(LocationServiceUpdateDesc, UpdateLocationRequest.fromPartial(request), metadata);
  }

  GetProviders(
    request: DeepPartial<GetLocationProviderRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetLocationProviderResponse> {
    return this.rpc.unary(LocationServiceGetProvidersDesc, GetLocationProviderRequest.fromPartial(request), metadata);
  }

  UpdateOnPeakHoursSchedule(
    request: DeepPartial<UpdateOnPeakHoursScheduleReq>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateOnPeakHoursScheduleRes> {
    return this.rpc.unary(
      LocationServiceUpdateOnPeakHoursScheduleDesc,
      UpdateOnPeakHoursScheduleReq.fromPartial(request),
      metadata,
    );
  }
}

export const LocationServiceDesc = { serviceName: "proto.LocationService" };

export const LocationServiceGetDesc: UnaryMethodDefinitionish = {
  methodName: "Get",
  service: LocationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetLocationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetLocationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const LocationServiceUpdateDesc: UnaryMethodDefinitionish = {
  methodName: "Update",
  service: LocationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateLocationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateLocationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const LocationServiceGetProvidersDesc: UnaryMethodDefinitionish = {
  methodName: "GetProviders",
  service: LocationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetLocationProviderRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetLocationProviderResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const LocationServiceUpdateOnPeakHoursScheduleDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateOnPeakHoursSchedule",
  service: LocationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateOnPeakHoursScheduleReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateOnPeakHoursScheduleRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export interface LocationBookingService {
  BookingSearch(request: DeepPartial<BookingSearchRequest>, metadata?: grpc.Metadata): Promise<BookingSearchResponse>;
  BookingSearchFilters(
    request: DeepPartial<BookingSearchFiltersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BookingSearchFiltersResponse>;
}

export class LocationBookingServiceClientImpl implements LocationBookingService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.BookingSearch = this.BookingSearch.bind(this);
    this.BookingSearchFilters = this.BookingSearchFilters.bind(this);
  }

  BookingSearch(request: DeepPartial<BookingSearchRequest>, metadata?: grpc.Metadata): Promise<BookingSearchResponse> {
    return this.rpc.unary(LocationBookingServiceBookingSearchDesc, BookingSearchRequest.fromPartial(request), metadata);
  }

  BookingSearchFilters(
    request: DeepPartial<BookingSearchFiltersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BookingSearchFiltersResponse> {
    return this.rpc.unary(
      LocationBookingServiceBookingSearchFiltersDesc,
      BookingSearchFiltersRequest.fromPartial(request),
      metadata,
    );
  }
}

export const LocationBookingServiceDesc = { serviceName: "proto.LocationBookingService" };

export const LocationBookingServiceBookingSearchDesc: UnaryMethodDefinitionish = {
  methodName: "BookingSearch",
  service: LocationBookingServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return BookingSearchRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingSearchResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const LocationBookingServiceBookingSearchFiltersDesc: UnaryMethodDefinitionish = {
  methodName: "BookingSearchFilters",
  service: LocationBookingServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return BookingSearchFiltersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingSearchFiltersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
