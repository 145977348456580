import { createContext, useContext } from "react";
import { IGrpcService } from "../grpc/GrpcService";

interface Props {
  implementation: IGrpcService;
  children?: JSX.Element;
}

const ServiceContext = createContext<IGrpcService>({} as any);

export function GrpcProvider(props: Props) {
  return (
    <ServiceContext.Provider value={props.implementation}>{props.children}</ServiceContext.Provider>
  );
}

export function useGrpc() {
  return useContext(ServiceContext);
}
