import React, { PureComponent } from "react";
import { AccordianContainer } from "./../../../components/elements/AccordianElements";
import LargeDottedButton from "./../../../components/LargeDottedButton";
import { BookingDTO, BookingClient, LineItem } from "sdk/dist/bookings_pb";
import { allLineItems, isGroupBooking } from "./../../../util/bookings";
import { BookingLineItem } from "../BookingLineItem";
import { TabContainer, TabHeader } from "../../elements/Tabs";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import { NewLineItemForm } from "../NewLineItemForm";
import { bookingStore } from "../../../stores/booking-store";

interface Props {
  bookingObj: BookingDTO.AsObject;
  client?: BookingClient.AsObject;
}

@observer
export default class Offerings extends PureComponent<Props> {
  @observable
  formOpen = false;

  async componentDidMount() {
    await bookingStore.load(this.props.bookingObj.id);
  }

  render() {
    const { bookingObj, client } = this.props;
    const editable = bookingObj.status === BookingDTO.Status.BOOKING_CREATED;

    let lineItems = new Array<LineItem.AsObject>();
    if (isGroupBooking(bookingObj)) {
      // if it's a group booking then we show line items
      // depending on if a client has been selected or not
      lineItems = client
        ? client.offeringsList.map((off) => off.lineItem!)
        : bookingObj.offeringsList.map((off) => off.lineItem!);
    } else {
      // otherwise we always show all items
      lineItems = allLineItems(bookingObj, client);
    }

    return (
      <TabContainer>
        <TabHeader>Products &amp; Services</TabHeader>
        <AccordianContainer>
          {lineItems.map((item, i) => (
            <BookingLineItem key={i} booking={bookingObj} item={item} />
          ))}
        </AccordianContainer>

        {editable && this.formOpen && (
          <AccordianContainer>
            <NewLineItemForm
              booking={bookingObj}
              client={client}
              onRequestClose={() => (this.formOpen = false)}
            />
          </AccordianContainer>
        )}

        {editable && !this.formOpen && (
          <LargeDottedButton onClick={() => (this.formOpen = true)}>
            ADD PRODUCT OR SERVICE
          </LargeDottedButton>
        )}
      </TabContainer>
    );
  }
}
