/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";
import { Media } from "./media";

export const protobufPackage = "proto";

export interface Category {
  id: string;
  name: string;
  description: string;
  image: Media | undefined;
  icon: Media | undefined;
  HomeVisibility: boolean;
  parent: string;
}

export interface GetCategoriesRequest {
  categoryName: string;
}

export interface GetCategoriesResponse {
  category: Category[];
}

export interface NewCategoryRequest {
  categoryName: string;
  description: string;
  imageMediaId: string;
  iconMediaId: string;
  parentId: string;
  id: string;
  HomeVisibility: boolean;
}

export interface GetCategoryRequest {
  categoryId: string;
}

export interface UpdateCategoryRequest {
  categoryId: string;
  valueReplace: string;
  descriptionReplace: string;
  iconIdReplace: string;
  imageIdReplace: string;
  parentValueReplace: string;
  homeVisibilityReplace: boolean;
}

function createBaseCategory(): Category {
  return { id: "", name: "", description: "", image: undefined, icon: undefined, HomeVisibility: false, parent: "" };
}

export const Category = {
  encode(message: Category, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.image !== undefined) {
      Media.encode(message.image, writer.uint32(34).fork()).ldelim();
    }
    if (message.icon !== undefined) {
      Media.encode(message.icon, writer.uint32(42).fork()).ldelim();
    }
    if (message.HomeVisibility === true) {
      writer.uint32(48).bool(message.HomeVisibility);
    }
    if (message.parent !== "") {
      writer.uint32(58).string(message.parent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Category {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.image = Media.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.icon = Media.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.HomeVisibility = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.parent = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Category {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      image: isSet(object.image) ? Media.fromJSON(object.image) : undefined,
      icon: isSet(object.icon) ? Media.fromJSON(object.icon) : undefined,
      HomeVisibility: isSet(object.HomeVisibility) ? globalThis.Boolean(object.HomeVisibility) : false,
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
    };
  },

  toJSON(message: Category): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.image !== undefined) {
      obj.image = Media.toJSON(message.image);
    }
    if (message.icon !== undefined) {
      obj.icon = Media.toJSON(message.icon);
    }
    if (message.HomeVisibility === true) {
      obj.HomeVisibility = message.HomeVisibility;
    }
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Category>, I>>(base?: I): Category {
    return Category.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Category>, I>>(object: I): Category {
    const message = createBaseCategory();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.image = (object.image !== undefined && object.image !== null) ? Media.fromPartial(object.image) : undefined;
    message.icon = (object.icon !== undefined && object.icon !== null) ? Media.fromPartial(object.icon) : undefined;
    message.HomeVisibility = object.HomeVisibility ?? false;
    message.parent = object.parent ?? "";
    return message;
  },
};

function createBaseGetCategoriesRequest(): GetCategoriesRequest {
  return { categoryName: "" };
}

export const GetCategoriesRequest = {
  encode(message: GetCategoriesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.categoryName !== "") {
      writer.uint32(10).string(message.categoryName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCategoriesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCategoriesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.categoryName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCategoriesRequest {
    return { categoryName: isSet(object.categoryName) ? globalThis.String(object.categoryName) : "" };
  },

  toJSON(message: GetCategoriesRequest): unknown {
    const obj: any = {};
    if (message.categoryName !== "") {
      obj.categoryName = message.categoryName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCategoriesRequest>, I>>(base?: I): GetCategoriesRequest {
    return GetCategoriesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCategoriesRequest>, I>>(object: I): GetCategoriesRequest {
    const message = createBaseGetCategoriesRequest();
    message.categoryName = object.categoryName ?? "";
    return message;
  },
};

function createBaseGetCategoriesResponse(): GetCategoriesResponse {
  return { category: [] };
}

export const GetCategoriesResponse = {
  encode(message: GetCategoriesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.category) {
      Category.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCategoriesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCategoriesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.category.push(Category.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCategoriesResponse {
    return {
      category: globalThis.Array.isArray(object?.category) ? object.category.map((e: any) => Category.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetCategoriesResponse): unknown {
    const obj: any = {};
    if (message.category?.length) {
      obj.category = message.category.map((e) => Category.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCategoriesResponse>, I>>(base?: I): GetCategoriesResponse {
    return GetCategoriesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCategoriesResponse>, I>>(object: I): GetCategoriesResponse {
    const message = createBaseGetCategoriesResponse();
    message.category = object.category?.map((e) => Category.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNewCategoryRequest(): NewCategoryRequest {
  return {
    categoryName: "",
    description: "",
    imageMediaId: "",
    iconMediaId: "",
    parentId: "",
    id: "",
    HomeVisibility: false,
  };
}

export const NewCategoryRequest = {
  encode(message: NewCategoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.categoryName !== "") {
      writer.uint32(10).string(message.categoryName);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.imageMediaId !== "") {
      writer.uint32(26).string(message.imageMediaId);
    }
    if (message.iconMediaId !== "") {
      writer.uint32(34).string(message.iconMediaId);
    }
    if (message.parentId !== "") {
      writer.uint32(42).string(message.parentId);
    }
    if (message.id !== "") {
      writer.uint32(50).string(message.id);
    }
    if (message.HomeVisibility === true) {
      writer.uint32(56).bool(message.HomeVisibility);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NewCategoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNewCategoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.categoryName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.imageMediaId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.iconMediaId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.id = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.HomeVisibility = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NewCategoryRequest {
    return {
      categoryName: isSet(object.categoryName) ? globalThis.String(object.categoryName) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      imageMediaId: isSet(object.imageMediaId) ? globalThis.String(object.imageMediaId) : "",
      iconMediaId: isSet(object.iconMediaId) ? globalThis.String(object.iconMediaId) : "",
      parentId: isSet(object.parentId) ? globalThis.String(object.parentId) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      HomeVisibility: isSet(object.HomeVisibility) ? globalThis.Boolean(object.HomeVisibility) : false,
    };
  },

  toJSON(message: NewCategoryRequest): unknown {
    const obj: any = {};
    if (message.categoryName !== "") {
      obj.categoryName = message.categoryName;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.imageMediaId !== "") {
      obj.imageMediaId = message.imageMediaId;
    }
    if (message.iconMediaId !== "") {
      obj.iconMediaId = message.iconMediaId;
    }
    if (message.parentId !== "") {
      obj.parentId = message.parentId;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.HomeVisibility === true) {
      obj.HomeVisibility = message.HomeVisibility;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NewCategoryRequest>, I>>(base?: I): NewCategoryRequest {
    return NewCategoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NewCategoryRequest>, I>>(object: I): NewCategoryRequest {
    const message = createBaseNewCategoryRequest();
    message.categoryName = object.categoryName ?? "";
    message.description = object.description ?? "";
    message.imageMediaId = object.imageMediaId ?? "";
    message.iconMediaId = object.iconMediaId ?? "";
    message.parentId = object.parentId ?? "";
    message.id = object.id ?? "";
    message.HomeVisibility = object.HomeVisibility ?? false;
    return message;
  },
};

function createBaseGetCategoryRequest(): GetCategoryRequest {
  return { categoryId: "" };
}

export const GetCategoryRequest = {
  encode(message: GetCategoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.categoryId !== "") {
      writer.uint32(10).string(message.categoryId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCategoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCategoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.categoryId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCategoryRequest {
    return { categoryId: isSet(object.categoryId) ? globalThis.String(object.categoryId) : "" };
  },

  toJSON(message: GetCategoryRequest): unknown {
    const obj: any = {};
    if (message.categoryId !== "") {
      obj.categoryId = message.categoryId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCategoryRequest>, I>>(base?: I): GetCategoryRequest {
    return GetCategoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCategoryRequest>, I>>(object: I): GetCategoryRequest {
    const message = createBaseGetCategoryRequest();
    message.categoryId = object.categoryId ?? "";
    return message;
  },
};

function createBaseUpdateCategoryRequest(): UpdateCategoryRequest {
  return {
    categoryId: "",
    valueReplace: "",
    descriptionReplace: "",
    iconIdReplace: "",
    imageIdReplace: "",
    parentValueReplace: "",
    homeVisibilityReplace: false,
  };
}

export const UpdateCategoryRequest = {
  encode(message: UpdateCategoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.categoryId !== "") {
      writer.uint32(10).string(message.categoryId);
    }
    if (message.valueReplace !== "") {
      writer.uint32(18).string(message.valueReplace);
    }
    if (message.descriptionReplace !== "") {
      writer.uint32(26).string(message.descriptionReplace);
    }
    if (message.iconIdReplace !== "") {
      writer.uint32(34).string(message.iconIdReplace);
    }
    if (message.imageIdReplace !== "") {
      writer.uint32(42).string(message.imageIdReplace);
    }
    if (message.parentValueReplace !== "") {
      writer.uint32(50).string(message.parentValueReplace);
    }
    if (message.homeVisibilityReplace === true) {
      writer.uint32(56).bool(message.homeVisibilityReplace);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCategoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCategoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.categoryId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.valueReplace = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.descriptionReplace = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.iconIdReplace = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.imageIdReplace = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.parentValueReplace = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.homeVisibilityReplace = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCategoryRequest {
    return {
      categoryId: isSet(object.categoryId) ? globalThis.String(object.categoryId) : "",
      valueReplace: isSet(object.valueReplace) ? globalThis.String(object.valueReplace) : "",
      descriptionReplace: isSet(object.descriptionReplace) ? globalThis.String(object.descriptionReplace) : "",
      iconIdReplace: isSet(object.iconIdReplace) ? globalThis.String(object.iconIdReplace) : "",
      imageIdReplace: isSet(object.imageIdReplace) ? globalThis.String(object.imageIdReplace) : "",
      parentValueReplace: isSet(object.parentValueReplace) ? globalThis.String(object.parentValueReplace) : "",
      homeVisibilityReplace: isSet(object.homeVisibilityReplace)
        ? globalThis.Boolean(object.homeVisibilityReplace)
        : false,
    };
  },

  toJSON(message: UpdateCategoryRequest): unknown {
    const obj: any = {};
    if (message.categoryId !== "") {
      obj.categoryId = message.categoryId;
    }
    if (message.valueReplace !== "") {
      obj.valueReplace = message.valueReplace;
    }
    if (message.descriptionReplace !== "") {
      obj.descriptionReplace = message.descriptionReplace;
    }
    if (message.iconIdReplace !== "") {
      obj.iconIdReplace = message.iconIdReplace;
    }
    if (message.imageIdReplace !== "") {
      obj.imageIdReplace = message.imageIdReplace;
    }
    if (message.parentValueReplace !== "") {
      obj.parentValueReplace = message.parentValueReplace;
    }
    if (message.homeVisibilityReplace === true) {
      obj.homeVisibilityReplace = message.homeVisibilityReplace;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCategoryRequest>, I>>(base?: I): UpdateCategoryRequest {
    return UpdateCategoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCategoryRequest>, I>>(object: I): UpdateCategoryRequest {
    const message = createBaseUpdateCategoryRequest();
    message.categoryId = object.categoryId ?? "";
    message.valueReplace = object.valueReplace ?? "";
    message.descriptionReplace = object.descriptionReplace ?? "";
    message.iconIdReplace = object.iconIdReplace ?? "";
    message.imageIdReplace = object.imageIdReplace ?? "";
    message.parentValueReplace = object.parentValueReplace ?? "";
    message.homeVisibilityReplace = object.homeVisibilityReplace ?? false;
    return message;
  },
};

export interface CategoryService {
  /**
   * Get all child categories listed for parent category
   * request will return parent category name
   */
  GetChildCategories(
    request: DeepPartial<GetCategoriesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCategoriesResponse>;
  GetParents(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetCategoriesResponse>;
  NewCategory(request: DeepPartial<Category>, metadata?: grpc.Metadata): Promise<Category>;
  GetCategory(request: DeepPartial<GetCategoryRequest>, metadata?: grpc.Metadata): Promise<Category>;
  UpdateCategory(request: DeepPartial<UpdateCategoryRequest>, metadata?: grpc.Metadata): Promise<Category>;
}

export class CategoryServiceClientImpl implements CategoryService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetChildCategories = this.GetChildCategories.bind(this);
    this.GetParents = this.GetParents.bind(this);
    this.NewCategory = this.NewCategory.bind(this);
    this.GetCategory = this.GetCategory.bind(this);
    this.UpdateCategory = this.UpdateCategory.bind(this);
  }

  GetChildCategories(
    request: DeepPartial<GetCategoriesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCategoriesResponse> {
    return this.rpc.unary(CategoryServiceGetChildCategoriesDesc, GetCategoriesRequest.fromPartial(request), metadata);
  }

  GetParents(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetCategoriesResponse> {
    return this.rpc.unary(CategoryServiceGetParentsDesc, Empty.fromPartial(request), metadata);
  }

  NewCategory(request: DeepPartial<Category>, metadata?: grpc.Metadata): Promise<Category> {
    return this.rpc.unary(CategoryServiceNewCategoryDesc, Category.fromPartial(request), metadata);
  }

  GetCategory(request: DeepPartial<GetCategoryRequest>, metadata?: grpc.Metadata): Promise<Category> {
    return this.rpc.unary(CategoryServiceGetCategoryDesc, GetCategoryRequest.fromPartial(request), metadata);
  }

  UpdateCategory(request: DeepPartial<UpdateCategoryRequest>, metadata?: grpc.Metadata): Promise<Category> {
    return this.rpc.unary(CategoryServiceUpdateCategoryDesc, UpdateCategoryRequest.fromPartial(request), metadata);
  }
}

export const CategoryServiceDesc = { serviceName: "proto.CategoryService" };

export const CategoryServiceGetChildCategoriesDesc: UnaryMethodDefinitionish = {
  methodName: "GetChildCategories",
  service: CategoryServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCategoriesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCategoriesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CategoryServiceGetParentsDesc: UnaryMethodDefinitionish = {
  methodName: "GetParents",
  service: CategoryServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCategoriesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CategoryServiceNewCategoryDesc: UnaryMethodDefinitionish = {
  methodName: "NewCategory",
  service: CategoryServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Category.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Category.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CategoryServiceGetCategoryDesc: UnaryMethodDefinitionish = {
  methodName: "GetCategory",
  service: CategoryServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCategoryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Category.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CategoryServiceUpdateCategoryDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateCategory",
  service: CategoryServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateCategoryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Category.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
