import Form from "../../../form/Form";
import { TextInput } from "../../../../ui-kit/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Typography } from "../../../../ui-kit/Typography";
import { FlexBox, FlexItem } from "../../../../ui-kit/FlexBox";
import { useGrpc } from "../../../../hooks/useGrpc";
import { AsyncButton } from "../../../../ui-kit/AsyncButton";
import { useAsync } from "../../../../hooks/useAsync";
import { useEffect, useState } from "react";
import { Dropdown } from "../../../../ui-kit/Dropdown";
import { toastStore } from "../../../../stores/toast-store";

interface IProps {
  locationId: string;
}

interface IForm {
  mindbodyClientId: string;
}

const schema = Yup.object<IForm>().shape({
  mindbodyClientId: Yup.string().min(1).required()
});

export function MindBodyIntegration(props: IProps) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {}
  });

  const { services } = useGrpc();

  useEffect(() => {
    const t = setInterval(() => {
      activationStatusFetcher.refresh();
    }, 5000);
    return () => clearTimeout(t);
  }, []);

  const activationStatusFetcher = useAsync(async () => {
    const res = await services.mindbodyService.CheckActivation({ locationId: props.locationId });
    return res.activated;
  }, []);

  const activationLinkFetcher = useAsync(async () => {
    const res = await services.mindbodyService.GetActivationLink({ locationId: props.locationId });
    setValue("mindbodyClientId", res.clientId?.clientId ?? "");
    return res;
  }, []);

  const removeClientId = async () => {
    await services.mindbodyService.RemoveClientId({ locationId: props.locationId });
    activationLinkFetcher.refresh();
    activationStatusFetcher.refresh();
  };

  const onSubmit = async (data: IForm) => {
    await services.mindbodyService.SetClientId({
      clientId: data.mindbodyClientId,
      locationId: props.locationId
    });

    activationLinkFetcher.refresh();
    activationStatusFetcher.refresh();

    const res = await services.mindbodyService.GetActivationLink({ locationId: props.locationId });
    if (res.link) {
      window.open(res.link, "_blank")?.focus();
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FlexBox direction="col" spacing={[0, 5]} justifyContent="between" fullHeight>
        <FlexItem>
          {activationStatusFetcher.value ? (
            <Typography.H4>
              <span style={{ color: "green" }}>Integration Activated</span>
            </Typography.H4>
          ) : (
            <Typography.H4>
              <span style={{ color: "red" }}>Integration Not Yet Activated</span>
            </Typography.H4>
          )}
        </FlexItem>

        <FlexItem>
          <TextInput
            label="Mindbody Client ID"
            placeholder="Enter your mindbody client ID"
            value={watch("mindbodyClientId")}
            onChange={(v) => setValue("mindbodyClientId", v || "")}
            error={errors.mindbodyClientId?.message}
            required
          />
          <Typography.Label>
            Find your client ID by following this guide{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="https://support.mindbodyonline.com/s/article/206398178-How-do-I-find-my-Client-ID?language=en_US#:~:text=Mindbody%20Subscription%20screen-,While%20on%20your%20Mindbody%20site,not%20logged%20into%20your%20site."
            >
              here
            </a>
            .
          </Typography.Label>
        </FlexItem>
        {activationLinkFetcher.value?.link && (
          <FlexItem>
            <Typography.Label>
              Follow this link to activate the integration{" "}
              <a
                style={{ textDecoration: "underline", overflowWrap: "anywhere" }}
                href={activationLinkFetcher.value.link}
                target="_blank"
              >
                {activationLinkFetcher.value.link}
              </a>
            </Typography.Label>
          </FlexItem>
        )}
        <FlexItem>
          <FlexBox direction="row" spacing={[5, 0]} justifyContent="between" fullHeight>
            <AsyncButton
              onClick={() => {}}
              isDisabled={isSubmitting}
              isSubmitting={isSubmitting}
              type="submit"
              variant="primary"
              rounded="md"
              size="md"
              fullWidth
            >
              Save
            </AsyncButton>

            <AsyncButton
              onClick={async () => {
                await removeClientId();
              }}
              variant="inverted"
              rounded="md"
              size="md"
              fullWidth
            >
              Remove
            </AsyncButton>
          </FlexBox>
        </FlexItem>
        {activationStatusFetcher.value && (
          <>
            <OfferingMappings locationId={props.locationId} />
            <ProviderMappings locationId={props.locationId} />
          </>
        )}

        <FlexItem>
          <hr style={{ backgroundColor: "#EAEAEB", height: "2px" }} />
        </FlexItem>

        <FlexItem>
          <div style={{ marginBottom: "1em" }}>
            <Typography.Body>
              Once you have mapped your Mindbody classes and Mindbody staff, you can then create
              Lyfe sessions automatically from your Mindbody classes for the next 12 months using
              this button below. Availability will then continue to by synchronised from Mindbody to
              Lyfe for those sessions. Use the below button again within 12 months to create more
              Lyfe sessions.
            </Typography.Body>
          </div>
          <FlexBox direction="row" spacing={[5, 0]} justifyContent="between" fullHeight>
            <AsyncButton
              onClick={async () => {
                await services.mindbodyService.ManuallySync({
                  locationId: props.locationId
                });
                toastStore.success("Successfully created Lyfe sessions from Mindbody");
              }}
              variant="primary"
              rounded="md"
              size="md"
              fullWidth
            >
              Create Lyfe sessions from Mindbody classes
            </AsyncButton>
          </FlexBox>
        </FlexItem>
      </FlexBox>
    </Form>
  );
}

const OfferingMappings = (props: { locationId: string }) => {
  const { services } = useGrpc();

  const [offeringMap, setOfferingMap] = useState(
    Array<Partial<{ offeringId: string; mindbodyClassDescriptionId: number }>>
  );

  const mindbodyOfferingsFetcher = useAsync(async () => {
    const res = await services.mindbodyService.ListMindbodyOfferings({
      locationId: props.locationId
    });
    return res.offerings;
  }, []);

  const offeringsFetcher = useAsync(async () => {
    const res = await services.offeringsService.List({ locationId: props.locationId });
    return res.offerings;
  }, []);

  const mappedOfferingsFetcher = useAsync(async () => {
    const res = await services.mindbodyService.ListMindbodyOfferingMappings({
      locationId: props.locationId
    });
    setOfferingMap(res.mappings);
    return res.mappings;
  }, []);

  return (
    <>
      <FlexItem>
        <Typography.Body>Syncing will ONLY work with Mindbody classes and staff that have been mapped. Please complete the mapping configuration below before syncing.</Typography.Body>
        <Typography.H3>Map Your Mindbody Classes to Lyfe Services</Typography.H3>
        <Typography.Body>You need to first configure your Lyfe services.</Typography.Body>
      </FlexItem>

      <FlexItem>
        {offeringMap.map((v, index) => (
          <FlexBox key={v.offeringId} direction="row" spacing={[5, 0]} fullHeight>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AsyncButton
                onClick={async () => {
                  setOfferingMap((e) => e.filter((_, i) => i !== index));
                }}
                type="button"
                variant="inverted"
                rounded="md"
                size="sm"
              >
                Remove
              </AsyncButton>
            </div>

            <div style={{ width: "100%" }}>
              <FlexItem fullWidth>
                <Typography.Label>Mindbody</Typography.Label>
              </FlexItem>
              <Dropdown
                fullWidth
                value={v.mindbodyClassDescriptionId}
                onChange={(o) =>
                  setOfferingMap((e) => {
                    const updated = e.slice();
                    updated[index] = {
                      ...updated[index],
                      mindbodyClassDescriptionId: o
                    };
                    return updated;
                  })
                }
                options={
                  mindbodyOfferingsFetcher.value?.map((o) => ({ value: o.id, label: o.name })) ?? []
                }
              />
            </div>

            <div style={{ width: "100%" }}>
              <FlexItem fullWidth>
                <Typography.Label>Lyfe</Typography.Label>
              </FlexItem>
              <Dropdown
                fullWidth
                value={v.offeringId}
                onChange={(o) =>
                  setOfferingMap((e) => {
                    const updated = e.slice();
                    updated[index] = {
                      ...updated[index],
                      offeringId: o
                    };
                    return updated;
                  })
                }
                options={offeringsFetcher.value?.map((o) => ({ value: o.id, label: o.name })) ?? []}
              />
            </div>
          </FlexBox>
        ))}

        <FlexBox justifyContent="between" direction="row" spacing={[5, 0]} fullHeight>
          <AsyncButton
            onClick={async () => {
              setOfferingMap((e) => [...e, {}]);
            }}
            type="button"
            variant="primary"
            rounded="md"
            size="md"
            fullWidth
          >
            Add Class
          </AsyncButton>
          <AsyncButton
            onClick={async () => {
              await services.mindbodyService.UpdateMindbodyOfferingMappings({
                locationId: props.locationId,
                mappings: offeringMap.map((o) => ({
                  mindbodyClassDescriptionId: o.mindbodyClassDescriptionId,
                  offeringId: o.offeringId
                }))
              });
              toastStore.success("Service mappings have been saved");
              mappedOfferingsFetcher.refresh();
            }}
            isDisabled={offeringMap.some(
              (o) => o.mindbodyClassDescriptionId === undefined || o.offeringId === undefined
            )}
            type="button"
            variant="primary"
            rounded="md"
            size="md"
            fullWidth
          >
            Save
          </AsyncButton>
        </FlexBox>
      </FlexItem>
    </>
  );
};

const ProviderMappings = (props: { locationId: string }) => {
  const { services } = useGrpc();

  const [providerMap, setProviderMap] = useState(
    Array<Partial<{ providerId: string; mindbodyStaffId: number }>>
  );

  const mindbodyStaffFetcher = useAsync(async () => {
    const res = await services.mindbodyService.ListMindbodyStaff({
      locationId: props.locationId
    });
    return res.staff;
  }, []);

  const providersFetcher = useAsync(async () => {
    const res = await services.locationService.GetProviders({ locationId: props.locationId });
    return res.providers;
  }, []);

  const mappedProvidersFetcher = useAsync(async () => {
    const res = await services.mindbodyService.ListMindbodyProviderMappings({
      locationId: props.locationId
    });
    setProviderMap(res.mappings);
    return res.mappings;
  }, []);

  return (
    <>
      <FlexItem>
        <Typography.H3>Map Your Mindbody Staff to Lyfe Providers</Typography.H3>
        <Typography.Body>You need to first configure your Lyfe providers.</Typography.Body>
      </FlexItem>

      <FlexItem>
        {providerMap.map((v, index) => (
          <FlexBox key={v.providerId} direction="row" spacing={[5, 0]} fullHeight>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AsyncButton
                onClick={async () => {
                  setProviderMap((e) => e.filter((_, i) => i !== index));
                }}
                type="button"
                variant="inverted"
                rounded="md"
                size="sm"
              >
                Remove
              </AsyncButton>
            </div>

            <div style={{ width: "100%" }}>
              <FlexItem fullWidth>
                <Typography.Label>Mindbody</Typography.Label>
              </FlexItem>
              <Dropdown
                fullWidth
                value={v.mindbodyStaffId}
                onChange={(o) =>
                  setProviderMap((e) => {
                    const updated = e.slice();
                    updated[index] = {
                      ...updated[index],
                      mindbodyStaffId: o
                    };
                    return updated;
                  })
                }
                options={
                  mindbodyStaffFetcher.value?.map((o) => ({ value: o.id, label: o.name })) ?? []
                }
              />
            </div>

            <div style={{ width: "100%" }}>
              <FlexItem fullWidth>
                <Typography.Label>Lyfe</Typography.Label>
              </FlexItem>
              <Dropdown
                fullWidth
                value={v.providerId}
                onChange={(o) =>
                  setProviderMap((e) => {
                    const updated = e.slice();
                    updated[index] = {
                      ...updated[index],
                      providerId: o
                    };
                    return updated;
                  })
                }
                options={
                  providersFetcher.value?.map((o) => ({
                    value: o.id,
                    label: `${o.firstName} ${o.lastName}`
                  })) ?? []
                }
              />
            </div>
          </FlexBox>
        ))}

        <FlexBox justifyContent="between" direction="row" spacing={[5, 0]} fullHeight>
          <AsyncButton
            onClick={async () => {
              setProviderMap((e) => [...e, {}]);
            }}
            type="button"
            variant="primary"
            rounded="md"
            size="md"
            fullWidth
          >
            Add Staff
          </AsyncButton>
          <AsyncButton
            onClick={async () => {
              await services.mindbodyService.UpdateMindbodyProviderMappings({
                locationId: props.locationId,
                mappings: providerMap.map((o) => ({
                  mindbodyStaffId: o.mindbodyStaffId,
                  providerId: o.providerId
                }))
              });
              toastStore.success("Provider mappings have been saved");
              mappedProvidersFetcher.refresh();
            }}
            isDisabled={providerMap.some(
              (o) => o.mindbodyStaffId === undefined || o.providerId === undefined
            )}
            type="button"
            variant="primary"
            rounded="md"
            size="md"
            fullWidth
          >
            Save
          </AsyncButton>
        </FlexBox>
      </FlexItem>
    </>
  );
};
