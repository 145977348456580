/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Duration } from "./google/protobuf/duration";
import { Empty } from "./google/protobuf/empty";
import { FieldMask } from "./google/protobuf/field_mask";
import { Timestamp } from "./google/protobuf/timestamp";
import { Int32Value, StringValue } from "./google/protobuf/wrappers";
import { Charge } from "./money";
import { FundType, fundTypeFromJSON, fundTypeToJSON, Offering } from "./offerings";
import { TokenAvailableResponse } from "./physitrack";
import { User } from "./user";

export const protobufPackage = "proto";

export enum ExternalServiceDTO {
  NONE = 0,
  MINDBODY_SERVICE = 1,
  UNRECOGNIZED = -1,
}

export function externalServiceDTOFromJSON(object: any): ExternalServiceDTO {
  switch (object) {
    case 0:
    case "NONE":
      return ExternalServiceDTO.NONE;
    case 1:
    case "MINDBODY_SERVICE":
      return ExternalServiceDTO.MINDBODY_SERVICE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ExternalServiceDTO.UNRECOGNIZED;
  }
}

export function externalServiceDTOToJSON(object: ExternalServiceDTO): string {
  switch (object) {
    case ExternalServiceDTO.NONE:
      return "NONE";
    case ExternalServiceDTO.MINDBODY_SERVICE:
      return "MINDBODY_SERVICE";
    case ExternalServiceDTO.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ListThirdPartyBookingsRequest {
  locationId: string;
  startDatetime: Date | undefined;
  endDatetime: Date | undefined;
}

export interface ListThirdPartyBookingsResponse {
  bookings: ThirdPartyBookingDTO[];
}

export interface ThirdPartyBookingDTO {
  name: string;
  externalId: string;
  externalService: ExternalServiceDTO;
  isMapped: boolean;
  startDatetime: Date | undefined;
  endDatetime: Date | undefined;
  staffName: string;
}

export interface BookingDTO {
  id: string;
  maxClients: number;
  startDatetime: Date | undefined;
  endDatetime: Date | undefined;
  duration: Duration | undefined;
  clients: BookingClient[];
  offerings: BookingOffering[];
  friendlyId: string;
  provider: Provider | undefined;
  status: BookingDTO_Status;
  locationId: string;
  lyfeFee: string;
  organisationId: string;
  type: BookingDTO_Type;
  total: Charge | undefined;
  spotsLeft: string;
  token: TokenAvailableResponse | undefined;
  room: string;
  externalSessionId: number | undefined;
  externallyRegisteredCount: number | undefined;
  externalService: ExternalServiceDTO;
}

export enum BookingDTO_Status {
  BOOKING_UNSPECIFIED = 0,
  BOOKING_CREATED = 1,
  BOOKING_PROCESSING = 2,
  BOOKING_CANCELLED = 3,
  BOOKING_COMPLETED = 4,
  UNRECOGNIZED = -1,
}

export function bookingDTO_StatusFromJSON(object: any): BookingDTO_Status {
  switch (object) {
    case 0:
    case "BOOKING_UNSPECIFIED":
      return BookingDTO_Status.BOOKING_UNSPECIFIED;
    case 1:
    case "BOOKING_CREATED":
      return BookingDTO_Status.BOOKING_CREATED;
    case 2:
    case "BOOKING_PROCESSING":
      return BookingDTO_Status.BOOKING_PROCESSING;
    case 3:
    case "BOOKING_CANCELLED":
      return BookingDTO_Status.BOOKING_CANCELLED;
    case 4:
    case "BOOKING_COMPLETED":
      return BookingDTO_Status.BOOKING_COMPLETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BookingDTO_Status.UNRECOGNIZED;
  }
}

export function bookingDTO_StatusToJSON(object: BookingDTO_Status): string {
  switch (object) {
    case BookingDTO_Status.BOOKING_UNSPECIFIED:
      return "BOOKING_UNSPECIFIED";
    case BookingDTO_Status.BOOKING_CREATED:
      return "BOOKING_CREATED";
    case BookingDTO_Status.BOOKING_PROCESSING:
      return "BOOKING_PROCESSING";
    case BookingDTO_Status.BOOKING_CANCELLED:
      return "BOOKING_CANCELLED";
    case BookingDTO_Status.BOOKING_COMPLETED:
      return "BOOKING_COMPLETED";
    case BookingDTO_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum BookingDTO_Type {
  BOOKING_TYPE_UNSPECIFIED = 0,
  BOOKING_SINGLE = 1,
  BOOKING_GROUP = 2,
  BOOKING_TIME_OFF = 3,
  UNRECOGNIZED = -1,
}

export function bookingDTO_TypeFromJSON(object: any): BookingDTO_Type {
  switch (object) {
    case 0:
    case "BOOKING_TYPE_UNSPECIFIED":
      return BookingDTO_Type.BOOKING_TYPE_UNSPECIFIED;
    case 1:
    case "BOOKING_SINGLE":
      return BookingDTO_Type.BOOKING_SINGLE;
    case 2:
    case "BOOKING_GROUP":
      return BookingDTO_Type.BOOKING_GROUP;
    case 3:
    case "BOOKING_TIME_OFF":
      return BookingDTO_Type.BOOKING_TIME_OFF;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BookingDTO_Type.UNRECOGNIZED;
  }
}

export function bookingDTO_TypeToJSON(object: BookingDTO_Type): string {
  switch (object) {
    case BookingDTO_Type.BOOKING_TYPE_UNSPECIFIED:
      return "BOOKING_TYPE_UNSPECIFIED";
    case BookingDTO_Type.BOOKING_SINGLE:
      return "BOOKING_SINGLE";
    case BookingDTO_Type.BOOKING_GROUP:
      return "BOOKING_GROUP";
    case BookingDTO_Type.BOOKING_TIME_OFF:
      return "BOOKING_TIME_OFF";
    case BookingDTO_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Provider {
  id: string;
  firstName: string;
  lastName: string;
  providerId: string;
}

export interface LineItem {
  /** the line item id is the BookingOffering table's ID column */
  id: string;
  offering: Offering | undefined;
  fundType: FundType;
  fee: Charge | undefined;
  rebate: Charge | undefined;
}

export interface BookingOffering {
  bookingId: string;
  lineItem: LineItem | undefined;
}

export interface ClientOffering {
  bookingId: string;
  clientId: string;
  lineItem: LineItem | undefined;
}

export interface BookingClient {
  clientId: string;
  bookingId: string;
  firstName: string;
  lastName: string;
  offerings: ClientOffering[];
  status: BookingClient_Status;
  statusMessage: string;
  paymentStatus: BookingClient_PaymentStatus;
  paymentStatusMessage: string;
  activeReferralId: string;
  activeCardId: string;
  preferredPaymentType: FundType;
  email: string;
  mobileNumber: string;
}

export enum BookingClient_Status {
  STATUS_UNSPECIFIED = 0,
  CREATED = 1,
  APPROVED = 2,
  REJECTED = 3,
  CANCELLED = 4,
  UNRECOGNIZED = -1,
}

export function bookingClient_StatusFromJSON(object: any): BookingClient_Status {
  switch (object) {
    case 0:
    case "STATUS_UNSPECIFIED":
      return BookingClient_Status.STATUS_UNSPECIFIED;
    case 1:
    case "CREATED":
      return BookingClient_Status.CREATED;
    case 2:
    case "APPROVED":
      return BookingClient_Status.APPROVED;
    case 3:
    case "REJECTED":
      return BookingClient_Status.REJECTED;
    case 4:
    case "CANCELLED":
      return BookingClient_Status.CANCELLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BookingClient_Status.UNRECOGNIZED;
  }
}

export function bookingClient_StatusToJSON(object: BookingClient_Status): string {
  switch (object) {
    case BookingClient_Status.STATUS_UNSPECIFIED:
      return "STATUS_UNSPECIFIED";
    case BookingClient_Status.CREATED:
      return "CREATED";
    case BookingClient_Status.APPROVED:
      return "APPROVED";
    case BookingClient_Status.REJECTED:
      return "REJECTED";
    case BookingClient_Status.CANCELLED:
      return "CANCELLED";
    case BookingClient_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum BookingClient_PaymentStatus {
  PAYMENT_STATUS_UNSPECIFIED = 0,
  PENDING = 1,
  PROCESSING = 2,
  COMPLETE = 3,
  UNRECOGNIZED = -1,
}

export function bookingClient_PaymentStatusFromJSON(object: any): BookingClient_PaymentStatus {
  switch (object) {
    case 0:
    case "PAYMENT_STATUS_UNSPECIFIED":
      return BookingClient_PaymentStatus.PAYMENT_STATUS_UNSPECIFIED;
    case 1:
    case "PENDING":
      return BookingClient_PaymentStatus.PENDING;
    case 2:
    case "PROCESSING":
      return BookingClient_PaymentStatus.PROCESSING;
    case 3:
    case "COMPLETE":
      return BookingClient_PaymentStatus.COMPLETE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BookingClient_PaymentStatus.UNRECOGNIZED;
  }
}

export function bookingClient_PaymentStatusToJSON(object: BookingClient_PaymentStatus): string {
  switch (object) {
    case BookingClient_PaymentStatus.PAYMENT_STATUS_UNSPECIFIED:
      return "PAYMENT_STATUS_UNSPECIFIED";
    case BookingClient_PaymentStatus.PENDING:
      return "PENDING";
    case BookingClient_PaymentStatus.PROCESSING:
      return "PROCESSING";
    case BookingClient_PaymentStatus.COMPLETE:
      return "COMPLETE";
    case BookingClient_PaymentStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface UserBookingRequest {
  bookingId: string;
}

export interface UserBookingResponse {
  booking: BookingDTO | undefined;
  client: BookingClient | undefined;
  user: User | undefined;
  address: string;
}

export interface ProcessBookingRequest {
  bookingId: string;
  /** if this is null, it means process booking for all clients (group booking) */
  clientId: string;
}

export interface GetBookingClientRequest {
  bookingId: string;
}

export interface CreateBookingClientRequest {
  bookingId: string;
  clientId: string;
}

export interface UpdateBookingRequest {
  id: string;
  providerId: string;
  startDatetime: Date | undefined;
  endDatetime: Date | undefined;
  timezone: string;
  maxGroupSize: number;
  notifyClient: boolean;
}

export interface GetLatestReferralRequest {
  bookingId: string;
  clientId: string;
}

export interface GetLatestReferralResponse {
  latestReferralId: string;
}

export interface UpdateBookingClientRequest {
  clientId: string;
  bookingId: string;
  activeCardId: string | undefined;
  activeReferralId: string | undefined;
}

export interface PartialUpdateBookingClientRequest {
  clientId: string;
  bookingId: string;
  activeCardId: string | undefined;
  activeReferralId: string | undefined;
}

export interface CancelBookingClientRequest {
  bookingId: string;
  clientId: string;
  message: string;
  sendNotification: boolean;
  allEvents: boolean;
}

export interface DeleteBookingClientRequest {
  bookingId: string;
  clientId: string;
}

export interface ApproveBookingClientRequest {
  bookingId: string;
  clientId: string;
}

export interface RejectBookingClientRequest {
  bookingId: string;
  clientId: string;
  message: string;
  sendNotification: boolean;
}

export interface ProcessBookingResponse {
  booking: BookingDTO | undefined;
  accountItemsId: string[];
}

export interface CancellationChargeClientRequest {
  bookingId: string;
  clientId: string;
}

export interface ProcessBookingCancellationResponse {
  booking: BookingDTO | undefined;
  accountItemId: string;
}

export interface GetBookingRequest {
  id: string;
}

export interface CreateBulkBookingRequest {
  booking: CreateBookingRequest | undefined;
  frequency: CreateBulkBookingRequest_BookingsFrequency;
  repetitions: number;
  repetitionEndDate: Date | undefined;
  days: CreateBulkBookingRequest_DaysOfTheWeek[];
}

export enum CreateBulkBookingRequest_BookingsFrequency {
  NONE = 0,
  DAILY = 1,
  WEEKLY = 2,
  BIWEEKLY = 3,
  MONTHLY = 4,
  UNRECOGNIZED = -1,
}

export function createBulkBookingRequest_BookingsFrequencyFromJSON(
  object: any,
): CreateBulkBookingRequest_BookingsFrequency {
  switch (object) {
    case 0:
    case "NONE":
      return CreateBulkBookingRequest_BookingsFrequency.NONE;
    case 1:
    case "DAILY":
      return CreateBulkBookingRequest_BookingsFrequency.DAILY;
    case 2:
    case "WEEKLY":
      return CreateBulkBookingRequest_BookingsFrequency.WEEKLY;
    case 3:
    case "BIWEEKLY":
      return CreateBulkBookingRequest_BookingsFrequency.BIWEEKLY;
    case 4:
    case "MONTHLY":
      return CreateBulkBookingRequest_BookingsFrequency.MONTHLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CreateBulkBookingRequest_BookingsFrequency.UNRECOGNIZED;
  }
}

export function createBulkBookingRequest_BookingsFrequencyToJSON(
  object: CreateBulkBookingRequest_BookingsFrequency,
): string {
  switch (object) {
    case CreateBulkBookingRequest_BookingsFrequency.NONE:
      return "NONE";
    case CreateBulkBookingRequest_BookingsFrequency.DAILY:
      return "DAILY";
    case CreateBulkBookingRequest_BookingsFrequency.WEEKLY:
      return "WEEKLY";
    case CreateBulkBookingRequest_BookingsFrequency.BIWEEKLY:
      return "BIWEEKLY";
    case CreateBulkBookingRequest_BookingsFrequency.MONTHLY:
      return "MONTHLY";
    case CreateBulkBookingRequest_BookingsFrequency.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CreateBulkBookingRequest_DaysOfTheWeek {
  monday = 0,
  tuesday = 1,
  wednesday = 2,
  thursday = 3,
  friday = 4,
  saturday = 5,
  sunday = 6,
  UNRECOGNIZED = -1,
}

export function createBulkBookingRequest_DaysOfTheWeekFromJSON(object: any): CreateBulkBookingRequest_DaysOfTheWeek {
  switch (object) {
    case 0:
    case "monday":
      return CreateBulkBookingRequest_DaysOfTheWeek.monday;
    case 1:
    case "tuesday":
      return CreateBulkBookingRequest_DaysOfTheWeek.tuesday;
    case 2:
    case "wednesday":
      return CreateBulkBookingRequest_DaysOfTheWeek.wednesday;
    case 3:
    case "thursday":
      return CreateBulkBookingRequest_DaysOfTheWeek.thursday;
    case 4:
    case "friday":
      return CreateBulkBookingRequest_DaysOfTheWeek.friday;
    case 5:
    case "saturday":
      return CreateBulkBookingRequest_DaysOfTheWeek.saturday;
    case 6:
    case "sunday":
      return CreateBulkBookingRequest_DaysOfTheWeek.sunday;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CreateBulkBookingRequest_DaysOfTheWeek.UNRECOGNIZED;
  }
}

export function createBulkBookingRequest_DaysOfTheWeekToJSON(object: CreateBulkBookingRequest_DaysOfTheWeek): string {
  switch (object) {
    case CreateBulkBookingRequest_DaysOfTheWeek.monday:
      return "monday";
    case CreateBulkBookingRequest_DaysOfTheWeek.tuesday:
      return "tuesday";
    case CreateBulkBookingRequest_DaysOfTheWeek.wednesday:
      return "wednesday";
    case CreateBulkBookingRequest_DaysOfTheWeek.thursday:
      return "thursday";
    case CreateBulkBookingRequest_DaysOfTheWeek.friday:
      return "friday";
    case CreateBulkBookingRequest_DaysOfTheWeek.saturday:
      return "saturday";
    case CreateBulkBookingRequest_DaysOfTheWeek.sunday:
      return "sunday";
    case CreateBulkBookingRequest_DaysOfTheWeek.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface CreateBulkBookingResponse {
  bookings: BookingDTO[];
}

export interface CancelBulkBookingResponse {
  bookings: BookingDTO[];
}

export interface CreateBookingRequest {
  locationId: string;
  startDatetime: Date | undefined;
  providerId: string;
  endDatetime: Date | undefined;
  repetitions: number;
  offeringId: string;
  type: BookingDTO_Type;
  maxClients: number;
  /** accepted timezones from tz database https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  timezone: string;
}

export interface BulkCancelBookingRequest {
  providerId: string;
  offeringId: string;
  startDatetime: Date | undefined;
  endDatetime:
    | Date
    | undefined;
  /** accepted timezones from tz database https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  timezone: string;
}

export interface CreateBookingOfferingRequest {
  bookingId: string;
  offeringId: string;
}

export interface CreateClientOfferingRequest {
  bookingId: string;
  offeringId: string;
  clientId: string;
}

export interface UpdateLineItemRequest {
  lineItem: LineItem | undefined;
  mask: string[] | undefined;
}

export interface DeleteLineItemRequest {
  lineItemId: string;
}

export interface ListBookingByLocationRequest {
  locationId: string;
  /** receives timestamp in UTC */
  bookingStartDate:
    | Date
    | undefined;
  /** receives timestamp in UTC */
  bookingEndDate: Date | undefined;
  timezone: string;
}

export interface ListBookingsResponse {
  bookings: BookingDTO[];
}

function createBaseListThirdPartyBookingsRequest(): ListThirdPartyBookingsRequest {
  return { locationId: "", startDatetime: undefined, endDatetime: undefined };
}

export const ListThirdPartyBookingsRequest = {
  encode(message: ListThirdPartyBookingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    if (message.startDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.startDatetime), writer.uint32(18).fork()).ldelim();
    }
    if (message.endDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.endDatetime), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListThirdPartyBookingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListThirdPartyBookingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListThirdPartyBookingsRequest {
    return {
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      startDatetime: isSet(object.startDatetime) ? fromJsonTimestamp(object.startDatetime) : undefined,
      endDatetime: isSet(object.endDatetime) ? fromJsonTimestamp(object.endDatetime) : undefined,
    };
  },

  toJSON(message: ListThirdPartyBookingsRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.startDatetime !== undefined) {
      obj.startDatetime = message.startDatetime.toISOString();
    }
    if (message.endDatetime !== undefined) {
      obj.endDatetime = message.endDatetime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListThirdPartyBookingsRequest>, I>>(base?: I): ListThirdPartyBookingsRequest {
    return ListThirdPartyBookingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListThirdPartyBookingsRequest>, I>>(
    object: I,
  ): ListThirdPartyBookingsRequest {
    const message = createBaseListThirdPartyBookingsRequest();
    message.locationId = object.locationId ?? "";
    message.startDatetime = object.startDatetime ?? undefined;
    message.endDatetime = object.endDatetime ?? undefined;
    return message;
  },
};

function createBaseListThirdPartyBookingsResponse(): ListThirdPartyBookingsResponse {
  return { bookings: [] };
}

export const ListThirdPartyBookingsResponse = {
  encode(message: ListThirdPartyBookingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.bookings) {
      ThirdPartyBookingDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListThirdPartyBookingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListThirdPartyBookingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookings.push(ThirdPartyBookingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListThirdPartyBookingsResponse {
    return {
      bookings: globalThis.Array.isArray(object?.bookings)
        ? object.bookings.map((e: any) => ThirdPartyBookingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListThirdPartyBookingsResponse): unknown {
    const obj: any = {};
    if (message.bookings?.length) {
      obj.bookings = message.bookings.map((e) => ThirdPartyBookingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListThirdPartyBookingsResponse>, I>>(base?: I): ListThirdPartyBookingsResponse {
    return ListThirdPartyBookingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListThirdPartyBookingsResponse>, I>>(
    object: I,
  ): ListThirdPartyBookingsResponse {
    const message = createBaseListThirdPartyBookingsResponse();
    message.bookings = object.bookings?.map((e) => ThirdPartyBookingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseThirdPartyBookingDTO(): ThirdPartyBookingDTO {
  return {
    name: "",
    externalId: "",
    externalService: 0,
    isMapped: false,
    startDatetime: undefined,
    endDatetime: undefined,
    staffName: "",
  };
}

export const ThirdPartyBookingDTO = {
  encode(message: ThirdPartyBookingDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.externalId !== "") {
      writer.uint32(18).string(message.externalId);
    }
    if (message.externalService !== 0) {
      writer.uint32(24).int32(message.externalService);
    }
    if (message.isMapped === true) {
      writer.uint32(32).bool(message.isMapped);
    }
    if (message.startDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.startDatetime), writer.uint32(42).fork()).ldelim();
    }
    if (message.endDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.endDatetime), writer.uint32(50).fork()).ldelim();
    }
    if (message.staffName !== "") {
      writer.uint32(58).string(message.staffName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ThirdPartyBookingDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseThirdPartyBookingDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.externalId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.externalService = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isMapped = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.startDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.endDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.staffName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ThirdPartyBookingDTO {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      externalId: isSet(object.externalId) ? globalThis.String(object.externalId) : "",
      externalService: isSet(object.externalService) ? externalServiceDTOFromJSON(object.externalService) : 0,
      isMapped: isSet(object.isMapped) ? globalThis.Boolean(object.isMapped) : false,
      startDatetime: isSet(object.startDatetime) ? fromJsonTimestamp(object.startDatetime) : undefined,
      endDatetime: isSet(object.endDatetime) ? fromJsonTimestamp(object.endDatetime) : undefined,
      staffName: isSet(object.staffName) ? globalThis.String(object.staffName) : "",
    };
  },

  toJSON(message: ThirdPartyBookingDTO): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.externalId !== "") {
      obj.externalId = message.externalId;
    }
    if (message.externalService !== 0) {
      obj.externalService = externalServiceDTOToJSON(message.externalService);
    }
    if (message.isMapped === true) {
      obj.isMapped = message.isMapped;
    }
    if (message.startDatetime !== undefined) {
      obj.startDatetime = message.startDatetime.toISOString();
    }
    if (message.endDatetime !== undefined) {
      obj.endDatetime = message.endDatetime.toISOString();
    }
    if (message.staffName !== "") {
      obj.staffName = message.staffName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ThirdPartyBookingDTO>, I>>(base?: I): ThirdPartyBookingDTO {
    return ThirdPartyBookingDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ThirdPartyBookingDTO>, I>>(object: I): ThirdPartyBookingDTO {
    const message = createBaseThirdPartyBookingDTO();
    message.name = object.name ?? "";
    message.externalId = object.externalId ?? "";
    message.externalService = object.externalService ?? 0;
    message.isMapped = object.isMapped ?? false;
    message.startDatetime = object.startDatetime ?? undefined;
    message.endDatetime = object.endDatetime ?? undefined;
    message.staffName = object.staffName ?? "";
    return message;
  },
};

function createBaseBookingDTO(): BookingDTO {
  return {
    id: "",
    maxClients: 0,
    startDatetime: undefined,
    endDatetime: undefined,
    duration: undefined,
    clients: [],
    offerings: [],
    friendlyId: "",
    provider: undefined,
    status: 0,
    locationId: "",
    lyfeFee: "",
    organisationId: "",
    type: 0,
    total: undefined,
    spotsLeft: "",
    token: undefined,
    room: "",
    externalSessionId: undefined,
    externallyRegisteredCount: undefined,
    externalService: 0,
  };
}

export const BookingDTO = {
  encode(message: BookingDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.maxClients !== 0) {
      writer.uint32(16).int32(message.maxClients);
    }
    if (message.startDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.startDatetime), writer.uint32(26).fork()).ldelim();
    }
    if (message.endDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.endDatetime), writer.uint32(34).fork()).ldelim();
    }
    if (message.duration !== undefined) {
      Duration.encode(message.duration, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.clients) {
      BookingClient.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.offerings) {
      BookingOffering.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.friendlyId !== "") {
      writer.uint32(66).string(message.friendlyId);
    }
    if (message.provider !== undefined) {
      Provider.encode(message.provider, writer.uint32(74).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(80).int32(message.status);
    }
    if (message.locationId !== "") {
      writer.uint32(90).string(message.locationId);
    }
    if (message.lyfeFee !== "") {
      writer.uint32(98).string(message.lyfeFee);
    }
    if (message.organisationId !== "") {
      writer.uint32(106).string(message.organisationId);
    }
    if (message.type !== 0) {
      writer.uint32(112).int32(message.type);
    }
    if (message.total !== undefined) {
      Charge.encode(message.total, writer.uint32(122).fork()).ldelim();
    }
    if (message.spotsLeft !== "") {
      writer.uint32(130).string(message.spotsLeft);
    }
    if (message.token !== undefined) {
      TokenAvailableResponse.encode(message.token, writer.uint32(138).fork()).ldelim();
    }
    if (message.room !== "") {
      writer.uint32(146).string(message.room);
    }
    if (message.externalSessionId !== undefined) {
      Int32Value.encode({ value: message.externalSessionId! }, writer.uint32(154).fork()).ldelim();
    }
    if (message.externallyRegisteredCount !== undefined) {
      Int32Value.encode({ value: message.externallyRegisteredCount! }, writer.uint32(162).fork()).ldelim();
    }
    if (message.externalService !== 0) {
      writer.uint32(168).int32(message.externalService);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookingDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBookingDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.maxClients = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.duration = Duration.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.clients.push(BookingClient.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.offerings.push(BookingOffering.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.friendlyId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.provider = Provider.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.lyfeFee = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.organisationId = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.total = Charge.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.spotsLeft = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.token = TokenAvailableResponse.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.room = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.externalSessionId = Int32Value.decode(reader, reader.uint32()).value;
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.externallyRegisteredCount = Int32Value.decode(reader, reader.uint32()).value;
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.externalService = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BookingDTO {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      maxClients: isSet(object.maxClients) ? globalThis.Number(object.maxClients) : 0,
      startDatetime: isSet(object.startDatetime) ? fromJsonTimestamp(object.startDatetime) : undefined,
      endDatetime: isSet(object.endDatetime) ? fromJsonTimestamp(object.endDatetime) : undefined,
      duration: isSet(object.duration) ? Duration.fromJSON(object.duration) : undefined,
      clients: globalThis.Array.isArray(object?.clients)
        ? object.clients.map((e: any) => BookingClient.fromJSON(e))
        : [],
      offerings: globalThis.Array.isArray(object?.offerings)
        ? object.offerings.map((e: any) => BookingOffering.fromJSON(e))
        : [],
      friendlyId: isSet(object.friendlyId) ? globalThis.String(object.friendlyId) : "",
      provider: isSet(object.provider) ? Provider.fromJSON(object.provider) : undefined,
      status: isSet(object.status) ? bookingDTO_StatusFromJSON(object.status) : 0,
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      lyfeFee: isSet(object.lyfeFee) ? globalThis.String(object.lyfeFee) : "",
      organisationId: isSet(object.organisationId) ? globalThis.String(object.organisationId) : "",
      type: isSet(object.type) ? bookingDTO_TypeFromJSON(object.type) : 0,
      total: isSet(object.total) ? Charge.fromJSON(object.total) : undefined,
      spotsLeft: isSet(object.spotsLeft) ? globalThis.String(object.spotsLeft) : "",
      token: isSet(object.token) ? TokenAvailableResponse.fromJSON(object.token) : undefined,
      room: isSet(object.room) ? globalThis.String(object.room) : "",
      externalSessionId: isSet(object.externalSessionId) ? Number(object.externalSessionId) : undefined,
      externallyRegisteredCount: isSet(object.externallyRegisteredCount)
        ? Number(object.externallyRegisteredCount)
        : undefined,
      externalService: isSet(object.externalService) ? externalServiceDTOFromJSON(object.externalService) : 0,
    };
  },

  toJSON(message: BookingDTO): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.maxClients !== 0) {
      obj.maxClients = Math.round(message.maxClients);
    }
    if (message.startDatetime !== undefined) {
      obj.startDatetime = message.startDatetime.toISOString();
    }
    if (message.endDatetime !== undefined) {
      obj.endDatetime = message.endDatetime.toISOString();
    }
    if (message.duration !== undefined) {
      obj.duration = Duration.toJSON(message.duration);
    }
    if (message.clients?.length) {
      obj.clients = message.clients.map((e) => BookingClient.toJSON(e));
    }
    if (message.offerings?.length) {
      obj.offerings = message.offerings.map((e) => BookingOffering.toJSON(e));
    }
    if (message.friendlyId !== "") {
      obj.friendlyId = message.friendlyId;
    }
    if (message.provider !== undefined) {
      obj.provider = Provider.toJSON(message.provider);
    }
    if (message.status !== 0) {
      obj.status = bookingDTO_StatusToJSON(message.status);
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.lyfeFee !== "") {
      obj.lyfeFee = message.lyfeFee;
    }
    if (message.organisationId !== "") {
      obj.organisationId = message.organisationId;
    }
    if (message.type !== 0) {
      obj.type = bookingDTO_TypeToJSON(message.type);
    }
    if (message.total !== undefined) {
      obj.total = Charge.toJSON(message.total);
    }
    if (message.spotsLeft !== "") {
      obj.spotsLeft = message.spotsLeft;
    }
    if (message.token !== undefined) {
      obj.token = TokenAvailableResponse.toJSON(message.token);
    }
    if (message.room !== "") {
      obj.room = message.room;
    }
    if (message.externalSessionId !== undefined) {
      obj.externalSessionId = message.externalSessionId;
    }
    if (message.externallyRegisteredCount !== undefined) {
      obj.externallyRegisteredCount = message.externallyRegisteredCount;
    }
    if (message.externalService !== 0) {
      obj.externalService = externalServiceDTOToJSON(message.externalService);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BookingDTO>, I>>(base?: I): BookingDTO {
    return BookingDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BookingDTO>, I>>(object: I): BookingDTO {
    const message = createBaseBookingDTO();
    message.id = object.id ?? "";
    message.maxClients = object.maxClients ?? 0;
    message.startDatetime = object.startDatetime ?? undefined;
    message.endDatetime = object.endDatetime ?? undefined;
    message.duration = (object.duration !== undefined && object.duration !== null)
      ? Duration.fromPartial(object.duration)
      : undefined;
    message.clients = object.clients?.map((e) => BookingClient.fromPartial(e)) || [];
    message.offerings = object.offerings?.map((e) => BookingOffering.fromPartial(e)) || [];
    message.friendlyId = object.friendlyId ?? "";
    message.provider = (object.provider !== undefined && object.provider !== null)
      ? Provider.fromPartial(object.provider)
      : undefined;
    message.status = object.status ?? 0;
    message.locationId = object.locationId ?? "";
    message.lyfeFee = object.lyfeFee ?? "";
    message.organisationId = object.organisationId ?? "";
    message.type = object.type ?? 0;
    message.total = (object.total !== undefined && object.total !== null)
      ? Charge.fromPartial(object.total)
      : undefined;
    message.spotsLeft = object.spotsLeft ?? "";
    message.token = (object.token !== undefined && object.token !== null)
      ? TokenAvailableResponse.fromPartial(object.token)
      : undefined;
    message.room = object.room ?? "";
    message.externalSessionId = object.externalSessionId ?? undefined;
    message.externallyRegisteredCount = object.externallyRegisteredCount ?? undefined;
    message.externalService = object.externalService ?? 0;
    return message;
  },
};

function createBaseProvider(): Provider {
  return { id: "", firstName: "", lastName: "", providerId: "" };
}

export const Provider = {
  encode(message: Provider, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.firstName !== "") {
      writer.uint32(18).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(26).string(message.lastName);
    }
    if (message.providerId !== "") {
      writer.uint32(34).string(message.providerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Provider {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvider();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.providerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Provider {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      providerId: isSet(object.providerId) ? globalThis.String(object.providerId) : "",
    };
  },

  toJSON(message: Provider): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.providerId !== "") {
      obj.providerId = message.providerId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Provider>, I>>(base?: I): Provider {
    return Provider.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Provider>, I>>(object: I): Provider {
    const message = createBaseProvider();
    message.id = object.id ?? "";
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.providerId = object.providerId ?? "";
    return message;
  },
};

function createBaseLineItem(): LineItem {
  return { id: "", offering: undefined, fundType: 0, fee: undefined, rebate: undefined };
}

export const LineItem = {
  encode(message: LineItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.offering !== undefined) {
      Offering.encode(message.offering, writer.uint32(18).fork()).ldelim();
    }
    if (message.fundType !== 0) {
      writer.uint32(24).int32(message.fundType);
    }
    if (message.fee !== undefined) {
      Charge.encode(message.fee, writer.uint32(34).fork()).ldelim();
    }
    if (message.rebate !== undefined) {
      Charge.encode(message.rebate, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LineItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.offering = Offering.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.fundType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fee = Charge.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.rebate = Charge.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LineItem {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      offering: isSet(object.offering) ? Offering.fromJSON(object.offering) : undefined,
      fundType: isSet(object.fundType) ? fundTypeFromJSON(object.fundType) : 0,
      fee: isSet(object.fee) ? Charge.fromJSON(object.fee) : undefined,
      rebate: isSet(object.rebate) ? Charge.fromJSON(object.rebate) : undefined,
    };
  },

  toJSON(message: LineItem): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.offering !== undefined) {
      obj.offering = Offering.toJSON(message.offering);
    }
    if (message.fundType !== 0) {
      obj.fundType = fundTypeToJSON(message.fundType);
    }
    if (message.fee !== undefined) {
      obj.fee = Charge.toJSON(message.fee);
    }
    if (message.rebate !== undefined) {
      obj.rebate = Charge.toJSON(message.rebate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LineItem>, I>>(base?: I): LineItem {
    return LineItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LineItem>, I>>(object: I): LineItem {
    const message = createBaseLineItem();
    message.id = object.id ?? "";
    message.offering = (object.offering !== undefined && object.offering !== null)
      ? Offering.fromPartial(object.offering)
      : undefined;
    message.fundType = object.fundType ?? 0;
    message.fee = (object.fee !== undefined && object.fee !== null) ? Charge.fromPartial(object.fee) : undefined;
    message.rebate = (object.rebate !== undefined && object.rebate !== null)
      ? Charge.fromPartial(object.rebate)
      : undefined;
    return message;
  },
};

function createBaseBookingOffering(): BookingOffering {
  return { bookingId: "", lineItem: undefined };
}

export const BookingOffering = {
  encode(message: BookingOffering, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.lineItem !== undefined) {
      LineItem.encode(message.lineItem, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookingOffering {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBookingOffering();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lineItem = LineItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BookingOffering {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      lineItem: isSet(object.lineItem) ? LineItem.fromJSON(object.lineItem) : undefined,
    };
  },

  toJSON(message: BookingOffering): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.lineItem !== undefined) {
      obj.lineItem = LineItem.toJSON(message.lineItem);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BookingOffering>, I>>(base?: I): BookingOffering {
    return BookingOffering.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BookingOffering>, I>>(object: I): BookingOffering {
    const message = createBaseBookingOffering();
    message.bookingId = object.bookingId ?? "";
    message.lineItem = (object.lineItem !== undefined && object.lineItem !== null)
      ? LineItem.fromPartial(object.lineItem)
      : undefined;
    return message;
  },
};

function createBaseClientOffering(): ClientOffering {
  return { bookingId: "", clientId: "", lineItem: undefined };
}

export const ClientOffering = {
  encode(message: ClientOffering, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    if (message.lineItem !== undefined) {
      LineItem.encode(message.lineItem, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientOffering {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientOffering();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lineItem = LineItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClientOffering {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      lineItem: isSet(object.lineItem) ? LineItem.fromJSON(object.lineItem) : undefined,
    };
  },

  toJSON(message: ClientOffering): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.lineItem !== undefined) {
      obj.lineItem = LineItem.toJSON(message.lineItem);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClientOffering>, I>>(base?: I): ClientOffering {
    return ClientOffering.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientOffering>, I>>(object: I): ClientOffering {
    const message = createBaseClientOffering();
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    message.lineItem = (object.lineItem !== undefined && object.lineItem !== null)
      ? LineItem.fromPartial(object.lineItem)
      : undefined;
    return message;
  },
};

function createBaseBookingClient(): BookingClient {
  return {
    clientId: "",
    bookingId: "",
    firstName: "",
    lastName: "",
    offerings: [],
    status: 0,
    statusMessage: "",
    paymentStatus: 0,
    paymentStatusMessage: "",
    activeReferralId: "",
    activeCardId: "",
    preferredPaymentType: 0,
    email: "",
    mobileNumber: "",
  };
}

export const BookingClient = {
  encode(message: BookingClient, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    if (message.bookingId !== "") {
      writer.uint32(90).string(message.bookingId);
    }
    if (message.firstName !== "") {
      writer.uint32(18).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(26).string(message.lastName);
    }
    for (const v of message.offerings) {
      ClientOffering.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(40).int32(message.status);
    }
    if (message.statusMessage !== "") {
      writer.uint32(50).string(message.statusMessage);
    }
    if (message.paymentStatus !== 0) {
      writer.uint32(56).int32(message.paymentStatus);
    }
    if (message.paymentStatusMessage !== "") {
      writer.uint32(66).string(message.paymentStatusMessage);
    }
    if (message.activeReferralId !== "") {
      writer.uint32(74).string(message.activeReferralId);
    }
    if (message.activeCardId !== "") {
      writer.uint32(82).string(message.activeCardId);
    }
    if (message.preferredPaymentType !== 0) {
      writer.uint32(96).int32(message.preferredPaymentType);
    }
    if (message.email !== "") {
      writer.uint32(106).string(message.email);
    }
    if (message.mobileNumber !== "") {
      writer.uint32(114).string(message.mobileNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookingClient {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBookingClient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.offerings.push(ClientOffering.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.statusMessage = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.paymentStatus = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.paymentStatusMessage = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.activeReferralId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.activeCardId = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.preferredPaymentType = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.email = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.mobileNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BookingClient {
    return {
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      offerings: globalThis.Array.isArray(object?.offerings)
        ? object.offerings.map((e: any) => ClientOffering.fromJSON(e))
        : [],
      status: isSet(object.status) ? bookingClient_StatusFromJSON(object.status) : 0,
      statusMessage: isSet(object.statusMessage) ? globalThis.String(object.statusMessage) : "",
      paymentStatus: isSet(object.paymentStatus) ? bookingClient_PaymentStatusFromJSON(object.paymentStatus) : 0,
      paymentStatusMessage: isSet(object.paymentStatusMessage) ? globalThis.String(object.paymentStatusMessage) : "",
      activeReferralId: isSet(object.activeReferralId) ? globalThis.String(object.activeReferralId) : "",
      activeCardId: isSet(object.activeCardId) ? globalThis.String(object.activeCardId) : "",
      preferredPaymentType: isSet(object.preferredPaymentType) ? fundTypeFromJSON(object.preferredPaymentType) : 0,
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      mobileNumber: isSet(object.mobileNumber) ? globalThis.String(object.mobileNumber) : "",
    };
  },

  toJSON(message: BookingClient): unknown {
    const obj: any = {};
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.offerings?.length) {
      obj.offerings = message.offerings.map((e) => ClientOffering.toJSON(e));
    }
    if (message.status !== 0) {
      obj.status = bookingClient_StatusToJSON(message.status);
    }
    if (message.statusMessage !== "") {
      obj.statusMessage = message.statusMessage;
    }
    if (message.paymentStatus !== 0) {
      obj.paymentStatus = bookingClient_PaymentStatusToJSON(message.paymentStatus);
    }
    if (message.paymentStatusMessage !== "") {
      obj.paymentStatusMessage = message.paymentStatusMessage;
    }
    if (message.activeReferralId !== "") {
      obj.activeReferralId = message.activeReferralId;
    }
    if (message.activeCardId !== "") {
      obj.activeCardId = message.activeCardId;
    }
    if (message.preferredPaymentType !== 0) {
      obj.preferredPaymentType = fundTypeToJSON(message.preferredPaymentType);
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.mobileNumber !== "") {
      obj.mobileNumber = message.mobileNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BookingClient>, I>>(base?: I): BookingClient {
    return BookingClient.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BookingClient>, I>>(object: I): BookingClient {
    const message = createBaseBookingClient();
    message.clientId = object.clientId ?? "";
    message.bookingId = object.bookingId ?? "";
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.offerings = object.offerings?.map((e) => ClientOffering.fromPartial(e)) || [];
    message.status = object.status ?? 0;
    message.statusMessage = object.statusMessage ?? "";
    message.paymentStatus = object.paymentStatus ?? 0;
    message.paymentStatusMessage = object.paymentStatusMessage ?? "";
    message.activeReferralId = object.activeReferralId ?? "";
    message.activeCardId = object.activeCardId ?? "";
    message.preferredPaymentType = object.preferredPaymentType ?? 0;
    message.email = object.email ?? "";
    message.mobileNumber = object.mobileNumber ?? "";
    return message;
  },
};

function createBaseUserBookingRequest(): UserBookingRequest {
  return { bookingId: "" };
}

export const UserBookingRequest = {
  encode(message: UserBookingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserBookingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserBookingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserBookingRequest {
    return { bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "" };
  },

  toJSON(message: UserBookingRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserBookingRequest>, I>>(base?: I): UserBookingRequest {
    return UserBookingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserBookingRequest>, I>>(object: I): UserBookingRequest {
    const message = createBaseUserBookingRequest();
    message.bookingId = object.bookingId ?? "";
    return message;
  },
};

function createBaseUserBookingResponse(): UserBookingResponse {
  return { booking: undefined, client: undefined, user: undefined, address: "" };
}

export const UserBookingResponse = {
  encode(message: UserBookingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.booking !== undefined) {
      BookingDTO.encode(message.booking, writer.uint32(10).fork()).ldelim();
    }
    if (message.client !== undefined) {
      BookingClient.encode(message.client, writer.uint32(18).fork()).ldelim();
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(26).fork()).ldelim();
    }
    if (message.address !== "") {
      writer.uint32(34).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserBookingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserBookingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.booking = BookingDTO.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.client = BookingClient.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserBookingResponse {
    return {
      booking: isSet(object.booking) ? BookingDTO.fromJSON(object.booking) : undefined,
      client: isSet(object.client) ? BookingClient.fromJSON(object.client) : undefined,
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      address: isSet(object.address) ? globalThis.String(object.address) : "",
    };
  },

  toJSON(message: UserBookingResponse): unknown {
    const obj: any = {};
    if (message.booking !== undefined) {
      obj.booking = BookingDTO.toJSON(message.booking);
    }
    if (message.client !== undefined) {
      obj.client = BookingClient.toJSON(message.client);
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserBookingResponse>, I>>(base?: I): UserBookingResponse {
    return UserBookingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserBookingResponse>, I>>(object: I): UserBookingResponse {
    const message = createBaseUserBookingResponse();
    message.booking = (object.booking !== undefined && object.booking !== null)
      ? BookingDTO.fromPartial(object.booking)
      : undefined;
    message.client = (object.client !== undefined && object.client !== null)
      ? BookingClient.fromPartial(object.client)
      : undefined;
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseProcessBookingRequest(): ProcessBookingRequest {
  return { bookingId: "", clientId: "" };
}

export const ProcessBookingRequest = {
  encode(message: ProcessBookingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessBookingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessBookingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProcessBookingRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: ProcessBookingRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProcessBookingRequest>, I>>(base?: I): ProcessBookingRequest {
    return ProcessBookingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProcessBookingRequest>, I>>(object: I): ProcessBookingRequest {
    const message = createBaseProcessBookingRequest();
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseGetBookingClientRequest(): GetBookingClientRequest {
  return { bookingId: "" };
}

export const GetBookingClientRequest = {
  encode(message: GetBookingClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBookingClientRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBookingClientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBookingClientRequest {
    return { bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "" };
  },

  toJSON(message: GetBookingClientRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBookingClientRequest>, I>>(base?: I): GetBookingClientRequest {
    return GetBookingClientRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBookingClientRequest>, I>>(object: I): GetBookingClientRequest {
    const message = createBaseGetBookingClientRequest();
    message.bookingId = object.bookingId ?? "";
    return message;
  },
};

function createBaseCreateBookingClientRequest(): CreateBookingClientRequest {
  return { bookingId: "", clientId: "" };
}

export const CreateBookingClientRequest = {
  encode(message: CreateBookingClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBookingClientRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBookingClientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBookingClientRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: CreateBookingClientRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBookingClientRequest>, I>>(base?: I): CreateBookingClientRequest {
    return CreateBookingClientRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBookingClientRequest>, I>>(object: I): CreateBookingClientRequest {
    const message = createBaseCreateBookingClientRequest();
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseUpdateBookingRequest(): UpdateBookingRequest {
  return {
    id: "",
    providerId: "",
    startDatetime: undefined,
    endDatetime: undefined,
    timezone: "",
    maxGroupSize: 0,
    notifyClient: false,
  };
}

export const UpdateBookingRequest = {
  encode(message: UpdateBookingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.providerId !== "") {
      writer.uint32(18).string(message.providerId);
    }
    if (message.startDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.startDatetime), writer.uint32(26).fork()).ldelim();
    }
    if (message.endDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.endDatetime), writer.uint32(34).fork()).ldelim();
    }
    if (message.timezone !== "") {
      writer.uint32(42).string(message.timezone);
    }
    if (message.maxGroupSize !== 0) {
      writer.uint32(48).int32(message.maxGroupSize);
    }
    if (message.notifyClient === true) {
      writer.uint32(56).bool(message.notifyClient);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateBookingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateBookingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.providerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.timezone = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.maxGroupSize = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.notifyClient = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateBookingRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      providerId: isSet(object.providerId) ? globalThis.String(object.providerId) : "",
      startDatetime: isSet(object.startDatetime) ? fromJsonTimestamp(object.startDatetime) : undefined,
      endDatetime: isSet(object.endDatetime) ? fromJsonTimestamp(object.endDatetime) : undefined,
      timezone: isSet(object.timezone) ? globalThis.String(object.timezone) : "",
      maxGroupSize: isSet(object.maxGroupSize) ? globalThis.Number(object.maxGroupSize) : 0,
      notifyClient: isSet(object.notifyClient) ? globalThis.Boolean(object.notifyClient) : false,
    };
  },

  toJSON(message: UpdateBookingRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.providerId !== "") {
      obj.providerId = message.providerId;
    }
    if (message.startDatetime !== undefined) {
      obj.startDatetime = message.startDatetime.toISOString();
    }
    if (message.endDatetime !== undefined) {
      obj.endDatetime = message.endDatetime.toISOString();
    }
    if (message.timezone !== "") {
      obj.timezone = message.timezone;
    }
    if (message.maxGroupSize !== 0) {
      obj.maxGroupSize = Math.round(message.maxGroupSize);
    }
    if (message.notifyClient === true) {
      obj.notifyClient = message.notifyClient;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateBookingRequest>, I>>(base?: I): UpdateBookingRequest {
    return UpdateBookingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateBookingRequest>, I>>(object: I): UpdateBookingRequest {
    const message = createBaseUpdateBookingRequest();
    message.id = object.id ?? "";
    message.providerId = object.providerId ?? "";
    message.startDatetime = object.startDatetime ?? undefined;
    message.endDatetime = object.endDatetime ?? undefined;
    message.timezone = object.timezone ?? "";
    message.maxGroupSize = object.maxGroupSize ?? 0;
    message.notifyClient = object.notifyClient ?? false;
    return message;
  },
};

function createBaseGetLatestReferralRequest(): GetLatestReferralRequest {
  return { bookingId: "", clientId: "" };
}

export const GetLatestReferralRequest = {
  encode(message: GetLatestReferralRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLatestReferralRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLatestReferralRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLatestReferralRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: GetLatestReferralRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLatestReferralRequest>, I>>(base?: I): GetLatestReferralRequest {
    return GetLatestReferralRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLatestReferralRequest>, I>>(object: I): GetLatestReferralRequest {
    const message = createBaseGetLatestReferralRequest();
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseGetLatestReferralResponse(): GetLatestReferralResponse {
  return { latestReferralId: "" };
}

export const GetLatestReferralResponse = {
  encode(message: GetLatestReferralResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.latestReferralId !== "") {
      writer.uint32(10).string(message.latestReferralId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLatestReferralResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLatestReferralResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.latestReferralId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLatestReferralResponse {
    return { latestReferralId: isSet(object.latestReferralId) ? globalThis.String(object.latestReferralId) : "" };
  },

  toJSON(message: GetLatestReferralResponse): unknown {
    const obj: any = {};
    if (message.latestReferralId !== "") {
      obj.latestReferralId = message.latestReferralId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLatestReferralResponse>, I>>(base?: I): GetLatestReferralResponse {
    return GetLatestReferralResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLatestReferralResponse>, I>>(object: I): GetLatestReferralResponse {
    const message = createBaseGetLatestReferralResponse();
    message.latestReferralId = object.latestReferralId ?? "";
    return message;
  },
};

function createBaseUpdateBookingClientRequest(): UpdateBookingClientRequest {
  return { clientId: "", bookingId: "", activeCardId: undefined, activeReferralId: undefined };
}

export const UpdateBookingClientRequest = {
  encode(message: UpdateBookingClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    if (message.bookingId !== "") {
      writer.uint32(18).string(message.bookingId);
    }
    if (message.activeCardId !== undefined) {
      StringValue.encode({ value: message.activeCardId! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.activeReferralId !== undefined) {
      StringValue.encode({ value: message.activeReferralId! }, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateBookingClientRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateBookingClientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.activeCardId = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.activeReferralId = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateBookingClientRequest {
    return {
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      activeCardId: isSet(object.activeCardId) ? String(object.activeCardId) : undefined,
      activeReferralId: isSet(object.activeReferralId) ? String(object.activeReferralId) : undefined,
    };
  },

  toJSON(message: UpdateBookingClientRequest): unknown {
    const obj: any = {};
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.activeCardId !== undefined) {
      obj.activeCardId = message.activeCardId;
    }
    if (message.activeReferralId !== undefined) {
      obj.activeReferralId = message.activeReferralId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateBookingClientRequest>, I>>(base?: I): UpdateBookingClientRequest {
    return UpdateBookingClientRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateBookingClientRequest>, I>>(object: I): UpdateBookingClientRequest {
    const message = createBaseUpdateBookingClientRequest();
    message.clientId = object.clientId ?? "";
    message.bookingId = object.bookingId ?? "";
    message.activeCardId = object.activeCardId ?? undefined;
    message.activeReferralId = object.activeReferralId ?? undefined;
    return message;
  },
};

function createBasePartialUpdateBookingClientRequest(): PartialUpdateBookingClientRequest {
  return { clientId: "", bookingId: "", activeCardId: undefined, activeReferralId: undefined };
}

export const PartialUpdateBookingClientRequest = {
  encode(message: PartialUpdateBookingClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    if (message.bookingId !== "") {
      writer.uint32(18).string(message.bookingId);
    }
    if (message.activeCardId !== undefined) {
      StringValue.encode({ value: message.activeCardId! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.activeReferralId !== undefined) {
      StringValue.encode({ value: message.activeReferralId! }, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PartialUpdateBookingClientRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePartialUpdateBookingClientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.activeCardId = StringValue.decode(reader, reader.uint32()).value;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.activeReferralId = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PartialUpdateBookingClientRequest {
    return {
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      activeCardId: isSet(object.activeCardId) ? String(object.activeCardId) : undefined,
      activeReferralId: isSet(object.activeReferralId) ? String(object.activeReferralId) : undefined,
    };
  },

  toJSON(message: PartialUpdateBookingClientRequest): unknown {
    const obj: any = {};
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.activeCardId !== undefined) {
      obj.activeCardId = message.activeCardId;
    }
    if (message.activeReferralId !== undefined) {
      obj.activeReferralId = message.activeReferralId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PartialUpdateBookingClientRequest>, I>>(
    base?: I,
  ): PartialUpdateBookingClientRequest {
    return PartialUpdateBookingClientRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PartialUpdateBookingClientRequest>, I>>(
    object: I,
  ): PartialUpdateBookingClientRequest {
    const message = createBasePartialUpdateBookingClientRequest();
    message.clientId = object.clientId ?? "";
    message.bookingId = object.bookingId ?? "";
    message.activeCardId = object.activeCardId ?? undefined;
    message.activeReferralId = object.activeReferralId ?? undefined;
    return message;
  },
};

function createBaseCancelBookingClientRequest(): CancelBookingClientRequest {
  return { bookingId: "", clientId: "", message: "", sendNotification: false, allEvents: false };
}

export const CancelBookingClientRequest = {
  encode(message: CancelBookingClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    if (message.message !== "") {
      writer.uint32(26).string(message.message);
    }
    if (message.sendNotification === true) {
      writer.uint32(32).bool(message.sendNotification);
    }
    if (message.allEvents === true) {
      writer.uint32(40).bool(message.allEvents);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelBookingClientRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelBookingClientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sendNotification = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.allEvents = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CancelBookingClientRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      sendNotification: isSet(object.sendNotification) ? globalThis.Boolean(object.sendNotification) : false,
      allEvents: isSet(object.allEvents) ? globalThis.Boolean(object.allEvents) : false,
    };
  },

  toJSON(message: CancelBookingClientRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.sendNotification === true) {
      obj.sendNotification = message.sendNotification;
    }
    if (message.allEvents === true) {
      obj.allEvents = message.allEvents;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CancelBookingClientRequest>, I>>(base?: I): CancelBookingClientRequest {
    return CancelBookingClientRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelBookingClientRequest>, I>>(object: I): CancelBookingClientRequest {
    const message = createBaseCancelBookingClientRequest();
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    message.message = object.message ?? "";
    message.sendNotification = object.sendNotification ?? false;
    message.allEvents = object.allEvents ?? false;
    return message;
  },
};

function createBaseDeleteBookingClientRequest(): DeleteBookingClientRequest {
  return { bookingId: "", clientId: "" };
}

export const DeleteBookingClientRequest = {
  encode(message: DeleteBookingClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteBookingClientRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteBookingClientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteBookingClientRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: DeleteBookingClientRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteBookingClientRequest>, I>>(base?: I): DeleteBookingClientRequest {
    return DeleteBookingClientRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteBookingClientRequest>, I>>(object: I): DeleteBookingClientRequest {
    const message = createBaseDeleteBookingClientRequest();
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseApproveBookingClientRequest(): ApproveBookingClientRequest {
  return { bookingId: "", clientId: "" };
}

export const ApproveBookingClientRequest = {
  encode(message: ApproveBookingClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApproveBookingClientRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveBookingClientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApproveBookingClientRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: ApproveBookingClientRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApproveBookingClientRequest>, I>>(base?: I): ApproveBookingClientRequest {
    return ApproveBookingClientRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApproveBookingClientRequest>, I>>(object: I): ApproveBookingClientRequest {
    const message = createBaseApproveBookingClientRequest();
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseRejectBookingClientRequest(): RejectBookingClientRequest {
  return { bookingId: "", clientId: "", message: "", sendNotification: false };
}

export const RejectBookingClientRequest = {
  encode(message: RejectBookingClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    if (message.message !== "") {
      writer.uint32(26).string(message.message);
    }
    if (message.sendNotification === true) {
      writer.uint32(32).bool(message.sendNotification);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RejectBookingClientRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRejectBookingClientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sendNotification = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RejectBookingClientRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      sendNotification: isSet(object.sendNotification) ? globalThis.Boolean(object.sendNotification) : false,
    };
  },

  toJSON(message: RejectBookingClientRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.sendNotification === true) {
      obj.sendNotification = message.sendNotification;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RejectBookingClientRequest>, I>>(base?: I): RejectBookingClientRequest {
    return RejectBookingClientRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RejectBookingClientRequest>, I>>(object: I): RejectBookingClientRequest {
    const message = createBaseRejectBookingClientRequest();
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    message.message = object.message ?? "";
    message.sendNotification = object.sendNotification ?? false;
    return message;
  },
};

function createBaseProcessBookingResponse(): ProcessBookingResponse {
  return { booking: undefined, accountItemsId: [] };
}

export const ProcessBookingResponse = {
  encode(message: ProcessBookingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.booking !== undefined) {
      BookingDTO.encode(message.booking, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.accountItemsId) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessBookingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessBookingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.booking = BookingDTO.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountItemsId.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProcessBookingResponse {
    return {
      booking: isSet(object.booking) ? BookingDTO.fromJSON(object.booking) : undefined,
      accountItemsId: globalThis.Array.isArray(object?.accountItemsId)
        ? object.accountItemsId.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ProcessBookingResponse): unknown {
    const obj: any = {};
    if (message.booking !== undefined) {
      obj.booking = BookingDTO.toJSON(message.booking);
    }
    if (message.accountItemsId?.length) {
      obj.accountItemsId = message.accountItemsId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProcessBookingResponse>, I>>(base?: I): ProcessBookingResponse {
    return ProcessBookingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProcessBookingResponse>, I>>(object: I): ProcessBookingResponse {
    const message = createBaseProcessBookingResponse();
    message.booking = (object.booking !== undefined && object.booking !== null)
      ? BookingDTO.fromPartial(object.booking)
      : undefined;
    message.accountItemsId = object.accountItemsId?.map((e) => e) || [];
    return message;
  },
};

function createBaseCancellationChargeClientRequest(): CancellationChargeClientRequest {
  return { bookingId: "", clientId: "" };
}

export const CancellationChargeClientRequest = {
  encode(message: CancellationChargeClientRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancellationChargeClientRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancellationChargeClientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CancellationChargeClientRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: CancellationChargeClientRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CancellationChargeClientRequest>, I>>(base?: I): CancellationChargeClientRequest {
    return CancellationChargeClientRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancellationChargeClientRequest>, I>>(
    object: I,
  ): CancellationChargeClientRequest {
    const message = createBaseCancellationChargeClientRequest();
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseProcessBookingCancellationResponse(): ProcessBookingCancellationResponse {
  return { booking: undefined, accountItemId: "" };
}

export const ProcessBookingCancellationResponse = {
  encode(message: ProcessBookingCancellationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.booking !== undefined) {
      BookingDTO.encode(message.booking, writer.uint32(10).fork()).ldelim();
    }
    if (message.accountItemId !== "") {
      writer.uint32(18).string(message.accountItemId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessBookingCancellationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessBookingCancellationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.booking = BookingDTO.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountItemId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProcessBookingCancellationResponse {
    return {
      booking: isSet(object.booking) ? BookingDTO.fromJSON(object.booking) : undefined,
      accountItemId: isSet(object.accountItemId) ? globalThis.String(object.accountItemId) : "",
    };
  },

  toJSON(message: ProcessBookingCancellationResponse): unknown {
    const obj: any = {};
    if (message.booking !== undefined) {
      obj.booking = BookingDTO.toJSON(message.booking);
    }
    if (message.accountItemId !== "") {
      obj.accountItemId = message.accountItemId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProcessBookingCancellationResponse>, I>>(
    base?: I,
  ): ProcessBookingCancellationResponse {
    return ProcessBookingCancellationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProcessBookingCancellationResponse>, I>>(
    object: I,
  ): ProcessBookingCancellationResponse {
    const message = createBaseProcessBookingCancellationResponse();
    message.booking = (object.booking !== undefined && object.booking !== null)
      ? BookingDTO.fromPartial(object.booking)
      : undefined;
    message.accountItemId = object.accountItemId ?? "";
    return message;
  },
};

function createBaseGetBookingRequest(): GetBookingRequest {
  return { id: "" };
}

export const GetBookingRequest = {
  encode(message: GetBookingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBookingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBookingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBookingRequest {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: GetBookingRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBookingRequest>, I>>(base?: I): GetBookingRequest {
    return GetBookingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBookingRequest>, I>>(object: I): GetBookingRequest {
    const message = createBaseGetBookingRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseCreateBulkBookingRequest(): CreateBulkBookingRequest {
  return { booking: undefined, frequency: 0, repetitions: 0, repetitionEndDate: undefined, days: [] };
}

export const CreateBulkBookingRequest = {
  encode(message: CreateBulkBookingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.booking !== undefined) {
      CreateBookingRequest.encode(message.booking, writer.uint32(10).fork()).ldelim();
    }
    if (message.frequency !== 0) {
      writer.uint32(16).int32(message.frequency);
    }
    if (message.repetitions !== 0) {
      writer.uint32(24).int32(message.repetitions);
    }
    if (message.repetitionEndDate !== undefined) {
      Timestamp.encode(toTimestamp(message.repetitionEndDate), writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).fork();
    for (const v of message.days) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBulkBookingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBulkBookingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.booking = CreateBookingRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.frequency = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.repetitions = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.repetitionEndDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag === 40) {
            message.days.push(reader.int32() as any);

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.days.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBulkBookingRequest {
    return {
      booking: isSet(object.booking) ? CreateBookingRequest.fromJSON(object.booking) : undefined,
      frequency: isSet(object.frequency) ? createBulkBookingRequest_BookingsFrequencyFromJSON(object.frequency) : 0,
      repetitions: isSet(object.repetitions) ? globalThis.Number(object.repetitions) : 0,
      repetitionEndDate: isSet(object.repetitionEndDate) ? fromJsonTimestamp(object.repetitionEndDate) : undefined,
      days: globalThis.Array.isArray(object?.days)
        ? object.days.map((e: any) => createBulkBookingRequest_DaysOfTheWeekFromJSON(e))
        : [],
    };
  },

  toJSON(message: CreateBulkBookingRequest): unknown {
    const obj: any = {};
    if (message.booking !== undefined) {
      obj.booking = CreateBookingRequest.toJSON(message.booking);
    }
    if (message.frequency !== 0) {
      obj.frequency = createBulkBookingRequest_BookingsFrequencyToJSON(message.frequency);
    }
    if (message.repetitions !== 0) {
      obj.repetitions = Math.round(message.repetitions);
    }
    if (message.repetitionEndDate !== undefined) {
      obj.repetitionEndDate = message.repetitionEndDate.toISOString();
    }
    if (message.days?.length) {
      obj.days = message.days.map((e) => createBulkBookingRequest_DaysOfTheWeekToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBulkBookingRequest>, I>>(base?: I): CreateBulkBookingRequest {
    return CreateBulkBookingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBulkBookingRequest>, I>>(object: I): CreateBulkBookingRequest {
    const message = createBaseCreateBulkBookingRequest();
    message.booking = (object.booking !== undefined && object.booking !== null)
      ? CreateBookingRequest.fromPartial(object.booking)
      : undefined;
    message.frequency = object.frequency ?? 0;
    message.repetitions = object.repetitions ?? 0;
    message.repetitionEndDate = object.repetitionEndDate ?? undefined;
    message.days = object.days?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateBulkBookingResponse(): CreateBulkBookingResponse {
  return { bookings: [] };
}

export const CreateBulkBookingResponse = {
  encode(message: CreateBulkBookingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.bookings) {
      BookingDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBulkBookingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBulkBookingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookings.push(BookingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBulkBookingResponse {
    return {
      bookings: globalThis.Array.isArray(object?.bookings)
        ? object.bookings.map((e: any) => BookingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CreateBulkBookingResponse): unknown {
    const obj: any = {};
    if (message.bookings?.length) {
      obj.bookings = message.bookings.map((e) => BookingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBulkBookingResponse>, I>>(base?: I): CreateBulkBookingResponse {
    return CreateBulkBookingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBulkBookingResponse>, I>>(object: I): CreateBulkBookingResponse {
    const message = createBaseCreateBulkBookingResponse();
    message.bookings = object.bookings?.map((e) => BookingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCancelBulkBookingResponse(): CancelBulkBookingResponse {
  return { bookings: [] };
}

export const CancelBulkBookingResponse = {
  encode(message: CancelBulkBookingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.bookings) {
      BookingDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelBulkBookingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelBulkBookingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookings.push(BookingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CancelBulkBookingResponse {
    return {
      bookings: globalThis.Array.isArray(object?.bookings)
        ? object.bookings.map((e: any) => BookingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CancelBulkBookingResponse): unknown {
    const obj: any = {};
    if (message.bookings?.length) {
      obj.bookings = message.bookings.map((e) => BookingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CancelBulkBookingResponse>, I>>(base?: I): CancelBulkBookingResponse {
    return CancelBulkBookingResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelBulkBookingResponse>, I>>(object: I): CancelBulkBookingResponse {
    const message = createBaseCancelBulkBookingResponse();
    message.bookings = object.bookings?.map((e) => BookingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateBookingRequest(): CreateBookingRequest {
  return {
    locationId: "",
    startDatetime: undefined,
    providerId: "",
    endDatetime: undefined,
    repetitions: 0,
    offeringId: "",
    type: 0,
    maxClients: 0,
    timezone: "",
  };
}

export const CreateBookingRequest = {
  encode(message: CreateBookingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    if (message.startDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.startDatetime), writer.uint32(18).fork()).ldelim();
    }
    if (message.providerId !== "") {
      writer.uint32(26).string(message.providerId);
    }
    if (message.endDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.endDatetime), writer.uint32(34).fork()).ldelim();
    }
    if (message.repetitions !== 0) {
      writer.uint32(40).int32(message.repetitions);
    }
    if (message.offeringId !== "") {
      writer.uint32(50).string(message.offeringId);
    }
    if (message.type !== 0) {
      writer.uint32(56).int32(message.type);
    }
    if (message.maxClients !== 0) {
      writer.uint32(64).int32(message.maxClients);
    }
    if (message.timezone !== "") {
      writer.uint32(74).string(message.timezone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBookingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBookingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.providerId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.repetitions = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.offeringId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.maxClients = reader.int32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.timezone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBookingRequest {
    return {
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      startDatetime: isSet(object.startDatetime) ? fromJsonTimestamp(object.startDatetime) : undefined,
      providerId: isSet(object.providerId) ? globalThis.String(object.providerId) : "",
      endDatetime: isSet(object.endDatetime) ? fromJsonTimestamp(object.endDatetime) : undefined,
      repetitions: isSet(object.repetitions) ? globalThis.Number(object.repetitions) : 0,
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
      type: isSet(object.type) ? bookingDTO_TypeFromJSON(object.type) : 0,
      maxClients: isSet(object.maxClients) ? globalThis.Number(object.maxClients) : 0,
      timezone: isSet(object.timezone) ? globalThis.String(object.timezone) : "",
    };
  },

  toJSON(message: CreateBookingRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.startDatetime !== undefined) {
      obj.startDatetime = message.startDatetime.toISOString();
    }
    if (message.providerId !== "") {
      obj.providerId = message.providerId;
    }
    if (message.endDatetime !== undefined) {
      obj.endDatetime = message.endDatetime.toISOString();
    }
    if (message.repetitions !== 0) {
      obj.repetitions = Math.round(message.repetitions);
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    if (message.type !== 0) {
      obj.type = bookingDTO_TypeToJSON(message.type);
    }
    if (message.maxClients !== 0) {
      obj.maxClients = Math.round(message.maxClients);
    }
    if (message.timezone !== "") {
      obj.timezone = message.timezone;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBookingRequest>, I>>(base?: I): CreateBookingRequest {
    return CreateBookingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBookingRequest>, I>>(object: I): CreateBookingRequest {
    const message = createBaseCreateBookingRequest();
    message.locationId = object.locationId ?? "";
    message.startDatetime = object.startDatetime ?? undefined;
    message.providerId = object.providerId ?? "";
    message.endDatetime = object.endDatetime ?? undefined;
    message.repetitions = object.repetitions ?? 0;
    message.offeringId = object.offeringId ?? "";
    message.type = object.type ?? 0;
    message.maxClients = object.maxClients ?? 0;
    message.timezone = object.timezone ?? "";
    return message;
  },
};

function createBaseBulkCancelBookingRequest(): BulkCancelBookingRequest {
  return { providerId: "", offeringId: "", startDatetime: undefined, endDatetime: undefined, timezone: "" };
}

export const BulkCancelBookingRequest = {
  encode(message: BulkCancelBookingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.providerId !== "") {
      writer.uint32(10).string(message.providerId);
    }
    if (message.offeringId !== "") {
      writer.uint32(18).string(message.offeringId);
    }
    if (message.startDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.startDatetime), writer.uint32(26).fork()).ldelim();
    }
    if (message.endDatetime !== undefined) {
      Timestamp.encode(toTimestamp(message.endDatetime), writer.uint32(34).fork()).ldelim();
    }
    if (message.timezone !== "") {
      writer.uint32(42).string(message.timezone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkCancelBookingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkCancelBookingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.providerId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.offeringId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endDatetime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.timezone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkCancelBookingRequest {
    return {
      providerId: isSet(object.providerId) ? globalThis.String(object.providerId) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
      startDatetime: isSet(object.startDatetime) ? fromJsonTimestamp(object.startDatetime) : undefined,
      endDatetime: isSet(object.endDatetime) ? fromJsonTimestamp(object.endDatetime) : undefined,
      timezone: isSet(object.timezone) ? globalThis.String(object.timezone) : "",
    };
  },

  toJSON(message: BulkCancelBookingRequest): unknown {
    const obj: any = {};
    if (message.providerId !== "") {
      obj.providerId = message.providerId;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    if (message.startDatetime !== undefined) {
      obj.startDatetime = message.startDatetime.toISOString();
    }
    if (message.endDatetime !== undefined) {
      obj.endDatetime = message.endDatetime.toISOString();
    }
    if (message.timezone !== "") {
      obj.timezone = message.timezone;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkCancelBookingRequest>, I>>(base?: I): BulkCancelBookingRequest {
    return BulkCancelBookingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkCancelBookingRequest>, I>>(object: I): BulkCancelBookingRequest {
    const message = createBaseBulkCancelBookingRequest();
    message.providerId = object.providerId ?? "";
    message.offeringId = object.offeringId ?? "";
    message.startDatetime = object.startDatetime ?? undefined;
    message.endDatetime = object.endDatetime ?? undefined;
    message.timezone = object.timezone ?? "";
    return message;
  },
};

function createBaseCreateBookingOfferingRequest(): CreateBookingOfferingRequest {
  return { bookingId: "", offeringId: "" };
}

export const CreateBookingOfferingRequest = {
  encode(message: CreateBookingOfferingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.offeringId !== "") {
      writer.uint32(18).string(message.offeringId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBookingOfferingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBookingOfferingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.offeringId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBookingOfferingRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
    };
  },

  toJSON(message: CreateBookingOfferingRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBookingOfferingRequest>, I>>(base?: I): CreateBookingOfferingRequest {
    return CreateBookingOfferingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBookingOfferingRequest>, I>>(object: I): CreateBookingOfferingRequest {
    const message = createBaseCreateBookingOfferingRequest();
    message.bookingId = object.bookingId ?? "";
    message.offeringId = object.offeringId ?? "";
    return message;
  },
};

function createBaseCreateClientOfferingRequest(): CreateClientOfferingRequest {
  return { bookingId: "", offeringId: "", clientId: "" };
}

export const CreateClientOfferingRequest = {
  encode(message: CreateClientOfferingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.offeringId !== "") {
      writer.uint32(18).string(message.offeringId);
    }
    if (message.clientId !== "") {
      writer.uint32(26).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateClientOfferingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateClientOfferingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.offeringId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateClientOfferingRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: CreateClientOfferingRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateClientOfferingRequest>, I>>(base?: I): CreateClientOfferingRequest {
    return CreateClientOfferingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateClientOfferingRequest>, I>>(object: I): CreateClientOfferingRequest {
    const message = createBaseCreateClientOfferingRequest();
    message.bookingId = object.bookingId ?? "";
    message.offeringId = object.offeringId ?? "";
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseUpdateLineItemRequest(): UpdateLineItemRequest {
  return { lineItem: undefined, mask: undefined };
}

export const UpdateLineItemRequest = {
  encode(message: UpdateLineItemRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lineItem !== undefined) {
      LineItem.encode(message.lineItem, writer.uint32(10).fork()).ldelim();
    }
    if (message.mask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.mask), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLineItemRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLineItemRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lineItem = LineItem.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateLineItemRequest {
    return {
      lineItem: isSet(object.lineItem) ? LineItem.fromJSON(object.lineItem) : undefined,
      mask: isSet(object.mask) ? FieldMask.unwrap(FieldMask.fromJSON(object.mask)) : undefined,
    };
  },

  toJSON(message: UpdateLineItemRequest): unknown {
    const obj: any = {};
    if (message.lineItem !== undefined) {
      obj.lineItem = LineItem.toJSON(message.lineItem);
    }
    if (message.mask !== undefined) {
      obj.mask = FieldMask.toJSON(FieldMask.wrap(message.mask));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateLineItemRequest>, I>>(base?: I): UpdateLineItemRequest {
    return UpdateLineItemRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLineItemRequest>, I>>(object: I): UpdateLineItemRequest {
    const message = createBaseUpdateLineItemRequest();
    message.lineItem = (object.lineItem !== undefined && object.lineItem !== null)
      ? LineItem.fromPartial(object.lineItem)
      : undefined;
    message.mask = object.mask ?? undefined;
    return message;
  },
};

function createBaseDeleteLineItemRequest(): DeleteLineItemRequest {
  return { lineItemId: "" };
}

export const DeleteLineItemRequest = {
  encode(message: DeleteLineItemRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lineItemId !== "") {
      writer.uint32(10).string(message.lineItemId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteLineItemRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteLineItemRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lineItemId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteLineItemRequest {
    return { lineItemId: isSet(object.lineItemId) ? globalThis.String(object.lineItemId) : "" };
  },

  toJSON(message: DeleteLineItemRequest): unknown {
    const obj: any = {};
    if (message.lineItemId !== "") {
      obj.lineItemId = message.lineItemId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteLineItemRequest>, I>>(base?: I): DeleteLineItemRequest {
    return DeleteLineItemRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteLineItemRequest>, I>>(object: I): DeleteLineItemRequest {
    const message = createBaseDeleteLineItemRequest();
    message.lineItemId = object.lineItemId ?? "";
    return message;
  },
};

function createBaseListBookingByLocationRequest(): ListBookingByLocationRequest {
  return { locationId: "", bookingStartDate: undefined, bookingEndDate: undefined, timezone: "" };
}

export const ListBookingByLocationRequest = {
  encode(message: ListBookingByLocationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    if (message.bookingStartDate !== undefined) {
      Timestamp.encode(toTimestamp(message.bookingStartDate), writer.uint32(18).fork()).ldelim();
    }
    if (message.bookingEndDate !== undefined) {
      Timestamp.encode(toTimestamp(message.bookingEndDate), writer.uint32(26).fork()).ldelim();
    }
    if (message.timezone !== "") {
      writer.uint32(34).string(message.timezone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListBookingByLocationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListBookingByLocationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bookingStartDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.bookingEndDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.timezone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListBookingByLocationRequest {
    return {
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      bookingStartDate: isSet(object.bookingStartDate) ? fromJsonTimestamp(object.bookingStartDate) : undefined,
      bookingEndDate: isSet(object.bookingEndDate) ? fromJsonTimestamp(object.bookingEndDate) : undefined,
      timezone: isSet(object.timezone) ? globalThis.String(object.timezone) : "",
    };
  },

  toJSON(message: ListBookingByLocationRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.bookingStartDate !== undefined) {
      obj.bookingStartDate = message.bookingStartDate.toISOString();
    }
    if (message.bookingEndDate !== undefined) {
      obj.bookingEndDate = message.bookingEndDate.toISOString();
    }
    if (message.timezone !== "") {
      obj.timezone = message.timezone;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListBookingByLocationRequest>, I>>(base?: I): ListBookingByLocationRequest {
    return ListBookingByLocationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListBookingByLocationRequest>, I>>(object: I): ListBookingByLocationRequest {
    const message = createBaseListBookingByLocationRequest();
    message.locationId = object.locationId ?? "";
    message.bookingStartDate = object.bookingStartDate ?? undefined;
    message.bookingEndDate = object.bookingEndDate ?? undefined;
    message.timezone = object.timezone ?? "";
    return message;
  },
};

function createBaseListBookingsResponse(): ListBookingsResponse {
  return { bookings: [] };
}

export const ListBookingsResponse = {
  encode(message: ListBookingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.bookings) {
      BookingDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListBookingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListBookingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookings.push(BookingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListBookingsResponse {
    return {
      bookings: globalThis.Array.isArray(object?.bookings)
        ? object.bookings.map((e: any) => BookingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListBookingsResponse): unknown {
    const obj: any = {};
    if (message.bookings?.length) {
      obj.bookings = message.bookings.map((e) => BookingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListBookingsResponse>, I>>(base?: I): ListBookingsResponse {
    return ListBookingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListBookingsResponse>, I>>(object: I): ListBookingsResponse {
    const message = createBaseListBookingsResponse();
    message.bookings = object.bookings?.map((e) => BookingDTO.fromPartial(e)) || [];
    return message;
  },
};

export interface Bookings {
  /**
   * This api will:
   * - update the booking status to "processing"
   * - create account items for all offerings in the booking
   * - submit jobs to process each account item
   * A successful response from this API indicates that all jobs
   * have been submitted but does not indicate if the jobs all
   * completed successfully.
   * The jobs themselves are idempotent and so this API is also
   * idempotent and may be called multiple times on a given
   * booking.
   * Booking process - Create account items actions
   */
  ProcessBooking(
    request: DeepPartial<ProcessBookingRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProcessBookingResponse>;
  Get(request: DeepPartial<GetBookingRequest>, metadata?: grpc.Metadata): Promise<BookingDTO>;
  Create(request: DeepPartial<CreateBookingRequest>, metadata?: grpc.Metadata): Promise<BookingDTO>;
  CreateBulk(
    request: DeepPartial<CreateBulkBookingRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateBulkBookingResponse>;
  CancelBulk(
    request: DeepPartial<CancelBookingClientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CancelBulkBookingResponse>;
  ListByLocation(
    request: DeepPartial<ListBookingByLocationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListBookingsResponse>;
  ListThirdPartyBookings(
    request: DeepPartial<ListThirdPartyBookingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListThirdPartyBookingsResponse>;
  Update(request: DeepPartial<UpdateBookingRequest>, metadata?: grpc.Metadata): Promise<BookingDTO>;
}

export class BookingsClientImpl implements Bookings {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ProcessBooking = this.ProcessBooking.bind(this);
    this.Get = this.Get.bind(this);
    this.Create = this.Create.bind(this);
    this.CreateBulk = this.CreateBulk.bind(this);
    this.CancelBulk = this.CancelBulk.bind(this);
    this.ListByLocation = this.ListByLocation.bind(this);
    this.ListThirdPartyBookings = this.ListThirdPartyBookings.bind(this);
    this.Update = this.Update.bind(this);
  }

  ProcessBooking(
    request: DeepPartial<ProcessBookingRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProcessBookingResponse> {
    return this.rpc.unary(BookingsProcessBookingDesc, ProcessBookingRequest.fromPartial(request), metadata);
  }

  Get(request: DeepPartial<GetBookingRequest>, metadata?: grpc.Metadata): Promise<BookingDTO> {
    return this.rpc.unary(BookingsGetDesc, GetBookingRequest.fromPartial(request), metadata);
  }

  Create(request: DeepPartial<CreateBookingRequest>, metadata?: grpc.Metadata): Promise<BookingDTO> {
    return this.rpc.unary(BookingsCreateDesc, CreateBookingRequest.fromPartial(request), metadata);
  }

  CreateBulk(
    request: DeepPartial<CreateBulkBookingRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateBulkBookingResponse> {
    return this.rpc.unary(BookingsCreateBulkDesc, CreateBulkBookingRequest.fromPartial(request), metadata);
  }

  CancelBulk(
    request: DeepPartial<CancelBookingClientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CancelBulkBookingResponse> {
    return this.rpc.unary(BookingsCancelBulkDesc, CancelBookingClientRequest.fromPartial(request), metadata);
  }

  ListByLocation(
    request: DeepPartial<ListBookingByLocationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListBookingsResponse> {
    return this.rpc.unary(BookingsListByLocationDesc, ListBookingByLocationRequest.fromPartial(request), metadata);
  }

  ListThirdPartyBookings(
    request: DeepPartial<ListThirdPartyBookingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListThirdPartyBookingsResponse> {
    return this.rpc.unary(
      BookingsListThirdPartyBookingsDesc,
      ListThirdPartyBookingsRequest.fromPartial(request),
      metadata,
    );
  }

  Update(request: DeepPartial<UpdateBookingRequest>, metadata?: grpc.Metadata): Promise<BookingDTO> {
    return this.rpc.unary(BookingsUpdateDesc, UpdateBookingRequest.fromPartial(request), metadata);
  }
}

export const BookingsDesc = { serviceName: "proto.Bookings" };

export const BookingsProcessBookingDesc: UnaryMethodDefinitionish = {
  methodName: "ProcessBooking",
  service: BookingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ProcessBookingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ProcessBookingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingsGetDesc: UnaryMethodDefinitionish = {
  methodName: "Get",
  service: BookingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBookingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingDTO.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingsCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: BookingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateBookingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingDTO.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingsCreateBulkDesc: UnaryMethodDefinitionish = {
  methodName: "CreateBulk",
  service: BookingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateBulkBookingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateBulkBookingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingsCancelBulkDesc: UnaryMethodDefinitionish = {
  methodName: "CancelBulk",
  service: BookingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CancelBookingClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CancelBulkBookingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingsListByLocationDesc: UnaryMethodDefinitionish = {
  methodName: "ListByLocation",
  service: BookingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListBookingByLocationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListBookingsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingsListThirdPartyBookingsDesc: UnaryMethodDefinitionish = {
  methodName: "ListThirdPartyBookings",
  service: BookingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListThirdPartyBookingsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListThirdPartyBookingsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingsUpdateDesc: UnaryMethodDefinitionish = {
  methodName: "Update",
  service: BookingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateBookingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingDTO.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export interface BookingClients {
  Get(request: DeepPartial<GetBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingClient>;
  Create(request: DeepPartial<CreateBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingClient>;
  Update(request: DeepPartial<UpdateBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingClient>;
  PartialUpdate(
    request: DeepPartial<PartialUpdateBookingClientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BookingClient>;
  Delete(request: DeepPartial<DeleteBookingClientRequest>, metadata?: grpc.Metadata): Promise<Empty>;
  CancellationCharge(
    request: DeepPartial<CancellationChargeClientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProcessBookingCancellationResponse>;
  Cancel(request: DeepPartial<CancelBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingDTO>;
  Approve(request: DeepPartial<ApproveBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingDTO>;
  Reject(request: DeepPartial<RejectBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingDTO>;
  GetLatestReferral(
    request: DeepPartial<GetLatestReferralRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetLatestReferralResponse>;
}

export class BookingClientsClientImpl implements BookingClients {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Get = this.Get.bind(this);
    this.Create = this.Create.bind(this);
    this.Update = this.Update.bind(this);
    this.PartialUpdate = this.PartialUpdate.bind(this);
    this.Delete = this.Delete.bind(this);
    this.CancellationCharge = this.CancellationCharge.bind(this);
    this.Cancel = this.Cancel.bind(this);
    this.Approve = this.Approve.bind(this);
    this.Reject = this.Reject.bind(this);
    this.GetLatestReferral = this.GetLatestReferral.bind(this);
  }

  Get(request: DeepPartial<GetBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingClient> {
    return this.rpc.unary(BookingClientsGetDesc, GetBookingClientRequest.fromPartial(request), metadata);
  }

  Create(request: DeepPartial<CreateBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingClient> {
    return this.rpc.unary(BookingClientsCreateDesc, CreateBookingClientRequest.fromPartial(request), metadata);
  }

  Update(request: DeepPartial<UpdateBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingClient> {
    return this.rpc.unary(BookingClientsUpdateDesc, UpdateBookingClientRequest.fromPartial(request), metadata);
  }

  PartialUpdate(
    request: DeepPartial<PartialUpdateBookingClientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<BookingClient> {
    return this.rpc.unary(
      BookingClientsPartialUpdateDesc,
      PartialUpdateBookingClientRequest.fromPartial(request),
      metadata,
    );
  }

  Delete(request: DeepPartial<DeleteBookingClientRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(BookingClientsDeleteDesc, DeleteBookingClientRequest.fromPartial(request), metadata);
  }

  CancellationCharge(
    request: DeepPartial<CancellationChargeClientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProcessBookingCancellationResponse> {
    return this.rpc.unary(
      BookingClientsCancellationChargeDesc,
      CancellationChargeClientRequest.fromPartial(request),
      metadata,
    );
  }

  Cancel(request: DeepPartial<CancelBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingDTO> {
    return this.rpc.unary(BookingClientsCancelDesc, CancelBookingClientRequest.fromPartial(request), metadata);
  }

  Approve(request: DeepPartial<ApproveBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingDTO> {
    return this.rpc.unary(BookingClientsApproveDesc, ApproveBookingClientRequest.fromPartial(request), metadata);
  }

  Reject(request: DeepPartial<RejectBookingClientRequest>, metadata?: grpc.Metadata): Promise<BookingDTO> {
    return this.rpc.unary(BookingClientsRejectDesc, RejectBookingClientRequest.fromPartial(request), metadata);
  }

  GetLatestReferral(
    request: DeepPartial<GetLatestReferralRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetLatestReferralResponse> {
    return this.rpc.unary(BookingClientsGetLatestReferralDesc, GetLatestReferralRequest.fromPartial(request), metadata);
  }
}

export const BookingClientsDesc = { serviceName: "proto.BookingClients" };

export const BookingClientsGetDesc: UnaryMethodDefinitionish = {
  methodName: "Get",
  service: BookingClientsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBookingClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingClient.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingClientsCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: BookingClientsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateBookingClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingClient.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingClientsUpdateDesc: UnaryMethodDefinitionish = {
  methodName: "Update",
  service: BookingClientsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateBookingClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingClient.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingClientsPartialUpdateDesc: UnaryMethodDefinitionish = {
  methodName: "PartialUpdate",
  service: BookingClientsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PartialUpdateBookingClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingClient.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingClientsDeleteDesc: UnaryMethodDefinitionish = {
  methodName: "Delete",
  service: BookingClientsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteBookingClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingClientsCancellationChargeDesc: UnaryMethodDefinitionish = {
  methodName: "CancellationCharge",
  service: BookingClientsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CancellationChargeClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ProcessBookingCancellationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingClientsCancelDesc: UnaryMethodDefinitionish = {
  methodName: "Cancel",
  service: BookingClientsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CancelBookingClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingDTO.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingClientsApproveDesc: UnaryMethodDefinitionish = {
  methodName: "Approve",
  service: BookingClientsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ApproveBookingClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingDTO.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingClientsRejectDesc: UnaryMethodDefinitionish = {
  methodName: "Reject",
  service: BookingClientsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RejectBookingClientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingDTO.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingClientsGetLatestReferralDesc: UnaryMethodDefinitionish = {
  methodName: "GetLatestReferral",
  service: BookingClientsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetLatestReferralRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetLatestReferralResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export interface UserBookings {
  Create(request: DeepPartial<UserBookingRequest>, metadata?: grpc.Metadata): Promise<UserBookingResponse>;
}

export class UserBookingsClientImpl implements UserBookings {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Create = this.Create.bind(this);
  }

  Create(request: DeepPartial<UserBookingRequest>, metadata?: grpc.Metadata): Promise<UserBookingResponse> {
    return this.rpc.unary(UserBookingsCreateDesc, UserBookingRequest.fromPartial(request), metadata);
  }
}

export const UserBookingsDesc = { serviceName: "proto.UserBookings" };

export const UserBookingsCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: UserBookingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UserBookingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UserBookingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export interface BookingOfferings {
  Create(request: DeepPartial<CreateBookingOfferingRequest>, metadata?: grpc.Metadata): Promise<BookingOffering>;
}

export class BookingOfferingsClientImpl implements BookingOfferings {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Create = this.Create.bind(this);
  }

  Create(request: DeepPartial<CreateBookingOfferingRequest>, metadata?: grpc.Metadata): Promise<BookingOffering> {
    return this.rpc.unary(BookingOfferingsCreateDesc, CreateBookingOfferingRequest.fromPartial(request), metadata);
  }
}

export const BookingOfferingsDesc = { serviceName: "proto.BookingOfferings" };

export const BookingOfferingsCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: BookingOfferingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateBookingOfferingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingOffering.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export interface ClientOfferings {
  Create(request: DeepPartial<CreateClientOfferingRequest>, metadata?: grpc.Metadata): Promise<ClientOffering>;
}

export class ClientOfferingsClientImpl implements ClientOfferings {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Create = this.Create.bind(this);
  }

  Create(request: DeepPartial<CreateClientOfferingRequest>, metadata?: grpc.Metadata): Promise<ClientOffering> {
    return this.rpc.unary(ClientOfferingsCreateDesc, CreateClientOfferingRequest.fromPartial(request), metadata);
  }
}

export const ClientOfferingsDesc = { serviceName: "proto.ClientOfferings" };

export const ClientOfferingsCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: ClientOfferingsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateClientOfferingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ClientOffering.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export interface LineItems {
  Update(request: DeepPartial<UpdateLineItemRequest>, metadata?: grpc.Metadata): Promise<BookingDTO>;
  Delete(request: DeepPartial<DeleteLineItemRequest>, metadata?: grpc.Metadata): Promise<BookingDTO>;
}

export class LineItemsClientImpl implements LineItems {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Update = this.Update.bind(this);
    this.Delete = this.Delete.bind(this);
  }

  Update(request: DeepPartial<UpdateLineItemRequest>, metadata?: grpc.Metadata): Promise<BookingDTO> {
    return this.rpc.unary(LineItemsUpdateDesc, UpdateLineItemRequest.fromPartial(request), metadata);
  }

  Delete(request: DeepPartial<DeleteLineItemRequest>, metadata?: grpc.Metadata): Promise<BookingDTO> {
    return this.rpc.unary(LineItemsDeleteDesc, DeleteLineItemRequest.fromPartial(request), metadata);
  }
}

export const LineItemsDesc = { serviceName: "proto.LineItems" };

export const LineItemsUpdateDesc: UnaryMethodDefinitionish = {
  methodName: "Update",
  service: LineItemsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateLineItemRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingDTO.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const LineItemsDeleteDesc: UnaryMethodDefinitionish = {
  methodName: "Delete",
  service: LineItemsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteLineItemRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingDTO.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
