import React, { Component } from "react";
import styled from "@emotion/styled";
import { css, ClassNames } from "@emotion/core";
import { Switch, NavLink, Route } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router";

// Redux
import * as ReduxDialogs from "./../../../redux/features/dialogs";
import * as GlobalActions from "./../../../redux/features/global/actions";
import { getAuthenticatedUser } from "./../../../components/elements/AuthGetUser";

// Util
import { breakpoints, colors, hexToRgba } from "./../../../util/consts";

// Components
import ResourceRender from "./../../../components/elements/ResourceRender";
import ResourceListRender from "./../../../components/elements/ResourceListRender";
import OrgAvatar from "./../../../components/OrgAvatar";
import UserAvatar from "./../../../components/UserAvatar";
import { getResource } from "./../../../redux/features/resources/helpers";
import typography from "./../../../util/typography";

import { userStore } from "../../../mobx/stores/UserStore";
import { FeatureFlag } from "../../feature-flag/FeatureFlag";
import money_legacy from "../../../util/money_legacy";

// important
const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
  background-color: ${colors.surface.light};

  ${breakpoints["phone-only"]} {
    overflow-x: auto;
  }

  ${breakpoints["tablet-up"]} {
    border: 1px solid ${hexToRgba(colors.primary.main, 0.1)};
    border-radius: 4px;
    margin-top: 7px;
    width: 280px;

    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: calc(100% + 1px);
      right: 16px;
      border-width: 0 7px 7px 7px;
      border-style: solid;
      border-color: ${hexToRgba(colors.primary.main, 0.1)} transparent;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      right: 16px;
      border-width: 0 7px 7px 7px;
      border-style: solid;
      border-color: ${colors.surface.light} transparent;
    }
  }
`;

const Content = styled.div`
  padding: 8px 0;
`;

const itemBase = css`
  width: 100%;
  height: 32px;
  padding: 0px 20px;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Item = styled.div`
  ${itemBase};
`;

const ClickableItem = styled.div`
  ${itemBase};
  cursor: pointer;
  background: none;
  outline: none;
  text-decoration: none;

  &:hover {
    background: #eee;
  }
`;

const Seperator = styled.div`
  height: 1px;
  background-color: rgba(44, 46, 60, 0.1);
  width: calc(100% - 5=40px);
  margin: 4px 20px;
`;

const Title = styled.span`
  font-size: 14.8px;
  color: ${colors.surfaceText.highEmphasis};
  font-weight: 600;
  letter-spacing: 0.19px;
  line-height: 26px;
  ${typography.elipse};
`;

const Subtitle = styled.span`
  color: ${colors.surfaceText.highEmphasis};
  font-size: 12.82px;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 26px;
  ${typography.elipse};
`;

const Body = styled.span`
  color: ${colors.surfaceText.medEmphasis};
  font-size: 12.82px;
  letter-spacing: 0.18px;
  line-height: 26px;
  ${typography.elipse};
`;

const LinkItem = React.memo(({ to, onClick, label }) => (
  <ClassNames>
    {({ css: othercss }) => (
      <ClickableItem
        as={NavLink}
        to={to}
        activeClassName={othercss`
          & > ${Body} {
            color: ${colors.secondary.main};
          }
        `}
        onClick={onClick}
      >
        <Body>{label}</Body>
      </ClickableItem>
    )}
  </ClassNames>
));

function mayEditOrg(orgUser) {
  if (!orgUser) {
    return false;
  }
  return orgUser.Roles.indexOf("Admin") >= 0 || orgUser.Roles.indexOf("Receptionist") >= 0;
}

class UnconnectedUserMenu extends Component {
  setOrgFunc = (val) => () => {
    const { dispatch, closeMenu, history } = this.props;
    dispatch(GlobalActions.setUserContext(val, ""));
    if (val === "") {
      history.push("/");
    } else {
      history.push("/admin/orgs/" + val + "/locations");
    }
    closeMenu();
  };

  openLoginModal = () => {
    this.props.dispatch(ReduxDialogs.openAccount());
    this.props.closeMenu();
  };

  renderContent = React.memo(({ resource, userContext }) =>
    resource.Org.ID === userContext.OrgID ? null : (
      <ClickableItem onClick={this.setOrgFunc(resource.Org.ID)}>
        <Body>Switch to {resource.Org.Name}</Body>
        <OrgAvatar orgID={resource.Org.ID} size={32} />
      </ClickableItem>
    )
  );

  renderList = React.memo(({ list, authUser, userContext }) => (
    <>
      {list.IDs.length > 0 ? <Seperator /> : null}
      {userContext.OrgID !== "" && (
        <ClickableItem onClick={this.setOrgFunc("")}>
          <Body>Switch to Personal</Body>
          <UserAvatar userID={authUser.ID} size={32} />
        </ClickableItem>
      )}
      {list.IDs.map((lid) => (
        <ResourceRender
          key={lid}
          type="OrganisationUser"
          ids={lid}
          denorm={true}
          compSuccess={this.renderContent}
          forwardedProps={{ userContext }}
        />
      ))}
      {list.IDs.length > 0 ? <Seperator /> : null}
    </>
  ));

  render() {
    const { closeMenu, containerStyles, innerRef, authUser, userContext, userOrg, org } =
      this.props;

    const isLoggedIn = authUser && typeof authUser.ID !== "undefined";
    const isLoggedInAsBusiness = userContext.OrgID !== "";
    const orgName = !org ? "Your Organisation" : org.Name;

    return (
      <Container style={containerStyles} ref={innerRef}>
        <Content>
          {isLoggedIn ? (
            <>
              <ScrollBox>
                <Item style={{ display: "inline-grid", textAlign: "left" }}>
                  <Title>
                    {isLoggedInAsBusiness ? orgName : `${authUser.FirstName} ${authUser.LastName}`}
                  </Title>
                  {!FeatureFlag.IsProduction && userStore.isCorporateUser() && (
                    <Subtitle>{`Remaining Credits: ${money_legacy.moneyToText(
                      userStore.user.corporateUser.remainingCredits,
                      false
                    )}`}</Subtitle>
                  )}
                </Item>
                {isLoggedInAsBusiness && mayEditOrg(userOrg) && (
                  <LinkItem
                    to={`/admin/orgs/${userContext.OrgID}/edit?page=general`}
                    onClick={closeMenu}
                    label="Business Profile"
                  />
                )}
                <Seperator />
                <Switch>
                  <Route
                    path="/admin/locs/:lid"
                    component={connect((state, props) => ({
                      provider: getResource(state.resource, "LocationUser", {
                        User: authUser.ID,
                        Location: props.match.params.lid
                      })
                    }))((p) => (
                      <LinkItem
                        to={`/users/edit?page=general`}
                        onClick={closeMenu}
                        label={
                          typeof p.provider.location === "object" &&
                          p.provider.location.Role.includes("Provider")
                            ? "Provider Profile"
                            : "Personal Profile"
                        }
                      />
                    ))}
                  />
                  <Route
                    render={(p) => (
                      <LinkItem
                        to={`/users/edit?page=general`}
                        onClick={closeMenu}
                        label="Personal Profile"
                      />
                    )}
                  />
                </Switch>
                {window.innerWidth < 744 && (
                  <LinkItem
                    to={`/users/appointments`}
                    onClick={closeMenu}
                    label="My Appointments"
                  />
                )}
                <LinkItem
                  to={`/users/edit?page=account`}
                  onClick={closeMenu}
                  label="Account Settings"
                />
                {!userStore.isCorporateUser() && (
                  <ResourceListRender
                    type="AuthUserOrgs"
                    compSuccess={this.renderList}
                    compLoading={() => null}
                    compError={() => null}
                    forwardedProps={{ userContext, authUser }}
                  />
                )}
                <LinkItem to="/logout" onClick={closeMenu} label="Logout" />
              </ScrollBox>
            </>
          ) : (
            <>
              <LinkItem to="/" onClick={closeMenu} label="Home" />
              <LinkItem to="/listyourbusiness" onClick={closeMenu} label="List your Business" />
              <Seperator />
              <ClickableItem onClick={this.openLoginModal}>
                <Body>Login</Body>
              </ClickableItem>
            </>
          )}
        </Content>
      </Container>
    );
  }
}

class UnconnectedOrgMenu extends Component {
  locationsNav = React.memo(({ resource, closeMenu, isOrgAdminRecep }) => {
    const isLocAdminRecep =
      resource.AuthUser &&
      ((resource.AuthUser.Roles || []).indexOf("Admin") >= 0 ||
        (resource.AuthUser.Roles || []).indexOf("Receptionist") >= 0);
    const isAdminRecep = isOrgAdminRecep || isLocAdminRecep;
    return (
      <>
        <Seperator />
        <Item>
          <Subtitle>{resource.Name}</Subtitle>
        </Item>
        <LinkItem
          to={`/admin/locs/${resource.ID}/dashboard`}
          exact
          onClick={closeMenu}
          label="Dashboard"
        />
        <LinkItem
          to={
            isAdminRecep || !resource.AuthUser
              ? `/admin/locs/${resource.ID}/appointments`
              : `/admin/locs/${resource.ID}/providers/${resource.AuthUser.User.ID}`
          }
          exact
          onClick={closeMenu}
          label="Appointments"
        />
        {isAdminRecep && (
          <LinkItem
            to={`/admin/locs/${resource.ID}/offerings`}
            onClick={closeMenu}
            label="Services &amp; Products"
          />
        )}
        {isAdminRecep && (
          <LinkItem
            to={`/admin/locs/${resource.ID}/staff`}
            exact
            onClick={closeMenu}
            label="Staff"
          />
        )}
        {isAdminRecep && (
          <LinkItem
            to={`/admin/locs/${resource.ID}/clients`}
            exact
            onClick={closeMenu}
            label="Clients"
          />
        )}
        {isAdminRecep && (
          <LinkItem
            to={`/admin/locs/${resource.ID}/accounts`}
            exact
            onClick={closeMenu}
            label="Accounts"
          />
        )}
        {isAdminRecep && (
          <LinkItem
            to={`/admin/locs/${resource.ID}/edit`}
            exact
            onClick={closeMenu}
            label="Settings"
          />
        )}
      </>
    );
  });

  render() {
    const { closeMenu, containerStyles, innerRef, userContext, userOrg } = this.props;
    const isOrgAdminRecep =
      (userOrg.Roles || []).indexOf("Admin") >= 0 ||
      (userOrg.Roles || []).indexOf("Receptionist") >= 0;
    return (
      <Container style={containerStyles} ref={innerRef}>
        <Content>
          <LinkItem
            to={`/admin/orgs/${userContext.OrgID}/locations`}
            onClick={closeMenu}
            label="All Locations"
          />
          {isOrgAdminRecep && (
            <LinkItem to={`/admin/orgs/${userContext.OrgID}/staff`} label="All Staff" />
          )}
          {isOrgAdminRecep && (
            <LinkItem to={`/admin/orgs/${userContext.OrgID}/accounts`} label="All Accounts" />
          )}
          {isOrgAdminRecep && (
            <LinkItem to={`/admin/orgs/${userContext.OrgID}/clients`} label="All Clients" />
          )}
          <Route
            path="/admin/locs/:lid"
            render={(p) => (
              <ResourceRender
                type="Location"
                ids={p.match.params.lid}
                denorm={true}
                compSuccess={this.locationsNav}
                forwardedProps={{ closeMenu, isOrgAdminRecep }}
              />
            )}
          />
        </Content>
      </Container>
    );
  }
}

const ScrollBox = styled.div`
  display: block;
  width: 100%;
  overflow-y: scroll;
  max-height: 500px;
`;

function mapStateToProps(state, props) {
  const { global } = state;
  const authUser = getAuthenticatedUser(state);
  return {
    authUser,
    org: !global.UserContext.OrgID
      ? null
      : getResource(state.resources, "Organisation", global.UserContext.OrgID),
    userOrg:
      !global.UserContext.OrgID || !authUser
        ? null
        : getResource(state.resources, "OrganisationUser", {
            Org: global.UserContext.OrgID,
            User: authUser.ID
          }),
    userContext: global.UserContext
  };
}

export const UserMenu = compose(withRouter, connect(mapStateToProps))(UnconnectedUserMenu);

export const OrgMenu = compose(withRouter, connect(mapStateToProps))(UnconnectedOrgMenu);
