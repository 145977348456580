/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: category.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var media_pb = require('./media_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.proto = require('./category_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.CategoryServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.CategoryServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetCategoriesRequest,
 *   !proto.proto.GetCategoriesResponse>}
 */
const methodDescriptor_CategoryService_GetChildCategories = new grpc.web.MethodDescriptor(
  '/proto.CategoryService/GetChildCategories',
  grpc.web.MethodType.UNARY,
  proto.proto.GetCategoriesRequest,
  proto.proto.GetCategoriesResponse,
  /**
   * @param {!proto.proto.GetCategoriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetCategoriesResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GetCategoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetCategoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CategoryServiceClient.prototype.getChildCategories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CategoryService/GetChildCategories',
      request,
      metadata || {},
      methodDescriptor_CategoryService_GetChildCategories,
      callback);
};


/**
 * @param {!proto.proto.GetCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetCategoriesResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CategoryServicePromiseClient.prototype.getChildCategories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CategoryService/GetChildCategories',
      request,
      metadata || {},
      methodDescriptor_CategoryService_GetChildCategories);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.proto.GetCategoriesResponse>}
 */
const methodDescriptor_CategoryService_GetParents = new grpc.web.MethodDescriptor(
  '/proto.CategoryService/GetParents',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.proto.GetCategoriesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetCategoriesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GetCategoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetCategoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CategoryServiceClient.prototype.getParents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CategoryService/GetParents',
      request,
      metadata || {},
      methodDescriptor_CategoryService_GetParents,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetCategoriesResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CategoryServicePromiseClient.prototype.getParents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CategoryService/GetParents',
      request,
      metadata || {},
      methodDescriptor_CategoryService_GetParents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.Category,
 *   !proto.proto.Category>}
 */
const methodDescriptor_CategoryService_NewCategory = new grpc.web.MethodDescriptor(
  '/proto.CategoryService/NewCategory',
  grpc.web.MethodType.UNARY,
  proto.proto.Category,
  proto.proto.Category,
  /**
   * @param {!proto.proto.Category} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Category.deserializeBinary
);


/**
 * @param {!proto.proto.Category} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.Category)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Category>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CategoryServiceClient.prototype.newCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CategoryService/NewCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_NewCategory,
      callback);
};


/**
 * @param {!proto.proto.Category} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Category>}
 *     Promise that resolves to the response
 */
proto.proto.CategoryServicePromiseClient.prototype.newCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CategoryService/NewCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_NewCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetCategoryRequest,
 *   !proto.proto.Category>}
 */
const methodDescriptor_CategoryService_GetCategory = new grpc.web.MethodDescriptor(
  '/proto.CategoryService/GetCategory',
  grpc.web.MethodType.UNARY,
  proto.proto.GetCategoryRequest,
  proto.proto.Category,
  /**
   * @param {!proto.proto.GetCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Category.deserializeBinary
);


/**
 * @param {!proto.proto.GetCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.Category)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Category>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CategoryServiceClient.prototype.getCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CategoryService/GetCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_GetCategory,
      callback);
};


/**
 * @param {!proto.proto.GetCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Category>}
 *     Promise that resolves to the response
 */
proto.proto.CategoryServicePromiseClient.prototype.getCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CategoryService/GetCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_GetCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateCategoryRequest,
 *   !proto.proto.Category>}
 */
const methodDescriptor_CategoryService_UpdateCategory = new grpc.web.MethodDescriptor(
  '/proto.CategoryService/UpdateCategory',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateCategoryRequest,
  proto.proto.Category,
  /**
   * @param {!proto.proto.UpdateCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.Category.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.Category)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.Category>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CategoryServiceClient.prototype.updateCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CategoryService/UpdateCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_UpdateCategory,
      callback);
};


/**
 * @param {!proto.proto.UpdateCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.Category>}
 *     Promise that resolves to the response
 */
proto.proto.CategoryServicePromiseClient.prototype.updateCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CategoryService/UpdateCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_UpdateCategory);
};


module.exports = proto.proto;

