import { EntityStore } from "./entity-store";
import { rpc, metadata } from "../grpc/grpc-legacy";
import {
  AccountRow,
  GetAccountTableRequest,
  ListAccountTableResponse,
  FiltersAccountTable
} from "sdk/dist/accounts_table_pb";
import { observable, action } from "mobx";
import { AccountItem } from "sdk/dist/account_items_pb";
import { toastStore } from "./toast-store";
import { Filters } from "react-table";
import { setAccountFilterValues } from "../util/accounts-table-filters";
import { timezone } from "../util/timestamp";

class AccTableStore extends EntityStore<AccountRow.AsObject> {
  @observable
  private activeTab = 0;

  @observable
  private accType: Set<AccountItem.Type> = new Set([AccountItem.Type.ACC_ITEM_TYPE_UNSPECIFIED]);

  async loadFromLocation(
    filters: Filters<AccountRow.AsObject>,
    pageSize: number,
    pageIndex: number
  ): Promise<ListAccountTableResponse.AsObject> {
    return new Promise(async (resolve, reject) => {
      const req = new GetAccountTableRequest();
      const filtersValue = setAccountFilterValues(filters);
      req.setPageSize(pageSize);
      req.setPageNo(pageIndex);
      req.setFilters(filtersValue);

      try {
        const res = await rpc.accountTable.getBookings(req, metadata());
        const response = new ListAccountTableResponse().toObject();

        response.accountrowsList = res.toObject().accountrowsList;
        response.totalRowCount = res.getTotalRowCount();

        resolve(response);
      } catch (e) {
        toastStore.grpcError(e);
        reject(e);
      }
    });
  }

  async loadFromOrganisation(
    filters: Filters<AccountRow.AsObject>,
    pageSize: number,
    pageIndex: number
  ): Promise<ListAccountTableResponse.AsObject> {
    return new Promise(async (resolve, reject) => {
      const req = new GetAccountTableRequest();
      const filtersValue = setAccountFilterValues(filters);

      req.setPageSize(pageSize);
      req.setPageNo(pageIndex);
      req.setFilters(filtersValue);

      try {
        const res = await rpc.accountTable.getLyfePaymentsAndBookings(req, metadata());
        const response = new ListAccountTableResponse().toObject();

        response.accountrowsList = res.toObject().accountrowsList;
        response.totalRowCount = res.getTotalRowCount();
        resolve(response);
      } catch (e) {
        toastStore.grpcError(e);
        reject(e);
      }
    });
  }

  async loadFromBooking(bookingId: string) {
    const req = new GetAccountTableRequest();
    const filters = new FiltersAccountTable();
    filters.setBookingId(bookingId);
    filters.setTimezone(timezone());
    req.setFilters(filters);
    try {
      const res = await rpc.accountTable.getBookings(req, metadata());
      if (res.toObject().accountrowsList.length > 0) {
        this.add(res.toObject().accountrowsList[0]);
      }
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  getAccType() {
    return Array.from(this.accType);
  }

  @action
  setNewAccType(accType: AccountItem.Type) {
    this.accType.clear();
    this.accType.add(accType);
  }

  @action
  addAccType(accType: AccountItem.Type) {
    this.accType.add(accType);
  }

  getActiveTab() {
    return this.activeTab;
  }

  @action
  setActiveTab(tab: number) {
    this.activeTab = tab;
  }
}

export const accTableStore = new AccTableStore();
