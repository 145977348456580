import { useState } from "react";
import FormHeader from "../../form/FormHeader";
import Section from "../../form/Section";
import SectionsContainer from "../../form/SectionsContainer";
import LoadingButton from "../../LoadingButton";
import FooterContainer from "../../form/FooterContainer";
import { toastStore } from "../../../stores/toast-store";
import { CorporateMembershipStatusDTO } from "../../../ts-sdk/dist/organisation";
import { useGrpc } from "../../../hooks/useGrpc";
import { useAsync } from "../../../hooks/useAsync";
import { CircularProgress } from "@material-ui/core";

interface CorporateWellnessProgramProps {
  orgID: string;
}

export function CorporateWellnessProgram(props: CorporateWellnessProgramProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { services } = useGrpc();

  const organisationFetcher = useAsync(async () => {
    const res = await services.organisationService.GetOrganisation({
      organisationId: props.orgID
    });
    return res.organisation;
  }, [props.orgID]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await services.organisationService.UpdateOrgCorporateMembershipStatus({
        organisationId: props.orgID,
        corporateMembershipStatus: CorporateMembershipStatusDTO.FORM_SUBMITTED_STATUS
      });
      organisationFetcher.refresh();
      toastStore.success("Application has been submitted.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!organisationFetcher.value) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <>
      <FormHeader title="Corporate Wellness Program" />
      <SectionsContainer>
        {organisationFetcher.value.corporateMembershipStatus ===
        CorporateMembershipStatusDTO.NOT_REGISTERED_STATUS ? (
          <Section>
            <SectionsContainer>
              <p>Who is Lyfe?</p>
              <p>
                Lyfe is an Australian enterprise SaaS that allows corporate clients to provide their
                employees with a wellness program that suits their needs and goals. Employees can
                improve their health and wellbeing WHERE they want, WHEN they want, and HOW they
                want.
              </p>
              <p>
                As a provider of corporate wellness solutions, you can help empowering businesses to
                create a culture of wellbeing that fosters happier, more engaged, and productive
                employees.
              </p>
              <p style={{ paddingTop: "10px" }}>
                <b>
                  Generate increased revenue for your fitness & wellness business by partnering with
                  us to gain access to corporate clients.
                </b>
              </p>
            </SectionsContainer>
            <FooterContainer>
              <LoadingButton
                style={{ width: 150 }}
                loading={isSubmitting}
                variant="contained"
                color="secondary"
                type="submit"
                onClick={handleSubmit}
              >
                Apply Now
              </LoadingButton>
            </FooterContainer>
          </Section>
        ) : organisationFetcher.value.corporateMembershipStatus ===
          CorporateMembershipStatusDTO.FORM_SUBMITTED_STATUS ? (
          <Section>
            Thank you for submitting your application. We will be in touch with you shortly.
          </Section>
        ) : organisationFetcher.value.corporateMembershipStatus === CorporateMembershipStatusDTO.INACTIVE_STATUS ? (
          <Section>
            The Corporate Wellness Program status for this organisation is inactive, please contact
            Lyfe admin for further support.
          </Section>
        ) : (
          organisationFetcher.value.corporateMembershipStatus === CorporateMembershipStatusDTO.ACTIVE_STATUS && (
            <Section>
              <p>Congratulations!</p>
              <p>
                Your application to be a partner in our corporate wellness program has been
                approved. We appreciate your interest in working with us.
              </p>
            </Section>
          )
        )}
      </SectionsContainer>
    </>
  );
}
