import { ReduxTypes } from "ReduxTypes";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userStore } from "../../mobx/stores/UserStore";
import * as ReduxDialogs from "../../redux/features/dialogs";
import { APITokenType } from "../../redux/services/api";
import { sharedBookingService } from "../../services/BookingService";
import { toastStore } from "../../stores/toast-store";
import { AsyncButton } from "../../ui-kit/AsyncButton";
import { Container } from "../../ui-kit/Container";
import { FlexBox, FlexItem } from "../../ui-kit/FlexBox";
import { Stack } from "../../ui-kit/Stack";
import { Typography } from "../../ui-kit/Typography";
import { durationToText } from "../../util/duration";
import { toDateTz } from "../../util/timestamp";
import Section from "../form/Section";
import Dialog from "./Dialog";
import { LocationUserDTO, ProviderOfferingDTO } from "../../ts-sdk/dist/location_user";
import { BookingDTO } from "../../ts-sdk/dist/bookings";
import { BookingSearchResponse } from "../../ts-sdk/dist/location";
import {
  OnPeakHours,
  OnPeakHoursTimeFrame
} from "../../ts-sdk/dist/location_corporate_membership_setting";
import money from "../../util/money";

export interface BookServiceDialogProps {
  data: {
    booking: BookingDTO;
    service: ProviderOfferingDTO;
    provider: LocationUserDTO;
    searchResults: BookingSearchResponse;
    cb?: () => void;
    fetchData: () => Promise<void>;
  };
  dialogId?: any;
}

export const BookServiceDialog: React.FC<BookServiceDialogProps> = observer((props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bookingConfirmed, setBookingConfirmed] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const auth = useSelector((state: ReduxTypes.RootState) => state.auth);

  const [discountPercentage, _] = getDiscountInformation(props.data.service, props.data.booking);

  const closeDialog = () => {
    if (!isLoading) {
      dispatch(ReduxDialogs.actions.close(props.dialogId));
    }
  };

  const processBooking = async (bookingId: string) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    try {
      const res = await sharedBookingService.create(bookingId);
      if (userStore.user && res.user) {
        userStore.setUser({ ...userStore.user, corporateUser: res.user.corporateUser });
      }
      setBookingConfirmed(true);
      toastStore.success(
        `Booking no ${
          res.booking!.friendlyId
        } successfully created, you"ll receive an email soon with the booking details`
      );
    } catch (err) {
      toastStore.grpcError(err);
    } finally {
      setIsLoading(false);
    }
    props.data.fetchData();
  };

  const getStartDate = () => {
    return props.data.booking.startDatetime
      ? toDateTz(props.data.booking.startDatetime).format("DD/MM/YYYY")
      : "";
  };

  const getStartTime = () => {
    return props.data.booking.startDatetime
      ? toDateTz(props.data.booking.startDatetime).format("HH:mma")
      : "";
  };

  const getEndTime = () => {
    return props.data.booking.endDatetime
      ? toDateTz(props.data.booking.endDatetime).format("HH:mma")
      : "";
  };

  const getTotal = () => {
    let total = "";
    if (
      props.data.booking.total &&
      props.data.booking.total!.amount &&
      props.data.booking.total!.amount!.currencyCode &&
      props.data.booking.total!.tax &&
      props.data.booking.total!.amount
    ) {
      let totalCur = money.add(
        props.data.booking.total!.amount!.currencyCode,
        props.data.booking.total!.tax,
        props.data.booking.total!.amount
      );
      total = (money.moneyToFloat(totalCur) * (1 - discountPercentage / 100)).toFixed(2);
    }
    return total;
  };

  return (
    <Dialog dialogId={props.dialogId} overideCloseDialog={closeDialog} noClose>
      <Container>
        {props.data.booking && (
          <Section>
            <Typography.H1 noPadding>
              {!bookingConfirmed ? "Do you want to book this appointment?" : "Booking Confirmed"}
            </Typography.H1>
            <Typography.H3>To</Typography.H3>
            <Typography.Body noPadding>
              Name:{" "}
              {userStore.isLoggedIn() && userStore.user !== undefined
                ? `${userStore.user.firstName || ""} ${userStore.user.lastName || ""}`
                : `Unspecified`}
            </Typography.Body>
            <Typography.Body noPadding>
              Email:{" "}
              {userStore.isLoggedIn() && userStore.user !== undefined
                ? userStore.user.email
                : `Unspecified`}
            </Typography.Body>

            <Typography.H3>Provider</Typography.H3>
            <Typography.Body noPadding>{`Name: ${
              props.data.provider.firstName + " " + props.data.provider.lastName
            }`}</Typography.Body>
            <Typography.Body
              noPadding
            >{`Address: ${props.data.searchResults.address}`}</Typography.Body>
            <Typography.H3>Booking</Typography.H3>
            {props.data.booking.friendlyId.length > 0 && (
              <Typography.Body
                noPadding
              >{`Booking No: ${props.data.booking.friendlyId}`}</Typography.Body>
            )}
            <Typography.Body noPadding>Date: {getStartDate()}</Typography.Body>
            <Typography.Body noPadding>Start Time: {getStartTime()}</Typography.Body>
            <Typography.Body noPadding>End Time: {getEndTime()}</Typography.Body>
            <Typography.Body noPadding>
              Duration: {durationToText(props.data.booking.duration)}
            </Typography.Body>
            <Typography.H3>Services</Typography.H3>
            <table className="table-fixed text-right">
              <thead>
                <tr>
                  <th className="w-4/12 text-left">Name</th>
                  <th className="w-1/12">Amount</th>
                  <th className="w-2/12 text-center">Tax</th>
                  <th className="w-3/12">Total</th>
                </tr>
              </thead>
              <tbody>
                {props.data.booking.offerings.length > 0 &&
                  props.data.booking.offerings.map((offering, key) => {
                    let amount, tax;
                    if (
                      offering.lineItem &&
                      offering.lineItem.offering &&
                      offering.lineItem.offering.fee &&
                      offering.lineItem.offering.fee.amount &&
                      offering.lineItem.offering.fee.tax
                    ) {
                      let total =
                        money.moneyToFloat(
                          money.add(
                            props.data.booking.total!.amount!.currencyCode,
                            offering.lineItem.offering.fee.amount,
                            offering.lineItem.offering.fee.tax
                          )
                        ) *
                        (1 - discountPercentage / 100);
                      tax = (
                        money.moneyToFloat(offering.lineItem.offering.fee.tax) *
                        (1 - discountPercentage / 100)
                      ).toFixed(2);
                      amount = (total - parseFloat(tax)).toFixed(2);
                    }
                    return (
                      <tr key={key}>
                        <td className="text-left">{`${offering.lineItem!.offering!.name} ${
                          discountPercentage > 0 ? " - " + discountPercentage + "% off" : ""
                        }`}</td>
                        <td>{amount ? amount : ""}</td>
                        <td>{tax ? tax : ""}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                <tr className="border-t-2">
                  <td className="text-left">Total</td>
                  <td>
                    {props.data.booking.total && props.data.booking.total.tax
                      ? (
                          parseFloat(getTotal()) -
                          parseFloat(
                            (
                              money.moneyToFloat(props.data.booking.total.tax) *
                              (1 - discountPercentage / 100)
                            ).toFixed(2)
                          )
                        ).toFixed(2)
                      : ""}
                  </td>
                  <td>
                    {props.data.booking.total && props.data.booking.total.tax
                      ? (
                          money.moneyToFloat(props.data.booking.total.tax) *
                          (1 - discountPercentage / 100)
                        ).toFixed(2)
                      : ""}
                  </td>
                  {<td>{getTotal()}</td>}
                </tr>
              </tbody>
            </table>
            <br />
            <Typography.Label weight="light" italics>
              *Charges only apply at the completion of the booking
            </Typography.Label>
          </Section>
        )}
        {auth!.Type === APITokenType.Client && (
          <Typography.Label color="danger" weight="light" italics>
            *You need to login/signup to be able to join this class
          </Typography.Label>
        )}

        {!bookingConfirmed ? (
          <Stack direction="col" gap={[3, 3]} span={2}>
            <Stack.Item direction="col" span={2}>
              <AsyncButton.Action
                onClick={closeDialog}
                isDisabled={isLoading}
                rounded="full"
                size="md"
                fullWidth
              >
                CANCEL
              </AsyncButton.Action>
            </Stack.Item>
            {auth !== undefined && auth.Type.toLowerCase() === "client" && (
              <>
                <Stack.Item direction="col" span={1}>
                  <AsyncButton.PrimaryGradient
                    onClick={() =>
                      dispatch(
                        ReduxDialogs.openAccount(
                          ref.current,
                          "login",
                          { disableRedirection: true },
                          () => {}
                        )
                      )
                    }
                    rounded="full"
                    size="md"
                    fullWidth
                  >
                    LOGIN
                  </AsyncButton.PrimaryGradient>
                </Stack.Item>
                <Stack.Item direction="col" span={1}>
                  <AsyncButton.PrimaryGradient
                    onClick={() =>
                      dispatch(
                        ReduxDialogs.openAccount(
                          ref.current,
                          "signup",
                          { disableRedirection: true },
                          () => {}
                        )
                      )
                    }
                    rounded="full"
                    size="md"
                    fullWidth
                  >
                    SIGN UP
                  </AsyncButton.PrimaryGradient>
                </Stack.Item>
              </>
            )}
            <Stack.Item direction="col" span={2}>
              <AsyncButton.PrimaryGradient
                onClick={() => processBooking(props.data.booking.id)}
                isDisabled={auth!.Type === APITokenType.Client}
                rounded="full"
                size="md"
                fullWidth
              >
                BOOK
              </AsyncButton.PrimaryGradient>
            </Stack.Item>
          </Stack>
        ) : (
          <FlexBox direction="col" spacing={[0, 3]}>
            <FlexItem>
              <AsyncButton.Action
                onClick={closeDialog}
                isDisabled={auth!.Type === APITokenType.Client}
                rounded="full"
                size="md"
                fullWidth
              >
                CLOSE
              </AsyncButton.Action>
            </FlexItem>
          </FlexBox>
        )}
      </Container>
    </Dialog>
  );
});

export function getDiscountInformation(
  service: ProviderOfferingDTO,
  booking: BookingDTO
): [number, number] {
  let isOnPeakHoursBooking: boolean = false;
  let discountPercentage: number = 0;
  let discountedFee: number = 0;
  if (
    service.locationCorporateMembershipSetting &&
    service.locationCorporateMembershipSetting.id !== "" &&
    booking.startDatetime
  ) {
    // If there is no on-peak hours defined, use off-peak hours discount percentage
    if (!service.locationCorporateMembershipSetting.onPeakHours) {
      discountPercentage = service.locationCorporateMembershipSetting.offPeakDiscountPercentage;
      return [
        discountPercentage,
        parseFloat((parseFloat(service.fee) * (1 - discountPercentage / 100)).toFixed(2))
      ];
    }

    let onPeakHours: OnPeakHours = service.locationCorporateMembershipSetting.onPeakHours;
    let daySchedule = getOnPeakHoursDay(onPeakHours, toDateTz(booking.startDatetime));
    let bookingStartTimeTotalMins =
      toDateTz(booking.startDatetime).minute() + toDateTz(booking.startDatetime).hour() * 60;

    daySchedule.forEach((day) => {
      let startHour = parseInt(day.Start.split(":")[0]);
      let startMinute = parseInt(day.Start.split(":")[1]);
      let endHour = parseInt(day.End.split(":")[0]);
      let endMinute = parseInt(day.End.split(":")[1]);
      let startTotalMins = startHour * 60 + startMinute;
      let endTotalMins = endHour * 60 + endMinute;
      if (startTotalMins <= bookingStartTimeTotalMins && bookingStartTimeTotalMins < endTotalMins) {
        isOnPeakHoursBooking = true;
      }
    });

    discountPercentage = isOnPeakHoursBooking
      ? service.locationCorporateMembershipSetting.onPeakDiscountPercentage
      : service.locationCorporateMembershipSetting.offPeakDiscountPercentage;
    discountedFee = parseFloat(
      (parseFloat(service.fee) * (1 - discountPercentage / 100)).toFixed(2)
    );
  }
  return [discountPercentage, discountedFee];
}

export function getOnPeakHoursDay(
  onPeakHoursSchedule: OnPeakHours,
  bookingStartDate: moment.Moment
): OnPeakHoursTimeFrame[] {
  let day = bookingStartDate.day(); // 0 is Sunday, 6 is Saturday
  let result: OnPeakHoursTimeFrame[] = [];
  if (Object.keys(onPeakHoursSchedule).length === 0 && onPeakHoursSchedule) {
    return result;
  }
  switch (day) {
    case 0:
      result = onPeakHoursSchedule.Sunday;
      break;
    case 1:
      result = onPeakHoursSchedule.Monday;
      break;
    case 2:
      result = onPeakHoursSchedule.Tuesday;
      break;
    case 3:
      result = onPeakHoursSchedule.Wednesday;
      break;
    case 4:
      result = onPeakHoursSchedule.Thursday;
      break;
    case 5:
      result = onPeakHoursSchedule.Friday;
      break;
    case 6:
      result = onPeakHoursSchedule.Saturday;
      break;
    default:
      result = [];
      break;
  }
  return result;
}
