import { Button } from "@material-ui/core";
import { useGrpc } from "../../../hooks/useGrpc";
import { toastStore } from "../../../stores/toast-store";

export function SeedData() {
  const { services } = useGrpc();

  const onClick = async () => {
    await services.corporateMembershipsService.AddSeedingData({});
    toastStore.success("Data is seeded.");
  };
  return (
    <Button variant="contained" onClick={onClick}>
      Seed Data
    </Button>
  );
}
