/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: location.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var media_pb = require('./media_pb.js')

var search_pb = require('./search_pb.js')

var location_user_pb = require('./location_user_pb.js')

var user_pb = require('./user_pb.js')

var currencies_pb = require('./currencies_pb.js')

var bookings_pb = require('./bookings_pb.js')

var location_corporate_membership_setting_pb = require('./location_corporate_membership_setting_pb.js')
const proto = {};
proto.proto = require('./location_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.LocationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.LocationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetLocationRequest,
 *   !proto.proto.GetLocationResponse>}
 */
const methodDescriptor_LocationService_Get = new grpc.web.MethodDescriptor(
  '/proto.LocationService/Get',
  grpc.web.MethodType.UNARY,
  proto.proto.GetLocationRequest,
  proto.proto.GetLocationResponse,
  /**
   * @param {!proto.proto.GetLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetLocationResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GetLocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetLocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LocationServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LocationService/Get',
      request,
      metadata || {},
      methodDescriptor_LocationService_Get,
      callback);
};


/**
 * @param {!proto.proto.GetLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetLocationResponse>}
 *     Promise that resolves to the response
 */
proto.proto.LocationServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LocationService/Get',
      request,
      metadata || {},
      methodDescriptor_LocationService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateLocationRequest,
 *   !proto.proto.UpdateLocationResponse>}
 */
const methodDescriptor_LocationService_Update = new grpc.web.MethodDescriptor(
  '/proto.LocationService/Update',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateLocationRequest,
  proto.proto.UpdateLocationResponse,
  /**
   * @param {!proto.proto.UpdateLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateLocationResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateLocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateLocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LocationServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LocationService/Update',
      request,
      metadata || {},
      methodDescriptor_LocationService_Update,
      callback);
};


/**
 * @param {!proto.proto.UpdateLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateLocationResponse>}
 *     Promise that resolves to the response
 */
proto.proto.LocationServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LocationService/Update',
      request,
      metadata || {},
      methodDescriptor_LocationService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetLocationProviderRequest,
 *   !proto.proto.GetLocationProviderResponse>}
 */
const methodDescriptor_LocationService_GetProviders = new grpc.web.MethodDescriptor(
  '/proto.LocationService/GetProviders',
  grpc.web.MethodType.UNARY,
  proto.proto.GetLocationProviderRequest,
  proto.proto.GetLocationProviderResponse,
  /**
   * @param {!proto.proto.GetLocationProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetLocationProviderResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetLocationProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GetLocationProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetLocationProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LocationServiceClient.prototype.getProviders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LocationService/GetProviders',
      request,
      metadata || {},
      methodDescriptor_LocationService_GetProviders,
      callback);
};


/**
 * @param {!proto.proto.GetLocationProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetLocationProviderResponse>}
 *     Promise that resolves to the response
 */
proto.proto.LocationServicePromiseClient.prototype.getProviders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LocationService/GetProviders',
      request,
      metadata || {},
      methodDescriptor_LocationService_GetProviders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateOnPeakHoursScheduleReq,
 *   !proto.proto.UpdateOnPeakHoursScheduleRes>}
 */
const methodDescriptor_LocationService_UpdateOnPeakHoursSchedule = new grpc.web.MethodDescriptor(
  '/proto.LocationService/UpdateOnPeakHoursSchedule',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateOnPeakHoursScheduleReq,
  proto.proto.UpdateOnPeakHoursScheduleRes,
  /**
   * @param {!proto.proto.UpdateOnPeakHoursScheduleReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateOnPeakHoursScheduleRes.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateOnPeakHoursScheduleReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateOnPeakHoursScheduleRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateOnPeakHoursScheduleRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LocationServiceClient.prototype.updateOnPeakHoursSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LocationService/UpdateOnPeakHoursSchedule',
      request,
      metadata || {},
      methodDescriptor_LocationService_UpdateOnPeakHoursSchedule,
      callback);
};


/**
 * @param {!proto.proto.UpdateOnPeakHoursScheduleReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateOnPeakHoursScheduleRes>}
 *     Promise that resolves to the response
 */
proto.proto.LocationServicePromiseClient.prototype.updateOnPeakHoursSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LocationService/UpdateOnPeakHoursSchedule',
      request,
      metadata || {},
      methodDescriptor_LocationService_UpdateOnPeakHoursSchedule);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.LocationBookingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.LocationBookingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.BookingSearchRequest,
 *   !proto.proto.BookingSearchResponse>}
 */
const methodDescriptor_LocationBookingService_BookingSearch = new grpc.web.MethodDescriptor(
  '/proto.LocationBookingService/BookingSearch',
  grpc.web.MethodType.UNARY,
  proto.proto.BookingSearchRequest,
  proto.proto.BookingSearchResponse,
  /**
   * @param {!proto.proto.BookingSearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingSearchResponse.deserializeBinary
);


/**
 * @param {!proto.proto.BookingSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingSearchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingSearchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LocationBookingServiceClient.prototype.bookingSearch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LocationBookingService/BookingSearch',
      request,
      metadata || {},
      methodDescriptor_LocationBookingService_BookingSearch,
      callback);
};


/**
 * @param {!proto.proto.BookingSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingSearchResponse>}
 *     Promise that resolves to the response
 */
proto.proto.LocationBookingServicePromiseClient.prototype.bookingSearch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LocationBookingService/BookingSearch',
      request,
      metadata || {},
      methodDescriptor_LocationBookingService_BookingSearch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.BookingSearchFiltersRequest,
 *   !proto.proto.BookingSearchFiltersResponse>}
 */
const methodDescriptor_LocationBookingService_BookingSearchFilters = new grpc.web.MethodDescriptor(
  '/proto.LocationBookingService/BookingSearchFilters',
  grpc.web.MethodType.UNARY,
  proto.proto.BookingSearchFiltersRequest,
  proto.proto.BookingSearchFiltersResponse,
  /**
   * @param {!proto.proto.BookingSearchFiltersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BookingSearchFiltersResponse.deserializeBinary
);


/**
 * @param {!proto.proto.BookingSearchFiltersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BookingSearchFiltersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BookingSearchFiltersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.LocationBookingServiceClient.prototype.bookingSearchFilters =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.LocationBookingService/BookingSearchFilters',
      request,
      metadata || {},
      methodDescriptor_LocationBookingService_BookingSearchFilters,
      callback);
};


/**
 * @param {!proto.proto.BookingSearchFiltersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BookingSearchFiltersResponse>}
 *     Promise that resolves to the response
 */
proto.proto.LocationBookingServicePromiseClient.prototype.bookingSearchFilters =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.LocationBookingService/BookingSearchFilters',
      request,
      metadata || {},
      methodDescriptor_LocationBookingService_BookingSearchFilters);
};


module.exports = proto.proto;

