import { ISOCurrencyName } from "../ts-sdk/dist/currencies";
import { Charge, Money } from "../ts-sdk/dist/money";
import { TaxType } from "../ts-sdk/dist/third_party_invoices";

class MoneyUtils {
  moneyZero(ccyIsoName: ISOCurrencyName): Money {
    return {
      currencyCode: ccyIsoName,
      units: 0,
      nanos: 0
    };
  }

  chargeZero(ccyIsoName: ISOCurrencyName): Charge {
    return {
      amount: this.moneyZero(ccyIsoName),
      tax: this.moneyZero(ccyIsoName)
    };
  }

  moneyToText(money: Money, showCurr: boolean): string {
    if (showCurr) {
      return `${this.CcyIsoNameToString(money.currencyCode)} $${this.moneyToFloat(money).toFixed(
        2
      )}`;
    }
    return `$${this.moneyToFloat(money).toFixed(2)}`;
  }

  chargeToText(charge: Charge): string {
    const total = this.add(charge.amount!.currencyCode, charge.amount!, charge.tax!);
    return this.moneyToText(total, false);
  }

  mul(a: Money, b: number, percentage: boolean): Money {
    const moneyA = this.moneyToFloat(a);
    var mul = moneyA * b;
    if (percentage) {
      mul = mul / 100;
    }
    return this.moneyFromFloat(a.currencyCode, mul);
  }

  add(ccyIsoName: ISOCurrencyName, ...values: Money[]): Money {
    return values.reduce((prev, curr) => this.addImpl(prev, curr), this.moneyZero(ccyIsoName));
  }

  addImpl(a: Money, b: Money): Money {
    const sum = this.moneyToFloat(a) + this.moneyToFloat(b);
    return this.moneyFromFloat(a.currencyCode, sum);
  }

  moneyFromFloat(ccyIsoName: ISOCurrencyName, float: number): Money {
    const units = Math.floor(float);
    // We round to only two digit number (for infinite or larger decimal places)
    // Then we do Math.pow to 9 from the round up function
    // Finally, we do a another Math floor to avoid new wrong decimal digits
    const nanos = Math.floor(parseFloat((float - units).toFixed(2)) * Math.pow(10, 9));
    return {
      currencyCode: ccyIsoName,
      units,
      nanos
    };
  }

  taxTypeToString(taxType: TaxType): string {
    switch (taxType) {
      case TaxType.INCLUDED:
        return "Included";
      case TaxType.EXCLUDED:
        return "Excluded";
      case TaxType.EXEMPT:
        return "Exempt";
      default:
        return "unknown";
    }
  }

  chargeFromFloat(ccyIsoName: ISOCurrencyName, float: number, taxType: TaxType): Charge {
    let amount = 0;
    let tax = 0;
    switch (taxType) {
      case TaxType.INCLUDED:
        amount = float / 1.1;
        tax = float - amount;
        break;
      case TaxType.EXCLUDED:
        amount = float;
        tax = float * 0.1; // 10% GST
        break;
      case TaxType.EXEMPT:
        amount = float;
        tax = 0;
        break;
      default:
        amount = float;
        tax = 0;
        break;
    }

    return {
      amount: this.moneyFromFloat(ccyIsoName, amount),
      tax: this.moneyFromFloat(ccyIsoName, tax)
    };
  }

  chargeToFloat(charge: Charge, desiredTaxType: TaxType): number {
    switch (desiredTaxType) {
      case TaxType.INCLUDED:
        return this.moneyToFloat(
          this.add(charge.amount!.currencyCode, charge.amount!, charge.tax!)
        );
      case TaxType.EXCLUDED:
        return this.moneyToFloat(this.add(charge.amount!.currencyCode, charge.amount!));
      case TaxType.EXEMPT:
        return this.moneyToFloat(this.add(charge.amount!.currencyCode, charge.amount!));
      default:
        return this.moneyToFloat(
          this.add(charge.amount!.currencyCode, charge.amount!, charge.tax!)
        );
    }
  }

  moneyToFloat(money: Money): number {
    const units = money.units;
    const nanos = money.nanos;
    return units + nanos * Math.pow(10, -9);
  }

  moneyToProto(money: Money): Money {
    const moneyMsg: Money = {
      currencyCode: money.currencyCode,
      units: money.units,
      nanos: money.nanos
    };
    return moneyMsg;
  }

  chargeToProto(charge: Charge): Charge {
    const chargeMsg: Charge = {
      amount: this.moneyToProto(charge.amount!),
      tax: this.moneyToProto(charge.tax!)
    };
    return chargeMsg;
  }

  CcyIsoNameToString = (ccyIsoName: ISOCurrencyName): string => {
    switch (ccyIsoName) {
      case ISOCurrencyName.AUD:
        return "AUD";
      case ISOCurrencyName.USD:
        return "USD";
      case ISOCurrencyName.CAD:
        return "CAD";
      case ISOCurrencyName.EUR:
        return "EUR";
      case ISOCurrencyName.CHF:
        return "CHF";
      case ISOCurrencyName.DKK:
        return "DKK";
      case ISOCurrencyName.GBP:
        return "GBP";
      case ISOCurrencyName.NOK:
        return "NOK";
      case ISOCurrencyName.NZD:
        return "NZD";
      case ISOCurrencyName.SEK:
        return "SEK";
      case ISOCurrencyName.SGD:
        return "SGD";
      default:
        return "XXX";
    }
  };
}

export default new MoneyUtils();
