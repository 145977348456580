/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { AccountItem } from "./account_items";
import { Timestamp } from "./google/protobuf/timestamp";
import { Media } from "./media";
import { Charge } from "./money";

export const protobufPackage = "proto";

export enum TaxType {
  INCLUDED = 0,
  EXCLUDED = 1,
  EXEMPT = 2,
  UNSPECIFIED = 3,
  UNRECOGNIZED = -1,
}

export function taxTypeFromJSON(object: any): TaxType {
  switch (object) {
    case 0:
    case "INCLUDED":
      return TaxType.INCLUDED;
    case 1:
    case "EXCLUDED":
      return TaxType.EXCLUDED;
    case 2:
    case "EXEMPT":
      return TaxType.EXEMPT;
    case 3:
    case "UNSPECIFIED":
      return TaxType.UNSPECIFIED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TaxType.UNRECOGNIZED;
  }
}

export function taxTypeToJSON(object: TaxType): string {
  switch (object) {
    case TaxType.INCLUDED:
      return "INCLUDED";
    case TaxType.EXCLUDED:
      return "EXCLUDED";
    case TaxType.EXEMPT:
      return "EXEMPT";
    case TaxType.UNSPECIFIED:
      return "UNSPECIFIED";
    case TaxType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ThirdPartyInvoice {
  id: string;
  details: ThirdPartyInvoiceDetails | undefined;
  status: ThirdPartyInvoice_Status;
  statusMessage: string;
  lastEmailAt: Date | undefined;
  media: Media | undefined;
  accountItem: AccountItem | undefined;
}

export enum ThirdPartyInvoice_Status {
  STATUS_UNSPECIFIED = 0,
  /** WAITING - pending means it's been created but not sent */
  WAITING = 1,
  /** SENT - complete means it has been emailed to the recipient */
  SENT = 2,
  UNRECOGNIZED = -1,
}

export function thirdPartyInvoice_StatusFromJSON(object: any): ThirdPartyInvoice_Status {
  switch (object) {
    case 0:
    case "STATUS_UNSPECIFIED":
      return ThirdPartyInvoice_Status.STATUS_UNSPECIFIED;
    case 1:
    case "WAITING":
      return ThirdPartyInvoice_Status.WAITING;
    case 2:
    case "SENT":
      return ThirdPartyInvoice_Status.SENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ThirdPartyInvoice_Status.UNRECOGNIZED;
  }
}

export function thirdPartyInvoice_StatusToJSON(object: ThirdPartyInvoice_Status): string {
  switch (object) {
    case ThirdPartyInvoice_Status.STATUS_UNSPECIFIED:
      return "STATUS_UNSPECIFIED";
    case ThirdPartyInvoice_Status.WAITING:
      return "WAITING";
    case ThirdPartyInvoice_Status.SENT:
      return "SENT";
    case ThirdPartyInvoice_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ThirdPartyInvoiceDetails {
  companyAbn: string;
  companyEmailAddress: string;
  companyName: string;
  providerId: string;
  claimId: string;
  lineItems: ThirdPartyInvoiceLineItem[];
}

export interface ThirdPartyInvoiceLineItem {
  code: string;
  description: string;
  charge: Charge | undefined;
  taxType: TaxType;
}

export interface ListThirdPartyInvoiceRequest {
  bookingId: string;
  clientId: string;
}

export interface ListThirdPartyInvoiceResponse {
  invoices: ThirdPartyInvoice[];
}

export interface CreateThirdPartyInvoiceRequest {
  bookingId: string;
  clientId: string;
  details: ThirdPartyInvoiceDetails | undefined;
}

export interface UpdateThirdPartyInvoiceRequest {
  thirdPartyInvoiceId: string;
  bookingId: string;
  clientId: string;
  details: ThirdPartyInvoiceDetails | undefined;
}

export interface DeleteThirdPartyInvoiceRequest {
  thirdPartyInvoiceId: string;
}

export interface SetPaidInvoiceRequest {
  thirdPartyInvoiceId: string;
  isPaid: boolean;
  details: ThirdPartyInvoiceDetails | undefined;
}

function createBaseThirdPartyInvoice(): ThirdPartyInvoice {
  return {
    id: "",
    details: undefined,
    status: 0,
    statusMessage: "",
    lastEmailAt: undefined,
    media: undefined,
    accountItem: undefined,
  };
}

export const ThirdPartyInvoice = {
  encode(message: ThirdPartyInvoice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.details !== undefined) {
      ThirdPartyInvoiceDetails.encode(message.details, writer.uint32(18).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.statusMessage !== "") {
      writer.uint32(34).string(message.statusMessage);
    }
    if (message.lastEmailAt !== undefined) {
      Timestamp.encode(toTimestamp(message.lastEmailAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.media !== undefined) {
      Media.encode(message.media, writer.uint32(50).fork()).ldelim();
    }
    if (message.accountItem !== undefined) {
      AccountItem.encode(message.accountItem, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ThirdPartyInvoice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseThirdPartyInvoice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.details = ThirdPartyInvoiceDetails.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.statusMessage = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastEmailAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.media = Media.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.accountItem = AccountItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ThirdPartyInvoice {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      details: isSet(object.details) ? ThirdPartyInvoiceDetails.fromJSON(object.details) : undefined,
      status: isSet(object.status) ? thirdPartyInvoice_StatusFromJSON(object.status) : 0,
      statusMessage: isSet(object.statusMessage) ? globalThis.String(object.statusMessage) : "",
      lastEmailAt: isSet(object.lastEmailAt) ? fromJsonTimestamp(object.lastEmailAt) : undefined,
      media: isSet(object.media) ? Media.fromJSON(object.media) : undefined,
      accountItem: isSet(object.accountItem) ? AccountItem.fromJSON(object.accountItem) : undefined,
    };
  },

  toJSON(message: ThirdPartyInvoice): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.details !== undefined) {
      obj.details = ThirdPartyInvoiceDetails.toJSON(message.details);
    }
    if (message.status !== 0) {
      obj.status = thirdPartyInvoice_StatusToJSON(message.status);
    }
    if (message.statusMessage !== "") {
      obj.statusMessage = message.statusMessage;
    }
    if (message.lastEmailAt !== undefined) {
      obj.lastEmailAt = message.lastEmailAt.toISOString();
    }
    if (message.media !== undefined) {
      obj.media = Media.toJSON(message.media);
    }
    if (message.accountItem !== undefined) {
      obj.accountItem = AccountItem.toJSON(message.accountItem);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ThirdPartyInvoice>, I>>(base?: I): ThirdPartyInvoice {
    return ThirdPartyInvoice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ThirdPartyInvoice>, I>>(object: I): ThirdPartyInvoice {
    const message = createBaseThirdPartyInvoice();
    message.id = object.id ?? "";
    message.details = (object.details !== undefined && object.details !== null)
      ? ThirdPartyInvoiceDetails.fromPartial(object.details)
      : undefined;
    message.status = object.status ?? 0;
    message.statusMessage = object.statusMessage ?? "";
    message.lastEmailAt = object.lastEmailAt ?? undefined;
    message.media = (object.media !== undefined && object.media !== null) ? Media.fromPartial(object.media) : undefined;
    message.accountItem = (object.accountItem !== undefined && object.accountItem !== null)
      ? AccountItem.fromPartial(object.accountItem)
      : undefined;
    return message;
  },
};

function createBaseThirdPartyInvoiceDetails(): ThirdPartyInvoiceDetails {
  return { companyAbn: "", companyEmailAddress: "", companyName: "", providerId: "", claimId: "", lineItems: [] };
}

export const ThirdPartyInvoiceDetails = {
  encode(message: ThirdPartyInvoiceDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.companyAbn !== "") {
      writer.uint32(18).string(message.companyAbn);
    }
    if (message.companyEmailAddress !== "") {
      writer.uint32(26).string(message.companyEmailAddress);
    }
    if (message.companyName !== "") {
      writer.uint32(34).string(message.companyName);
    }
    if (message.providerId !== "") {
      writer.uint32(42).string(message.providerId);
    }
    if (message.claimId !== "") {
      writer.uint32(50).string(message.claimId);
    }
    for (const v of message.lineItems) {
      ThirdPartyInvoiceLineItem.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ThirdPartyInvoiceDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseThirdPartyInvoiceDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.companyAbn = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.companyEmailAddress = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.companyName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.providerId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.claimId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lineItems.push(ThirdPartyInvoiceLineItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ThirdPartyInvoiceDetails {
    return {
      companyAbn: isSet(object.companyAbn) ? globalThis.String(object.companyAbn) : "",
      companyEmailAddress: isSet(object.companyEmailAddress) ? globalThis.String(object.companyEmailAddress) : "",
      companyName: isSet(object.companyName) ? globalThis.String(object.companyName) : "",
      providerId: isSet(object.providerId) ? globalThis.String(object.providerId) : "",
      claimId: isSet(object.claimId) ? globalThis.String(object.claimId) : "",
      lineItems: globalThis.Array.isArray(object?.lineItems)
        ? object.lineItems.map((e: any) => ThirdPartyInvoiceLineItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ThirdPartyInvoiceDetails): unknown {
    const obj: any = {};
    if (message.companyAbn !== "") {
      obj.companyAbn = message.companyAbn;
    }
    if (message.companyEmailAddress !== "") {
      obj.companyEmailAddress = message.companyEmailAddress;
    }
    if (message.companyName !== "") {
      obj.companyName = message.companyName;
    }
    if (message.providerId !== "") {
      obj.providerId = message.providerId;
    }
    if (message.claimId !== "") {
      obj.claimId = message.claimId;
    }
    if (message.lineItems?.length) {
      obj.lineItems = message.lineItems.map((e) => ThirdPartyInvoiceLineItem.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ThirdPartyInvoiceDetails>, I>>(base?: I): ThirdPartyInvoiceDetails {
    return ThirdPartyInvoiceDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ThirdPartyInvoiceDetails>, I>>(object: I): ThirdPartyInvoiceDetails {
    const message = createBaseThirdPartyInvoiceDetails();
    message.companyAbn = object.companyAbn ?? "";
    message.companyEmailAddress = object.companyEmailAddress ?? "";
    message.companyName = object.companyName ?? "";
    message.providerId = object.providerId ?? "";
    message.claimId = object.claimId ?? "";
    message.lineItems = object.lineItems?.map((e) => ThirdPartyInvoiceLineItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseThirdPartyInvoiceLineItem(): ThirdPartyInvoiceLineItem {
  return { code: "", description: "", charge: undefined, taxType: 0 };
}

export const ThirdPartyInvoiceLineItem = {
  encode(message: ThirdPartyInvoiceLineItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.charge !== undefined) {
      Charge.encode(message.charge, writer.uint32(26).fork()).ldelim();
    }
    if (message.taxType !== 0) {
      writer.uint32(32).int32(message.taxType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ThirdPartyInvoiceLineItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseThirdPartyInvoiceLineItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.charge = Charge.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.taxType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ThirdPartyInvoiceLineItem {
    return {
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      charge: isSet(object.charge) ? Charge.fromJSON(object.charge) : undefined,
      taxType: isSet(object.taxType) ? taxTypeFromJSON(object.taxType) : 0,
    };
  },

  toJSON(message: ThirdPartyInvoiceLineItem): unknown {
    const obj: any = {};
    if (message.code !== "") {
      obj.code = message.code;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.charge !== undefined) {
      obj.charge = Charge.toJSON(message.charge);
    }
    if (message.taxType !== 0) {
      obj.taxType = taxTypeToJSON(message.taxType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ThirdPartyInvoiceLineItem>, I>>(base?: I): ThirdPartyInvoiceLineItem {
    return ThirdPartyInvoiceLineItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ThirdPartyInvoiceLineItem>, I>>(object: I): ThirdPartyInvoiceLineItem {
    const message = createBaseThirdPartyInvoiceLineItem();
    message.code = object.code ?? "";
    message.description = object.description ?? "";
    message.charge = (object.charge !== undefined && object.charge !== null)
      ? Charge.fromPartial(object.charge)
      : undefined;
    message.taxType = object.taxType ?? 0;
    return message;
  },
};

function createBaseListThirdPartyInvoiceRequest(): ListThirdPartyInvoiceRequest {
  return { bookingId: "", clientId: "" };
}

export const ListThirdPartyInvoiceRequest = {
  encode(message: ListThirdPartyInvoiceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListThirdPartyInvoiceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListThirdPartyInvoiceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListThirdPartyInvoiceRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: ListThirdPartyInvoiceRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListThirdPartyInvoiceRequest>, I>>(base?: I): ListThirdPartyInvoiceRequest {
    return ListThirdPartyInvoiceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListThirdPartyInvoiceRequest>, I>>(object: I): ListThirdPartyInvoiceRequest {
    const message = createBaseListThirdPartyInvoiceRequest();
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseListThirdPartyInvoiceResponse(): ListThirdPartyInvoiceResponse {
  return { invoices: [] };
}

export const ListThirdPartyInvoiceResponse = {
  encode(message: ListThirdPartyInvoiceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.invoices) {
      ThirdPartyInvoice.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListThirdPartyInvoiceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListThirdPartyInvoiceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.invoices.push(ThirdPartyInvoice.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListThirdPartyInvoiceResponse {
    return {
      invoices: globalThis.Array.isArray(object?.invoices)
        ? object.invoices.map((e: any) => ThirdPartyInvoice.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListThirdPartyInvoiceResponse): unknown {
    const obj: any = {};
    if (message.invoices?.length) {
      obj.invoices = message.invoices.map((e) => ThirdPartyInvoice.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListThirdPartyInvoiceResponse>, I>>(base?: I): ListThirdPartyInvoiceResponse {
    return ListThirdPartyInvoiceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListThirdPartyInvoiceResponse>, I>>(
    object: I,
  ): ListThirdPartyInvoiceResponse {
    const message = createBaseListThirdPartyInvoiceResponse();
    message.invoices = object.invoices?.map((e) => ThirdPartyInvoice.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateThirdPartyInvoiceRequest(): CreateThirdPartyInvoiceRequest {
  return { bookingId: "", clientId: "", details: undefined };
}

export const CreateThirdPartyInvoiceRequest = {
  encode(message: CreateThirdPartyInvoiceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    if (message.details !== undefined) {
      ThirdPartyInvoiceDetails.encode(message.details, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateThirdPartyInvoiceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateThirdPartyInvoiceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.details = ThirdPartyInvoiceDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateThirdPartyInvoiceRequest {
    return {
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      details: isSet(object.details) ? ThirdPartyInvoiceDetails.fromJSON(object.details) : undefined,
    };
  },

  toJSON(message: CreateThirdPartyInvoiceRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.details !== undefined) {
      obj.details = ThirdPartyInvoiceDetails.toJSON(message.details);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateThirdPartyInvoiceRequest>, I>>(base?: I): CreateThirdPartyInvoiceRequest {
    return CreateThirdPartyInvoiceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateThirdPartyInvoiceRequest>, I>>(
    object: I,
  ): CreateThirdPartyInvoiceRequest {
    const message = createBaseCreateThirdPartyInvoiceRequest();
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    message.details = (object.details !== undefined && object.details !== null)
      ? ThirdPartyInvoiceDetails.fromPartial(object.details)
      : undefined;
    return message;
  },
};

function createBaseUpdateThirdPartyInvoiceRequest(): UpdateThirdPartyInvoiceRequest {
  return { thirdPartyInvoiceId: "", bookingId: "", clientId: "", details: undefined };
}

export const UpdateThirdPartyInvoiceRequest = {
  encode(message: UpdateThirdPartyInvoiceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.thirdPartyInvoiceId !== "") {
      writer.uint32(10).string(message.thirdPartyInvoiceId);
    }
    if (message.bookingId !== "") {
      writer.uint32(18).string(message.bookingId);
    }
    if (message.clientId !== "") {
      writer.uint32(26).string(message.clientId);
    }
    if (message.details !== undefined) {
      ThirdPartyInvoiceDetails.encode(message.details, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateThirdPartyInvoiceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateThirdPartyInvoiceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.thirdPartyInvoiceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.details = ThirdPartyInvoiceDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateThirdPartyInvoiceRequest {
    return {
      thirdPartyInvoiceId: isSet(object.thirdPartyInvoiceId) ? globalThis.String(object.thirdPartyInvoiceId) : "",
      bookingId: isSet(object.bookingId) ? globalThis.String(object.bookingId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      details: isSet(object.details) ? ThirdPartyInvoiceDetails.fromJSON(object.details) : undefined,
    };
  },

  toJSON(message: UpdateThirdPartyInvoiceRequest): unknown {
    const obj: any = {};
    if (message.thirdPartyInvoiceId !== "") {
      obj.thirdPartyInvoiceId = message.thirdPartyInvoiceId;
    }
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.details !== undefined) {
      obj.details = ThirdPartyInvoiceDetails.toJSON(message.details);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateThirdPartyInvoiceRequest>, I>>(base?: I): UpdateThirdPartyInvoiceRequest {
    return UpdateThirdPartyInvoiceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateThirdPartyInvoiceRequest>, I>>(
    object: I,
  ): UpdateThirdPartyInvoiceRequest {
    const message = createBaseUpdateThirdPartyInvoiceRequest();
    message.thirdPartyInvoiceId = object.thirdPartyInvoiceId ?? "";
    message.bookingId = object.bookingId ?? "";
    message.clientId = object.clientId ?? "";
    message.details = (object.details !== undefined && object.details !== null)
      ? ThirdPartyInvoiceDetails.fromPartial(object.details)
      : undefined;
    return message;
  },
};

function createBaseDeleteThirdPartyInvoiceRequest(): DeleteThirdPartyInvoiceRequest {
  return { thirdPartyInvoiceId: "" };
}

export const DeleteThirdPartyInvoiceRequest = {
  encode(message: DeleteThirdPartyInvoiceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.thirdPartyInvoiceId !== "") {
      writer.uint32(10).string(message.thirdPartyInvoiceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteThirdPartyInvoiceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteThirdPartyInvoiceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.thirdPartyInvoiceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteThirdPartyInvoiceRequest {
    return {
      thirdPartyInvoiceId: isSet(object.thirdPartyInvoiceId) ? globalThis.String(object.thirdPartyInvoiceId) : "",
    };
  },

  toJSON(message: DeleteThirdPartyInvoiceRequest): unknown {
    const obj: any = {};
    if (message.thirdPartyInvoiceId !== "") {
      obj.thirdPartyInvoiceId = message.thirdPartyInvoiceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteThirdPartyInvoiceRequest>, I>>(base?: I): DeleteThirdPartyInvoiceRequest {
    return DeleteThirdPartyInvoiceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteThirdPartyInvoiceRequest>, I>>(
    object: I,
  ): DeleteThirdPartyInvoiceRequest {
    const message = createBaseDeleteThirdPartyInvoiceRequest();
    message.thirdPartyInvoiceId = object.thirdPartyInvoiceId ?? "";
    return message;
  },
};

function createBaseSetPaidInvoiceRequest(): SetPaidInvoiceRequest {
  return { thirdPartyInvoiceId: "", isPaid: false, details: undefined };
}

export const SetPaidInvoiceRequest = {
  encode(message: SetPaidInvoiceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.thirdPartyInvoiceId !== "") {
      writer.uint32(10).string(message.thirdPartyInvoiceId);
    }
    if (message.isPaid === true) {
      writer.uint32(16).bool(message.isPaid);
    }
    if (message.details !== undefined) {
      ThirdPartyInvoiceDetails.encode(message.details, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPaidInvoiceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPaidInvoiceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.thirdPartyInvoiceId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isPaid = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.details = ThirdPartyInvoiceDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetPaidInvoiceRequest {
    return {
      thirdPartyInvoiceId: isSet(object.thirdPartyInvoiceId) ? globalThis.String(object.thirdPartyInvoiceId) : "",
      isPaid: isSet(object.isPaid) ? globalThis.Boolean(object.isPaid) : false,
      details: isSet(object.details) ? ThirdPartyInvoiceDetails.fromJSON(object.details) : undefined,
    };
  },

  toJSON(message: SetPaidInvoiceRequest): unknown {
    const obj: any = {};
    if (message.thirdPartyInvoiceId !== "") {
      obj.thirdPartyInvoiceId = message.thirdPartyInvoiceId;
    }
    if (message.isPaid === true) {
      obj.isPaid = message.isPaid;
    }
    if (message.details !== undefined) {
      obj.details = ThirdPartyInvoiceDetails.toJSON(message.details);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetPaidInvoiceRequest>, I>>(base?: I): SetPaidInvoiceRequest {
    return SetPaidInvoiceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetPaidInvoiceRequest>, I>>(object: I): SetPaidInvoiceRequest {
    const message = createBaseSetPaidInvoiceRequest();
    message.thirdPartyInvoiceId = object.thirdPartyInvoiceId ?? "";
    message.isPaid = object.isPaid ?? false;
    message.details = (object.details !== undefined && object.details !== null)
      ? ThirdPartyInvoiceDetails.fromPartial(object.details)
      : undefined;
    return message;
  },
};

export interface ThirdPartyInvoices {
  List(
    request: DeepPartial<ListThirdPartyInvoiceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListThirdPartyInvoiceResponse>;
  Create(
    request: DeepPartial<CreateThirdPartyInvoiceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListThirdPartyInvoiceResponse>;
  Update(
    request: DeepPartial<UpdateThirdPartyInvoiceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListThirdPartyInvoiceResponse>;
  Delete(request: DeepPartial<DeleteThirdPartyInvoiceRequest>, metadata?: grpc.Metadata): Promise<ThirdPartyInvoice>;
  SetPaid(request: DeepPartial<SetPaidInvoiceRequest>, metadata?: grpc.Metadata): Promise<ThirdPartyInvoice>;
}

export class ThirdPartyInvoicesClientImpl implements ThirdPartyInvoices {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.List = this.List.bind(this);
    this.Create = this.Create.bind(this);
    this.Update = this.Update.bind(this);
    this.Delete = this.Delete.bind(this);
    this.SetPaid = this.SetPaid.bind(this);
  }

  List(
    request: DeepPartial<ListThirdPartyInvoiceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListThirdPartyInvoiceResponse> {
    return this.rpc.unary(ThirdPartyInvoicesListDesc, ListThirdPartyInvoiceRequest.fromPartial(request), metadata);
  }

  Create(
    request: DeepPartial<CreateThirdPartyInvoiceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListThirdPartyInvoiceResponse> {
    return this.rpc.unary(ThirdPartyInvoicesCreateDesc, CreateThirdPartyInvoiceRequest.fromPartial(request), metadata);
  }

  Update(
    request: DeepPartial<UpdateThirdPartyInvoiceRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListThirdPartyInvoiceResponse> {
    return this.rpc.unary(ThirdPartyInvoicesUpdateDesc, UpdateThirdPartyInvoiceRequest.fromPartial(request), metadata);
  }

  Delete(request: DeepPartial<DeleteThirdPartyInvoiceRequest>, metadata?: grpc.Metadata): Promise<ThirdPartyInvoice> {
    return this.rpc.unary(ThirdPartyInvoicesDeleteDesc, DeleteThirdPartyInvoiceRequest.fromPartial(request), metadata);
  }

  SetPaid(request: DeepPartial<SetPaidInvoiceRequest>, metadata?: grpc.Metadata): Promise<ThirdPartyInvoice> {
    return this.rpc.unary(ThirdPartyInvoicesSetPaidDesc, SetPaidInvoiceRequest.fromPartial(request), metadata);
  }
}

export const ThirdPartyInvoicesDesc = { serviceName: "proto.ThirdPartyInvoices" };

export const ThirdPartyInvoicesListDesc: UnaryMethodDefinitionish = {
  methodName: "List",
  service: ThirdPartyInvoicesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListThirdPartyInvoiceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListThirdPartyInvoiceResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ThirdPartyInvoicesCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: ThirdPartyInvoicesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateThirdPartyInvoiceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListThirdPartyInvoiceResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ThirdPartyInvoicesUpdateDesc: UnaryMethodDefinitionish = {
  methodName: "Update",
  service: ThirdPartyInvoicesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateThirdPartyInvoiceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListThirdPartyInvoiceResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ThirdPartyInvoicesDeleteDesc: UnaryMethodDefinitionish = {
  methodName: "Delete",
  service: ThirdPartyInvoicesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteThirdPartyInvoiceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ThirdPartyInvoice.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ThirdPartyInvoicesSetPaidDesc: UnaryMethodDefinitionish = {
  methodName: "SetPaid",
  service: ThirdPartyInvoicesDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetPaidInvoiceRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ThirdPartyInvoice.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
