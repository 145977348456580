/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { BoolValue } from "./google/protobuf/wrappers";

export const protobufPackage = "proto";

export enum UserStatusEnumDTO {
  USER_STATUS_ACTIVE = 0,
  USER_STATUS_DELETED = 1,
  UNRECOGNIZED = -1,
}

export function userStatusEnumDTOFromJSON(object: any): UserStatusEnumDTO {
  switch (object) {
    case 0:
    case "USER_STATUS_ACTIVE":
      return UserStatusEnumDTO.USER_STATUS_ACTIVE;
    case 1:
    case "USER_STATUS_DELETED":
      return UserStatusEnumDTO.USER_STATUS_DELETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserStatusEnumDTO.UNRECOGNIZED;
  }
}

export function userStatusEnumDTOToJSON(object: UserStatusEnumDTO): string {
  switch (object) {
    case UserStatusEnumDTO.USER_STATUS_ACTIVE:
      return "USER_STATUS_ACTIVE";
    case UserStatusEnumDTO.USER_STATUS_DELETED:
      return "USER_STATUS_DELETED";
    case UserStatusEnumDTO.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ListCorporateUsersRequest {
  corporateMembershipId: string;
}

export interface ListCorporateUsersResponse {
  data: CorporateUserDTO[];
}

export interface ListCorporateApprovedEmailsRequest {
  corporateMembershipId: string;
  activated: boolean | undefined;
}

export interface ListCorporateApprovedEmailsResponse {
  data: CorporateApprovedEmailDTO[];
}

export interface RemoveCorporateApprovedEmailRequest {
  email: string;
}

export interface RemoveCorporateApprovedEmailResponse {
}

export interface UpdateCorporateUserMemberRequest {
  corporateMembershipUserId: string;
  status: UserStatusEnumDTO;
}

export interface UpdateCorporateUserMemberResponse {
  data: CorporateUserDTO | undefined;
}

export interface ImportCorporateMembershipsCsvRequest {
  corporateMembershipUserId: string;
  csvMediaId: string;
}

export interface ImportCorporateMembershipsCsvResponse {
  usersAddedAmount: number;
}

export interface CorporateApprovedEmailDTO {
  email: string;
  corporateMembershipId: string;
  activated: boolean;
}

export interface CorporateUserDTO {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  remainingCredit: number;
  status: UserStatusEnumDTO;
}

function createBaseListCorporateUsersRequest(): ListCorporateUsersRequest {
  return { corporateMembershipId: "" };
}

export const ListCorporateUsersRequest = {
  encode(message: ListCorporateUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateMembershipId !== "") {
      writer.uint32(10).string(message.corporateMembershipId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCorporateUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCorporateUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateMembershipId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCorporateUsersRequest {
    return {
      corporateMembershipId: isSet(object.corporateMembershipId) ? globalThis.String(object.corporateMembershipId) : "",
    };
  },

  toJSON(message: ListCorporateUsersRequest): unknown {
    const obj: any = {};
    if (message.corporateMembershipId !== "") {
      obj.corporateMembershipId = message.corporateMembershipId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCorporateUsersRequest>, I>>(base?: I): ListCorporateUsersRequest {
    return ListCorporateUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCorporateUsersRequest>, I>>(object: I): ListCorporateUsersRequest {
    const message = createBaseListCorporateUsersRequest();
    message.corporateMembershipId = object.corporateMembershipId ?? "";
    return message;
  },
};

function createBaseListCorporateUsersResponse(): ListCorporateUsersResponse {
  return { data: [] };
}

export const ListCorporateUsersResponse = {
  encode(message: ListCorporateUsersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.data) {
      CorporateUserDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCorporateUsersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCorporateUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(CorporateUserDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCorporateUsersResponse {
    return {
      data: globalThis.Array.isArray(object?.data) ? object.data.map((e: any) => CorporateUserDTO.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListCorporateUsersResponse): unknown {
    const obj: any = {};
    if (message.data?.length) {
      obj.data = message.data.map((e) => CorporateUserDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCorporateUsersResponse>, I>>(base?: I): ListCorporateUsersResponse {
    return ListCorporateUsersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCorporateUsersResponse>, I>>(object: I): ListCorporateUsersResponse {
    const message = createBaseListCorporateUsersResponse();
    message.data = object.data?.map((e) => CorporateUserDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListCorporateApprovedEmailsRequest(): ListCorporateApprovedEmailsRequest {
  return { corporateMembershipId: "", activated: undefined };
}

export const ListCorporateApprovedEmailsRequest = {
  encode(message: ListCorporateApprovedEmailsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateMembershipId !== "") {
      writer.uint32(10).string(message.corporateMembershipId);
    }
    if (message.activated !== undefined) {
      BoolValue.encode({ value: message.activated! }, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCorporateApprovedEmailsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCorporateApprovedEmailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateMembershipId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.activated = BoolValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCorporateApprovedEmailsRequest {
    return {
      corporateMembershipId: isSet(object.corporateMembershipId) ? globalThis.String(object.corporateMembershipId) : "",
      activated: isSet(object.activated) ? Boolean(object.activated) : undefined,
    };
  },

  toJSON(message: ListCorporateApprovedEmailsRequest): unknown {
    const obj: any = {};
    if (message.corporateMembershipId !== "") {
      obj.corporateMembershipId = message.corporateMembershipId;
    }
    if (message.activated !== undefined) {
      obj.activated = message.activated;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCorporateApprovedEmailsRequest>, I>>(
    base?: I,
  ): ListCorporateApprovedEmailsRequest {
    return ListCorporateApprovedEmailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCorporateApprovedEmailsRequest>, I>>(
    object: I,
  ): ListCorporateApprovedEmailsRequest {
    const message = createBaseListCorporateApprovedEmailsRequest();
    message.corporateMembershipId = object.corporateMembershipId ?? "";
    message.activated = object.activated ?? undefined;
    return message;
  },
};

function createBaseListCorporateApprovedEmailsResponse(): ListCorporateApprovedEmailsResponse {
  return { data: [] };
}

export const ListCorporateApprovedEmailsResponse = {
  encode(message: ListCorporateApprovedEmailsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.data) {
      CorporateApprovedEmailDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCorporateApprovedEmailsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCorporateApprovedEmailsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(CorporateApprovedEmailDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCorporateApprovedEmailsResponse {
    return {
      data: globalThis.Array.isArray(object?.data)
        ? object.data.map((e: any) => CorporateApprovedEmailDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListCorporateApprovedEmailsResponse): unknown {
    const obj: any = {};
    if (message.data?.length) {
      obj.data = message.data.map((e) => CorporateApprovedEmailDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCorporateApprovedEmailsResponse>, I>>(
    base?: I,
  ): ListCorporateApprovedEmailsResponse {
    return ListCorporateApprovedEmailsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCorporateApprovedEmailsResponse>, I>>(
    object: I,
  ): ListCorporateApprovedEmailsResponse {
    const message = createBaseListCorporateApprovedEmailsResponse();
    message.data = object.data?.map((e) => CorporateApprovedEmailDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRemoveCorporateApprovedEmailRequest(): RemoveCorporateApprovedEmailRequest {
  return { email: "" };
}

export const RemoveCorporateApprovedEmailRequest = {
  encode(message: RemoveCorporateApprovedEmailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveCorporateApprovedEmailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveCorporateApprovedEmailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveCorporateApprovedEmailRequest {
    return { email: isSet(object.email) ? globalThis.String(object.email) : "" };
  },

  toJSON(message: RemoveCorporateApprovedEmailRequest): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveCorporateApprovedEmailRequest>, I>>(
    base?: I,
  ): RemoveCorporateApprovedEmailRequest {
    return RemoveCorporateApprovedEmailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveCorporateApprovedEmailRequest>, I>>(
    object: I,
  ): RemoveCorporateApprovedEmailRequest {
    const message = createBaseRemoveCorporateApprovedEmailRequest();
    message.email = object.email ?? "";
    return message;
  },
};

function createBaseRemoveCorporateApprovedEmailResponse(): RemoveCorporateApprovedEmailResponse {
  return {};
}

export const RemoveCorporateApprovedEmailResponse = {
  encode(_: RemoveCorporateApprovedEmailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveCorporateApprovedEmailResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveCorporateApprovedEmailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RemoveCorporateApprovedEmailResponse {
    return {};
  },

  toJSON(_: RemoveCorporateApprovedEmailResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveCorporateApprovedEmailResponse>, I>>(
    base?: I,
  ): RemoveCorporateApprovedEmailResponse {
    return RemoveCorporateApprovedEmailResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveCorporateApprovedEmailResponse>, I>>(
    _: I,
  ): RemoveCorporateApprovedEmailResponse {
    const message = createBaseRemoveCorporateApprovedEmailResponse();
    return message;
  },
};

function createBaseUpdateCorporateUserMemberRequest(): UpdateCorporateUserMemberRequest {
  return { corporateMembershipUserId: "", status: 0 };
}

export const UpdateCorporateUserMemberRequest = {
  encode(message: UpdateCorporateUserMemberRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateMembershipUserId !== "") {
      writer.uint32(10).string(message.corporateMembershipUserId);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCorporateUserMemberRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCorporateUserMemberRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateMembershipUserId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCorporateUserMemberRequest {
    return {
      corporateMembershipUserId: isSet(object.corporateMembershipUserId)
        ? globalThis.String(object.corporateMembershipUserId)
        : "",
      status: isSet(object.status) ? userStatusEnumDTOFromJSON(object.status) : 0,
    };
  },

  toJSON(message: UpdateCorporateUserMemberRequest): unknown {
    const obj: any = {};
    if (message.corporateMembershipUserId !== "") {
      obj.corporateMembershipUserId = message.corporateMembershipUserId;
    }
    if (message.status !== 0) {
      obj.status = userStatusEnumDTOToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCorporateUserMemberRequest>, I>>(
    base?: I,
  ): UpdateCorporateUserMemberRequest {
    return UpdateCorporateUserMemberRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCorporateUserMemberRequest>, I>>(
    object: I,
  ): UpdateCorporateUserMemberRequest {
    const message = createBaseUpdateCorporateUserMemberRequest();
    message.corporateMembershipUserId = object.corporateMembershipUserId ?? "";
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseUpdateCorporateUserMemberResponse(): UpdateCorporateUserMemberResponse {
  return { data: undefined };
}

export const UpdateCorporateUserMemberResponse = {
  encode(message: UpdateCorporateUserMemberResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data !== undefined) {
      CorporateUserDTO.encode(message.data, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCorporateUserMemberResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCorporateUserMemberResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = CorporateUserDTO.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCorporateUserMemberResponse {
    return { data: isSet(object.data) ? CorporateUserDTO.fromJSON(object.data) : undefined };
  },

  toJSON(message: UpdateCorporateUserMemberResponse): unknown {
    const obj: any = {};
    if (message.data !== undefined) {
      obj.data = CorporateUserDTO.toJSON(message.data);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCorporateUserMemberResponse>, I>>(
    base?: I,
  ): UpdateCorporateUserMemberResponse {
    return UpdateCorporateUserMemberResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCorporateUserMemberResponse>, I>>(
    object: I,
  ): UpdateCorporateUserMemberResponse {
    const message = createBaseUpdateCorporateUserMemberResponse();
    message.data = (object.data !== undefined && object.data !== null)
      ? CorporateUserDTO.fromPartial(object.data)
      : undefined;
    return message;
  },
};

function createBaseImportCorporateMembershipsCsvRequest(): ImportCorporateMembershipsCsvRequest {
  return { corporateMembershipUserId: "", csvMediaId: "" };
}

export const ImportCorporateMembershipsCsvRequest = {
  encode(message: ImportCorporateMembershipsCsvRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateMembershipUserId !== "") {
      writer.uint32(10).string(message.corporateMembershipUserId);
    }
    if (message.csvMediaId !== "") {
      writer.uint32(18).string(message.csvMediaId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImportCorporateMembershipsCsvRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImportCorporateMembershipsCsvRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateMembershipUserId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.csvMediaId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImportCorporateMembershipsCsvRequest {
    return {
      corporateMembershipUserId: isSet(object.corporateMembershipUserId)
        ? globalThis.String(object.corporateMembershipUserId)
        : "",
      csvMediaId: isSet(object.csvMediaId) ? globalThis.String(object.csvMediaId) : "",
    };
  },

  toJSON(message: ImportCorporateMembershipsCsvRequest): unknown {
    const obj: any = {};
    if (message.corporateMembershipUserId !== "") {
      obj.corporateMembershipUserId = message.corporateMembershipUserId;
    }
    if (message.csvMediaId !== "") {
      obj.csvMediaId = message.csvMediaId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImportCorporateMembershipsCsvRequest>, I>>(
    base?: I,
  ): ImportCorporateMembershipsCsvRequest {
    return ImportCorporateMembershipsCsvRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImportCorporateMembershipsCsvRequest>, I>>(
    object: I,
  ): ImportCorporateMembershipsCsvRequest {
    const message = createBaseImportCorporateMembershipsCsvRequest();
    message.corporateMembershipUserId = object.corporateMembershipUserId ?? "";
    message.csvMediaId = object.csvMediaId ?? "";
    return message;
  },
};

function createBaseImportCorporateMembershipsCsvResponse(): ImportCorporateMembershipsCsvResponse {
  return { usersAddedAmount: 0 };
}

export const ImportCorporateMembershipsCsvResponse = {
  encode(message: ImportCorporateMembershipsCsvResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.usersAddedAmount !== 0) {
      writer.uint32(8).int32(message.usersAddedAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ImportCorporateMembershipsCsvResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImportCorporateMembershipsCsvResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.usersAddedAmount = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImportCorporateMembershipsCsvResponse {
    return { usersAddedAmount: isSet(object.usersAddedAmount) ? globalThis.Number(object.usersAddedAmount) : 0 };
  },

  toJSON(message: ImportCorporateMembershipsCsvResponse): unknown {
    const obj: any = {};
    if (message.usersAddedAmount !== 0) {
      obj.usersAddedAmount = Math.round(message.usersAddedAmount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ImportCorporateMembershipsCsvResponse>, I>>(
    base?: I,
  ): ImportCorporateMembershipsCsvResponse {
    return ImportCorporateMembershipsCsvResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImportCorporateMembershipsCsvResponse>, I>>(
    object: I,
  ): ImportCorporateMembershipsCsvResponse {
    const message = createBaseImportCorporateMembershipsCsvResponse();
    message.usersAddedAmount = object.usersAddedAmount ?? 0;
    return message;
  },
};

function createBaseCorporateApprovedEmailDTO(): CorporateApprovedEmailDTO {
  return { email: "", corporateMembershipId: "", activated: false };
}

export const CorporateApprovedEmailDTO = {
  encode(message: CorporateApprovedEmailDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    if (message.corporateMembershipId !== "") {
      writer.uint32(18).string(message.corporateMembershipId);
    }
    if (message.activated === true) {
      writer.uint32(24).bool(message.activated);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CorporateApprovedEmailDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCorporateApprovedEmailDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.corporateMembershipId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.activated = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CorporateApprovedEmailDTO {
    return {
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      corporateMembershipId: isSet(object.corporateMembershipId) ? globalThis.String(object.corporateMembershipId) : "",
      activated: isSet(object.activated) ? globalThis.Boolean(object.activated) : false,
    };
  },

  toJSON(message: CorporateApprovedEmailDTO): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.corporateMembershipId !== "") {
      obj.corporateMembershipId = message.corporateMembershipId;
    }
    if (message.activated === true) {
      obj.activated = message.activated;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CorporateApprovedEmailDTO>, I>>(base?: I): CorporateApprovedEmailDTO {
    return CorporateApprovedEmailDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CorporateApprovedEmailDTO>, I>>(object: I): CorporateApprovedEmailDTO {
    const message = createBaseCorporateApprovedEmailDTO();
    message.email = object.email ?? "";
    message.corporateMembershipId = object.corporateMembershipId ?? "";
    message.activated = object.activated ?? false;
    return message;
  },
};

function createBaseCorporateUserDTO(): CorporateUserDTO {
  return { id: "", email: "", firstName: "", lastName: "", remainingCredit: 0, status: 0 };
}

export const CorporateUserDTO = {
  encode(message: CorporateUserDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.firstName !== "") {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(34).string(message.lastName);
    }
    if (message.remainingCredit !== 0) {
      writer.uint32(41).double(message.remainingCredit);
    }
    if (message.status !== 0) {
      writer.uint32(48).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CorporateUserDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCorporateUserDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.remainingCredit = reader.double();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CorporateUserDTO {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      remainingCredit: isSet(object.remainingCredit) ? globalThis.Number(object.remainingCredit) : 0,
      status: isSet(object.status) ? userStatusEnumDTOFromJSON(object.status) : 0,
    };
  },

  toJSON(message: CorporateUserDTO): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.remainingCredit !== 0) {
      obj.remainingCredit = message.remainingCredit;
    }
    if (message.status !== 0) {
      obj.status = userStatusEnumDTOToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CorporateUserDTO>, I>>(base?: I): CorporateUserDTO {
    return CorporateUserDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CorporateUserDTO>, I>>(object: I): CorporateUserDTO {
    const message = createBaseCorporateUserDTO();
    message.id = object.id ?? "";
    message.email = object.email ?? "";
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.remainingCredit = object.remainingCredit ?? 0;
    message.status = object.status ?? 0;
    return message;
  },
};

export interface CorporateUsersService {
  ListCorporateUsers(
    request: DeepPartial<ListCorporateUsersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCorporateUsersResponse>;
  ListCorporateApprovedEmails(
    request: DeepPartial<ListCorporateApprovedEmailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCorporateApprovedEmailsResponse>;
  RemoveCorporateApprovedEmail(
    request: DeepPartial<RemoveCorporateApprovedEmailRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveCorporateApprovedEmailResponse>;
  UpdateCorporateUserMember(
    request: DeepPartial<UpdateCorporateUserMemberRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateCorporateUserMemberResponse>;
  ImportCorporateMembershipsCsv(
    request: DeepPartial<ImportCorporateMembershipsCsvRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ImportCorporateMembershipsCsvResponse>;
}

export class CorporateUsersServiceClientImpl implements CorporateUsersService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListCorporateUsers = this.ListCorporateUsers.bind(this);
    this.ListCorporateApprovedEmails = this.ListCorporateApprovedEmails.bind(this);
    this.RemoveCorporateApprovedEmail = this.RemoveCorporateApprovedEmail.bind(this);
    this.UpdateCorporateUserMember = this.UpdateCorporateUserMember.bind(this);
    this.ImportCorporateMembershipsCsv = this.ImportCorporateMembershipsCsv.bind(this);
  }

  ListCorporateUsers(
    request: DeepPartial<ListCorporateUsersRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCorporateUsersResponse> {
    return this.rpc.unary(
      CorporateUsersServiceListCorporateUsersDesc,
      ListCorporateUsersRequest.fromPartial(request),
      metadata,
    );
  }

  ListCorporateApprovedEmails(
    request: DeepPartial<ListCorporateApprovedEmailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCorporateApprovedEmailsResponse> {
    return this.rpc.unary(
      CorporateUsersServiceListCorporateApprovedEmailsDesc,
      ListCorporateApprovedEmailsRequest.fromPartial(request),
      metadata,
    );
  }

  RemoveCorporateApprovedEmail(
    request: DeepPartial<RemoveCorporateApprovedEmailRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveCorporateApprovedEmailResponse> {
    return this.rpc.unary(
      CorporateUsersServiceRemoveCorporateApprovedEmailDesc,
      RemoveCorporateApprovedEmailRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateCorporateUserMember(
    request: DeepPartial<UpdateCorporateUserMemberRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateCorporateUserMemberResponse> {
    return this.rpc.unary(
      CorporateUsersServiceUpdateCorporateUserMemberDesc,
      UpdateCorporateUserMemberRequest.fromPartial(request),
      metadata,
    );
  }

  ImportCorporateMembershipsCsv(
    request: DeepPartial<ImportCorporateMembershipsCsvRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ImportCorporateMembershipsCsvResponse> {
    return this.rpc.unary(
      CorporateUsersServiceImportCorporateMembershipsCsvDesc,
      ImportCorporateMembershipsCsvRequest.fromPartial(request),
      metadata,
    );
  }
}

export const CorporateUsersServiceDesc = { serviceName: "proto.CorporateUsersService" };

export const CorporateUsersServiceListCorporateUsersDesc: UnaryMethodDefinitionish = {
  methodName: "ListCorporateUsers",
  service: CorporateUsersServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListCorporateUsersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListCorporateUsersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateUsersServiceListCorporateApprovedEmailsDesc: UnaryMethodDefinitionish = {
  methodName: "ListCorporateApprovedEmails",
  service: CorporateUsersServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListCorporateApprovedEmailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListCorporateApprovedEmailsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateUsersServiceRemoveCorporateApprovedEmailDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveCorporateApprovedEmail",
  service: CorporateUsersServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveCorporateApprovedEmailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveCorporateApprovedEmailResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateUsersServiceUpdateCorporateUserMemberDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateCorporateUserMember",
  service: CorporateUsersServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateCorporateUserMemberRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateCorporateUserMemberResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateUsersServiceImportCorporateMembershipsCsvDesc: UnaryMethodDefinitionish = {
  methodName: "ImportCorporateMembershipsCsv",
  service: CorporateUsersServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ImportCorporateMembershipsCsvRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ImportCorporateMembershipsCsvResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
