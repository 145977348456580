import styled from "@emotion/styled";
import { withFormik } from "formik";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as Yup from "yup";
import { toastStore } from "../../../src/stores/toast-store";
import { metadata, rpc } from "../../grpc/grpc-legacy";
import { useAsync } from "../../hooks/useAsync";
import { UpsertOrganisationUserRequest } from "sdk/dist/organisation_user_pb";
import { sharedOrganizationService } from "../../services/OrganizationService";
import { sharedOrganizationUserService } from "../../services/OrganizationUserService";
import { Banner } from "../../ui-kit/Banner";
import { LockIcon } from "../../ui-kit/icons/LockIcon";
import Tooltip from "../elements/Tooltip";
import LoadingButton from "./../../components/LoadingButton";
import CategoriesSelector from "./../../components/form/CategoriesSelector";
import CheckRadioBox from "./../../components/form/CheckRadioBox";
import FormError from "./../../components/form/Error";
import FooterContainer from "./../../components/form/FooterContainer";
import Form from "./../../components/form/Form";
import FormHeader from "./../../components/form/FormHeader";
import Input from "./../../components/form/Input";
import Label from "./../../components/form/Label";
import MobileInput from "./../../components/form/MobileInput";
import Section from "./../../components/form/Section";
import SectionsContainer from "./../../components/form/SectionsContainer";
import TextArea from "./../../components/form/TextArea";
import WeekSchedule from "./../../components/form/WeekSchedule";
import * as ReduxDialogs from "./../../redux/features/dialogs";
import apiService from "./../../redux/services/api";
import { breakpoints } from "./../../util/consts";
import { parseFormError } from "./../../util/form";
import typography from "./../../util/typography";
import Dialog from "./Dialog";

interface IProps {
  dialogId: string;
  dispatch: any;
  data: {
    loc: { Org: { ID: string; SubscriptionType: string } };
  };
}

const AddStaffModal = (props: IProps) => {
  const closeDialog = () => {
    const { dispatch, dialogId } = props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  };
  const organizationDetailsFetcher = useAsync(async () => {
    if (props.data && props.data.loc && props.data.loc.Org) {
      var res;
      try {
        res = await sharedOrganizationService.getOrganizationDetails(props.data.loc.Org.ID);
        if (!res.AdminOrReceptionist) {
          throw new Error("You don't have enough permissions");
        }
      } catch (err) {
        toastStore.error(err.message);
        throw err;
      }
      return res;
    }
  });

  const staffFetcher = useAsync(async () => {
    if (props.data && props.data.loc && props.data.loc.Org) {
      return await sharedOrganizationUserService.listOfUsers(props.data.loc.Org.ID);
    }
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = props;
  // Number of provider active in the organization
  const currentProviderCount =
    staffFetcher.value && staffFetcher.value.usersList
      ? new Set(staffFetcher.value.usersList.map((v) => v.id)).size
      : 0;
  // Setting of provider cap set in business profile
  const providerCap = organizationDetailsFetcher.value
    ? organizationDetailsFetcher.value.ProviderCap
    : 0;
  // Monthly cost of current subscription
  const subscriptionCost =
    organizationDetailsFetcher.value &&
    organizationDetailsFetcher.value.SubscriptionDetails &&
    organizationDetailsFetcher.value.SubscriptionDetails.SubscriptionMonthly
      ? organizationDetailsFetcher.value.SubscriptionDetails.SubscriptionMonthly / 100
      : 0;

  return organizationDetailsFetcher.error ? null : (
    <Dialog dialogId={props.dialogId}>
      <Container>
        <Form
          onSubmit={
            isSubmitting
              ? (e) => {
                  e.preventDefault();
                  return false;
                }
              : handleSubmit
          }
        >
          <FormHeader title="New Staff Member" />
          <FormSection>Staff Details</FormSection>
          <SectionsContainer>
            <Section>
              <Label htmlFor="addstaff-FirstName">First Name</Label>
              <Input
                id="addstaff-FirstName"
                name="FirstName"
                type="text"
                placeholder="First name"
                value={values.FirstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.FirstName && errors.FirstName}
              />
              {!!errors.FirstName && touched.FirstName && <FormError>{errors.FirstName}</FormError>}
            </Section>
            <Section>
              <Label htmlFor="addstaff-LastName">Last Name</Label>
              <Input
                id="addstaff-LastName"
                name="LastName"
                type="text"
                placeholder="Last name"
                value={values.LastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.LastName && errors.LastName}
              />
              {!!errors.LastName && touched.LastName && <FormError>{errors.LastName}</FormError>}
            </Section>
            <Section>
              <Label htmlFor="addstaff-Email">Email</Label>
              <Input
                id="addstaff-Email"
                name="Email"
                type="email"
                placeholder="example@app.lyfe.io"
                value={values.Email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Email && errors.Email}
              />
              {!!errors.Email && touched.Email && <FormError>{errors.Email}</FormError>}
            </Section>
            <Section>
              <Label htmlFor="addstaff-Phone">Phone Number</Label>
              <MobileInput
                defaultCountry={process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "AU" ? "au" : "us"}
                onCountryChange={(e) => {
                  values.MobileCountryCode = e;
                }}
                onPhoneChange={(e) => {
                  values.MobileNumber = e;
                }}
              />
              {!!errors.MobileNumber && touched.MobileNumber && (
                <FormError>{errors.MobileNumber}</FormError>
              )}
            </Section>
          </SectionsContainer>

          <FormSection>
            Staff Role{" "}
            <Tooltip>
              Admin can process bookings and payments at all locations. Receptionists can process
              bookings and payments at their location and providers can process bookings and
              payments for themselves only. You can assign multiple roles to allow providers more
              access. Note:The subscription package headcountis based on providers only which means
              you can have unlimited Admin and Receptionist users and it will not affect your
              subscription package.
            </Tooltip>
          </FormSection>
          {/* (?) Admin can process bookings and payments at all locations. Receptionists can process bookings and payments at their location and providers can process bookings and payments for themselves only. You can assign multiple roles to allow providers more access. Note:The subscription package headcountis based on providers only which means you can have unlimited Admin and Receptionist users and it will not affect your subscription package. */}
          <SectionsContainer>
            <Section>
              <RolesContainer>
                <CheckRadioBox
                  id="addstaff-Roles-Admin"
                  name="Roles"
                  value="Admin"
                  type="checkbox"
                  values={values.Roles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Roles && errors.Roles}
                >
                  Admin
                </CheckRadioBox>
                <CheckRadioBox
                  id="addstaff-Roles-Receptionist"
                  name="Roles"
                  value="Receptionist"
                  type="checkbox"
                  values={values.Roles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Roles && errors.Roles}
                >
                  Receptionist
                </CheckRadioBox>
                <CheckRadioBox
                  id="addstaff-Roles-Provider"
                  name="Roles"
                  value="Provider"
                  type="checkbox"
                  values={values.Roles}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Roles && errors.Roles}
                  disabled={
                    props.data &&
                    props.data.loc &&
                    props.data.loc.Org &&
                    props.data.loc.Org.SubscriptionType === "Starter"
                  }
                >
                  <ProviderText>
                    Provider
                    {props.data &&
                      props.data.loc &&
                      props.data.loc.Org &&
                      props.data.loc.Org.SubscriptionType === "Starter" && (
                        <LockIcon size="small" />
                      )}
                  </ProviderText>
                </CheckRadioBox>
              </RolesContainer>
              {!!errors.Roles && touched.Roles && <FormError>{errors.Roles}</FormError>}
            </Section>
          </SectionsContainer>

          {values.Roles.indexOf("Provider") >= 0 && (
            <React.Fragment>
              <FormSection>Provider Details</FormSection>
              <SectionsContainer>
                <Section>
                  <Label htmlFor="addstaff-ProviderProfession">Occupation</Label>
                  <Input
                    id="addstaff-ProviderProfession"
                    name="ProviderProfession"
                    type="text"
                    placeholder="Personal Trainer, Boxing Coach etc."
                    value={values.ProviderProfession}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ProviderProfession && errors.ProviderProfession}
                  />
                  {!!errors.ProviderProfession && touched.ProviderProfession && (
                    <FormError>{errors.ProviderProfession}</FormError>
                  )}
                </Section>

                <Section>
                  <Label htmlFor="addstaff-ProviderBio">Bio</Label>
                  <TextArea
                    id="addstaff-ProviderBio"
                    name="ProviderBio"
                    type="text"
                    placeholder="Biographics details"
                    value={values.ProviderBio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ProviderBio && errors.ProviderBio}
                    rows={8}
                  />
                  {!!errors.ProviderBio && touched.ProviderBio && (
                    <FormError>{errors.ProviderBio}</FormError>
                  )}
                </Section>

                <Section>
                  <Label htmlFor="addstaff-ProviderCategories">
                    Categories{" "}
                    <Tooltip>
                      If you can’t find a category that fits your purposes email support@lyfe.io and
                      we can add it to the list.
                    </Tooltip>
                  </Label>
                  {/* (?) If you can’t find a category that fits your purposes email support@lyfe.io and we can add it to the list. */}
                  <CategoriesSelector
                    id="addstaff-ProviderCategories"
                    name="ProviderCategories"
                    value={values.ProviderCategories}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ProviderCategories && errors.ProviderCategories}
                  />
                  {!!errors.ProviderCategories && touched.ProviderCategories && (
                    <FormError>{errors.ProviderCategories}</FormError>
                  )}
                </Section>
              </SectionsContainer>

              <FormSection>Provider Schedule</FormSection>
              <SectionsContainer>
                <Section>
                  <WeekSchedule
                    name="ProviderSchedule"
                    value={values.ProviderSchedule}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.ProviderSchedule && errors.ProviderSchedule}
                  />
                  {!!errors.ProviderSchedule && touched.ProviderSchedule && (
                    <FormError>{errors.ProviderSchedule}</FormError>
                  )}
                </Section>
              </SectionsContainer>

              {process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "AU" ? (
                <>
                  <FormSection>Provider Fund Details</FormSection>
                  <SectionsContainer>
                    <Section>
                      <Label htmlFor="addstaff-ProviderFund_AUMedicare">
                        Medicare Provider Number
                      </Label>
                      <Input
                        id="addstaff-ProviderFund_AUMedicare"
                        name="ProviderFund_AUMedicare"
                        type="text"
                        placeholder="000"
                        value={values.ProviderFund_AUMedicare}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.ProviderFund_AUMedicare && errors.ProviderFund_AUMedicare}
                      />
                      {!!errors.ProviderFund_AUMedicare && touched.ProviderFund_AUMedicare && (
                        <FormError>{errors.ProviderFund_AUMedicare}</FormError>
                      )}
                    </Section>

                    <Section>
                      <Label htmlFor="addstaff-ProviderFund_AUDVA">DVA Provider Number</Label>
                      <Input
                        id="addstaff-ProviderFund_AUDVA"
                        name="ProviderFund_AUDVA"
                        type="text"
                        placeholder="000"
                        value={values.ProviderFund_AUDVA}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.ProviderFund_AUDVA && errors.ProviderFund_AUDVA}
                      />
                      {!!errors.ProviderFund_AUDVA && touched.ProviderFund_AUDVA && (
                        <FormError>{errors.ProviderFund_AUDVA}</FormError>
                      )}
                    </Section>

                    <Section>
                      <Label htmlFor="addstaff-ProviderFund_AUHICAPS">
                        HICAPS/Medipass Provider Number
                      </Label>
                      <Input
                        id="addstaff-ProviderFund_AUHICAPS"
                        name="ProviderFund_AUHICAPS"
                        type="text"
                        placeholder="000"
                        value={values.ProviderFund_AUHICAPS}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.ProviderFund_AUHICAPS && errors.ProviderFund_AUHICAPS}
                      />
                      {!!errors.ProviderFund_AUHICAPS && touched.ProviderFund_AUHICAPS && (
                        <FormError>{errors.ProviderFund_AUHICAPS}</FormError>
                      )}
                    </Section>
                  </SectionsContainer>
                </>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
          {values.Roles &&
            values.Roles.includes("Provider") &&
            props.data &&
            props.data.loc.Org.SubscriptionType.toLowerCase() === "pro" && (
              <BannerContainer>
                <Banner type="information">
                  The system will send an invitation email to join Lyfe and create a new Physitrack
                  account using the email address you've entered in the form.
                </Banner>
              </BannerContainer>
            )}

          {values.Roles &&
            values.Roles.includes("Provider") &&
            providerCap !== 0 &&
            props.data &&
            props.data.loc.Org.SubscriptionType &&
            props.data.loc.Org.SubscriptionType.toLowerCase() !== "starter" &&
            currentProviderCount >= providerCap && (
              <BannerContainer>
                <Banner type="information">
                  The organization has reached the cap limit for the number of providers in an
                  organization. The current provider cap is {providerCap}. The system will update
                  the provider cap from {providerCap} to {providerCap + 1} automatically once you
                  add the new provider to the organization, and the monthly cost will change from $
                  {Number(subscriptionCost) * providerCap} to $
                  {Number(subscriptionCost) * (providerCap + 1)}.
                </Banner>
              </BannerContainer>
            )}

          <FooterContainer>
            <LoadingButton
              style={{
                minWidth: 150
              }}
              loading={isSubmitting}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Save
            </LoadingButton>
          </FooterContainer>
        </Form>
      </Container>
    </Dialog>
  );
};

const mcareProvCoefs = [3, 5, 8, 4, 2, 1];
const mcarePLVMap = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  A: 10,
  B: 11,
  C: 12,
  D: 13,
  E: 14,
  F: 15,
  G: 16,
  H: 17,
  J: 18,
  K: 19,
  L: 20,
  M: 21,
  N: 22,
  P: 23,
  Q: 24,
  R: 25,
  T: 26,
  U: 27,
  V: 28,
  W: 29,
  X: 30,
  Y: 31
};

const mcareCSIdx = ["Y", "X", "W", "T", "L", "K", "J", "H", "F", "B", "A"];
export function checkMedicareProviderNo(pno) {
  if (!pno) {
    return true;
  }

  let pc = pno.trim();

  if (!/^[0-9]{5,6}[0-9ABCDEFGHJKLMNPQRTUVWXY][ABFHJKLTWXY]$/.test(pc)) {
    return false;
  }

  if (pc.length === 7) {
    pc = "0" + pc;
  }

  let val = 0;
  for (let i = 0; i < 6; i++) {
    val += mcareProvCoefs[i] * parseInt(pc[i]);
  }
  val += mcarePLVMap[pc[6]] * 6;
  return pc[7] === mcareCSIdx[val % 11];
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ data }) => ({
    FirstName: "",
    LastName: "",
    Email: "",
    MobileNumber: "",
    MobileCountryCode: "",
    Roles: [],
    Type: "1",
    ProviderProfession: "",
    ProviderBio: "",
    ProviderCategories: [],
    ProviderSchedule: data.loc.Schedule,
    ProviderFund_AUMedicare: "",
    ProviderFund_AUDVA: "",
    ProviderFund_AUHICAPS: ""
  }),
  validationSchema: Yup.object().shape({
    FirstName: Yup.string().required("First name is required"),
    LastName: Yup.string().required("Last name is required"),
    Email: Yup.string().email("Email is invalid").required("Email is required"),
    MobileNumber: Yup.string().min(5, "Number is too short").required("Mobile number is required"),
    Roles: Yup.array()
      .of(Yup.string())
      .min(1, "At least one role must be specified")
      .required("Roles are required"),
    ProviderProfession: Yup.string().notRequired(),
    ProviderBio: Yup.string().notRequired(),
    ProviderCategories: Yup.array().of(Yup.string()).notRequired(),
    ProviderFund_AUMedicare: Yup.string()
      .test("medicare", "Invalid provider number", checkMedicareProviderNo)
      .notRequired(),
    ProviderFund_AUDVA: Yup.string()
      .test("dva", "Invalid provider number", checkMedicareProviderNo)
      .notRequired(),
    ProviderFund_AUHICAPS: Yup.string().notRequired(),
    Type: Yup.string().required("User type is required")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    try {
      const object = {
        User: {
          FirstName: values.FirstName.trim(),
          LastName: values.LastName.trim(),
          Email: values.Email.trim()
        },
        Roles: values.Roles,
        IsActive: true
      };

      if (values.MobileNumber !== "") {
        object.User.MobileNumber = values.MobileNumber;
        object.User.MobileCountryCode = values.MobileCountryCode;
      } else {
        object.User.MobileNumber = null;
        object.User.MobileCountryCode = null;
      }

      if (values.Roles.indexOf("Provider") >= 0) {
        object.RoleProperties = {
          ProviderProfession: values.ProviderProfession.trim(),
          ProviderBio: values.ProviderBio.trim()
        };

        object.ProviderCategories = values.ProviderCategories;
        object.ProviderSchedule = values.ProviderSchedule;
        object.ProviderFundProperties = {};

        if (values.ProviderFund_AUMedicare !== "") {
          object.ProviderFundProperties.AUMedicare = {
            ProviderID: values.ProviderFund_AUMedicare.trim()
          };
        }
        if (values.ProviderFund_AUDVA !== "") {
          object.ProviderFundProperties.AUDVA = {
            ProviderID: values.ProviderFund_AUDVA.trim()
          };
        }
        if (values.ProviderFund_AUHICAPS !== "") {
          object.ProviderFundProperties.AUHICAPS = {
            ProviderID: values.ProviderFund_AUHICAPS.trim()
          };
        }
      }

      const response = await apiService.performRequest({
        method: "post",
        path: `/api/v1/locations/${props.data.loc.ID}/users`,
        data: object
      });

      props.data.cb && props.data.cb();
      const req = new UpsertOrganisationUserRequest();
      req.setUserid(response.Payload.User.ID);
      req.setOrganisationid(props.data.loc.Org.ID);
      req.setType(values.Type);

      await rpc.organisationUser.upsertOrganisationUser(req, metadata());
      toastStore.success("Staff added");

      props.dispatch(ReduxDialogs.actions.close(props.dialogId));
    } catch (err) {
      parseFormError(err, values, setFieldError, props);
    }

    setSubmitting(false);
  },
  displayName: "AddStaff"
});

export default compose<IProps, {}>(connect(), formikEnhancer)(AddStaffModal);

const Container = styled.div`
  width: 100vw;
  padding: 24px;

  ${breakpoints["tablet-up"]} {
    padding: 64px 90px;
    max-width: 700px;
  }
`;

const FormSection = styled.div`
  ${typography.heading6};
  font-weight: 600;
  margin-top: 36px;
  margin-bottom: 18px;
`;

const RolesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const TypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "AU"
    ? "space-around"
    : "flex-start"};
  align-items: center;
`;

const BannerContainer = styled.div`
  margin: 0 0 1.5rem 0;
`;

const ProviderText = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.2rem;
  }
`;
