export const AppConfig = {
  BOOKLYFE_VERSION: process.env.BOOKLYFE_VERSION || "development",
  BOOKLYFE_ENVIRONMENT: process.env.BOOKLYFE_ENVIRONMENT,
  BOOKLYFE_GIT_COMMIT_TAG: process.env.BOOKLYFE_GIT_COMMIT_TAG,
  BOOKLYFE_STRIPE_KEYSK: process.env.BOOKLYFE_STRIPE_KEYSK,
  BOOKLYFE_STRIPE_KEYPK: process.env.BOOKLYFE_STRIPE_KEYPK,
  BOOKLYFE_SERVER_INSTANCE_COUNTRY: process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY,
  BOOKLYFE_IDENTITY_ISSUER: process.env.BOOKLYFE_IDENTITY_ISSUER,
  BOOKLYFE_LINKEDIN_CONVERSION_IDS: {
    organizationFormStepper: {
      info: 10603044,
      verification: 10603052,
      description: 10603060,
      subscription: 10603068,
      payment: 10603076
    },
    interested: {
      client: 10603084,
      organization: 10603092
    },
    signup: {
      client: 10603028,
      organization: 10603036
    },
    iframe: {
      organizationRegistrationCompleted: 10603108
    }
  },
  BOOKLYFE_GOOGLEOAUTH2_CLIENTID: process.env.BOOKLYFE_GOOGLEOAUTH2_CLIENTID
};
