import React, { Component } from "react";
import styled from "@emotion/styled";
import Dropzone from "react-dropzone";
import * as dfns from "date-fns";

import apiService from "./../../redux/services/api";
import { hexToRgba, colors } from "./../../util/consts";
import typography from "./../../util/typography";

import IconButton from "./../../components/IconButton";
import ClearIcon from "./../../components/icons/Clear";
import InsertDriveIcon from "./../../components/icons/Document";

import ButtonBase from "./../../components/ButtonBase";
import * as ResourceActions from "./../../redux/features/resources/thunkactions";
import { CreateMediaRequest, Media } from "sdk/dist/media_pb";
import { readFileBytes } from "./../../util/files";
import { rpc, metadata } from "../../grpc/grpc-legacy";
import { ResourceTypes } from "./../../redux/features/resources/definitions";

export const IMAGE_MIMES = ["image/gif", "image/jpeg", "image/png", "image/bmp"];
export const DOCUMENT_MIMES = ["application/pdf"];
export const ALL_MIMES = [...IMAGE_MIMES, ...DOCUMENT_MIMES];

interface Props {
  type?: "avatar" | "banner";
  value: Media.AsObject[] | undefined;
  name: string;
  setFieldValue: (name: string, media: Media.AsObject[]) => void;
  setFieldTouched: Function;
  setFieldError: (name: string, error: string) => void;
  disabled?: boolean;
}

export class DocumentsInput extends Component<Props> {
  componentDidMount() {
    this.props.setFieldTouched(this.props.name, true);
  }

  componentWillUnmount() {
    this.cancelUpload();
  }

  handleFiles = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return;
    }
    this.props.setFieldTouched(this.props.name, false);
    await Promise.all(acceptedFiles.map((f) => this.handleUpload(f)));
    this.props.setFieldTouched(this.props.name, true);
  };

  handleUpload = async (file: File) => {
    const { name, setFieldValue, setFieldError, value } = this.props;

    try {
      const req = new CreateMediaRequest();
      req.setFile(await readFileBytes(file));
      req.setFilename(file.name);
      req.setMime(file.type);
      const media = await rpc.media.create(req, metadata());
      if (value !== undefined) {
        value.push(media.toObject());
        setFieldValue(name, value);
      } else {
        setFieldError(name, "MediaObject[] undefined");
      }
    } catch (error) {
      const apiError = apiService.errorProcess(error);
      setFieldError(name, apiError.userMessages);
    }
  };

  cancelUpload = (media?: any) => () => {
    if (!media) return; // eslint-disable-line curly
    const { name, value, setFieldValue } = this.props;
    if (value !== undefined) {
      setFieldValue(
        name,
        value.filter((v: any) => media.ID !== v)
      );
    }
  };

  clearMedia = (media: any) => () => {
    if (!media) return; // eslint-disable-line curly
    const { name, value, setFieldValue } = this.props;
    if (value !== undefined) {
      setFieldValue(
        name,
        value.filter((v: any) => media.ID !== v)
      );
    }
  };

  renderItem(media: Media.AsObject) {
    const { disabled } = this.props;
    if (disabled) {
      return (
        <DocButton
          key={`show-${media.id}`}
          onClick={() => window.open(media.downloadUrl, "_blank")}
          type="button"
        >
          <InsertDriveIcon fill={colors.secondary.main} width="28" height="28" />
          <DocButtonContent>
            <DocButtonFilename>{media.filename}</DocButtonFilename>
            <DocButtonDate>
              Added{" "}
              {media.createdAt
                ? dfns.format(new Date(media.createdAt.seconds * 1000), "EEEE do MMMM yyyy")
                : "2001/01/01"}
            </DocButtonDate>
          </DocButtonContent>
        </DocButton>
      );
    } else {
      return (
        <DocEdit key={`edit-${media.id}`}>
          <InsertDriveIcon fill={colors.secondary.main} />
          <DocButtonContent>
            <DocButtonFilename>{media.filename}</DocButtonFilename>
            <DocButtonDate>
              Added{" "}
              {media.createdAt
                ? dfns.format(new Date(media.createdAt.seconds * 1000), "EEEE do MMMM yyyy")
                : "2001/01/01"}
            </DocButtonDate>
          </DocButtonContent>
          <IconButton onClick={this.clearMedia(media)}>
            <ClearIcon />
          </IconButton>
        </DocEdit>
      );
    }
  }

  render() {
    const { value, disabled } = this.props;
    return (
      /* eslint-disable no-nested-ternary */
      <Container>
        {value ? value.map((doc: Media.AsObject) => this.renderItem(doc)) : null}

        {!disabled && (
          <Dropzone onDrop={this.handleFiles}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <TheZone {...getRootProps()} isDragActive={isDragActive} type="button">
                <TheZoneContent>
                  <input {...getInputProps()} />
                  <UploadText>ADD DOCUMENTS</UploadText>
                </TheZoneContent>
              </TheZone>
            )}
          </Dropzone>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  border-radius: 4px;
`;

const TheZone = styled(ButtonBase)`
  width: 100%;
  height: 100%;
  outline: none;
  cursor: pointer;
  padding 10px;
  border: 1px ${({ isDragActive }) => (isDragActive ? "solid" : "dashed")} ${hexToRgba(
    colors.primary.main,
    0.3
  )};
  border-radius: 4px;
`;

const TheZoneContent = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  /* background-color: ${hexToRgba(colors.primary.main, 0.02)}; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UploadText = styled.div`
  ${typography.subtitle2};
  font-weight: 600;
  letter-spacing: 0.22px;
  color: ${colors.surfaceText.lowEmphasis};
  text-transform: uppercase;
`;

const DocButton = styled(ButtonBase)`
  background-color: transparent;
  width: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding: 0;
`;

const DocEdit = styled.div`
  background-color: transparent;
  width: 100%;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const DocButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
  flex: 1 0 50px;
`;

const DocButtonFilename = styled.div`
  ${typography.body2};
  letter-spacing: 0.19px;
  line-height: 18px;
`;

const DocButtonDate = styled.div`
  font-size: 9.82px;
  color: ${colors.surfaceText.lowEmphasis};
  letter-spacing: 0.2px;
  line-height: 13px;
  margin-top: 2px;
`;
