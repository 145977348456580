import React, { Component, SyntheticEvent, useRef, ComponentType } from "react";
import Select, { createFilter, OptionsType, ValueType } from "react-select";
import styled from "@emotion/styled";
import CSS from "csstype";

// grpc
import { rpc, metadata } from "../../../grpc/grpc-legacy";

// utils
import { colors } from "./../../../util/consts";

// api
import { CreateTagRequest, Tag } from "sdk/dist/tag_pb";
import { observer } from "mobx-react";
import { observable, toJS } from "mobx";
import { Option } from "react-select/src/filters";
import { toastStore } from "./../../../stores/toast-store";

const multiTagsContainer = (tags: Array<Tag.AsObject> | undefined) => {
  if (!tags || tags.length === 0) {
    return "";
  }

  const lastTagLabel = tags[tags.length - 1].name;
  const editedLastTagLabel =
    lastTagLabel.length > 10 ? lastTagLabel.slice(0, 11) + "..." : lastTagLabel;
  return editedLastTagLabel + (tags.length < 2 ? "" : " + " + (tags.length - 1) + " Other Tags");
};

const multiValueContainer = (containerData: any): any => {
  const name = containerData.data.name;
  const allSelected = containerData.selectProps.value;
  const index = allSelected.findIndex((selected: any) => selected.name === name);
  const isLastSelected = index === allSelected.length - 1;
  const nameSuffix = isLastSelected ? "" : ", ";
  const val = `${name}${nameSuffix}`;
  return val;
};

const emptyContainer = () => {
  return "";
};

const customFilter = createFilter({ matchFrom: "start" });

interface Props {
  orgId: string;
  tagOptions: Array<Tag.AsObject>;
  selectedTags: Array<Tag.AsObject>;
  onChangeSelectedTags: (
    selectedTags: Array<Tag.AsObject>,
    selectedTag: Tag.AsObject,
    action: string
  ) => void;
  onChangeTagOptions: (tags: Array<Tag.AsObject>) => void;
  getTags: () => Promise<Array<Tag.AsObject>>;
  isDisabled: boolean;
  isEditingClient: boolean;
}

@observer
export default class TagsDropdown extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleNewTagChange = this.handleNewTagChange.bind(this);
  }
  @observable private isModalOpen = false;
  @observable private filteredTags = new Set<string>();
  @observable private inputValue: string = "";
  @observable private newTag = "";
  @observable private isCreatingTag = false;
  @observable private multiTags: any;
  @observable private wrapperRef: any;

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.multiTags = multiTagsContainer(this.props.selectedTags);
  }

  async componentDidUpdate() {
    this.multiTags = multiTagsContainer(this.props.selectedTags);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  onChangeInputValue = (inputValue: string) => {
    this.inputValue = inputValue;
  };

  // Set the wrapper ref
  setWrapperRef(node: any) {
    this.wrapperRef = node;
  }

  // close create tag modal when clicked outside of it
  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.closeModal();
      this.isCreatingTag = false;
      this.newTag = "";
    }
  };

  openModal = () => {
    this.isModalOpen = true;
  };

  closeModal = () => {
    this.isModalOpen = false;
  };

  handleNewTagChange(event: any) {
    this.newTag = event.target.value;
  }

  handleNewTagSubmit = async (tagName: string) => {
    try {
      const req = new CreateTagRequest();
      req.setOrganisationId(this.props.orgId);
      req.setName(tagName);
      await rpc.freeFormTag.create(req, metadata());
      const tags = await this.props.getTags();
      this.props.onChangeTagOptions(tags);
    } catch (err) {
      toastStore.grpcError(err);
    }
  };

  isFilteredIn = (option: Option, inputValue: string) => {
    const isFiltered = customFilter(option, inputValue);
    const newTag = new Tag().toObject();
    newTag.id = option.value;
    newTag.name = option.label;
    newTag.organisationId = this.props.orgId;
    if (isFiltered) {
      this.filteredTags.add(newTag.id);
    } else {
      this.filteredTags.delete(newTag.id);
    }
    return isFiltered;
  };

  render() {
    return (
      <div ref={this.setWrapperRef}>
        {this.isModalOpen === true && <DropdownBox>{this.multiTags}</DropdownBox>}
        {this.isModalOpen === true && (
          <div style={assignTagsStyle}>{this.isCreatingTag ? "Create New Tag" : "Assign Tags"}</div>
        )}

        {this.isCreatingTag ? (
          <>
            <CreateTagInput
              id="createNewTag"
              name="createNewTag"
              type="text"
              placeholder="Create new tag"
              value={this.newTag}
              onChange={this.handleNewTagChange}
            />

            <div style={createTagsStyle}>
              <TagButton
                type="button"
                onClick={() => {
                  this.handleNewTagSubmit(this.newTag);
                  this.isCreatingTag = false;
                  this.newTag = "";
                }}
              >
                Yes
              </TagButton>
              <TagButton
                type="button"
                onClick={() => {
                  this.isCreatingTag = false;
                  this.newTag = "";
                }}
              >
                No
              </TagButton>
            </div>
          </>
        ) : (
          <>
            <Select
              onMenuOpen={this.openModal}
              menuIsOpen={this.isModalOpen}
              components={
                this.isModalOpen
                  ? {
                      MultiValueContainer: emptyContainer,
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null
                    }
                  : {
                      MultiValueContainer: multiValueContainer,
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null
                    }
              }
              defaultValue={[]}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              isSearchable={this.isModalOpen ? true : false}
              inputValue={this.inputValue}
              onSelectResetsInput={false}
              onInputChange={(e) => {
                this.onChangeInputValue(e);
              }}
              isClearable={false}
              onChange={(e: any, options: any) => {
                if (options.option) {
                  // create new tag based on selected tag
                  const selectedTag = new Tag().toObject();

                  selectedTag.id = options.option.id;
                  selectedTag.name = options.option.name;
                  selectedTag.organisationId = options.option.organisationId;

                  this.props.onChangeSelectedTags(e, selectedTag, options.action);
                }
              }}
              isDisabled={this.props.isDisabled}
              styles={
                this.isModalOpen ? focusedStyles : this.props.isDisabled ? disabledStyles : styles
              }
              filterOption={(option, inputValue) => {
                return this.isFilteredIn(option, inputValue);
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              maxMenuHeight={140}
              isMulti
              name="tags"
              value={this.props.selectedTags}
              placeholder={"Select tags"}
              options={this.props.tagOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#00B1B6",
                  primary25: "rgb(82, 185, 189)"
                }
              })}
            />

            {this.isModalOpen === true && (
              <div
                style={{
                  ...manageTagsStyle,

                  // set the top margin of "add tags" button based on number of filtered tags
                  marginTop:
                    this.filteredTags.size <= 1
                      ? "38px"
                      : this.filteredTags.size === 2
                      ? "71px"
                      : this.filteredTags.size === 3
                      ? "106px"
                      : "136px"
                }}
              >
                <TagButton
                  type="button"
                  onClick={() => {
                    this.isCreatingTag = true;
                  }}
                >
                  Add New Tag
                </TagButton>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const TagButton = styled.button`
  width: 100%;
  text-align: center;
  background-color: ${colors.surface.dark};
  cursor: pointer;
  &:hover {
    background-color: ${colors.surface.light};
  }
  &:focus {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

const manageTagsStyle: CSS.Properties = {
  fontWeight: "bold",
  display: "flex",
  flexDirection: "row",
  marginTop: "136px",
  width: "100%",
  height: "40px",
  fontSize: "15.8px",
  letterSpacing: "0.15px",
  textAlign: "center",
  border: "1px solid " + colors.primary.main,
  outline: "none",
  color: colors.surfaceText.highEmphasis,
  backgroundColor: "white",
  boxShadow: "0"
};

const assignTagsStyle: CSS.Properties = {
  fontWeight: "bold",
  display: "flex",
  flexDirection: "column",
  marginTop: "15px",
  marginBottom: "-5px",
  width: "100%",
  height: "40px",
  fontSize: "15.8px",
  letterSpacing: "0.15px",
  textAlign: "center",
  paddingTop: "9px",
  paddingBottom: "13px",
  paddingLeft: "12px",
  border: "1px solid " + colors.primary.main,
  outline: "none",
  color: colors.surfaceText.highEmphasis,
  backgroundColor: "white",
  boxShadow: "0"
};

const createTagsStyle: CSS.Properties = {
  fontWeight: "bold",
  display: "flex",
  flexDirection: "row",
  marginTop: "-1px",
  width: "100%",
  height: "40px",
  fontSize: "15.8px",
  letterSpacing: "0.15px",
  textAlign: "center",
  border: "1px solid " + colors.primary.main,
  outline: "none",
  color: colors.surfaceText.highEmphasis,
  backgroundColor: "white",
  boxShadow: "0"
};

const styles = {
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "initial"
  }),

  menu: (base: any) => ({
    ...base,
    borderRadius: "0",
    width: "100%",
    boxShadow: "none",
    border: "1px solid " + colors.primary.main,
    marginTop: "-5px"
  }),

  control: (base: any, state: any) => ({
    ...base,
    fontSize: "15.8px",
    letterSpacing: "0.15px",
    textAlign: "left",
    height: "44px",
    width: "100%",
    padding: "0px 7px",
    border: "1px solid " + colors.primary[100],
    borderRadius: "4px",
    outline: "none",
    color: colors.surfaceText.highEmphasis,
    background: "#fff",
    boxShadow: "0",
    // borderColor: state.isFocused ? colors.primary.main + " !important" : colors.primary[100], // changes border color based on focus
    "&:hover": colors.primary[100] // changes border color based on hover
  })
};

const disabledStyles = {
  control: (base: any, state: any) => ({
    ...base,
    fontSize: "12.82px",
    letterSpacing: "0.25px",
    lineHeight: "16px",
    margin: "0",
    marginLeft: "-9px",
    color: colors.surfaceText.highEmphasis,
    opacity: "0.6",
    textAlign: "left",
    height: "auto",
    width: "100%",
    border: "none",
    padding: "0",
    outline: "none",
    background: "#fff",
    boxShadow: "0",

    // borderColor: state.isFocused ? colors.primary.main + " !important" : colors.primary[100], // changes border color based on focus
    "&:hover": colors.primary[100] // changes border color based on hover
  })
};

const focusedStyles = {
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "initial"
  }),

  menu: (base: any) => ({
    ...base,
    borderRadius: "0",
    width: "100%",
    boxShadow: "none",
    border: "1px solid " + colors.primary.main,
    marginTop: "-5px"
  }),

  control: (base: any, state: any) => ({
    ...base,
    fontSize: "15.8px",
    letterSpacing: "0.15px",
    textAlign: "left",
    height: "44px",
    width: "100%",
    padding: "0px 7px",
    border: "1px solid " + colors.primary.main,
    borderRadius: "0px",
    outline: "none",
    color: colors.surfaceText.highEmphasis,
    background: "#fff",
    boxShadow: "0",
    // borderColor: state.isFocused ? colors.primary.main + " !important" : colors.primary[100], // changes border color based on focus
    "&:hover": colors.primary[100] // changes border color based on hover
  })
};

const DropdownBox = styled.div`
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 22px;
  text-align: left;
  height: 44px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid ${colors.primary.main};
  border-radius: 4px;
  outline: none;
  color: ${colors.surfaceText.highEmphasis};
  background: #fff;
  -webkit-appearance: none;

  &:focus {
    border-color: ${colors.primary.main};
  }

  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  transition: border-color 0.2s ease;
`;

const CreateTagInput = styled.input`
  font-size: 15.8px;
  letter-spacing: 0.15px;
  line-height: 22px;
  text-align: left;
  height: 44px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid ${colors.primary.main};
  border-radius: 0px;
  outline: none;
  color: ${colors.surfaceText.highEmphasis};
  background: #fff;
  -webkit-appearance: none;

  &:focus {
    border-color: ${colors.primary.main};
  }

  &::placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::placeholder-shown {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-webkit-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &::-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-ms-input-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  &:-moz-placeholder {
    color: ${colors.surfaceText.medEmphasis};
  }

  transition: border-color 0.2s ease;
`;
