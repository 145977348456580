/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: corporate_memberships.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.proto = require('./corporate_memberships_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.CorporateMembershipsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.CorporateMembershipsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AddCorporateMembershipRequest,
 *   !proto.proto.AddCorporateMembershipResponse>}
 */
const methodDescriptor_CorporateMembershipsService_AddCorporateMembership = new grpc.web.MethodDescriptor(
  '/proto.CorporateMembershipsService/AddCorporateMembership',
  grpc.web.MethodType.UNARY,
  proto.proto.AddCorporateMembershipRequest,
  proto.proto.AddCorporateMembershipResponse,
  /**
   * @param {!proto.proto.AddCorporateMembershipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AddCorporateMembershipResponse.deserializeBinary
);


/**
 * @param {!proto.proto.AddCorporateMembershipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.AddCorporateMembershipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AddCorporateMembershipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CorporateMembershipsServiceClient.prototype.addCorporateMembership =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CorporateMembershipsService/AddCorporateMembership',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_AddCorporateMembership,
      callback);
};


/**
 * @param {!proto.proto.AddCorporateMembershipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AddCorporateMembershipResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CorporateMembershipsServicePromiseClient.prototype.addCorporateMembership =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CorporateMembershipsService/AddCorporateMembership',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_AddCorporateMembership);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetCorporateMembershipRequest,
 *   !proto.proto.GetCorporateMembershipResponse>}
 */
const methodDescriptor_CorporateMembershipsService_GetCorporateMembership = new grpc.web.MethodDescriptor(
  '/proto.CorporateMembershipsService/GetCorporateMembership',
  grpc.web.MethodType.UNARY,
  proto.proto.GetCorporateMembershipRequest,
  proto.proto.GetCorporateMembershipResponse,
  /**
   * @param {!proto.proto.GetCorporateMembershipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetCorporateMembershipResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetCorporateMembershipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GetCorporateMembershipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetCorporateMembershipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CorporateMembershipsServiceClient.prototype.getCorporateMembership =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CorporateMembershipsService/GetCorporateMembership',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_GetCorporateMembership,
      callback);
};


/**
 * @param {!proto.proto.GetCorporateMembershipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetCorporateMembershipResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CorporateMembershipsServicePromiseClient.prototype.getCorporateMembership =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CorporateMembershipsService/GetCorporateMembership',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_GetCorporateMembership);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateCorporateMembershipRequest,
 *   !proto.proto.UpdateCorporateMembershipResponse>}
 */
const methodDescriptor_CorporateMembershipsService_UpdateCorporateMembership = new grpc.web.MethodDescriptor(
  '/proto.CorporateMembershipsService/UpdateCorporateMembership',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateCorporateMembershipRequest,
  proto.proto.UpdateCorporateMembershipResponse,
  /**
   * @param {!proto.proto.UpdateCorporateMembershipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateCorporateMembershipResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateCorporateMembershipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateCorporateMembershipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateCorporateMembershipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CorporateMembershipsServiceClient.prototype.updateCorporateMembership =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CorporateMembershipsService/UpdateCorporateMembership',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_UpdateCorporateMembership,
      callback);
};


/**
 * @param {!proto.proto.UpdateCorporateMembershipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateCorporateMembershipResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CorporateMembershipsServicePromiseClient.prototype.updateCorporateMembership =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CorporateMembershipsService/UpdateCorporateMembership',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_UpdateCorporateMembership);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateCorporateMembershipDetailsRequest,
 *   !proto.proto.UpdateCorporateMembershipDetailsResponse>}
 */
const methodDescriptor_CorporateMembershipsService_UpdateCorporateMembershipDetails = new grpc.web.MethodDescriptor(
  '/proto.CorporateMembershipsService/UpdateCorporateMembershipDetails',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateCorporateMembershipDetailsRequest,
  proto.proto.UpdateCorporateMembershipDetailsResponse,
  /**
   * @param {!proto.proto.UpdateCorporateMembershipDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateCorporateMembershipDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateCorporateMembershipDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateCorporateMembershipDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateCorporateMembershipDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CorporateMembershipsServiceClient.prototype.updateCorporateMembershipDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CorporateMembershipsService/UpdateCorporateMembershipDetails',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_UpdateCorporateMembershipDetails,
      callback);
};


/**
 * @param {!proto.proto.UpdateCorporateMembershipDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateCorporateMembershipDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CorporateMembershipsServicePromiseClient.prototype.updateCorporateMembershipDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CorporateMembershipsService/UpdateCorporateMembershipDetails',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_UpdateCorporateMembershipDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateCorporateMembershipStatusRequest,
 *   !proto.proto.UpdateCorporateMembershipStatusResponse>}
 */
const methodDescriptor_CorporateMembershipsService_UpdateCorporateMembershipStatus = new grpc.web.MethodDescriptor(
  '/proto.CorporateMembershipsService/UpdateCorporateMembershipStatus',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateCorporateMembershipStatusRequest,
  proto.proto.UpdateCorporateMembershipStatusResponse,
  /**
   * @param {!proto.proto.UpdateCorporateMembershipStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateCorporateMembershipStatusResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateCorporateMembershipStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateCorporateMembershipStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateCorporateMembershipStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CorporateMembershipsServiceClient.prototype.updateCorporateMembershipStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CorporateMembershipsService/UpdateCorporateMembershipStatus',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_UpdateCorporateMembershipStatus,
      callback);
};


/**
 * @param {!proto.proto.UpdateCorporateMembershipStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateCorporateMembershipStatusResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CorporateMembershipsServicePromiseClient.prototype.updateCorporateMembershipStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CorporateMembershipsService/UpdateCorporateMembershipStatus',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_UpdateCorporateMembershipStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListCorporateMembershipsRequest,
 *   !proto.proto.ListCorporateMembershipsResponse>}
 */
const methodDescriptor_CorporateMembershipsService_ListCorporateMemberships = new grpc.web.MethodDescriptor(
  '/proto.CorporateMembershipsService/ListCorporateMemberships',
  grpc.web.MethodType.UNARY,
  proto.proto.ListCorporateMembershipsRequest,
  proto.proto.ListCorporateMembershipsResponse,
  /**
   * @param {!proto.proto.ListCorporateMembershipsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCorporateMembershipsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListCorporateMembershipsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListCorporateMembershipsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListCorporateMembershipsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CorporateMembershipsServiceClient.prototype.listCorporateMemberships =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CorporateMembershipsService/ListCorporateMemberships',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_ListCorporateMemberships,
      callback);
};


/**
 * @param {!proto.proto.ListCorporateMembershipsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListCorporateMembershipsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CorporateMembershipsServicePromiseClient.prototype.listCorporateMemberships =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CorporateMembershipsService/ListCorporateMemberships',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_ListCorporateMemberships);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.GetMonthlyReviewRequest,
 *   !proto.proto.GetMonthlyReviewResponse>}
 */
const methodDescriptor_CorporateMembershipsService_GetMonthlyReview = new grpc.web.MethodDescriptor(
  '/proto.CorporateMembershipsService/GetMonthlyReview',
  grpc.web.MethodType.UNARY,
  proto.proto.GetMonthlyReviewRequest,
  proto.proto.GetMonthlyReviewResponse,
  /**
   * @param {!proto.proto.GetMonthlyReviewRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.GetMonthlyReviewResponse.deserializeBinary
);


/**
 * @param {!proto.proto.GetMonthlyReviewRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.GetMonthlyReviewResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.GetMonthlyReviewResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CorporateMembershipsServiceClient.prototype.getMonthlyReview =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CorporateMembershipsService/GetMonthlyReview',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_GetMonthlyReview,
      callback);
};


/**
 * @param {!proto.proto.GetMonthlyReviewRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.GetMonthlyReviewResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CorporateMembershipsServicePromiseClient.prototype.getMonthlyReview =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CorporateMembershipsService/GetMonthlyReview',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_GetMonthlyReview);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.AddSeedingDataRequest,
 *   !proto.proto.AddSeedingDataResponse>}
 */
const methodDescriptor_CorporateMembershipsService_AddSeedingData = new grpc.web.MethodDescriptor(
  '/proto.CorporateMembershipsService/AddSeedingData',
  grpc.web.MethodType.UNARY,
  proto.proto.AddSeedingDataRequest,
  proto.proto.AddSeedingDataResponse,
  /**
   * @param {!proto.proto.AddSeedingDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.AddSeedingDataResponse.deserializeBinary
);


/**
 * @param {!proto.proto.AddSeedingDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.AddSeedingDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.AddSeedingDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CorporateMembershipsServiceClient.prototype.addSeedingData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CorporateMembershipsService/AddSeedingData',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_AddSeedingData,
      callback);
};


/**
 * @param {!proto.proto.AddSeedingDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.AddSeedingDataResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CorporateMembershipsServicePromiseClient.prototype.addSeedingData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CorporateMembershipsService/AddSeedingData',
      request,
      metadata || {},
      methodDescriptor_CorporateMembershipsService_AddSeedingData);
};


module.exports = proto.proto;

