import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Entry } from "../Entry";
import { GroupPayment } from "../GroupPayment";
import { Payment } from "../Payment";
import { BookingDTO, BookingClient } from "sdk/dist/bookings_pb";

interface Props {
  bookingObj: BookingDTO.AsObject;
  client?: BookingClient.AsObject;
}

export const BookingWizard: React.FC<Props> = (props) => {
  const [showPaymentWizard, setShowPaymentWizard] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const { bookingObj, client } = props;
    if (
      (bookingObj.status == BookingDTO.Status.BOOKING_COMPLETED ||
        bookingObj.status == BookingDTO.Status.BOOKING_PROCESSING) &&
      client
    ) {
      setShowPaymentWizard(true);
    }
  }, []);

  const { bookingObj, client } = props;
  if (showPaymentWizard) {
    if (bookingObj.type === BookingDTO.Type.BOOKING_SINGLE) {
      return (
        <Payment
          booking={bookingObj}
          client={client!}
          onRequestClose={() => setShowPaymentWizard(false)}
          dispatch={dispatch}
        />
      );
    }
    return (
      <GroupPayment
        booking={bookingObj}
        onRequestClose={() => setShowPaymentWizard(false)}
        dispatch={dispatch}
      />
    );
  }

  return (
    <Entry
      booking={bookingObj}
      client={client}
      orgID={bookingObj.organisationId}
      onPayClick={() => setShowPaymentWizard(true)}
      dispatch={dispatch}
    />
  );
};
