import React from "react";
import { BookingClient, BookingDTO, LineItem } from "sdk/dist/bookings_pb";
import { LeftSection, LeftFooterText } from "../../SplitDialog";
import { fundTypeToText } from "./../../../../util/funds";
import { allLineItems } from "./../../../../util/bookings";
import money_legacy from "../../../../util/money_legacy";

interface Props {
  booking: BookingDTO.AsObject;
  client: BookingClient.AsObject;
}

export class ClientBreakdown extends React.Component<Props> {
  render() {
    const { booking, client } = this.props;
    return allLineItems(booking, client).map((item, i) => (
      <LineItemView key={i} item={item} {...this.props} />
    ));
  }
}

function LineItemView(props: { item: LineItem.AsObject; booking: BookingDTO.AsObject }) {
  const off = props.item.offering!;

  return (
    <LeftSection>
      <LeftFooterText fontWeight={400} style={{ width: "200px" }}>
        {off.name} - {fundTypeToText(props.item.fundType)}
      </LeftFooterText>
      <LeftFooterText fontWeight={700}>
        {money_legacy.moneyToText(props.item.fee!.amount!, false)}
      </LeftFooterText>
    </LeftSection>
  );
}
