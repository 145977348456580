/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "proto";

export interface GetCorporateProviderLocationRequest {
  corporateProviderLocationId: string;
}

export interface GetCorporateProviderLocationResponse {
  data: CorporateProviderLocationDTO | undefined;
}

export interface ListCorporateProviderLocationsRequest {
  corporateOrganisationId: string;
}

export interface ListCorporateProviderLocationsResponse {
  data: CorporateProviderLocationDTO[];
}

export interface UpdateCorporateProviderLocationRequest {
  corporateProviderLocationId: string;
  lyfePercentage: number;
  peakDiscountPercentage: number;
  offPeakDiscountPercentage: number;
  disabled: boolean;
}

export interface UpdateCorporateProviderLocationResponse {
  data: CorporateProviderLocationDTO | undefined;
}

export interface GetCorporateProviderLocationServicesRequest {
  corporateProviderLocationId: string;
}

export interface GetCorporateProviderLocationServicesResponse {
  services: CorporateProviderLocationServiceDTO[];
}

export interface AddCorporateProviderOrganisationRequest {
  organisationId: string;
}

export interface AddCorporateProviderOrganisationResponse {
}

export interface CorporateProviderLocationDTO {
  id: string;
  name: string;
  ownerEmail: string;
  phoneNumber: string;
  lyfePercentage: number;
  peakDiscountPercentage: number;
  offPeakDiscountPercentage: number;
  disabled: boolean;
  createdAt: Date | undefined;
}

export interface CorporateProviderLocationServiceDTO {
  name: string;
  baseFee: number;
  onPeakFee: number;
  offPeakFee: number;
}

function createBaseGetCorporateProviderLocationRequest(): GetCorporateProviderLocationRequest {
  return { corporateProviderLocationId: "" };
}

export const GetCorporateProviderLocationRequest = {
  encode(message: GetCorporateProviderLocationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateProviderLocationId !== "") {
      writer.uint32(10).string(message.corporateProviderLocationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCorporateProviderLocationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCorporateProviderLocationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateProviderLocationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCorporateProviderLocationRequest {
    return {
      corporateProviderLocationId: isSet(object.corporateProviderLocationId)
        ? globalThis.String(object.corporateProviderLocationId)
        : "",
    };
  },

  toJSON(message: GetCorporateProviderLocationRequest): unknown {
    const obj: any = {};
    if (message.corporateProviderLocationId !== "") {
      obj.corporateProviderLocationId = message.corporateProviderLocationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCorporateProviderLocationRequest>, I>>(
    base?: I,
  ): GetCorporateProviderLocationRequest {
    return GetCorporateProviderLocationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCorporateProviderLocationRequest>, I>>(
    object: I,
  ): GetCorporateProviderLocationRequest {
    const message = createBaseGetCorporateProviderLocationRequest();
    message.corporateProviderLocationId = object.corporateProviderLocationId ?? "";
    return message;
  },
};

function createBaseGetCorporateProviderLocationResponse(): GetCorporateProviderLocationResponse {
  return { data: undefined };
}

export const GetCorporateProviderLocationResponse = {
  encode(message: GetCorporateProviderLocationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data !== undefined) {
      CorporateProviderLocationDTO.encode(message.data, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCorporateProviderLocationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCorporateProviderLocationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = CorporateProviderLocationDTO.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCorporateProviderLocationResponse {
    return { data: isSet(object.data) ? CorporateProviderLocationDTO.fromJSON(object.data) : undefined };
  },

  toJSON(message: GetCorporateProviderLocationResponse): unknown {
    const obj: any = {};
    if (message.data !== undefined) {
      obj.data = CorporateProviderLocationDTO.toJSON(message.data);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCorporateProviderLocationResponse>, I>>(
    base?: I,
  ): GetCorporateProviderLocationResponse {
    return GetCorporateProviderLocationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCorporateProviderLocationResponse>, I>>(
    object: I,
  ): GetCorporateProviderLocationResponse {
    const message = createBaseGetCorporateProviderLocationResponse();
    message.data = (object.data !== undefined && object.data !== null)
      ? CorporateProviderLocationDTO.fromPartial(object.data)
      : undefined;
    return message;
  },
};

function createBaseListCorporateProviderLocationsRequest(): ListCorporateProviderLocationsRequest {
  return { corporateOrganisationId: "" };
}

export const ListCorporateProviderLocationsRequest = {
  encode(message: ListCorporateProviderLocationsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateOrganisationId !== "") {
      writer.uint32(10).string(message.corporateOrganisationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCorporateProviderLocationsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCorporateProviderLocationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateOrganisationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCorporateProviderLocationsRequest {
    return {
      corporateOrganisationId: isSet(object.corporateOrganisationId)
        ? globalThis.String(object.corporateOrganisationId)
        : "",
    };
  },

  toJSON(message: ListCorporateProviderLocationsRequest): unknown {
    const obj: any = {};
    if (message.corporateOrganisationId !== "") {
      obj.corporateOrganisationId = message.corporateOrganisationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCorporateProviderLocationsRequest>, I>>(
    base?: I,
  ): ListCorporateProviderLocationsRequest {
    return ListCorporateProviderLocationsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCorporateProviderLocationsRequest>, I>>(
    object: I,
  ): ListCorporateProviderLocationsRequest {
    const message = createBaseListCorporateProviderLocationsRequest();
    message.corporateOrganisationId = object.corporateOrganisationId ?? "";
    return message;
  },
};

function createBaseListCorporateProviderLocationsResponse(): ListCorporateProviderLocationsResponse {
  return { data: [] };
}

export const ListCorporateProviderLocationsResponse = {
  encode(message: ListCorporateProviderLocationsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.data) {
      CorporateProviderLocationDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCorporateProviderLocationsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCorporateProviderLocationsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data.push(CorporateProviderLocationDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCorporateProviderLocationsResponse {
    return {
      data: globalThis.Array.isArray(object?.data)
        ? object.data.map((e: any) => CorporateProviderLocationDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListCorporateProviderLocationsResponse): unknown {
    const obj: any = {};
    if (message.data?.length) {
      obj.data = message.data.map((e) => CorporateProviderLocationDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCorporateProviderLocationsResponse>, I>>(
    base?: I,
  ): ListCorporateProviderLocationsResponse {
    return ListCorporateProviderLocationsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCorporateProviderLocationsResponse>, I>>(
    object: I,
  ): ListCorporateProviderLocationsResponse {
    const message = createBaseListCorporateProviderLocationsResponse();
    message.data = object.data?.map((e) => CorporateProviderLocationDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateCorporateProviderLocationRequest(): UpdateCorporateProviderLocationRequest {
  return {
    corporateProviderLocationId: "",
    lyfePercentage: 0,
    peakDiscountPercentage: 0,
    offPeakDiscountPercentage: 0,
    disabled: false,
  };
}

export const UpdateCorporateProviderLocationRequest = {
  encode(message: UpdateCorporateProviderLocationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateProviderLocationId !== "") {
      writer.uint32(10).string(message.corporateProviderLocationId);
    }
    if (message.lyfePercentage !== 0) {
      writer.uint32(16).int32(message.lyfePercentage);
    }
    if (message.peakDiscountPercentage !== 0) {
      writer.uint32(24).int32(message.peakDiscountPercentage);
    }
    if (message.offPeakDiscountPercentage !== 0) {
      writer.uint32(32).int32(message.offPeakDiscountPercentage);
    }
    if (message.disabled === true) {
      writer.uint32(40).bool(message.disabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCorporateProviderLocationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCorporateProviderLocationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateProviderLocationId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.lyfePercentage = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.peakDiscountPercentage = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.offPeakDiscountPercentage = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.disabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCorporateProviderLocationRequest {
    return {
      corporateProviderLocationId: isSet(object.corporateProviderLocationId)
        ? globalThis.String(object.corporateProviderLocationId)
        : "",
      lyfePercentage: isSet(object.lyfePercentage) ? globalThis.Number(object.lyfePercentage) : 0,
      peakDiscountPercentage: isSet(object.peakDiscountPercentage)
        ? globalThis.Number(object.peakDiscountPercentage)
        : 0,
      offPeakDiscountPercentage: isSet(object.offPeakDiscountPercentage)
        ? globalThis.Number(object.offPeakDiscountPercentage)
        : 0,
      disabled: isSet(object.disabled) ? globalThis.Boolean(object.disabled) : false,
    };
  },

  toJSON(message: UpdateCorporateProviderLocationRequest): unknown {
    const obj: any = {};
    if (message.corporateProviderLocationId !== "") {
      obj.corporateProviderLocationId = message.corporateProviderLocationId;
    }
    if (message.lyfePercentage !== 0) {
      obj.lyfePercentage = Math.round(message.lyfePercentage);
    }
    if (message.peakDiscountPercentage !== 0) {
      obj.peakDiscountPercentage = Math.round(message.peakDiscountPercentage);
    }
    if (message.offPeakDiscountPercentage !== 0) {
      obj.offPeakDiscountPercentage = Math.round(message.offPeakDiscountPercentage);
    }
    if (message.disabled === true) {
      obj.disabled = message.disabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCorporateProviderLocationRequest>, I>>(
    base?: I,
  ): UpdateCorporateProviderLocationRequest {
    return UpdateCorporateProviderLocationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCorporateProviderLocationRequest>, I>>(
    object: I,
  ): UpdateCorporateProviderLocationRequest {
    const message = createBaseUpdateCorporateProviderLocationRequest();
    message.corporateProviderLocationId = object.corporateProviderLocationId ?? "";
    message.lyfePercentage = object.lyfePercentage ?? 0;
    message.peakDiscountPercentage = object.peakDiscountPercentage ?? 0;
    message.offPeakDiscountPercentage = object.offPeakDiscountPercentage ?? 0;
    message.disabled = object.disabled ?? false;
    return message;
  },
};

function createBaseUpdateCorporateProviderLocationResponse(): UpdateCorporateProviderLocationResponse {
  return { data: undefined };
}

export const UpdateCorporateProviderLocationResponse = {
  encode(message: UpdateCorporateProviderLocationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.data !== undefined) {
      CorporateProviderLocationDTO.encode(message.data, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCorporateProviderLocationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCorporateProviderLocationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.data = CorporateProviderLocationDTO.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCorporateProviderLocationResponse {
    return { data: isSet(object.data) ? CorporateProviderLocationDTO.fromJSON(object.data) : undefined };
  },

  toJSON(message: UpdateCorporateProviderLocationResponse): unknown {
    const obj: any = {};
    if (message.data !== undefined) {
      obj.data = CorporateProviderLocationDTO.toJSON(message.data);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCorporateProviderLocationResponse>, I>>(
    base?: I,
  ): UpdateCorporateProviderLocationResponse {
    return UpdateCorporateProviderLocationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCorporateProviderLocationResponse>, I>>(
    object: I,
  ): UpdateCorporateProviderLocationResponse {
    const message = createBaseUpdateCorporateProviderLocationResponse();
    message.data = (object.data !== undefined && object.data !== null)
      ? CorporateProviderLocationDTO.fromPartial(object.data)
      : undefined;
    return message;
  },
};

function createBaseGetCorporateProviderLocationServicesRequest(): GetCorporateProviderLocationServicesRequest {
  return { corporateProviderLocationId: "" };
}

export const GetCorporateProviderLocationServicesRequest = {
  encode(message: GetCorporateProviderLocationServicesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.corporateProviderLocationId !== "") {
      writer.uint32(10).string(message.corporateProviderLocationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCorporateProviderLocationServicesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCorporateProviderLocationServicesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.corporateProviderLocationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCorporateProviderLocationServicesRequest {
    return {
      corporateProviderLocationId: isSet(object.corporateProviderLocationId)
        ? globalThis.String(object.corporateProviderLocationId)
        : "",
    };
  },

  toJSON(message: GetCorporateProviderLocationServicesRequest): unknown {
    const obj: any = {};
    if (message.corporateProviderLocationId !== "") {
      obj.corporateProviderLocationId = message.corporateProviderLocationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCorporateProviderLocationServicesRequest>, I>>(
    base?: I,
  ): GetCorporateProviderLocationServicesRequest {
    return GetCorporateProviderLocationServicesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCorporateProviderLocationServicesRequest>, I>>(
    object: I,
  ): GetCorporateProviderLocationServicesRequest {
    const message = createBaseGetCorporateProviderLocationServicesRequest();
    message.corporateProviderLocationId = object.corporateProviderLocationId ?? "";
    return message;
  },
};

function createBaseGetCorporateProviderLocationServicesResponse(): GetCorporateProviderLocationServicesResponse {
  return { services: [] };
}

export const GetCorporateProviderLocationServicesResponse = {
  encode(message: GetCorporateProviderLocationServicesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.services) {
      CorporateProviderLocationServiceDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCorporateProviderLocationServicesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCorporateProviderLocationServicesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.services.push(CorporateProviderLocationServiceDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCorporateProviderLocationServicesResponse {
    return {
      services: globalThis.Array.isArray(object?.services)
        ? object.services.map((e: any) => CorporateProviderLocationServiceDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetCorporateProviderLocationServicesResponse): unknown {
    const obj: any = {};
    if (message.services?.length) {
      obj.services = message.services.map((e) => CorporateProviderLocationServiceDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCorporateProviderLocationServicesResponse>, I>>(
    base?: I,
  ): GetCorporateProviderLocationServicesResponse {
    return GetCorporateProviderLocationServicesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCorporateProviderLocationServicesResponse>, I>>(
    object: I,
  ): GetCorporateProviderLocationServicesResponse {
    const message = createBaseGetCorporateProviderLocationServicesResponse();
    message.services = object.services?.map((e) => CorporateProviderLocationServiceDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddCorporateProviderOrganisationRequest(): AddCorporateProviderOrganisationRequest {
  return { organisationId: "" };
}

export const AddCorporateProviderOrganisationRequest = {
  encode(message: AddCorporateProviderOrganisationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organisationId !== "") {
      writer.uint32(10).string(message.organisationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddCorporateProviderOrganisationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddCorporateProviderOrganisationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organisationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddCorporateProviderOrganisationRequest {
    return { organisationId: isSet(object.organisationId) ? globalThis.String(object.organisationId) : "" };
  },

  toJSON(message: AddCorporateProviderOrganisationRequest): unknown {
    const obj: any = {};
    if (message.organisationId !== "") {
      obj.organisationId = message.organisationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddCorporateProviderOrganisationRequest>, I>>(
    base?: I,
  ): AddCorporateProviderOrganisationRequest {
    return AddCorporateProviderOrganisationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddCorporateProviderOrganisationRequest>, I>>(
    object: I,
  ): AddCorporateProviderOrganisationRequest {
    const message = createBaseAddCorporateProviderOrganisationRequest();
    message.organisationId = object.organisationId ?? "";
    return message;
  },
};

function createBaseAddCorporateProviderOrganisationResponse(): AddCorporateProviderOrganisationResponse {
  return {};
}

export const AddCorporateProviderOrganisationResponse = {
  encode(_: AddCorporateProviderOrganisationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddCorporateProviderOrganisationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddCorporateProviderOrganisationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AddCorporateProviderOrganisationResponse {
    return {};
  },

  toJSON(_: AddCorporateProviderOrganisationResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AddCorporateProviderOrganisationResponse>, I>>(
    base?: I,
  ): AddCorporateProviderOrganisationResponse {
    return AddCorporateProviderOrganisationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddCorporateProviderOrganisationResponse>, I>>(
    _: I,
  ): AddCorporateProviderOrganisationResponse {
    const message = createBaseAddCorporateProviderOrganisationResponse();
    return message;
  },
};

function createBaseCorporateProviderLocationDTO(): CorporateProviderLocationDTO {
  return {
    id: "",
    name: "",
    ownerEmail: "",
    phoneNumber: "",
    lyfePercentage: 0,
    peakDiscountPercentage: 0,
    offPeakDiscountPercentage: 0,
    disabled: false,
    createdAt: undefined,
  };
}

export const CorporateProviderLocationDTO = {
  encode(message: CorporateProviderLocationDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.ownerEmail !== "") {
      writer.uint32(26).string(message.ownerEmail);
    }
    if (message.phoneNumber !== "") {
      writer.uint32(34).string(message.phoneNumber);
    }
    if (message.lyfePercentage !== 0) {
      writer.uint32(40).int32(message.lyfePercentage);
    }
    if (message.peakDiscountPercentage !== 0) {
      writer.uint32(48).int32(message.peakDiscountPercentage);
    }
    if (message.offPeakDiscountPercentage !== 0) {
      writer.uint32(56).int32(message.offPeakDiscountPercentage);
    }
    if (message.disabled === true) {
      writer.uint32(64).bool(message.disabled);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CorporateProviderLocationDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCorporateProviderLocationDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ownerEmail = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.lyfePercentage = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.peakDiscountPercentage = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.offPeakDiscountPercentage = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.disabled = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CorporateProviderLocationDTO {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      ownerEmail: isSet(object.ownerEmail) ? globalThis.String(object.ownerEmail) : "",
      phoneNumber: isSet(object.phoneNumber) ? globalThis.String(object.phoneNumber) : "",
      lyfePercentage: isSet(object.lyfePercentage) ? globalThis.Number(object.lyfePercentage) : 0,
      peakDiscountPercentage: isSet(object.peakDiscountPercentage)
        ? globalThis.Number(object.peakDiscountPercentage)
        : 0,
      offPeakDiscountPercentage: isSet(object.offPeakDiscountPercentage)
        ? globalThis.Number(object.offPeakDiscountPercentage)
        : 0,
      disabled: isSet(object.disabled) ? globalThis.Boolean(object.disabled) : false,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
    };
  },

  toJSON(message: CorporateProviderLocationDTO): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.ownerEmail !== "") {
      obj.ownerEmail = message.ownerEmail;
    }
    if (message.phoneNumber !== "") {
      obj.phoneNumber = message.phoneNumber;
    }
    if (message.lyfePercentage !== 0) {
      obj.lyfePercentage = Math.round(message.lyfePercentage);
    }
    if (message.peakDiscountPercentage !== 0) {
      obj.peakDiscountPercentage = Math.round(message.peakDiscountPercentage);
    }
    if (message.offPeakDiscountPercentage !== 0) {
      obj.offPeakDiscountPercentage = Math.round(message.offPeakDiscountPercentage);
    }
    if (message.disabled === true) {
      obj.disabled = message.disabled;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CorporateProviderLocationDTO>, I>>(base?: I): CorporateProviderLocationDTO {
    return CorporateProviderLocationDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CorporateProviderLocationDTO>, I>>(object: I): CorporateProviderLocationDTO {
    const message = createBaseCorporateProviderLocationDTO();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.ownerEmail = object.ownerEmail ?? "";
    message.phoneNumber = object.phoneNumber ?? "";
    message.lyfePercentage = object.lyfePercentage ?? 0;
    message.peakDiscountPercentage = object.peakDiscountPercentage ?? 0;
    message.offPeakDiscountPercentage = object.offPeakDiscountPercentage ?? 0;
    message.disabled = object.disabled ?? false;
    message.createdAt = object.createdAt ?? undefined;
    return message;
  },
};

function createBaseCorporateProviderLocationServiceDTO(): CorporateProviderLocationServiceDTO {
  return { name: "", baseFee: 0, onPeakFee: 0, offPeakFee: 0 };
}

export const CorporateProviderLocationServiceDTO = {
  encode(message: CorporateProviderLocationServiceDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.baseFee !== 0) {
      writer.uint32(17).double(message.baseFee);
    }
    if (message.onPeakFee !== 0) {
      writer.uint32(25).double(message.onPeakFee);
    }
    if (message.offPeakFee !== 0) {
      writer.uint32(33).double(message.offPeakFee);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CorporateProviderLocationServiceDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCorporateProviderLocationServiceDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.baseFee = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.onPeakFee = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.offPeakFee = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CorporateProviderLocationServiceDTO {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      baseFee: isSet(object.baseFee) ? globalThis.Number(object.baseFee) : 0,
      onPeakFee: isSet(object.onPeakFee) ? globalThis.Number(object.onPeakFee) : 0,
      offPeakFee: isSet(object.offPeakFee) ? globalThis.Number(object.offPeakFee) : 0,
    };
  },

  toJSON(message: CorporateProviderLocationServiceDTO): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.baseFee !== 0) {
      obj.baseFee = message.baseFee;
    }
    if (message.onPeakFee !== 0) {
      obj.onPeakFee = message.onPeakFee;
    }
    if (message.offPeakFee !== 0) {
      obj.offPeakFee = message.offPeakFee;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CorporateProviderLocationServiceDTO>, I>>(
    base?: I,
  ): CorporateProviderLocationServiceDTO {
    return CorporateProviderLocationServiceDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CorporateProviderLocationServiceDTO>, I>>(
    object: I,
  ): CorporateProviderLocationServiceDTO {
    const message = createBaseCorporateProviderLocationServiceDTO();
    message.name = object.name ?? "";
    message.baseFee = object.baseFee ?? 0;
    message.onPeakFee = object.onPeakFee ?? 0;
    message.offPeakFee = object.offPeakFee ?? 0;
    return message;
  },
};

export interface CorporateProviderLocationsService {
  GetCorporateProviderLocation(
    request: DeepPartial<GetCorporateProviderLocationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCorporateProviderLocationResponse>;
  ListCorporateProviderLocations(
    request: DeepPartial<ListCorporateProviderLocationsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCorporateProviderLocationsResponse>;
  UpdateCorporateProviderLocation(
    request: DeepPartial<UpdateCorporateProviderLocationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateCorporateProviderLocationResponse>;
  AddCorporateProviderOrganisation(
    request: DeepPartial<AddCorporateProviderOrganisationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddCorporateProviderOrganisationResponse>;
  GetCorporateProviderLocationServices(
    request: DeepPartial<GetCorporateProviderLocationServicesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCorporateProviderLocationServicesResponse>;
}

export class CorporateProviderLocationsServiceClientImpl implements CorporateProviderLocationsService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetCorporateProviderLocation = this.GetCorporateProviderLocation.bind(this);
    this.ListCorporateProviderLocations = this.ListCorporateProviderLocations.bind(this);
    this.UpdateCorporateProviderLocation = this.UpdateCorporateProviderLocation.bind(this);
    this.AddCorporateProviderOrganisation = this.AddCorporateProviderOrganisation.bind(this);
    this.GetCorporateProviderLocationServices = this.GetCorporateProviderLocationServices.bind(this);
  }

  GetCorporateProviderLocation(
    request: DeepPartial<GetCorporateProviderLocationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCorporateProviderLocationResponse> {
    return this.rpc.unary(
      CorporateProviderLocationsServiceGetCorporateProviderLocationDesc,
      GetCorporateProviderLocationRequest.fromPartial(request),
      metadata,
    );
  }

  ListCorporateProviderLocations(
    request: DeepPartial<ListCorporateProviderLocationsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListCorporateProviderLocationsResponse> {
    return this.rpc.unary(
      CorporateProviderLocationsServiceListCorporateProviderLocationsDesc,
      ListCorporateProviderLocationsRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateCorporateProviderLocation(
    request: DeepPartial<UpdateCorporateProviderLocationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateCorporateProviderLocationResponse> {
    return this.rpc.unary(
      CorporateProviderLocationsServiceUpdateCorporateProviderLocationDesc,
      UpdateCorporateProviderLocationRequest.fromPartial(request),
      metadata,
    );
  }

  AddCorporateProviderOrganisation(
    request: DeepPartial<AddCorporateProviderOrganisationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<AddCorporateProviderOrganisationResponse> {
    return this.rpc.unary(
      CorporateProviderLocationsServiceAddCorporateProviderOrganisationDesc,
      AddCorporateProviderOrganisationRequest.fromPartial(request),
      metadata,
    );
  }

  GetCorporateProviderLocationServices(
    request: DeepPartial<GetCorporateProviderLocationServicesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCorporateProviderLocationServicesResponse> {
    return this.rpc.unary(
      CorporateProviderLocationsServiceGetCorporateProviderLocationServicesDesc,
      GetCorporateProviderLocationServicesRequest.fromPartial(request),
      metadata,
    );
  }
}

export const CorporateProviderLocationsServiceDesc = { serviceName: "proto.CorporateProviderLocationsService" };

export const CorporateProviderLocationsServiceGetCorporateProviderLocationDesc: UnaryMethodDefinitionish = {
  methodName: "GetCorporateProviderLocation",
  service: CorporateProviderLocationsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCorporateProviderLocationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCorporateProviderLocationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateProviderLocationsServiceListCorporateProviderLocationsDesc: UnaryMethodDefinitionish = {
  methodName: "ListCorporateProviderLocations",
  service: CorporateProviderLocationsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListCorporateProviderLocationsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListCorporateProviderLocationsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateProviderLocationsServiceUpdateCorporateProviderLocationDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateCorporateProviderLocation",
  service: CorporateProviderLocationsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateCorporateProviderLocationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateCorporateProviderLocationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateProviderLocationsServiceAddCorporateProviderOrganisationDesc: UnaryMethodDefinitionish = {
  methodName: "AddCorporateProviderOrganisation",
  service: CorporateProviderLocationsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddCorporateProviderOrganisationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddCorporateProviderOrganisationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CorporateProviderLocationsServiceGetCorporateProviderLocationServicesDesc: UnaryMethodDefinitionish = {
  methodName: "GetCorporateProviderLocationServices",
  service: CorporateProviderLocationsServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCorporateProviderLocationServicesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCorporateProviderLocationServicesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
