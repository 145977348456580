import { Empty } from "google-protobuf/google/protobuf/empty_pb";
import * as grpcWeb from "grpc-web";
import { metadata, rpc } from "../grpc/grpc-legacy";
import apiService, { HTTPMethod } from "../redux/services/api";
import { GetUserDetailsRequest } from "sdk/dist/user_invite_pb";
import { GetUserRequest, ResetPasswordReq } from "sdk/dist/user_pb";
import { toastStore } from "../stores/toast-store";
import { grpcResponseHandler } from "../util/grpcResponseHandler";

class UserService {
  async fetchUser(subjectId: string, meta?: () => grpcWeb.Metadata) {
    try {
      const req = new GetUserRequest();
      req.setSubjectId(subjectId);
      return (await rpc.user.getUser(req, meta ? meta() : metadata())).toObject();
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  async getUserDetailsFromInvitationUrl(url: string) {
    try {
      const req = new GetUserDetailsRequest();
      req.setUrl(url);
      return (await rpc.userInvite.getUserDetails(req, metadata())).toObject();
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  async resetPassword(subjectId: string, token: string, password: string) {
    const req = new ResetPasswordReq();
    req.setSubjectId(subjectId);
    req.setPassword(password);
    req.setToken(token);
    return (await rpc.user.resetPassword(req, metadata())).toObject();
  }

  @grpcResponseHandler()
  async isUserLoggedIn() {
    return (await rpc.user.isUserLoggedIn(new Empty(), metadata())).toObject();
  }

  async addUser(firstName: string, lastName: string, email: string, password: string) {
    await apiService.performRequest({
      method: HTTPMethod.Post,
      path: "/api/v1/users",
      data: {
        FirstName: firstName.trim(),
        LastName: lastName,
        Email: email.trim(),
        Password: password,
        DateOfBirth: null,
        Role: "Standard"
      }
    });
  }
}

export const userService = new UserService();
