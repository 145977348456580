import jwtDecode, { JwtPayload } from "jwt-decode";
import { observable, toJS } from "mobx";
import { persistence } from "../../util/persistence";
import { User } from "sdk/dist/user_pb";

class UserStore {
  @observable
  token?: string = persistence.restore("token");

  @observable
  clientToken?: string = persistence.restore("clientToken");

  @observable
  user?: User.AsObject = toJS(persistence.restore("user"));

  setToken(token: string) {
    persistence.store("token", token);
    this.token = token;
  }

  setClientToken(token: string) {
    persistence.store("clientToken", token);
    this.clientToken = token;
  }

  setUser(user: User.AsObject) {
    this.user = user;
    persistence.store("user", this.user);
  }

  getAuthHeader() {
    // check the user token exists or not
    return `Bearer ${this.token ? this.token : this.clientToken}`;
  }

  isLoggedIn() {
    if (!this.token) {
      return false;
    }
    return this.isValidToken(this.token);
  }

  isValidToken(token: string) {
    try {
      const jwtToken = jwtDecode<JwtPayload>(token);
      if (!jwtToken || !jwtToken.exp) {
        return false;
      }
      return new Date(jwtToken.exp * 1000) >= new Date();
    } catch {
      return false;
    }
  }

  isCorporateUser() {
    return this.user?.corporateUser?.corporate !== "";
  }
}

export const userStore = new UserStore();
