import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import * as fns from "date-fns";
import { withFormik } from "formik";
import { Component, Fragment, createRef } from "react";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import * as Yup from "yup";

import * as ReduxDialogs from "./../../redux/features/dialogs";
import { breakpoints, colors, mapWeekDaysToDTO, weekdays } from "./../../util/consts";
import { parseFormError } from "./../../util/form";

// Custom components
import moment from "moment";
import { FrequencySelect } from "../../components/form/BaseSelect";
import { rebookDataStore } from "../../mobx/stores/RebookDataStore";
import apiService from "../../redux/services/api";
import { BookingDTO } from "sdk/dist/bookings_pb";
import { FormSplitContainer, FormSplitItem } from "../dialogs/OfferingDialog";
import Button from "./../../components/Button";
import IconButton from "./../../components/IconButton";
import CheckRadioBox from "./../../components/form/CheckRadioBox";
import DayPickerInput from "./../../components/form/DayPickerInput";
import DayTimePickerRangeInput from "./../../components/form/DayTimeRangePickerInput";
import Error from "./../../components/form/Error";
import Input from "./../../components/form/Input";
import Label from "./../../components/form/Label";
import { LocationOfferingSelector } from "./../../components/form/ResourceSelector";
import Section from "./../../components/form/Section";
import ClearIcon from "./../../components/icons/Clear";
import { bookingStore } from "./../../stores/booking-store";
import { intToTimestamp } from "./../../util/time";
import Dialog from "./Dialog";

/**
 * NOTES: currently using 2 buttons instead of a set of radio buttons
 */

const addTime = (time, duration) => {
  let times = time.split(":");
  const endTimeInMinutes = times[0] * 60 + parseInt(times[1]) + parseInt(duration);
  times[0] = Math.floor(endTimeInMinutes / 60);
  times[1] = endTimeInMinutes % 60;
  if (times[1].toString().length == 1) {
    times[1] = "0" + times[1];
  }
  if (times[0].toString().length == 1) {
    times[0] = "0" + times[0];
  }
  return times.join(":");
};

class ScheduleQuickBook extends Component {
  modalRef = createRef();

  state = {
    modalHeight: 0,
    loading: true
  };

  async componentDidMount() {
    this.props.setStatus({ Type: "Appointment" });
    window.requestAnimationFrame(this.updateModalHeight);

    if (rebookDataStore.isRebooking) {
      const client = await apiService.performRequest({
        method: "get",
        path: `/api/v1/locations/${rebookDataStore.locationId}/clients`
      });

      const rebookedClient = client.Payload.find(
        (client) => client.ID === rebookDataStore.clientId
      );

      let endTime = fns.parse(this.props.values.StartTime, "HH:mm:ss", new Date());
      endTime = fns.addSeconds(endTime, rebookDataStore.duration.seconds);

      const service = await apiService.performRequest({
        method: "get",
        path: `/api/v1/offerings/${rebookDataStore.offering}`
      });
      this.props.handleChange({
        target: { name: "Service", value: service.Payload }
      });
      this.props.handleChange({
        target: { name: "Client", value: rebookedClient }
      });
      this.props.handleChange({
        target: { name: "EndTime", value: fns.format(endTime, "HH:mm:00") }
      });
    }
  }

  updateModalHeight = () => {
    const newHeight = this.modalRef.current ? this.modalRef.current.offsetHeight : 0;
    if (newHeight !== this.state.modalHeight) {
      this.setState({
        modalHeight: this.modalRef.current ? this.modalRef.current.offsetHeight : 0
      });
    }
  };

  getModalStyle = () => {
    const { modalHeight } = this.state;
    const { top, left, width, height } = this.props.data.stuff.cardStyle;
    const { innerWidth, innerHeight } = window;

    const right = Math.abs(left + width - innerWidth);
    const bottom = Math.abs(top + height - innerHeight);
    const isRightOfCard = right > left;
    const isBelowCard = bottom > top;

    const leftRight = css`
      ${isRightOfCard ? `left: ${width + 17}px` : `right: ${width + 17}px`};
    `;

    const minSpaceFromEdge = 50;
    const spaceToBottom = -(top + modalHeight - innerHeight);
    const spaceToTop = -(bottom + modalHeight - innerHeight);

    const topBottom = css`
      ${isBelowCard
        ? `top: ${spaceToBottom < minSpaceFromEdge ? -(minSpaceFromEdge - spaceToBottom) : 0}px`
        : `bottom: ${spaceToTop < minSpaceFromEdge ? -(minSpaceFromEdge - spaceToTop) : 0}px`};
    `;

    const styles = css`
      ${breakpoints["tablet-up"]} {
        width: 554px;
        ${leftRight};
        ${topBottom};
      }
    `;

    return styles;
  };

  handleServiceSelect = (offering) => {
    const { values, setFieldValue } = this.props;
    if (values.StartTime) {
      const bd = new Date();
      const stime = fns.parse(values.StartTime, "HH:mm:00", bd);
      if (fns.isDate(stime)) {
        let etime = addTime(values.StartTime, offering.ServiceDuration);
        setFieldValue("EndTime", etime);
      }
    }
  };

  closeDialog = () => {
    const { dispatch, dialogId } = this.props;
    dispatch(ReduxDialogs.actions.close(dialogId));
  };

  handleWeekChange = (value) => {
    this.setState({ loading: true });
    if (this.props.values.Week.includes(value)) {
      this.props.values.Week = this.props.values.Week.filter((item) => item !== value);
    } else {
      this.props.values.Week.push(value);
    }
    this.setState({ loading: false });
  };

  handleRepeatTypeChange = (repeatTypeNum) => {
    this.setState({ loading: true });
    this.props.values.RepeatType = repeatTypeNum;
    if (repeatTypeNum == "1") {
      this.props.values.Week = weekdays.slice(1, 6);
    } else {
      let selectedDay = weekdays[this.props.data.stuff.date.getDay()];
      this.props.values.Week = [selectedDay.toUpperCase()];
    }
    this.props.handleBlur();
    this.setState({ loading: false });
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      dispatch,
      data
    } = this.props;

    return (
      <Dialog dialogId={this.props.dialogId} noContent>
        <Block style={data.stuff.cardStyle}>
          <Container css={this.getModalStyle()} ref={this.modalRef} aria-label="quick-book-modal">
            <IconButton
              css={css`
                position: absolute !important;
                top: 2px;
                right: 0px;
                z-index: 12;

                ${breakpoints["tablet-up"]} {
                  display: none;
                }
              `}
              onClick={this.closeDialog}
              aria-labelledby="close-modal"
            >
              <ClearIcon fill={colors.primary.main} size="small" />
              <HideVisually id="close-modal">Close</HideVisually>
            </IconButton>
            <ScrollContainer>
              <Form onSubmit={handleSubmit}>
                <Section style={{ display: "flex" }}>
                  <Button
                    variant={values.Type === "Appointment" ? "contained" : "outlined"}
                    onClick={() => handleChange({ target: { name: "Type", value: "Appointment" } })}
                    style={{ marginRight: 12, flex: "1 1 50px" }}
                    type="button"
                  >
                    APPOINTMENT
                  </Button>
                </Section>
                {values.Type === "Appointment" && (
                  <Fragment>
                    <Section>
                      <Label htmlFor="quickbook-service">Service</Label>
                      <LocationOfferingSelector
                        id="quickbook-service"
                        name="Service"
                        placeholder="Select a provided service"
                        location={data.stuff.provider.Location}
                        onlyServices
                        value={values.Service}
                        onChange={handleChange}
                        onSelect={this.handleServiceSelect}
                        onBlur={handleBlur}
                        error={touched.Service && errors.Service}
                      />
                      {!!errors.Service && touched.Service && <Error>{errors.Service}</Error>}
                    </Section>
                    <Section>
                      <Label htmlFor="quickbook-datetime">Date &amp; Time</Label>
                      <DayTimePickerRangeInput
                        id="quickbook-datetime"
                        interval={5}
                        placeholder="Select the time"
                        dayName="Date"
                        dayValue={values.Date}
                        startName="StartTime"
                        startValue={values.StartTime}
                        endName="EndTime"
                        endValue={values.EndTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          (touched.Date && errors.Date) ||
                          (touched.StartTime && errors.StartTime) ||
                          (touched.EndTime && errors.EndTime)
                        }
                      />
                      {!!errors.Date && touched.Date && <Error>{errors.Date}</Error>}
                      {!!errors.StartTime && touched.StartTime && <Error>{errors.StartTime}</Error>}
                      {!!errors.EndTime && touched.EndTime && <Error>{errors.EndTime}</Error>}
                    </Section>
                    {values.ServiceType === "Group" && (
                      <Section>
                        <Label htmlFor="newbook-maxgroupsize">Maximum Group Size</Label>
                        <Input
                          id="newbook-maxgroupsize"
                          name="MaxGroupSize"
                          type="number"
                          min={1}
                          max={100}
                          placeholder="Item code"
                          value={values.MaxGroupSize}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.MaxGroupSize && errors.MaxGroupSize}
                        />
                        {!!errors.MaxGroupSize && touched.MaxGroupSize && (
                          <Error>{errors.MaxGroupSize}</Error>
                        )}
                      </Section>
                    )}
                    <Section>
                      <Label htmlFor="quick-end">Repeating Frequency</Label>
                      <FrequencySelect
                        id="quick-repeat"
                        name="Repeat"
                        value={values.RepeatType}
                        onChange={(e) => this.handleRepeatTypeChange(String(e.target.value))}
                        onBlur={handleBlur}
                        error={touched.RepeatType && errors.RepeatType}
                      />
                      {!!errors.RepeatType && touched.RepeatType && (
                        <Error>{errors.RepeatType}</Error>
                      )}
                    </Section>
                    {values.RepeatType !== "0" && (
                      <Fragment>
                        <Label htmlFor="quick-end">Repeat On</Label>
                        <Section>
                          <Section style={{ display: "flex" }}>
                            {weekdays.map((day, index) => {
                              return (
                                <Button
                                  key={index}
                                  variant={values.Week.includes(day) ? "contained" : "outlined"}
                                  onClick={() => this.handleWeekChange(day)}
                                  style={{ marginRight: 12, flex: "1 1 50px" }}
                                  type="button"
                                >
                                  {day.slice(0, 1)}
                                </Button>
                              );
                            })}
                          </Section>
                          {!!errors.Level && touched.Level && <Error>{errors.Level}</Error>}
                        </Section>
                        <Label htmlFor="quick-end">Ends</Label>
                        <FormSplitContainer>
                          <FormSplitItem>
                            <CheckRadioBox
                              id="set-end-date-yes"
                              name="UseEndDate"
                              type="radio"
                              value={"true"}
                              checked={values.UseEndDate === "true"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.UseEndDate && errors.UseEndDate}
                            >
                              On Date
                            </CheckRadioBox>
                            <DayPickerInput
                              id="quick-end-date"
                              name="EndDate"
                              // step={900}
                              disabled={values.UseEndDate !== "true"}
                              value={values.EndDate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.EndDate && errors.EndDate}
                            />
                            {!!errors.EndDate && touched.EndDate && <Error>{errors.EndDate}</Error>}
                          </FormSplitItem>
                          <FormSplitItem>
                            <CheckRadioBox
                              id="set-end-date-no"
                              name="UseEndDate"
                              type="radio"
                              value={"false"}
                              checked={values.UseEndDate === "false"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.UseEndDate && errors.UseEndDate}
                            >
                              After No of Events
                            </CheckRadioBox>
                            <Input
                              id="newbook-Repetitions"
                              name="Repetitions"
                              type="number"
                              disabled={values.UseEndDate !== "false"}
                              min={1}
                              max={100}
                              placeholder="Occurences"
                              value={values.Repetitions}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.Repetitions && errors.Repetitions}
                            />
                            {!!errors.Repetitions && touched.Repetitions && (
                              <Error>{errors.Repetitions}</Error>
                            )}
                          </FormSplitItem>
                        </FormSplitContainer>
                      </Fragment>
                    )}
                  </Fragment>
                )}
                <ButtonContainer>
                  <Button
                    css={css`
                      width: 149px;
                    `}
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    SAVE
                  </Button>
                </ButtonContainer>
              </Form>
            </ScrollContainer>
          </Container>
        </Block>
      </Dialog>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: (props) => ({
    Type: "Appointment",
    ServiceType: "Group",
    RepeatType: "0",
    Service: null,
    Client: null,
    Week: [],
    Date: props.data.stuff.date || new Date(),
    StartTime: props.data.stuff.startTime || "",
    EndTime: props.data.stuff.endTime || "",
    FromDate: fns.parse(props.data.stuff.startTime, "HH:mm:ss", props.data.stuff.date),
    ToDate: fns.parse(props.data.stuff.endTime, "HH:mm:ss", props.data.stuff.date),
    MaxGroupSize: 1,
    UseEndDate: "true",
    EndDate: fns.format(new Date().setMonth(new Date().getMonth() + 1), "yyyy-MM-dd"),
    Repetitions: 10
  }),
  validationSchema: Yup.lazy((v) => {
    if (v.Type === "Appointment" && v.ServiceType === "Single") {
      return Yup.object().shape({
        Service: Yup.object().nullable(true).required("A service is required"),
        Client: Yup.object().nullable(true).required("A client is required"),
        Date: Yup.date().required("Day is required"),
        StartTime: Yup.string().required("Start time is required"),
        EndTime: Yup.string().required("End time is required")
      });
    } else if (v.Type === "Appointment") {
      return Yup.object().shape({
        Service: Yup.object().required("A service is required"),
        Date: Yup.date().required("Day is required"),
        StartTime: Yup.string().required("Start time is required"),
        EndTime: Yup.string().required("End time is required"),
        MaxGroupSize: Yup.number()
          .min(1, "Must be one or more.")
          .max(100, "Must be less than one hundred.")
          .required("End time is required")
      });
    } else {
      return Yup.object().shape({
        FromDate: Yup.date().required("From date is required"),
        ToDate: Yup.date().required("To date is required")
      });
    }
  }),
  handleSubmit: async (values, { setSubmitting, status, setFieldError, props }) => {
    const { dispatch, dialogId } = props;
    if (values.Type === "Appointment") {
      try {
        let startTime = moment(
          `${fns.format(values.Date, "yyyy-MM-dd")} ${values.StartTime}`,
          "YYYY-MM-DD HH:mm:ss"
        );
        let endTime = moment(
          `${fns.format(values.Date, "yyyy-MM-dd")} ${values.EndTime}`,
          "YYYY-MM-DD HH:mm:ss"
        );

        let type = BookingDTO.Type.BOOKING_GROUP;
        let WeekReqArray = mapWeekDaysToDTO(values.Week);

        bookingStore.Create(
          props.data.stuff.provider.Location.ID, // location
          values.MaxGroupSize, // group size
          props.data.stuff.provider.User.ID, // provider
          values.RepeatType, // daily, weekly or biweekly
          WeekReqArray,
          values.UseEndDate == "false" && values.RepeatType != "0" ? values.Repetitions : 0, // set repitions to 0 if not used
          startTime.toDate(),
          endTime.toDate(),
          values.UseEndDate == "true" && values.RepeatType != "0" ? values.EndDate : null,
          values.Service.ID,
          type
        );

        await rebookDataStore.reset();
        props.data.cb && props.data.cb();
        dispatch(ReduxDialogs.actions.close(dialogId));
        // The startDate and the endDate should be the date from the above and it should be in UTC because it send the string "yyyy-MM-dd" without timezone
        const queryDateInt = Math.round(
          Date.parse(fns.format(values.Date, "yyyy-MM-dd") + "T00:00:00+00:00") / 1000
        );
        bookingStore.loadByLocation(
          props.data.stuff.provider.Location.ID,
          intToTimestamp(queryDateInt),
          intToTimestamp(queryDateInt)
        );
      } catch (err) {
        parseFormError(err, values, setFieldError, props);
      }
    } else {
      try {
        let type = BookingDTO.Type.BOOKING_TIME_OFF;

        bookingStore.Create(
          props.data.stuff.provider.Location.ID, // location
          null, // group size
          props.data.stuff.provider.User.ID, // provider
          0, // daily, weekly or biweekly
          null,
          0, // set repitions to 0 if not used
          values.FromDate,
          values.ToDate,
          null,
          null,
          type
        );
        props.data.cb && props.data.cb();
        dispatch(ReduxDialogs.actions.close(dialogId));
      } catch (err) {
        parseFormError(err, values, setFieldError, props);
      }
    }

    setSubmitting(false);
  },
  displayName: "NewBooking"
});

export default compose(connect(), withToastManager, formikEnhancer)(ScheduleQuickBook);

const Block = styled.div`
  position: absolute;
  background-color: ${colors.surface.light};
  border-radius: 4px;
`;

const Container = styled.div`
  background-color: ${colors.surface.light};

  ${breakpoints["phone-only"]} {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }

  ${breakpoints["tablet-up"]} {
    position: absolute;
    border-radius: 4px;
  }
`;

const Form = styled.form`
  padding: 64px 24px;

  ${breakpoints["tablet-up"]} {
    padding: 54px 64px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HideVisually = styled.span`
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  @media screen and (min-width: 768px) {
    height: 540px;
  }
`;
