import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { withFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withToastManager } from "react-toast-notifications";

// Utils
import { parseFormError } from "../../../util/form";

// grpc
import { rpc, metadata } from "../../../grpc/grpc-legacy";
import { UpdateUserRequest, User, UpdateUserResponse } from "sdk/dist/user_pb";
import { stripeStore } from "../../../stores/stripe-store";

// Stores
import apiService from "../../../redux/services/api";
import { toastStore } from "../../../stores/toast-store";

// Custom components
import Button from "../../Button";
import SectionsContainer from "../../form/SectionsContainer";
import Section from "../../form/Section";
import Label from "../../form/Label";
import Input from "../../form/Input";
import Error from "../../form/Error";
import FormHeader from "../../form/FormHeader";
import Form from "../../form/Form";
import FooterContainer from "../../form/FooterContainer";
import MobileInput from "../../form/MobileInput";
import {
  CARD_ELEMENT_OPTIONS,
  StripeElementsContainer
} from "../../stripe/CreditCard/NewCardFormStripe";

import { getAuthenticatedUser } from "../../elements/AuthGetUser";

const IS_PHONE_REGEX = /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1})?[-\s\./0-9]*$/; // eslint-disable-line no-useless-escape

let history: any;

interface Props {
  data: any;
  values: Values;
  errors: any;
  touched: any;
  handleChange: () => void;
  handleBlur: () => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
  pageData: PageData;
  historys: any;
}

interface Values {
  FirstName: string;
  LastName: string;
  EmailPhone: string;
  PhoneEmail: string;
  Email: string;
  MobileNumber: string;
  MobileCountryCode: string;
  Password: string;
  PasswordCheck: string;
}

interface PageData {
  FirstName: string;
  LastName: string;
  EmailPhone: string;
  PhoneEmail: string;
  Email: string;
  MobileNumber: string;
  MobileCountryCode: string;
  Password: string;
  PasswordCheck: string;
  ID: string;
}

let cardElement: any;
let stripeInstance: any = null;
class UpdateUser extends Component<Props> {
  constructor(Props: Props) {
    super(Props);
  }

  async componentDidMount() {
    history = this.props.historys;
    stripeStore.loadScriptOnDom();
    stripeInstance = await stripeStore.getPKInstance();
    if (stripeInstance !== null) {
      var elements = stripeInstance.elements();
      if (CARD_ELEMENT_OPTIONS.style) {
        cardElement = elements.create("card", { style: CARD_ELEMENT_OPTIONS.style.base });
        cardElement.mount("#card-element");
      }
    }
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      pageData
    } = this.props;

    let country: string | undefined = undefined;
    if (this.props.pageData.MobileCountryCode) {
      country = this.props.pageData.MobileCountryCode.toLowerCase();
    }
    return (
      <Form onSubmit={handleSubmit}>
        <FormHeader
          title="Create Your Account"
          subtitle="Enter the correct details and a password to start using lyfe today!"
        />
        <SectionsContainer>
          <Section>
            <Label htmlFor="login-First-Name">First Name</Label>
            <Input
              id="login-First-Name"
              placeholder="First Name"
              type="text"
              name="FirstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.FirstName}
            />
            {!!errors.FirstName && touched.FirstName && <Error>{errors.FirstName}</Error>}
          </Section>
          <Section>
            <Label htmlFor="login-Last-Name">Last Name</Label>
            <Input
              id="login-Last-Name"
              placeholder="Last Name"
              type="text"
              name="LastName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.LastName}
            />
            {!!errors.LastName && touched.LastName && <Error>{errors.LastName}</Error>}
          </Section>
          {this.props.pageData.Email ? (
            <Section>
              <Label htmlFor="login-EmailPhone" style={{ color: "grey" }}>
                {this.props.pageData.Email ? "Email" : "Phone"}
              </Label>
              <Input
                id="login-EmailPhone"
                disabled={this.props.pageData.Email ? true : false}
                placeholder={this.props.pageData.Email}
                value={this.props.pageData.Email}
              />
            </Section>
          ) : (
            <Section>
              <Label htmlFor="addclient-Phone">Phone Number</Label>
              <MobileInput
                onCountryChange={(e) => {
                  values.MobileCountryCode = e;
                }}
                onPhoneChange={(e) => {
                  values.MobileNumber = e;
                }}
                disabled={this.props.pageData.Email ? false : true}
                defaultCountry={country}
                value={this.props.pageData.MobileNumber}
              />
            </Section>
          )}
          {this.props.pageData.Email ? (
            <Section>
              <Label htmlFor="addclient-Phone">Phone Number</Label>
              <MobileInput
                onCountryChange={(e) => {
                  values.MobileCountryCode = e;
                }}
                onPhoneChange={(e) => {
                  values.MobileNumber = e;
                }}
                value={values.MobileNumber}
              />
            </Section>
          ) : (
            <Section>
              <Label htmlFor="login-Email">Email</Label>
              <Input
                id="login-Email"
                name="Email"
                onChange={handleChange}
                placeholder={this.props.pageData.Email}
                value={values.Email}
                // value={this.props.pageData.Email ? this.props.pageData.MobileNumber: this.props.pageData.Email }
              />
            </Section>
          )}
          {!!errors.PhoneEmail && touched.PhoneEmail && <Error>{errors.PhoneEmail}</Error>}
          <Section>
            <Label htmlFor="signup-CreditCard">Credit Card</Label>
            <StripeElementsContainer>
              <div id="card-element"></div>
            </StripeElementsContainer>
          </Section>
          <Section>
            <Label htmlFor="login-Password">New Password</Label>
            <Input
              id="login-Password"
              placeholder="8+ characters"
              type="password"
              name="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Password}
            />
            {!!errors.Password && touched.Password && <Error>{errors.Password}</Error>}
          </Section>
          <Section>
            <Label htmlFor="login-PasswordCheck">Retype New Password</Label>
            <Input
              id="login-PasswordCheck"
              placeholder="8+ characters"
              type="password"
              name="PasswordCheck"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.PasswordCheck}
            />
            {!!errors.PasswordCheck && touched.PasswordCheck && (
              <Error>{errors.PasswordCheck}</Error>
            )}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <Button
            css={css`
              width: 200px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            Sign Up
          </Button>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: ({ pageData }: Props) => ({
    FirstName: pageData.FirstName,
    LastName: pageData.LastName,
    EmailPhone: pageData.EmailPhone,
    PhoneEmail: pageData.Email ? pageData.MobileNumber : pageData.Email,
    Email: pageData.Email,
    MobileNumber: pageData.MobileNumber,
    MobileCountryCode: pageData.MobileCountryCode,
    Password: "",
    PasswordCheck: ""
  }),
  validationSchema: Yup.object().shape({
    Password: Yup.string()
      .min(8, "Passwords have an 8 character minimum.")
      .required("Please enter your new password."),
    PasswordCheck: Yup.string()
      .min(8, "Passwords have an 8 character minimum.")
      .required("Please enter a matching password.")
      .oneOf([Yup.ref("Password"), null], "Passwords does not match")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    setSubmitting(true);
    try {
      if (!stripeInstance) {
        toastStore.error("stripe instance not defined");
        setSubmitting(false);
        return;
      }
      const result = await stripeInstance.createToken(cardElement);
      if (result.error) {
        toastStore.error("Credit card couldn't be added, error: \n" + result.error.message);
        setSubmitting(false);
        return;
      }
      if (!result.token) {
        toastStore.error("Stripe token undefined");
        setSubmitting(false);
        return;
      }
      let updateUserResponse: UpdateUserResponse.AsObject;
      const req = new UpdateUserRequest();
      const user = new User();
      user.setId(props.pageData.ID);
      user.setFirstName(values.FirstName.trim());
      user.setLastName(values.LastName.trim());
      user.setMobileNumber(values.MobileNumber ? values.MobileNumber : "");
      user.setMobileCountryCode(values.MobileCountryCode ? values.MobileCountryCode : "");
      user.setEmail(values.Email ? values.Email : "");
      user.setRole("Standard");

      req.setUser(user);
      req.setPassword(values.Password);
      req.setStripeCcToken(result.token.id);
      const res = await rpc.user.updateUser(req, metadata());
      updateUserResponse = res.toObject();
      const logRes = await apiService.userLogin(
        props.pageData.Email == "" ? null : props.pageData.Email,
        props.pageData.Email == "" ? props.pageData.MobileNumber : null,
        props.pageData.Email == "" ? props.pageData.MobileCountryCode.toUpperCase() : null,
        values.Password
      );
      // window.location.href = '/';
      props.historys.push("/");
    } catch (err) {
      if (err.code && err.message) {
        toastStore.grpcError(err);
      } else {
        parseFormError(err, values, setFieldError, props);
      }
    }
    setSubmitting(false);
  },
  displayName: "LoginForm"
});

export default compose<any, any>(
  connect((state) => ({ authUser: getAuthenticatedUser(state) })),
  withToastManager,
  formikEnhancer
)(UpdateUser);
