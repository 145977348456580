import React, { PureComponent } from "react";

export default class Minus extends PureComponent {
  render() {
    const { ...otherProps } = this.props;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#D2D5E3"
        {...otherProps}
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M18 13H6c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1z" />
      </svg>
    );
  }
}
