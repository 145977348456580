import styled from "@emotion/styled";
import convert from "color-convert";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { ExternalServiceDTO } from "../../../ts-sdk/dist/bookings";
import { ThirdPartyBookingDTO } from "../../../ts-sdk/dist/bookings";
import { splitTime } from "../../../util/index";
import typography from "../../../util/typography";
import { useGrpc } from "../../../hooks/useGrpc";
import { useAsync } from "../../../hooks/useAsync";
import * as datefns from "date-fns";

interface IProps {
  startDate: string;
  endDate: string;
  openingHours: { OpensAt: string; ClosesAt: string };
  locationId: string;
}

export const ThirdPartyCards = (props: IProps) => {
  const grpc = useGrpc();

  const thirdPartyBookingsFetcher = useAsync(async () => {
    const reqStart = datefns.startOfDay(new Date(props.startDate));
    const reqEnd = datefns.endOfDay(new Date(props.endDate));
    const res = await grpc.services.bookingsService.ListThirdPartyBookings({
      locationId: props.locationId,
      startDatetime: reqStart,
      endDatetime: reqEnd
    });
    return res.bookings;
  }, [props.locationId, props.startDate, props.endDate]);

  if (thirdPartyBookingsFetcher.value === undefined) {
    return null;
  }
  const [openingHour, openingMinute] = splitTime(props.openingHours.OpensAt);
  const [closingHour, closingMinute] = splitTime(props.openingHours.ClosesAt);

  return thirdPartyBookingsFetcher.value
    .sort((a, b) => (a.startDatetime! > b.startDatetime! ? 1 : -1))
    .filter((a) => {
      return (
        (a.startDatetime!.getHours() > openingHour ||
          (a.startDatetime!.getHours() >= openingHour &&
            a.startDatetime!.getMinutes() >= openingMinute)) &&
        (a.endDatetime!.getHours() < closingHour ||
          (a.endDatetime!.getHours() <= closingHour &&
            a.endDatetime!.getMinutes() <= closingMinute))
      );
    })
    .map((c) => (
      <ThirdPartyCard
        key={c.externalId}
        startDate={props.startDate}
        endDate={props.endDate}
        openingHours={props.openingHours}
        booking={c}
      />
    ));
};

interface CardProps {
  startDate: string;
  endDate: string;
  openingHours: { OpensAt: string; ClosesAt: string };
  booking: ThirdPartyBookingDTO;
}

const ThirdPartyCard = (props: CardProps) => {
  const { booking, openingHours, startDate, endDate } = props;

  const color = props.booking.isMapped ? "#70F" : "#dddddd";

  const [hue, saturation] = convert.hex.hsl(color);
  const lightness = "97%";
  let lighter = "hsl(" + hue + ", " + saturation + "%, " + lightness + ")";

  const style = useMemo(() => {
    let startDatetime = format(booking.startDatetime!, "yyyy-mm-dd HH:mm");
    let endDatetime = format(booking.endDatetime!, "yyyy-mm-dd HH:mm");
    if (startDatetime.localeCompare(startDate) < 0) {
      startDatetime = openingHours.OpensAt;
    }
    if (endDatetime.localeCompare(endDate) > 0) {
      endDatetime = openingHours.ClosesAt;
    }
    if (startDatetime.localeCompare(openingHours.OpensAt) < 0) {
      startDatetime = openingHours.ClosesAt;
    }
    if (endDatetime.localeCompare(openingHours.ClosesAt) > 0) {
      endDatetime = openingHours.ClosesAt;
    }
    const [startHour, startMinute] = splitTime(format(booking.startDatetime!, "HH:mm"));
    const [endHour, endMinute] = splitTime(format(booking.endDatetime!, "HH:mm"));

    const durationInMinutes = endHour * 60 + endMinute - (startHour * 60 + startMinute);
    const height = durationInMinutes * 2 - 2;

    const [openingStartHour, openingStartMinute] = splitTime(openingHours.OpensAt);
    const timeSinceOpenInMinutes =
      startHour * 60 + startMinute - (openingStartHour * 60 + openingStartMinute);
    const top = timeSinceOpenInMinutes * 2;

    const topBase = 62;
    return {
      height: height,
      top: topBase + top
    };
  }, [
    openingHours.OpensAt,
    openingHours.ClosesAt,
    booking.startDatetime,
    booking.endDatetime,
    endDate
  ]);

  const renderCardTitle = () => {
    const { booking } = props;
    return props.booking.isMapped ? booking.name : `${booking.name}`;
  };

  const renderCardDate = () => {
    const { booking } = props;
    return (
      <React.Fragment>
        {format(booking.startDatetime!, "HH:mm")} - {format(booking.endDatetime!, "HH:mm")}
      </React.Fragment>
    );
  };

  const renderCardBody = () => {
    // TODO: add capacity utilisation
    return booking.staffName;
  };

  const renderCardFooter = () => {
    switch (booking.externalService) {
      case ExternalServiceDTO.MINDBODY_SERVICE:
        return props.booking.isMapped ? "Mindbody - Synced to Lyfe" : "Mindbody Only";
      default:
        return "";
    }
  };

  return (
    <Card
      {...props}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{
        ...style,
        borderColor: color,
        backgroundColor: lighter
      }}
    >
      {booking && (
        <CardContainer>
          <CardTitle>{renderCardTitle()}</CardTitle>
          <CardText>{renderCardDate()}</CardText>
          <CardText>{renderCardBody()}</CardText>
          <CardText>
            <em>{renderCardFooter()}</em>
          </CardText>
        </CardContainer>
      )}
    </Card>
  );
};

const Card = styled.div`
  position: absolute;
  width: 100%;
  height: 58px;
  padding: 6px 13px 8px;
  border-top: 4px solid transparent;
  position: absolute;
  left: 0;
  top: 62px;
  z-index: 2;
  overflow: hidden;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardTitle = styled.span`
  color: rgba(44, 46, 60, 1);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.12px;
  line-height: 14px;
  ${typography.elipse};
`;

const CardText = styled.span`
  color: rgba(44, 46, 60, 1);
  font-size: 10px;
  letter-spacing: 0.11px;
  line-height: 10px;
  margin-top: 1px;
  ${typography.elipse};
`;
