import { AppConfig } from "../AppConfig";
import { GrpcInterceptor, IMiddleware } from "./GrpcInterceptor";
import {
  CorporateMembershipsService,
  CorporateMembershipsServiceClientImpl,
  GrpcWebImpl
} from "../ts-sdk/dist/corporate_memberships";
import { BrowserHeaders } from "browser-headers";
import {
  BookingClients,
  BookingClientsClientImpl,
  Bookings,
  BookingsClientImpl
} from "../ts-sdk/dist/bookings";
import {
  CorporateProviderLocationsService,
  CorporateProviderLocationsServiceClientImpl
} from "../ts-sdk/dist/corporate_provider_locations";
import {
  CorporateCreditRecordsService,
  CorporateCreditRecordsServiceClientImpl
} from "../ts-sdk/dist/corporate_credit_records";
import {
  CorporateUsersService,
  CorporateUsersServiceClientImpl
} from "../ts-sdk/dist/corporate_users";
import { OrganisationService, OrganisationServiceClientImpl } from "../ts-sdk/dist/organisation";
import { Offerings, OfferingsClientImpl } from "../ts-sdk/dist/offerings";
import {
  LocationBookingService,
  LocationBookingServiceClientImpl,
  LocationService,
  LocationServiceClientImpl
} from "../ts-sdk/dist/location";
import { MindbodyService, MindbodyServiceClientImpl } from "../ts-sdk/dist/mindbody";

export interface IGrpcService {
  services: {
    bookingsService: Bookings;
    bookingClientsService: BookingClients;
    corporateMembershipsService: CorporateMembershipsService;
    corporateProviderLocationsService: CorporateProviderLocationsService;
    corporateCreditRecordsService: CorporateCreditRecordsService;
    corporateUsersService: CorporateUsersService;
    offeringsService: Offerings;
    organisationService: OrganisationService;
    locationService: LocationService;
    locationBookingService: LocationBookingService;
    mindbodyService: MindbodyService;
  };
}

export class GrpcService implements IGrpcService {
  constructor(
    private host: string,
    private middleware: IMiddleware[],
    private getBearerToken: () => string
  ) {}

  get services() {
    const rpc = new GrpcInterceptor(
      new GrpcWebImpl(this.host, {
        debug: AppConfig.BOOKLYFE_VERSION === "development",
        metadata: this.metadata
      }),
      this.middleware
    );

    return {
      bookingsService: new BookingsClientImpl(rpc),
      bookingClientsService: new BookingClientsClientImpl(rpc),
      corporateMembershipsService: new CorporateMembershipsServiceClientImpl(rpc),
      corporateProviderLocationsService: new CorporateProviderLocationsServiceClientImpl(rpc),
      corporateCreditRecordsService: new CorporateCreditRecordsServiceClientImpl(rpc),
      corporateUsersService: new CorporateUsersServiceClientImpl(rpc),
      offeringsService: new OfferingsClientImpl(rpc),
      organisationService: new OrganisationServiceClientImpl(rpc),
      locationService: new LocationServiceClientImpl(rpc),
      locationBookingService: new LocationBookingServiceClientImpl(rpc),
      mindbodyService: new MindbodyServiceClientImpl(rpc)
    };
  }

  private get metadata(): BrowserHeaders {
    return new BrowserHeaders({
      Authorization: this.getBearerToken()
    });
  }
}
