/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";
import { Timestamp } from "./google/protobuf/timestamp";
import { Media } from "./media";
import { Money } from "./money";

export const protobufPackage = "proto";

export enum UserType {
  /** USER_TYPE_UNKNOWN - default type */
  USER_TYPE_UNKNOWN = 0,
  USER_TYPE_LYFE = 1,
  USER_TYPE_ATHLETEHUB = 2,
  UNRECOGNIZED = -1,
}

export function userTypeFromJSON(object: any): UserType {
  switch (object) {
    case 0:
    case "USER_TYPE_UNKNOWN":
      return UserType.USER_TYPE_UNKNOWN;
    case 1:
    case "USER_TYPE_LYFE":
      return UserType.USER_TYPE_LYFE;
    case 2:
    case "USER_TYPE_ATHLETEHUB":
      return UserType.USER_TYPE_ATHLETEHUB;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserType.UNRECOGNIZED;
  }
}

export function userTypeToJSON(object: UserType): string {
  switch (object) {
    case UserType.USER_TYPE_UNKNOWN:
      return "USER_TYPE_UNKNOWN";
    case UserType.USER_TYPE_LYFE:
      return "USER_TYPE_LYFE";
    case UserType.USER_TYPE_ATHLETEHUB:
      return "USER_TYPE_ATHLETEHUB";
    case UserType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ResetPasswordReq {
  token: string;
  password: string;
  subjectId: string;
}

export interface ResetPasswordRes {
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  dateOfBirth: string;
  email: string;
  fundProperties: string;
  gender: string;
  joinedAt: string;
  lastModifiedAt: string;
  lastPasswordReset: string;
  mobileCountryCode: string;
  mobileNumber: string;
  passwordExpiry: string;
  passwordHash: string;
  photo: Media | undefined;
  role: string;
  status: string;
  validatedFields: string[];
  address: string;
  type: UserType;
  corporateUser: CorporateUser | undefined;
}

export interface CorporateUser {
  id: string;
  corporate: string;
  user: string;
  remainingCredits: Money | undefined;
}

export interface AddUserRequest {
  user: User | undefined;
  clientId: string;
}

export interface UpdateUserRequest {
  user: User | undefined;
  clientId: string;
  stripeCcToken: string;
  password: string;
}

export interface UpdateUserResponse {
  id: string;
}

export interface AddUserResponse {
  id: string;
}

export interface UserLoggedInResponse {
  isLoggedIn: boolean;
  isActive: boolean;
}

export interface GetUserRequest {
  /**
   * either user_id or subject_id must be provided,
   * an empty string can be used for the id that isn't used.
   */
  userId: string;
  subjectId: string;
}

export interface GetUserResponse {
  user: User | undefined;
}

export interface CreateUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  mobileCountryCode: string;
  mobileNumber: string;
  password: string;
  type: UserType;
  stripeCcToken: string;
  dateOfBirth: Date | undefined;
  gender: string;
}

export interface CreateUserResponse {
  id: string;
}

export interface CreateMobileUserAccountRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  gender: string;
}

export interface CreateMobileUserAccountResponse {
}

function createBaseResetPasswordReq(): ResetPasswordReq {
  return { token: "", password: "", subjectId: "" };
}

export const ResetPasswordReq = {
  encode(message: ResetPasswordReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    if (message.subjectId !== "") {
      writer.uint32(26).string(message.subjectId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetPasswordReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetPasswordReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subjectId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResetPasswordReq {
    return {
      token: isSet(object.token) ? globalThis.String(object.token) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
      subjectId: isSet(object.subjectId) ? globalThis.String(object.subjectId) : "",
    };
  },

  toJSON(message: ResetPasswordReq): unknown {
    const obj: any = {};
    if (message.token !== "") {
      obj.token = message.token;
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    if (message.subjectId !== "") {
      obj.subjectId = message.subjectId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ResetPasswordReq>, I>>(base?: I): ResetPasswordReq {
    return ResetPasswordReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetPasswordReq>, I>>(object: I): ResetPasswordReq {
    const message = createBaseResetPasswordReq();
    message.token = object.token ?? "";
    message.password = object.password ?? "";
    message.subjectId = object.subjectId ?? "";
    return message;
  },
};

function createBaseResetPasswordRes(): ResetPasswordRes {
  return {};
}

export const ResetPasswordRes = {
  encode(_: ResetPasswordRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetPasswordRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetPasswordRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ResetPasswordRes {
    return {};
  },

  toJSON(_: ResetPasswordRes): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ResetPasswordRes>, I>>(base?: I): ResetPasswordRes {
    return ResetPasswordRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetPasswordRes>, I>>(_: I): ResetPasswordRes {
    const message = createBaseResetPasswordRes();
    return message;
  },
};

function createBaseUser(): User {
  return {
    id: "",
    firstName: "",
    lastName: "",
    createdAt: "",
    dateOfBirth: "",
    email: "",
    fundProperties: "",
    gender: "",
    joinedAt: "",
    lastModifiedAt: "",
    lastPasswordReset: "",
    mobileCountryCode: "",
    mobileNumber: "",
    passwordExpiry: "",
    passwordHash: "",
    photo: undefined,
    role: "",
    status: "",
    validatedFields: [],
    address: "",
    type: 0,
    corporateUser: undefined,
  };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.firstName !== "") {
      writer.uint32(18).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(26).string(message.lastName);
    }
    if (message.createdAt !== "") {
      writer.uint32(34).string(message.createdAt);
    }
    if (message.dateOfBirth !== "") {
      writer.uint32(42).string(message.dateOfBirth);
    }
    if (message.email !== "") {
      writer.uint32(50).string(message.email);
    }
    if (message.fundProperties !== "") {
      writer.uint32(58).string(message.fundProperties);
    }
    if (message.gender !== "") {
      writer.uint32(66).string(message.gender);
    }
    if (message.joinedAt !== "") {
      writer.uint32(74).string(message.joinedAt);
    }
    if (message.lastModifiedAt !== "") {
      writer.uint32(82).string(message.lastModifiedAt);
    }
    if (message.lastPasswordReset !== "") {
      writer.uint32(90).string(message.lastPasswordReset);
    }
    if (message.mobileCountryCode !== "") {
      writer.uint32(98).string(message.mobileCountryCode);
    }
    if (message.mobileNumber !== "") {
      writer.uint32(106).string(message.mobileNumber);
    }
    if (message.passwordExpiry !== "") {
      writer.uint32(114).string(message.passwordExpiry);
    }
    if (message.passwordHash !== "") {
      writer.uint32(122).string(message.passwordHash);
    }
    if (message.photo !== undefined) {
      Media.encode(message.photo, writer.uint32(130).fork()).ldelim();
    }
    if (message.role !== "") {
      writer.uint32(138).string(message.role);
    }
    if (message.status !== "") {
      writer.uint32(146).string(message.status);
    }
    for (const v of message.validatedFields) {
      writer.uint32(154).string(v!);
    }
    if (message.address !== "") {
      writer.uint32(162).string(message.address);
    }
    if (message.type !== 0) {
      writer.uint32(168).int32(message.type);
    }
    if (message.corporateUser !== undefined) {
      CorporateUser.encode(message.corporateUser, writer.uint32(178).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.dateOfBirth = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.email = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fundProperties = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.gender = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.joinedAt = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lastModifiedAt = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.lastPasswordReset = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.mobileCountryCode = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.mobileNumber = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.passwordExpiry = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.passwordHash = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.photo = Media.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.role = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.status = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.validatedFields.push(reader.string());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.address = reader.string();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.corporateUser = CorporateUser.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      createdAt: isSet(object.createdAt) ? globalThis.String(object.createdAt) : "",
      dateOfBirth: isSet(object.dateOfBirth) ? globalThis.String(object.dateOfBirth) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      fundProperties: isSet(object.fundProperties) ? globalThis.String(object.fundProperties) : "",
      gender: isSet(object.gender) ? globalThis.String(object.gender) : "",
      joinedAt: isSet(object.joinedAt) ? globalThis.String(object.joinedAt) : "",
      lastModifiedAt: isSet(object.lastModifiedAt) ? globalThis.String(object.lastModifiedAt) : "",
      lastPasswordReset: isSet(object.lastPasswordReset) ? globalThis.String(object.lastPasswordReset) : "",
      mobileCountryCode: isSet(object.mobileCountryCode) ? globalThis.String(object.mobileCountryCode) : "",
      mobileNumber: isSet(object.mobileNumber) ? globalThis.String(object.mobileNumber) : "",
      passwordExpiry: isSet(object.passwordExpiry) ? globalThis.String(object.passwordExpiry) : "",
      passwordHash: isSet(object.passwordHash) ? globalThis.String(object.passwordHash) : "",
      photo: isSet(object.photo) ? Media.fromJSON(object.photo) : undefined,
      role: isSet(object.role) ? globalThis.String(object.role) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      validatedFields: globalThis.Array.isArray(object?.validatedFields)
        ? object.validatedFields.map((e: any) => globalThis.String(e))
        : [],
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      type: isSet(object.type) ? userTypeFromJSON(object.type) : 0,
      corporateUser: isSet(object.corporateUser) ? CorporateUser.fromJSON(object.corporateUser) : undefined,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.dateOfBirth !== "") {
      obj.dateOfBirth = message.dateOfBirth;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.fundProperties !== "") {
      obj.fundProperties = message.fundProperties;
    }
    if (message.gender !== "") {
      obj.gender = message.gender;
    }
    if (message.joinedAt !== "") {
      obj.joinedAt = message.joinedAt;
    }
    if (message.lastModifiedAt !== "") {
      obj.lastModifiedAt = message.lastModifiedAt;
    }
    if (message.lastPasswordReset !== "") {
      obj.lastPasswordReset = message.lastPasswordReset;
    }
    if (message.mobileCountryCode !== "") {
      obj.mobileCountryCode = message.mobileCountryCode;
    }
    if (message.mobileNumber !== "") {
      obj.mobileNumber = message.mobileNumber;
    }
    if (message.passwordExpiry !== "") {
      obj.passwordExpiry = message.passwordExpiry;
    }
    if (message.passwordHash !== "") {
      obj.passwordHash = message.passwordHash;
    }
    if (message.photo !== undefined) {
      obj.photo = Media.toJSON(message.photo);
    }
    if (message.role !== "") {
      obj.role = message.role;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.validatedFields?.length) {
      obj.validatedFields = message.validatedFields;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.type !== 0) {
      obj.type = userTypeToJSON(message.type);
    }
    if (message.corporateUser !== undefined) {
      obj.corporateUser = CorporateUser.toJSON(message.corporateUser);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.id = object.id ?? "";
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.createdAt = object.createdAt ?? "";
    message.dateOfBirth = object.dateOfBirth ?? "";
    message.email = object.email ?? "";
    message.fundProperties = object.fundProperties ?? "";
    message.gender = object.gender ?? "";
    message.joinedAt = object.joinedAt ?? "";
    message.lastModifiedAt = object.lastModifiedAt ?? "";
    message.lastPasswordReset = object.lastPasswordReset ?? "";
    message.mobileCountryCode = object.mobileCountryCode ?? "";
    message.mobileNumber = object.mobileNumber ?? "";
    message.passwordExpiry = object.passwordExpiry ?? "";
    message.passwordHash = object.passwordHash ?? "";
    message.photo = (object.photo !== undefined && object.photo !== null) ? Media.fromPartial(object.photo) : undefined;
    message.role = object.role ?? "";
    message.status = object.status ?? "";
    message.validatedFields = object.validatedFields?.map((e) => e) || [];
    message.address = object.address ?? "";
    message.type = object.type ?? 0;
    message.corporateUser = (object.corporateUser !== undefined && object.corporateUser !== null)
      ? CorporateUser.fromPartial(object.corporateUser)
      : undefined;
    return message;
  },
};

function createBaseCorporateUser(): CorporateUser {
  return { id: "", corporate: "", user: "", remainingCredits: undefined };
}

export const CorporateUser = {
  encode(message: CorporateUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.corporate !== "") {
      writer.uint32(18).string(message.corporate);
    }
    if (message.user !== "") {
      writer.uint32(26).string(message.user);
    }
    if (message.remainingCredits !== undefined) {
      Money.encode(message.remainingCredits, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CorporateUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCorporateUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.corporate = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.user = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.remainingCredits = Money.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CorporateUser {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      corporate: isSet(object.corporate) ? globalThis.String(object.corporate) : "",
      user: isSet(object.user) ? globalThis.String(object.user) : "",
      remainingCredits: isSet(object.remainingCredits) ? Money.fromJSON(object.remainingCredits) : undefined,
    };
  },

  toJSON(message: CorporateUser): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.corporate !== "") {
      obj.corporate = message.corporate;
    }
    if (message.user !== "") {
      obj.user = message.user;
    }
    if (message.remainingCredits !== undefined) {
      obj.remainingCredits = Money.toJSON(message.remainingCredits);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CorporateUser>, I>>(base?: I): CorporateUser {
    return CorporateUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CorporateUser>, I>>(object: I): CorporateUser {
    const message = createBaseCorporateUser();
    message.id = object.id ?? "";
    message.corporate = object.corporate ?? "";
    message.user = object.user ?? "";
    message.remainingCredits = (object.remainingCredits !== undefined && object.remainingCredits !== null)
      ? Money.fromPartial(object.remainingCredits)
      : undefined;
    return message;
  },
};

function createBaseAddUserRequest(): AddUserRequest {
  return { user: undefined, clientId: "" };
}

export const AddUserRequest = {
  encode(message: AddUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddUserRequest {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: AddUserRequest): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddUserRequest>, I>>(base?: I): AddUserRequest {
    return AddUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddUserRequest>, I>>(object: I): AddUserRequest {
    const message = createBaseAddUserRequest();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseUpdateUserRequest(): UpdateUserRequest {
  return { user: undefined, clientId: "", stripeCcToken: "", password: "" };
}

export const UpdateUserRequest = {
  encode(message: UpdateUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.clientId !== "") {
      writer.uint32(18).string(message.clientId);
    }
    if (message.stripeCcToken !== "") {
      writer.uint32(26).string(message.stripeCcToken);
    }
    if (message.password !== "") {
      writer.uint32(34).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.stripeCcToken = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateUserRequest {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      stripeCcToken: isSet(object.stripeCcToken) ? globalThis.String(object.stripeCcToken) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
    };
  },

  toJSON(message: UpdateUserRequest): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.stripeCcToken !== "") {
      obj.stripeCcToken = message.stripeCcToken;
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUserRequest>, I>>(base?: I): UpdateUserRequest {
    return UpdateUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUserRequest>, I>>(object: I): UpdateUserRequest {
    const message = createBaseUpdateUserRequest();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.clientId = object.clientId ?? "";
    message.stripeCcToken = object.stripeCcToken ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseUpdateUserResponse(): UpdateUserResponse {
  return { id: "" };
}

export const UpdateUserResponse = {
  encode(message: UpdateUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateUserResponse {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: UpdateUserResponse): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUserResponse>, I>>(base?: I): UpdateUserResponse {
    return UpdateUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUserResponse>, I>>(object: I): UpdateUserResponse {
    const message = createBaseUpdateUserResponse();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseAddUserResponse(): AddUserResponse {
  return { id: "" };
}

export const AddUserResponse = {
  encode(message: AddUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddUserResponse {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: AddUserResponse): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddUserResponse>, I>>(base?: I): AddUserResponse {
    return AddUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddUserResponse>, I>>(object: I): AddUserResponse {
    const message = createBaseAddUserResponse();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseUserLoggedInResponse(): UserLoggedInResponse {
  return { isLoggedIn: false, isActive: false };
}

export const UserLoggedInResponse = {
  encode(message: UserLoggedInResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isLoggedIn === true) {
      writer.uint32(8).bool(message.isLoggedIn);
    }
    if (message.isActive === true) {
      writer.uint32(16).bool(message.isActive);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserLoggedInResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserLoggedInResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isLoggedIn = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isActive = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserLoggedInResponse {
    return {
      isLoggedIn: isSet(object.isLoggedIn) ? globalThis.Boolean(object.isLoggedIn) : false,
      isActive: isSet(object.isActive) ? globalThis.Boolean(object.isActive) : false,
    };
  },

  toJSON(message: UserLoggedInResponse): unknown {
    const obj: any = {};
    if (message.isLoggedIn === true) {
      obj.isLoggedIn = message.isLoggedIn;
    }
    if (message.isActive === true) {
      obj.isActive = message.isActive;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserLoggedInResponse>, I>>(base?: I): UserLoggedInResponse {
    return UserLoggedInResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserLoggedInResponse>, I>>(object: I): UserLoggedInResponse {
    const message = createBaseUserLoggedInResponse();
    message.isLoggedIn = object.isLoggedIn ?? false;
    message.isActive = object.isActive ?? false;
    return message;
  },
};

function createBaseGetUserRequest(): GetUserRequest {
  return { userId: "", subjectId: "" };
}

export const GetUserRequest = {
  encode(message: GetUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.subjectId !== "") {
      writer.uint32(18).string(message.subjectId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.subjectId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserRequest {
    return {
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      subjectId: isSet(object.subjectId) ? globalThis.String(object.subjectId) : "",
    };
  },

  toJSON(message: GetUserRequest): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.subjectId !== "") {
      obj.subjectId = message.subjectId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserRequest>, I>>(base?: I): GetUserRequest {
    return GetUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserRequest>, I>>(object: I): GetUserRequest {
    const message = createBaseGetUserRequest();
    message.userId = object.userId ?? "";
    message.subjectId = object.subjectId ?? "";
    return message;
  },
};

function createBaseGetUserResponse(): GetUserResponse {
  return { user: undefined };
}

export const GetUserResponse = {
  encode(message: GetUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserResponse {
    return { user: isSet(object.user) ? User.fromJSON(object.user) : undefined };
  },

  toJSON(message: GetUserResponse): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserResponse>, I>>(base?: I): GetUserResponse {
    return GetUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserResponse>, I>>(object: I): GetUserResponse {
    const message = createBaseGetUserResponse();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseCreateUserRequest(): CreateUserRequest {
  return {
    firstName: "",
    lastName: "",
    email: "",
    mobileCountryCode: "",
    mobileNumber: "",
    password: "",
    type: 0,
    stripeCcToken: "",
    dateOfBirth: undefined,
    gender: "",
  };
}

export const CreateUserRequest = {
  encode(message: CreateUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.firstName !== "") {
      writer.uint32(10).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(18).string(message.lastName);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.mobileCountryCode !== "") {
      writer.uint32(34).string(message.mobileCountryCode);
    }
    if (message.mobileNumber !== "") {
      writer.uint32(42).string(message.mobileNumber);
    }
    if (message.password !== "") {
      writer.uint32(50).string(message.password);
    }
    if (message.type !== 0) {
      writer.uint32(56).int32(message.type);
    }
    if (message.stripeCcToken !== "") {
      writer.uint32(66).string(message.stripeCcToken);
    }
    if (message.dateOfBirth !== undefined) {
      Timestamp.encode(toTimestamp(message.dateOfBirth), writer.uint32(74).fork()).ldelim();
    }
    if (message.gender !== "") {
      writer.uint32(82).string(message.gender);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.mobileCountryCode = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.mobileNumber = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.password = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.stripeCcToken = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.dateOfBirth = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.gender = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateUserRequest {
    return {
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      mobileCountryCode: isSet(object.mobileCountryCode) ? globalThis.String(object.mobileCountryCode) : "",
      mobileNumber: isSet(object.mobileNumber) ? globalThis.String(object.mobileNumber) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
      type: isSet(object.type) ? userTypeFromJSON(object.type) : 0,
      stripeCcToken: isSet(object.stripeCcToken) ? globalThis.String(object.stripeCcToken) : "",
      dateOfBirth: isSet(object.dateOfBirth) ? fromJsonTimestamp(object.dateOfBirth) : undefined,
      gender: isSet(object.gender) ? globalThis.String(object.gender) : "",
    };
  },

  toJSON(message: CreateUserRequest): unknown {
    const obj: any = {};
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.mobileCountryCode !== "") {
      obj.mobileCountryCode = message.mobileCountryCode;
    }
    if (message.mobileNumber !== "") {
      obj.mobileNumber = message.mobileNumber;
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    if (message.type !== 0) {
      obj.type = userTypeToJSON(message.type);
    }
    if (message.stripeCcToken !== "") {
      obj.stripeCcToken = message.stripeCcToken;
    }
    if (message.dateOfBirth !== undefined) {
      obj.dateOfBirth = message.dateOfBirth.toISOString();
    }
    if (message.gender !== "") {
      obj.gender = message.gender;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateUserRequest>, I>>(base?: I): CreateUserRequest {
    return CreateUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateUserRequest>, I>>(object: I): CreateUserRequest {
    const message = createBaseCreateUserRequest();
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.email = object.email ?? "";
    message.mobileCountryCode = object.mobileCountryCode ?? "";
    message.mobileNumber = object.mobileNumber ?? "";
    message.password = object.password ?? "";
    message.type = object.type ?? 0;
    message.stripeCcToken = object.stripeCcToken ?? "";
    message.dateOfBirth = object.dateOfBirth ?? undefined;
    message.gender = object.gender ?? "";
    return message;
  },
};

function createBaseCreateUserResponse(): CreateUserResponse {
  return { id: "" };
}

export const CreateUserResponse = {
  encode(message: CreateUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateUserResponse {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: CreateUserResponse): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateUserResponse>, I>>(base?: I): CreateUserResponse {
    return CreateUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateUserResponse>, I>>(object: I): CreateUserResponse {
    const message = createBaseCreateUserResponse();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseCreateMobileUserAccountRequest(): CreateMobileUserAccountRequest {
  return { firstName: "", lastName: "", email: "", password: "", gender: "" };
}

export const CreateMobileUserAccountRequest = {
  encode(message: CreateMobileUserAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.firstName !== "") {
      writer.uint32(10).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(18).string(message.lastName);
    }
    if (message.email !== "") {
      writer.uint32(26).string(message.email);
    }
    if (message.password !== "") {
      writer.uint32(50).string(message.password);
    }
    if (message.gender !== "") {
      writer.uint32(74).string(message.gender);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateMobileUserAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMobileUserAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.password = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.gender = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateMobileUserAccountRequest {
    return {
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
      gender: isSet(object.gender) ? globalThis.String(object.gender) : "",
    };
  },

  toJSON(message: CreateMobileUserAccountRequest): unknown {
    const obj: any = {};
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    if (message.gender !== "") {
      obj.gender = message.gender;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateMobileUserAccountRequest>, I>>(base?: I): CreateMobileUserAccountRequest {
    return CreateMobileUserAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateMobileUserAccountRequest>, I>>(
    object: I,
  ): CreateMobileUserAccountRequest {
    const message = createBaseCreateMobileUserAccountRequest();
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.email = object.email ?? "";
    message.password = object.password ?? "";
    message.gender = object.gender ?? "";
    return message;
  },
};

function createBaseCreateMobileUserAccountResponse(): CreateMobileUserAccountResponse {
  return {};
}

export const CreateMobileUserAccountResponse = {
  encode(_: CreateMobileUserAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateMobileUserAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMobileUserAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CreateMobileUserAccountResponse {
    return {};
  },

  toJSON(_: CreateMobileUserAccountResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateMobileUserAccountResponse>, I>>(base?: I): CreateMobileUserAccountResponse {
    return CreateMobileUserAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateMobileUserAccountResponse>, I>>(_: I): CreateMobileUserAccountResponse {
    const message = createBaseCreateMobileUserAccountResponse();
    return message;
  },
};

export interface UserService {
  IsUserLoggedIn(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<UserLoggedInResponse>;
  GetUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<GetUserResponse>;
  AddUser(request: DeepPartial<AddUserRequest>, metadata?: grpc.Metadata): Promise<AddUserResponse>;
  UpdateUser(request: DeepPartial<UpdateUserRequest>, metadata?: grpc.Metadata): Promise<UpdateUserResponse>;
  CreateUser(request: DeepPartial<CreateUserRequest>, metadata?: grpc.Metadata): Promise<CreateUserResponse>;
  /** API only for Lyfe Mobile */
  CreateMobileUserAccount(
    request: DeepPartial<CreateMobileUserAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateMobileUserAccountResponse>;
  ResetPassword(request: DeepPartial<ResetPasswordReq>, metadata?: grpc.Metadata): Promise<ResetPasswordRes>;
}

export class UserServiceClientImpl implements UserService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.IsUserLoggedIn = this.IsUserLoggedIn.bind(this);
    this.GetUser = this.GetUser.bind(this);
    this.AddUser = this.AddUser.bind(this);
    this.UpdateUser = this.UpdateUser.bind(this);
    this.CreateUser = this.CreateUser.bind(this);
    this.CreateMobileUserAccount = this.CreateMobileUserAccount.bind(this);
    this.ResetPassword = this.ResetPassword.bind(this);
  }

  IsUserLoggedIn(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<UserLoggedInResponse> {
    return this.rpc.unary(UserServiceIsUserLoggedInDesc, Empty.fromPartial(request), metadata);
  }

  GetUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<GetUserResponse> {
    return this.rpc.unary(UserServiceGetUserDesc, GetUserRequest.fromPartial(request), metadata);
  }

  AddUser(request: DeepPartial<AddUserRequest>, metadata?: grpc.Metadata): Promise<AddUserResponse> {
    return this.rpc.unary(UserServiceAddUserDesc, AddUserRequest.fromPartial(request), metadata);
  }

  UpdateUser(request: DeepPartial<UpdateUserRequest>, metadata?: grpc.Metadata): Promise<UpdateUserResponse> {
    return this.rpc.unary(UserServiceUpdateUserDesc, UpdateUserRequest.fromPartial(request), metadata);
  }

  CreateUser(request: DeepPartial<CreateUserRequest>, metadata?: grpc.Metadata): Promise<CreateUserResponse> {
    return this.rpc.unary(UserServiceCreateUserDesc, CreateUserRequest.fromPartial(request), metadata);
  }

  CreateMobileUserAccount(
    request: DeepPartial<CreateMobileUserAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateMobileUserAccountResponse> {
    return this.rpc.unary(
      UserServiceCreateMobileUserAccountDesc,
      CreateMobileUserAccountRequest.fromPartial(request),
      metadata,
    );
  }

  ResetPassword(request: DeepPartial<ResetPasswordReq>, metadata?: grpc.Metadata): Promise<ResetPasswordRes> {
    return this.rpc.unary(UserServiceResetPasswordDesc, ResetPasswordReq.fromPartial(request), metadata);
  }
}

export const UserServiceDesc = { serviceName: "proto.UserService" };

export const UserServiceIsUserLoggedInDesc: UnaryMethodDefinitionish = {
  methodName: "IsUserLoggedIn",
  service: UserServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UserLoggedInResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UserServiceGetUserDesc: UnaryMethodDefinitionish = {
  methodName: "GetUser",
  service: UserServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UserServiceAddUserDesc: UnaryMethodDefinitionish = {
  methodName: "AddUser",
  service: UserServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AddUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UserServiceUpdateUserDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateUser",
  service: UserServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UserServiceCreateUserDesc: UnaryMethodDefinitionish = {
  methodName: "CreateUser",
  service: UserServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UserServiceCreateMobileUserAccountDesc: UnaryMethodDefinitionish = {
  methodName: "CreateMobileUserAccount",
  service: UserServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateMobileUserAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateMobileUserAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UserServiceResetPasswordDesc: UnaryMethodDefinitionish = {
  methodName: "ResetPassword",
  service: UserServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResetPasswordReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ResetPasswordRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
