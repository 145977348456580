import { BookingDTO, BookingClient, LineItem } from "sdk/dist/bookings_pb";
import { Money } from "sdk/dist/money_pb";
import { FundType } from "sdk/dist/offerings_pb";
import { clientFundStore } from "./../stores/client_fund-store";
import { AccountItem } from "sdk/dist/account_items_pb";
import { accItemStore } from "./../stores/acc_item-store";
import { ISOCurrencyName } from "sdk/dist/currencies_pb";
import moment from "moment";
import money_legacy from "./money_legacy";

export function isGroupBooking(booking: BookingDTO.AsObject) {
  // return booking.clientsList.length > 1;
  return booking.type === BookingDTO.Type.BOOKING_GROUP;
}

export function calculateAmountRebate(
  booking: BookingDTO.AsObject,
  client?: BookingClient.AsObject
): Money.AsObject {
  const rebateAmounts = allLineItems(booking, client)
    .filter((item) => !!item.rebate)
    .map((item) => item.rebate!.amount!);
  const currencyCode = rebateAmounts[0] ? rebateAmounts[0].currencyCode : ISOCurrencyName.XXX;
  return money_legacy.mul(money_legacy.add(currencyCode, ...rebateAmounts), -1, false);
}

export function calculateTaxRebate(
  booking: BookingDTO.AsObject,
  client?: BookingClient.AsObject
): Money.AsObject {
  const rebateTaxes = allLineItems(booking, client)
    .filter((item) => !!item.rebate)
    .map((item) => item.rebate!.tax!);
  const currencyCode = rebateTaxes[0] ? rebateTaxes[0].currencyCode : ISOCurrencyName.XXX;
  return money_legacy.mul(money_legacy.add(currencyCode, ...rebateTaxes), -1, false);
}

export function calculateTotalRebate(
  booking: BookingDTO.AsObject,
  client?: BookingClient.AsObject
) {
  const rebate = calculateAmountRebate(booking, client);
  const currencyCode = rebate.currencyCode ? rebate.currencyCode : ISOCurrencyName.XXX;
  return money_legacy.add(currencyCode, rebate, calculateTaxRebate(booking, client));
}

export function calculateLyfeFee(
  booking: BookingDTO.AsObject,
  client?: BookingClient.AsObject
): Money.AsObject {
  const fees = allLineItems(booking, client)
    .filter((item) => item.fundType != FundType.THIRD_PARTY_INVOICE)
    .map((item) => item.fee!.amount!);

  const taxes = allLineItems(booking, client)
    .filter((item) => item.fundType != FundType.THIRD_PARTY_INVOICE)
    .map((item) => item.fee!)
    .filter((fee) => !!fee.tax)
    .map((fee) => fee.tax!);

  const rebate = calculateTotalRebate(booking, client);
  if (fees.length > 0) {
    const currencyCode = fees[0] ? fees[0].currencyCode : ISOCurrencyName.XXX;
    const total = money_legacy.add(currencyCode, ...fees, ...taxes, rebate);
    return money_legacy.mul(total, parseFloat(booking.lyfeFee), true);
  }
  return money_legacy.moneyZero(ISOCurrencyName.XXX);
}

export function calculateSubtotal(
  booking: BookingDTO.AsObject,
  client?: BookingClient.AsObject
): Money.AsObject {
  // get all the line items for the booking
  // then map the LineItems into their Fee Amount.
  // The Fee Amount is the cost before tax.
  // Then reduce all the Fee Amounts into a sum
  const fees = allLineItems(booking, client).map((item) => {
    return item.fee!.amount!;
  });
  const rebate = calculateAmountRebate(booking, client);
  const currencyCode = fees[0] ? fees[0].currencyCode : ISOCurrencyName.XXX;
  return money_legacy.add(currencyCode, ...fees, rebate);
}

export function calculateTotalGST(
  booking: BookingDTO.AsObject,
  client?: BookingClient.AsObject
): Money.AsObject {
  const taxes = allLineItems(booking, client)
    // .filter(item =>  item.rebate!.amount == moneyZero())
    .map((item) => item.fee!)
    .filter((fee) => !!fee.tax)
    .map((fee) => fee.tax!);
  const currencyCode = taxes[0] ? taxes[0].currencyCode : ISOCurrencyName.XXX;
  return money_legacy.add(currencyCode, ...taxes, calculateTaxRebate(booking, client));
}

export function calculateTotal(
  booking: BookingDTO.AsObject,
  client?: BookingClient.AsObject
): Money.AsObject {
  var subtotal: Money.AsObject = calculateSubtotal(booking, client);
  var gst: Money.AsObject = calculateTotalGST(booking, client);
  var lyfeFee: Money.AsObject = calculateLyfeFee(booking, client);
  return money_legacy.add(subtotal.currencyCode, subtotal, gst, lyfeFee);
}

// return all the line items that are relevant for a booking.
// if a client is passed in then the client's offerings will also be
// in the returned list.
export function allLineItems(
  booking: BookingDTO.AsObject,
  client?: BookingClient.AsObject
): LineItem.AsObject[] {
  let bookingOfferings = new Array<LineItem.AsObject>();
  if (isGroupBooking(booking) && !client) {
    booking.clientsList
      .filter(
        (client) =>
          client.status !== BookingClient.Status.CANCELLED &&
          client.status !== BookingClient.Status.REJECTED
      )
      .forEach((client) => {
        booking.offeringsList.map((offering) => {
          bookingOfferings.push(offering.lineItem!);
        });
      });
  } else {
    bookingOfferings = booking.offeringsList.map((off) => off.lineItem!);
  }

  let clientOfferings = new Array<LineItem.AsObject>();
  if (client) {
    clientOfferings = client.offeringsList.map((off) => off.lineItem!);
  } else {
    clientOfferings = booking.clientsList
      .filter(
        (client) =>
          client.status !== BookingClient.Status.CANCELLED &&
          client.status !== BookingClient.Status.REJECTED
      )
      .flatMap((client) => client.offeringsList)
      .map((offering) => offering.lineItem!);
  }

  return [...bookingOfferings, ...clientOfferings];
}

export interface FundRequirements {
  required: FundType[];
  missing: FundType[];
}

/**
 * This method returns a list of the funds that are required for a given
 * booking client and a list of the funds that the client is currently missing
 */
export function requiredFundsForBooking(
  booking: BookingDTO.AsObject,
  client: BookingClient.AsObject,
  accountItems: AccountItem.AsObject[]
): FundRequirements {
  const currentFunds = clientFundStore.get(client.clientId);
  const fundsRequired = new Set<FundType>();
  const missingFunds = new Set<FundType>();

  // if booking is cancelled, add credit card required
  if (booking.status == BookingDTO.Status.BOOKING_CANCELLED) {
    fundsRequired.add(FundType.CREDIT_CARD);
    // if not credit card active selected, add to missing funds
    if (client.activeCardId === "") {
      missingFunds.add(FundType.CREDIT_CARD);
    }
  } else {
    for (const item of allLineItems(booking, client)) {
      fundsRequired.add(item.fundType);
      missingFunds.add(item.fundType);
      // When medicare std, credit card is required
      if (item.fundType == FundType.MEDICARE_STANDARD) {
        fundsRequired.add(FundType.CREDIT_CARD);
      }
      // if current funds have number, remove this fund type from missing funds list
      if (currentFunds) {
        if (
          item.fundType == FundType.MEDICARE_STANDARD ||
          item.fundType == FundType.MEDICARE_BULK
        ) {
          if (currentFunds!.medicare!.number !== "") {
            missingFunds.delete(item.fundType);
          }
        }
        if (item.fundType == FundType.DVA_STANDARD || item.fundType == FundType.DVA_ALLIED) {
          if (currentFunds!.dva!.disability !== "") {
            missingFunds.delete(item.fundType);
          }
        }
        if (item.fundType == FundType.HICAPS) {
          // assume only one of the medipass id and mobile is required
          if (
            currentFunds!.hicaps!.medipassMemberId !== "" ||
            currentFunds!.hicaps!.mobile !== ""
          ) {
            missingFunds.delete(item.fundType);
          }
        }

        if (item.fundType === FundType.THIRD_PARTY_INVOICE) {
          if (accountItems.length > 0) {
            missingFunds.delete(item.fundType);
          }
        }
      }
    }
    // check if medicare std or credit card have and active card already, if so delete from missing fund type list
    if (
      (fundsRequired.has(FundType.MEDICARE_STANDARD) || fundsRequired.has(FundType.CREDIT_CARD)) &&
      client.activeCardId !== ""
    ) {
      missingFunds.delete(FundType.CREDIT_CARD);
    }
  }

  // if an account item has been completed, remove it from required funds
  if (accountItems.length > 0) {
    accountItems
      .filter((ai) => ai.status == AccountItem.Status.ACC_ITEM_COMPLETED)
      .map((ai) => {
        missingFunds.delete(ai.fundType);
        fundsRequired.delete(ai.fundType);
      });
  }

  return {
    required: Array.from(fundsRequired.values()),
    missing: Array.from(missingFunds.values())
  };
}

export function clientPaymentStatusToText(status: BookingClient.PaymentStatus): string {
  switch (status) {
    case BookingClient.PaymentStatus.COMPLETE:
      return "Complete";
    case BookingClient.PaymentStatus.PROCESSING:
      return "Processing";
    case BookingClient.PaymentStatus.PENDING:
      return "Pending";
    default:
      return "Status unspecified";
  }
}

export function renderBookingClientStatus(booking: BookingDTO.AsObject) {
  switch (booking.type) {
    case BookingDTO.Type.BOOKING_GROUP:
      return renderBookingStatus(booking, undefined);
    case BookingDTO.Type.BOOKING_SINGLE:
      return renderBookingStatus(booking, booking.clientsList[0]);
    default:
      return booking.status;
  }
}
//

export function renderGroupBookingFooter(booking: BookingDTO.AsObject) {
  let footer = "";
  if (booking.offeringsList.length > 0) {
    let lineItem = booking.offeringsList[0].lineItem;
    let offering = lineItem ? lineItem.offering : null;
    if (offering != null) {
      let category = offering ? offering.categoryList[0] : null;
      let level = offering ? offering.level : null;
      if (category != null) {
        footer += category.name;
      }
      if (level != "") {
        if (category == null) {
          footer += level ? level : "";
        } else {
          footer += level ? " | " + level : "";
        }
      }
    }
  }
  return footer;
}
export function renderBookingStatus(booking: BookingDTO.AsObject, client?: BookingClient.AsObject) {
  switch (booking.status) {
    case BookingDTO.Status.BOOKING_CREATED:
      if (client && client.status === BookingClient.Status.APPROVED) {
        return "Approved";
      } else if (client) {
        return "Waiting For Approval";
      } else {
        return "Created";
      }
    case BookingDTO.Status.BOOKING_PROCESSING:
      return renderProcessingStatus(booking);
    case BookingDTO.Status.BOOKING_CANCELLED:
      if (client && client.status === BookingClient.Status.REJECTED) {
        return "Rejected";
      } else {
        return "Cancelled";
      }
    case BookingDTO.Status.BOOKING_COMPLETED:
      return "Completed";
    default:
      return "Unknown";
  }
}

function renderProcessingStatus(booking: BookingDTO.AsObject) {
  const accountItems = accItemStore.all().filter((ai) => {
    return ai.booking!.id === booking.id;
  });

  if (
    accountItems.findIndex(
      (v) =>
        v.status === AccountItem.Status.ACC_ITEM_ERROR &&
        v.type === AccountItem.Type.ACC_ITEM_BOOKING_PAYMENT
    ) >= 0
  ) {
    return "Charge failed";
  } else if (
    accountItems.findIndex(
      (v) =>
        v.status === AccountItem.Status.ACC_ITEM_ERROR && v.type === AccountItem.Type.ACC_ITEM_FUND
    ) >= 0
  ) {
    return "Fund failed";
  } else if (accountItems.findIndex((v) => v.status === AccountItem.Status.ACC_ITEM_ERROR) >= 0) {
    return "Error";
  } else {
    return "Processing";
  }
}

export function bookingStatusToText(status: BookingDTO.Status) {
  switch (status) {
    case BookingDTO.Status.BOOKING_CREATED:
      return "Created";
    case BookingDTO.Status.BOOKING_PROCESSING:
      return "Processing";
    case BookingDTO.Status.BOOKING_COMPLETED:
      return "Completed";
    case BookingDTO.Status.BOOKING_CANCELLED:
      return "Cancelled";
    default:
      return "Unknown";
  }
}

export function bookingTypeToDTO(bkType: string): BookingDTO.Type {
  switch (bkType) {
    case "Group":
      return BookingDTO.Type.BOOKING_GROUP;
    case "Single":
      return BookingDTO.Type.BOOKING_SINGLE;
    case "Time Off":
      return BookingDTO.Type.BOOKING_TIME_OFF;
    default:
      return BookingDTO.Type.BOOKING_TYPE_UNSPECIFIED;
  }
}

export function bookingTypeToText(bkType: BookingDTO.Type): string {
  switch (bkType) {
    case BookingDTO.Type.BOOKING_GROUP:
      return "Group";
    case BookingDTO.Type.BOOKING_SINGLE:
      return "Single";
    case BookingDTO.Type.BOOKING_TIME_OFF:
      return "Time Off";
    default:
      return "Unknown";
  }
}
