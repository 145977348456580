/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "proto";

export interface GetActivationLinkRequest {
  locationId: string;
}

export interface GetActivationLinkResponse {
  clientId: MindBodyClientIdDTO | undefined;
  link: string;
}

export interface SetClientIdRequest {
  clientId: string;
  locationId: string;
}

export interface SetClientIdResponse {
  clientId: MindBodyClientIdDTO | undefined;
}

export interface RemoveClientIdRequest {
  locationId: string;
}

export interface RemoveClientIdResponse {
}

export interface GetClientIdRequest {
  locationId: string;
}

export interface GetClientIdResponse {
  clientId: MindBodyClientIdDTO | undefined;
}

export interface MindBodyClientIdDTO {
  clientId: string;
  locationId: string;
  activationLink: string;
  updatedAt: Date | undefined;
  createdAt: Date | undefined;
}

export interface ManuallySyncRequest {
  locationId: string;
}

export interface ManuallySyncResponse {
}

export interface ListMindbodyOfferingsRequest {
  locationId: string;
}

export interface ListMindbodyOfferingsResponse {
  offerings: MindbodyOfferingDTO[];
}

export interface ListMindbodyStaffRequest {
  locationId: string;
}

export interface ListMindbodyStaffResponse {
  staff: MindbodyStaffDTO[];
}

export interface MindbodyStaffDTO {
  id: number;
  name: string;
}

export interface MindbodyOfferingDTO {
  id: number;
  name: string;
}

export interface CheckActivationRequest {
  locationId: string;
}

export interface CheckActivationResponse {
  activated: boolean;
}

export interface UpdateMindbodyOfferingMappingsRequest {
  locationId: string;
  mappings: MindbodyOfferingMappingDTO[];
}

export interface UpdateMindbodyOfferingMappingsResponse {
  mappings: MindbodyOfferingMappingDTO[];
}

export interface UpdateMindbodyProviderMappingsRequest {
  locationId: string;
  mappings: MindbodyProviderMappingDTO[];
}

export interface UpdateMindbodyProviderMappingsResponse {
  mappings: MindbodyProviderMappingDTO[];
}

export interface ListMindbodyOfferingMappingsRequest {
  locationId: string;
}

export interface ListMindbodyOfferingMappingsResponse {
  mappings: MindbodyOfferingMappingDTO[];
}

export interface ListMindbodyProviderMappingsRequest {
  locationId: string;
}

export interface ListMindbodyProviderMappingsResponse {
  mappings: MindbodyProviderMappingDTO[];
}

export interface MindbodyOfferingMappingDTO {
  mindbodyClassDescriptionId: number;
  offeringId: string;
}

export interface MindbodyProviderMappingDTO {
  mindbodyStaffId: number;
  providerId: string;
}

function createBaseGetActivationLinkRequest(): GetActivationLinkRequest {
  return { locationId: "" };
}

export const GetActivationLinkRequest = {
  encode(message: GetActivationLinkRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetActivationLinkRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetActivationLinkRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetActivationLinkRequest {
    return { locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "" };
  },

  toJSON(message: GetActivationLinkRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetActivationLinkRequest>, I>>(base?: I): GetActivationLinkRequest {
    return GetActivationLinkRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetActivationLinkRequest>, I>>(object: I): GetActivationLinkRequest {
    const message = createBaseGetActivationLinkRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseGetActivationLinkResponse(): GetActivationLinkResponse {
  return { clientId: undefined, link: "" };
}

export const GetActivationLinkResponse = {
  encode(message: GetActivationLinkResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== undefined) {
      MindBodyClientIdDTO.encode(message.clientId, writer.uint32(10).fork()).ldelim();
    }
    if (message.link !== "") {
      writer.uint32(18).string(message.link);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetActivationLinkResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetActivationLinkResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = MindBodyClientIdDTO.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.link = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetActivationLinkResponse {
    return {
      clientId: isSet(object.clientId) ? MindBodyClientIdDTO.fromJSON(object.clientId) : undefined,
      link: isSet(object.link) ? globalThis.String(object.link) : "",
    };
  },

  toJSON(message: GetActivationLinkResponse): unknown {
    const obj: any = {};
    if (message.clientId !== undefined) {
      obj.clientId = MindBodyClientIdDTO.toJSON(message.clientId);
    }
    if (message.link !== "") {
      obj.link = message.link;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetActivationLinkResponse>, I>>(base?: I): GetActivationLinkResponse {
    return GetActivationLinkResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetActivationLinkResponse>, I>>(object: I): GetActivationLinkResponse {
    const message = createBaseGetActivationLinkResponse();
    message.clientId = (object.clientId !== undefined && object.clientId !== null)
      ? MindBodyClientIdDTO.fromPartial(object.clientId)
      : undefined;
    message.link = object.link ?? "";
    return message;
  },
};

function createBaseSetClientIdRequest(): SetClientIdRequest {
  return { clientId: "", locationId: "" };
}

export const SetClientIdRequest = {
  encode(message: SetClientIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    if (message.locationId !== "") {
      writer.uint32(18).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetClientIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetClientIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetClientIdRequest {
    return {
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
    };
  },

  toJSON(message: SetClientIdRequest): unknown {
    const obj: any = {};
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetClientIdRequest>, I>>(base?: I): SetClientIdRequest {
    return SetClientIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetClientIdRequest>, I>>(object: I): SetClientIdRequest {
    const message = createBaseSetClientIdRequest();
    message.clientId = object.clientId ?? "";
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseSetClientIdResponse(): SetClientIdResponse {
  return { clientId: undefined };
}

export const SetClientIdResponse = {
  encode(message: SetClientIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== undefined) {
      MindBodyClientIdDTO.encode(message.clientId, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetClientIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetClientIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = MindBodyClientIdDTO.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetClientIdResponse {
    return { clientId: isSet(object.clientId) ? MindBodyClientIdDTO.fromJSON(object.clientId) : undefined };
  },

  toJSON(message: SetClientIdResponse): unknown {
    const obj: any = {};
    if (message.clientId !== undefined) {
      obj.clientId = MindBodyClientIdDTO.toJSON(message.clientId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetClientIdResponse>, I>>(base?: I): SetClientIdResponse {
    return SetClientIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetClientIdResponse>, I>>(object: I): SetClientIdResponse {
    const message = createBaseSetClientIdResponse();
    message.clientId = (object.clientId !== undefined && object.clientId !== null)
      ? MindBodyClientIdDTO.fromPartial(object.clientId)
      : undefined;
    return message;
  },
};

function createBaseRemoveClientIdRequest(): RemoveClientIdRequest {
  return { locationId: "" };
}

export const RemoveClientIdRequest = {
  encode(message: RemoveClientIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveClientIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveClientIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveClientIdRequest {
    return { locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "" };
  },

  toJSON(message: RemoveClientIdRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveClientIdRequest>, I>>(base?: I): RemoveClientIdRequest {
    return RemoveClientIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveClientIdRequest>, I>>(object: I): RemoveClientIdRequest {
    const message = createBaseRemoveClientIdRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseRemoveClientIdResponse(): RemoveClientIdResponse {
  return {};
}

export const RemoveClientIdResponse = {
  encode(_: RemoveClientIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveClientIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveClientIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RemoveClientIdResponse {
    return {};
  },

  toJSON(_: RemoveClientIdResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveClientIdResponse>, I>>(base?: I): RemoveClientIdResponse {
    return RemoveClientIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveClientIdResponse>, I>>(_: I): RemoveClientIdResponse {
    const message = createBaseRemoveClientIdResponse();
    return message;
  },
};

function createBaseGetClientIdRequest(): GetClientIdRequest {
  return { locationId: "" };
}

export const GetClientIdRequest = {
  encode(message: GetClientIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetClientIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetClientIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetClientIdRequest {
    return { locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "" };
  },

  toJSON(message: GetClientIdRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetClientIdRequest>, I>>(base?: I): GetClientIdRequest {
    return GetClientIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetClientIdRequest>, I>>(object: I): GetClientIdRequest {
    const message = createBaseGetClientIdRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseGetClientIdResponse(): GetClientIdResponse {
  return { clientId: undefined };
}

export const GetClientIdResponse = {
  encode(message: GetClientIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== undefined) {
      MindBodyClientIdDTO.encode(message.clientId, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetClientIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetClientIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = MindBodyClientIdDTO.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetClientIdResponse {
    return { clientId: isSet(object.clientId) ? MindBodyClientIdDTO.fromJSON(object.clientId) : undefined };
  },

  toJSON(message: GetClientIdResponse): unknown {
    const obj: any = {};
    if (message.clientId !== undefined) {
      obj.clientId = MindBodyClientIdDTO.toJSON(message.clientId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetClientIdResponse>, I>>(base?: I): GetClientIdResponse {
    return GetClientIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetClientIdResponse>, I>>(object: I): GetClientIdResponse {
    const message = createBaseGetClientIdResponse();
    message.clientId = (object.clientId !== undefined && object.clientId !== null)
      ? MindBodyClientIdDTO.fromPartial(object.clientId)
      : undefined;
    return message;
  },
};

function createBaseMindBodyClientIdDTO(): MindBodyClientIdDTO {
  return { clientId: "", locationId: "", activationLink: "", updatedAt: undefined, createdAt: undefined };
}

export const MindBodyClientIdDTO = {
  encode(message: MindBodyClientIdDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    if (message.locationId !== "") {
      writer.uint32(18).string(message.locationId);
    }
    if (message.activationLink !== "") {
      writer.uint32(26).string(message.activationLink);
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MindBodyClientIdDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMindBodyClientIdDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.activationLink = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MindBodyClientIdDTO {
    return {
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      activationLink: isSet(object.activationLink) ? globalThis.String(object.activationLink) : "",
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
    };
  },

  toJSON(message: MindBodyClientIdDTO): unknown {
    const obj: any = {};
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.activationLink !== "") {
      obj.activationLink = message.activationLink;
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MindBodyClientIdDTO>, I>>(base?: I): MindBodyClientIdDTO {
    return MindBodyClientIdDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MindBodyClientIdDTO>, I>>(object: I): MindBodyClientIdDTO {
    const message = createBaseMindBodyClientIdDTO();
    message.clientId = object.clientId ?? "";
    message.locationId = object.locationId ?? "";
    message.activationLink = object.activationLink ?? "";
    message.updatedAt = object.updatedAt ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    return message;
  },
};

function createBaseManuallySyncRequest(): ManuallySyncRequest {
  return { locationId: "" };
}

export const ManuallySyncRequest = {
  encode(message: ManuallySyncRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManuallySyncRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManuallySyncRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManuallySyncRequest {
    return { locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "" };
  },

  toJSON(message: ManuallySyncRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManuallySyncRequest>, I>>(base?: I): ManuallySyncRequest {
    return ManuallySyncRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManuallySyncRequest>, I>>(object: I): ManuallySyncRequest {
    const message = createBaseManuallySyncRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseManuallySyncResponse(): ManuallySyncResponse {
  return {};
}

export const ManuallySyncResponse = {
  encode(_: ManuallySyncResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManuallySyncResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManuallySyncResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ManuallySyncResponse {
    return {};
  },

  toJSON(_: ManuallySyncResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ManuallySyncResponse>, I>>(base?: I): ManuallySyncResponse {
    return ManuallySyncResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManuallySyncResponse>, I>>(_: I): ManuallySyncResponse {
    const message = createBaseManuallySyncResponse();
    return message;
  },
};

function createBaseListMindbodyOfferingsRequest(): ListMindbodyOfferingsRequest {
  return { locationId: "" };
}

export const ListMindbodyOfferingsRequest = {
  encode(message: ListMindbodyOfferingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMindbodyOfferingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMindbodyOfferingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMindbodyOfferingsRequest {
    return { locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "" };
  },

  toJSON(message: ListMindbodyOfferingsRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMindbodyOfferingsRequest>, I>>(base?: I): ListMindbodyOfferingsRequest {
    return ListMindbodyOfferingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMindbodyOfferingsRequest>, I>>(object: I): ListMindbodyOfferingsRequest {
    const message = createBaseListMindbodyOfferingsRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseListMindbodyOfferingsResponse(): ListMindbodyOfferingsResponse {
  return { offerings: [] };
}

export const ListMindbodyOfferingsResponse = {
  encode(message: ListMindbodyOfferingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.offerings) {
      MindbodyOfferingDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMindbodyOfferingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMindbodyOfferingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.offerings.push(MindbodyOfferingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMindbodyOfferingsResponse {
    return {
      offerings: globalThis.Array.isArray(object?.offerings)
        ? object.offerings.map((e: any) => MindbodyOfferingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListMindbodyOfferingsResponse): unknown {
    const obj: any = {};
    if (message.offerings?.length) {
      obj.offerings = message.offerings.map((e) => MindbodyOfferingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMindbodyOfferingsResponse>, I>>(base?: I): ListMindbodyOfferingsResponse {
    return ListMindbodyOfferingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMindbodyOfferingsResponse>, I>>(
    object: I,
  ): ListMindbodyOfferingsResponse {
    const message = createBaseListMindbodyOfferingsResponse();
    message.offerings = object.offerings?.map((e) => MindbodyOfferingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListMindbodyStaffRequest(): ListMindbodyStaffRequest {
  return { locationId: "" };
}

export const ListMindbodyStaffRequest = {
  encode(message: ListMindbodyStaffRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMindbodyStaffRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMindbodyStaffRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMindbodyStaffRequest {
    return { locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "" };
  },

  toJSON(message: ListMindbodyStaffRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMindbodyStaffRequest>, I>>(base?: I): ListMindbodyStaffRequest {
    return ListMindbodyStaffRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMindbodyStaffRequest>, I>>(object: I): ListMindbodyStaffRequest {
    const message = createBaseListMindbodyStaffRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseListMindbodyStaffResponse(): ListMindbodyStaffResponse {
  return { staff: [] };
}

export const ListMindbodyStaffResponse = {
  encode(message: ListMindbodyStaffResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.staff) {
      MindbodyStaffDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMindbodyStaffResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMindbodyStaffResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staff.push(MindbodyStaffDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMindbodyStaffResponse {
    return {
      staff: globalThis.Array.isArray(object?.staff) ? object.staff.map((e: any) => MindbodyStaffDTO.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListMindbodyStaffResponse): unknown {
    const obj: any = {};
    if (message.staff?.length) {
      obj.staff = message.staff.map((e) => MindbodyStaffDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMindbodyStaffResponse>, I>>(base?: I): ListMindbodyStaffResponse {
    return ListMindbodyStaffResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMindbodyStaffResponse>, I>>(object: I): ListMindbodyStaffResponse {
    const message = createBaseListMindbodyStaffResponse();
    message.staff = object.staff?.map((e) => MindbodyStaffDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMindbodyStaffDTO(): MindbodyStaffDTO {
  return { id: 0, name: "" };
}

export const MindbodyStaffDTO = {
  encode(message: MindbodyStaffDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MindbodyStaffDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMindbodyStaffDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MindbodyStaffDTO {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: MindbodyStaffDTO): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MindbodyStaffDTO>, I>>(base?: I): MindbodyStaffDTO {
    return MindbodyStaffDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MindbodyStaffDTO>, I>>(object: I): MindbodyStaffDTO {
    const message = createBaseMindbodyStaffDTO();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseMindbodyOfferingDTO(): MindbodyOfferingDTO {
  return { id: 0, name: "" };
}

export const MindbodyOfferingDTO = {
  encode(message: MindbodyOfferingDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MindbodyOfferingDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMindbodyOfferingDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MindbodyOfferingDTO {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: MindbodyOfferingDTO): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MindbodyOfferingDTO>, I>>(base?: I): MindbodyOfferingDTO {
    return MindbodyOfferingDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MindbodyOfferingDTO>, I>>(object: I): MindbodyOfferingDTO {
    const message = createBaseMindbodyOfferingDTO();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCheckActivationRequest(): CheckActivationRequest {
  return { locationId: "" };
}

export const CheckActivationRequest = {
  encode(message: CheckActivationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckActivationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckActivationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckActivationRequest {
    return { locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "" };
  },

  toJSON(message: CheckActivationRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckActivationRequest>, I>>(base?: I): CheckActivationRequest {
    return CheckActivationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CheckActivationRequest>, I>>(object: I): CheckActivationRequest {
    const message = createBaseCheckActivationRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseCheckActivationResponse(): CheckActivationResponse {
  return { activated: false };
}

export const CheckActivationResponse = {
  encode(message: CheckActivationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.activated === true) {
      writer.uint32(8).bool(message.activated);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckActivationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckActivationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.activated = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckActivationResponse {
    return { activated: isSet(object.activated) ? globalThis.Boolean(object.activated) : false };
  },

  toJSON(message: CheckActivationResponse): unknown {
    const obj: any = {};
    if (message.activated === true) {
      obj.activated = message.activated;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckActivationResponse>, I>>(base?: I): CheckActivationResponse {
    return CheckActivationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CheckActivationResponse>, I>>(object: I): CheckActivationResponse {
    const message = createBaseCheckActivationResponse();
    message.activated = object.activated ?? false;
    return message;
  },
};

function createBaseUpdateMindbodyOfferingMappingsRequest(): UpdateMindbodyOfferingMappingsRequest {
  return { locationId: "", mappings: [] };
}

export const UpdateMindbodyOfferingMappingsRequest = {
  encode(message: UpdateMindbodyOfferingMappingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    for (const v of message.mappings) {
      MindbodyOfferingMappingDTO.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMindbodyOfferingMappingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMindbodyOfferingMappingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mappings.push(MindbodyOfferingMappingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMindbodyOfferingMappingsRequest {
    return {
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      mappings: globalThis.Array.isArray(object?.mappings)
        ? object.mappings.map((e: any) => MindbodyOfferingMappingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateMindbodyOfferingMappingsRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.mappings?.length) {
      obj.mappings = message.mappings.map((e) => MindbodyOfferingMappingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateMindbodyOfferingMappingsRequest>, I>>(
    base?: I,
  ): UpdateMindbodyOfferingMappingsRequest {
    return UpdateMindbodyOfferingMappingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateMindbodyOfferingMappingsRequest>, I>>(
    object: I,
  ): UpdateMindbodyOfferingMappingsRequest {
    const message = createBaseUpdateMindbodyOfferingMappingsRequest();
    message.locationId = object.locationId ?? "";
    message.mappings = object.mappings?.map((e) => MindbodyOfferingMappingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateMindbodyOfferingMappingsResponse(): UpdateMindbodyOfferingMappingsResponse {
  return { mappings: [] };
}

export const UpdateMindbodyOfferingMappingsResponse = {
  encode(message: UpdateMindbodyOfferingMappingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.mappings) {
      MindbodyOfferingMappingDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMindbodyOfferingMappingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMindbodyOfferingMappingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mappings.push(MindbodyOfferingMappingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMindbodyOfferingMappingsResponse {
    return {
      mappings: globalThis.Array.isArray(object?.mappings)
        ? object.mappings.map((e: any) => MindbodyOfferingMappingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateMindbodyOfferingMappingsResponse): unknown {
    const obj: any = {};
    if (message.mappings?.length) {
      obj.mappings = message.mappings.map((e) => MindbodyOfferingMappingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateMindbodyOfferingMappingsResponse>, I>>(
    base?: I,
  ): UpdateMindbodyOfferingMappingsResponse {
    return UpdateMindbodyOfferingMappingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateMindbodyOfferingMappingsResponse>, I>>(
    object: I,
  ): UpdateMindbodyOfferingMappingsResponse {
    const message = createBaseUpdateMindbodyOfferingMappingsResponse();
    message.mappings = object.mappings?.map((e) => MindbodyOfferingMappingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateMindbodyProviderMappingsRequest(): UpdateMindbodyProviderMappingsRequest {
  return { locationId: "", mappings: [] };
}

export const UpdateMindbodyProviderMappingsRequest = {
  encode(message: UpdateMindbodyProviderMappingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    for (const v of message.mappings) {
      MindbodyProviderMappingDTO.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMindbodyProviderMappingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMindbodyProviderMappingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mappings.push(MindbodyProviderMappingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMindbodyProviderMappingsRequest {
    return {
      locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "",
      mappings: globalThis.Array.isArray(object?.mappings)
        ? object.mappings.map((e: any) => MindbodyProviderMappingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateMindbodyProviderMappingsRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.mappings?.length) {
      obj.mappings = message.mappings.map((e) => MindbodyProviderMappingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateMindbodyProviderMappingsRequest>, I>>(
    base?: I,
  ): UpdateMindbodyProviderMappingsRequest {
    return UpdateMindbodyProviderMappingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateMindbodyProviderMappingsRequest>, I>>(
    object: I,
  ): UpdateMindbodyProviderMappingsRequest {
    const message = createBaseUpdateMindbodyProviderMappingsRequest();
    message.locationId = object.locationId ?? "";
    message.mappings = object.mappings?.map((e) => MindbodyProviderMappingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateMindbodyProviderMappingsResponse(): UpdateMindbodyProviderMappingsResponse {
  return { mappings: [] };
}

export const UpdateMindbodyProviderMappingsResponse = {
  encode(message: UpdateMindbodyProviderMappingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.mappings) {
      MindbodyProviderMappingDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMindbodyProviderMappingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMindbodyProviderMappingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mappings.push(MindbodyProviderMappingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMindbodyProviderMappingsResponse {
    return {
      mappings: globalThis.Array.isArray(object?.mappings)
        ? object.mappings.map((e: any) => MindbodyProviderMappingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateMindbodyProviderMappingsResponse): unknown {
    const obj: any = {};
    if (message.mappings?.length) {
      obj.mappings = message.mappings.map((e) => MindbodyProviderMappingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateMindbodyProviderMappingsResponse>, I>>(
    base?: I,
  ): UpdateMindbodyProviderMappingsResponse {
    return UpdateMindbodyProviderMappingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateMindbodyProviderMappingsResponse>, I>>(
    object: I,
  ): UpdateMindbodyProviderMappingsResponse {
    const message = createBaseUpdateMindbodyProviderMappingsResponse();
    message.mappings = object.mappings?.map((e) => MindbodyProviderMappingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListMindbodyOfferingMappingsRequest(): ListMindbodyOfferingMappingsRequest {
  return { locationId: "" };
}

export const ListMindbodyOfferingMappingsRequest = {
  encode(message: ListMindbodyOfferingMappingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMindbodyOfferingMappingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMindbodyOfferingMappingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMindbodyOfferingMappingsRequest {
    return { locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "" };
  },

  toJSON(message: ListMindbodyOfferingMappingsRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMindbodyOfferingMappingsRequest>, I>>(
    base?: I,
  ): ListMindbodyOfferingMappingsRequest {
    return ListMindbodyOfferingMappingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMindbodyOfferingMappingsRequest>, I>>(
    object: I,
  ): ListMindbodyOfferingMappingsRequest {
    const message = createBaseListMindbodyOfferingMappingsRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseListMindbodyOfferingMappingsResponse(): ListMindbodyOfferingMappingsResponse {
  return { mappings: [] };
}

export const ListMindbodyOfferingMappingsResponse = {
  encode(message: ListMindbodyOfferingMappingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.mappings) {
      MindbodyOfferingMappingDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMindbodyOfferingMappingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMindbodyOfferingMappingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mappings.push(MindbodyOfferingMappingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMindbodyOfferingMappingsResponse {
    return {
      mappings: globalThis.Array.isArray(object?.mappings)
        ? object.mappings.map((e: any) => MindbodyOfferingMappingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListMindbodyOfferingMappingsResponse): unknown {
    const obj: any = {};
    if (message.mappings?.length) {
      obj.mappings = message.mappings.map((e) => MindbodyOfferingMappingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMindbodyOfferingMappingsResponse>, I>>(
    base?: I,
  ): ListMindbodyOfferingMappingsResponse {
    return ListMindbodyOfferingMappingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMindbodyOfferingMappingsResponse>, I>>(
    object: I,
  ): ListMindbodyOfferingMappingsResponse {
    const message = createBaseListMindbodyOfferingMappingsResponse();
    message.mappings = object.mappings?.map((e) => MindbodyOfferingMappingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListMindbodyProviderMappingsRequest(): ListMindbodyProviderMappingsRequest {
  return { locationId: "" };
}

export const ListMindbodyProviderMappingsRequest = {
  encode(message: ListMindbodyProviderMappingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMindbodyProviderMappingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMindbodyProviderMappingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMindbodyProviderMappingsRequest {
    return { locationId: isSet(object.locationId) ? globalThis.String(object.locationId) : "" };
  },

  toJSON(message: ListMindbodyProviderMappingsRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMindbodyProviderMappingsRequest>, I>>(
    base?: I,
  ): ListMindbodyProviderMappingsRequest {
    return ListMindbodyProviderMappingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMindbodyProviderMappingsRequest>, I>>(
    object: I,
  ): ListMindbodyProviderMappingsRequest {
    const message = createBaseListMindbodyProviderMappingsRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseListMindbodyProviderMappingsResponse(): ListMindbodyProviderMappingsResponse {
  return { mappings: [] };
}

export const ListMindbodyProviderMappingsResponse = {
  encode(message: ListMindbodyProviderMappingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.mappings) {
      MindbodyProviderMappingDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListMindbodyProviderMappingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMindbodyProviderMappingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.mappings.push(MindbodyProviderMappingDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMindbodyProviderMappingsResponse {
    return {
      mappings: globalThis.Array.isArray(object?.mappings)
        ? object.mappings.map((e: any) => MindbodyProviderMappingDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListMindbodyProviderMappingsResponse): unknown {
    const obj: any = {};
    if (message.mappings?.length) {
      obj.mappings = message.mappings.map((e) => MindbodyProviderMappingDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListMindbodyProviderMappingsResponse>, I>>(
    base?: I,
  ): ListMindbodyProviderMappingsResponse {
    return ListMindbodyProviderMappingsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListMindbodyProviderMappingsResponse>, I>>(
    object: I,
  ): ListMindbodyProviderMappingsResponse {
    const message = createBaseListMindbodyProviderMappingsResponse();
    message.mappings = object.mappings?.map((e) => MindbodyProviderMappingDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMindbodyOfferingMappingDTO(): MindbodyOfferingMappingDTO {
  return { mindbodyClassDescriptionId: 0, offeringId: "" };
}

export const MindbodyOfferingMappingDTO = {
  encode(message: MindbodyOfferingMappingDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mindbodyClassDescriptionId !== 0) {
      writer.uint32(8).int32(message.mindbodyClassDescriptionId);
    }
    if (message.offeringId !== "") {
      writer.uint32(18).string(message.offeringId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MindbodyOfferingMappingDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMindbodyOfferingMappingDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.mindbodyClassDescriptionId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.offeringId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MindbodyOfferingMappingDTO {
    return {
      mindbodyClassDescriptionId: isSet(object.mindbodyClassDescriptionId)
        ? globalThis.Number(object.mindbodyClassDescriptionId)
        : 0,
      offeringId: isSet(object.offeringId) ? globalThis.String(object.offeringId) : "",
    };
  },

  toJSON(message: MindbodyOfferingMappingDTO): unknown {
    const obj: any = {};
    if (message.mindbodyClassDescriptionId !== 0) {
      obj.mindbodyClassDescriptionId = Math.round(message.mindbodyClassDescriptionId);
    }
    if (message.offeringId !== "") {
      obj.offeringId = message.offeringId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MindbodyOfferingMappingDTO>, I>>(base?: I): MindbodyOfferingMappingDTO {
    return MindbodyOfferingMappingDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MindbodyOfferingMappingDTO>, I>>(object: I): MindbodyOfferingMappingDTO {
    const message = createBaseMindbodyOfferingMappingDTO();
    message.mindbodyClassDescriptionId = object.mindbodyClassDescriptionId ?? 0;
    message.offeringId = object.offeringId ?? "";
    return message;
  },
};

function createBaseMindbodyProviderMappingDTO(): MindbodyProviderMappingDTO {
  return { mindbodyStaffId: 0, providerId: "" };
}

export const MindbodyProviderMappingDTO = {
  encode(message: MindbodyProviderMappingDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mindbodyStaffId !== 0) {
      writer.uint32(8).int32(message.mindbodyStaffId);
    }
    if (message.providerId !== "") {
      writer.uint32(18).string(message.providerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MindbodyProviderMappingDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMindbodyProviderMappingDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.mindbodyStaffId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.providerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MindbodyProviderMappingDTO {
    return {
      mindbodyStaffId: isSet(object.mindbodyStaffId) ? globalThis.Number(object.mindbodyStaffId) : 0,
      providerId: isSet(object.providerId) ? globalThis.String(object.providerId) : "",
    };
  },

  toJSON(message: MindbodyProviderMappingDTO): unknown {
    const obj: any = {};
    if (message.mindbodyStaffId !== 0) {
      obj.mindbodyStaffId = Math.round(message.mindbodyStaffId);
    }
    if (message.providerId !== "") {
      obj.providerId = message.providerId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MindbodyProviderMappingDTO>, I>>(base?: I): MindbodyProviderMappingDTO {
    return MindbodyProviderMappingDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MindbodyProviderMappingDTO>, I>>(object: I): MindbodyProviderMappingDTO {
    const message = createBaseMindbodyProviderMappingDTO();
    message.mindbodyStaffId = object.mindbodyStaffId ?? 0;
    message.providerId = object.providerId ?? "";
    return message;
  },
};

export interface MindbodyService {
  SetClientId(request: DeepPartial<SetClientIdRequest>, metadata?: grpc.Metadata): Promise<SetClientIdResponse>;
  RemoveClientId(
    request: DeepPartial<RemoveClientIdRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveClientIdResponse>;
  GetClientId(request: DeepPartial<GetClientIdRequest>, metadata?: grpc.Metadata): Promise<GetClientIdResponse>;
  GetActivationLink(
    request: DeepPartial<GetActivationLinkRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetActivationLinkResponse>;
  ManuallySync(request: DeepPartial<ManuallySyncRequest>, metadata?: grpc.Metadata): Promise<ManuallySyncResponse>;
  CheckActivation(
    request: DeepPartial<CheckActivationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CheckActivationResponse>;
  ListMindbodyOfferings(
    request: DeepPartial<ListMindbodyOfferingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMindbodyOfferingsResponse>;
  ListMindbodyStaff(
    request: DeepPartial<ListMindbodyStaffRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMindbodyStaffResponse>;
  UpdateMindbodyOfferingMappings(
    request: DeepPartial<UpdateMindbodyOfferingMappingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateMindbodyOfferingMappingsResponse>;
  ListMindbodyOfferingMappings(
    request: DeepPartial<ListMindbodyOfferingMappingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMindbodyOfferingMappingsResponse>;
  UpdateMindbodyProviderMappings(
    request: DeepPartial<UpdateMindbodyProviderMappingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateMindbodyProviderMappingsResponse>;
  ListMindbodyProviderMappings(
    request: DeepPartial<ListMindbodyProviderMappingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMindbodyProviderMappingsResponse>;
}

export class MindbodyServiceClientImpl implements MindbodyService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.SetClientId = this.SetClientId.bind(this);
    this.RemoveClientId = this.RemoveClientId.bind(this);
    this.GetClientId = this.GetClientId.bind(this);
    this.GetActivationLink = this.GetActivationLink.bind(this);
    this.ManuallySync = this.ManuallySync.bind(this);
    this.CheckActivation = this.CheckActivation.bind(this);
    this.ListMindbodyOfferings = this.ListMindbodyOfferings.bind(this);
    this.ListMindbodyStaff = this.ListMindbodyStaff.bind(this);
    this.UpdateMindbodyOfferingMappings = this.UpdateMindbodyOfferingMappings.bind(this);
    this.ListMindbodyOfferingMappings = this.ListMindbodyOfferingMappings.bind(this);
    this.UpdateMindbodyProviderMappings = this.UpdateMindbodyProviderMappings.bind(this);
    this.ListMindbodyProviderMappings = this.ListMindbodyProviderMappings.bind(this);
  }

  SetClientId(request: DeepPartial<SetClientIdRequest>, metadata?: grpc.Metadata): Promise<SetClientIdResponse> {
    return this.rpc.unary(MindbodyServiceSetClientIdDesc, SetClientIdRequest.fromPartial(request), metadata);
  }

  RemoveClientId(
    request: DeepPartial<RemoveClientIdRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveClientIdResponse> {
    return this.rpc.unary(MindbodyServiceRemoveClientIdDesc, RemoveClientIdRequest.fromPartial(request), metadata);
  }

  GetClientId(request: DeepPartial<GetClientIdRequest>, metadata?: grpc.Metadata): Promise<GetClientIdResponse> {
    return this.rpc.unary(MindbodyServiceGetClientIdDesc, GetClientIdRequest.fromPartial(request), metadata);
  }

  GetActivationLink(
    request: DeepPartial<GetActivationLinkRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetActivationLinkResponse> {
    return this.rpc.unary(
      MindbodyServiceGetActivationLinkDesc,
      GetActivationLinkRequest.fromPartial(request),
      metadata,
    );
  }

  ManuallySync(request: DeepPartial<ManuallySyncRequest>, metadata?: grpc.Metadata): Promise<ManuallySyncResponse> {
    return this.rpc.unary(MindbodyServiceManuallySyncDesc, ManuallySyncRequest.fromPartial(request), metadata);
  }

  CheckActivation(
    request: DeepPartial<CheckActivationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CheckActivationResponse> {
    return this.rpc.unary(MindbodyServiceCheckActivationDesc, CheckActivationRequest.fromPartial(request), metadata);
  }

  ListMindbodyOfferings(
    request: DeepPartial<ListMindbodyOfferingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMindbodyOfferingsResponse> {
    return this.rpc.unary(
      MindbodyServiceListMindbodyOfferingsDesc,
      ListMindbodyOfferingsRequest.fromPartial(request),
      metadata,
    );
  }

  ListMindbodyStaff(
    request: DeepPartial<ListMindbodyStaffRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMindbodyStaffResponse> {
    return this.rpc.unary(
      MindbodyServiceListMindbodyStaffDesc,
      ListMindbodyStaffRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateMindbodyOfferingMappings(
    request: DeepPartial<UpdateMindbodyOfferingMappingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateMindbodyOfferingMappingsResponse> {
    return this.rpc.unary(
      MindbodyServiceUpdateMindbodyOfferingMappingsDesc,
      UpdateMindbodyOfferingMappingsRequest.fromPartial(request),
      metadata,
    );
  }

  ListMindbodyOfferingMappings(
    request: DeepPartial<ListMindbodyOfferingMappingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMindbodyOfferingMappingsResponse> {
    return this.rpc.unary(
      MindbodyServiceListMindbodyOfferingMappingsDesc,
      ListMindbodyOfferingMappingsRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateMindbodyProviderMappings(
    request: DeepPartial<UpdateMindbodyProviderMappingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateMindbodyProviderMappingsResponse> {
    return this.rpc.unary(
      MindbodyServiceUpdateMindbodyProviderMappingsDesc,
      UpdateMindbodyProviderMappingsRequest.fromPartial(request),
      metadata,
    );
  }

  ListMindbodyProviderMappings(
    request: DeepPartial<ListMindbodyProviderMappingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListMindbodyProviderMappingsResponse> {
    return this.rpc.unary(
      MindbodyServiceListMindbodyProviderMappingsDesc,
      ListMindbodyProviderMappingsRequest.fromPartial(request),
      metadata,
    );
  }
}

export const MindbodyServiceDesc = { serviceName: "proto.MindbodyService" };

export const MindbodyServiceSetClientIdDesc: UnaryMethodDefinitionish = {
  methodName: "SetClientId",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetClientIdRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetClientIdResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MindbodyServiceRemoveClientIdDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveClientId",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveClientIdRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveClientIdResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MindbodyServiceGetClientIdDesc: UnaryMethodDefinitionish = {
  methodName: "GetClientId",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetClientIdRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetClientIdResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MindbodyServiceGetActivationLinkDesc: UnaryMethodDefinitionish = {
  methodName: "GetActivationLink",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetActivationLinkRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetActivationLinkResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MindbodyServiceManuallySyncDesc: UnaryMethodDefinitionish = {
  methodName: "ManuallySync",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ManuallySyncRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ManuallySyncResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MindbodyServiceCheckActivationDesc: UnaryMethodDefinitionish = {
  methodName: "CheckActivation",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CheckActivationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CheckActivationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MindbodyServiceListMindbodyOfferingsDesc: UnaryMethodDefinitionish = {
  methodName: "ListMindbodyOfferings",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListMindbodyOfferingsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListMindbodyOfferingsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MindbodyServiceListMindbodyStaffDesc: UnaryMethodDefinitionish = {
  methodName: "ListMindbodyStaff",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListMindbodyStaffRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListMindbodyStaffResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MindbodyServiceUpdateMindbodyOfferingMappingsDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateMindbodyOfferingMappings",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateMindbodyOfferingMappingsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateMindbodyOfferingMappingsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MindbodyServiceListMindbodyOfferingMappingsDesc: UnaryMethodDefinitionish = {
  methodName: "ListMindbodyOfferingMappings",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListMindbodyOfferingMappingsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListMindbodyOfferingMappingsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MindbodyServiceUpdateMindbodyProviderMappingsDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateMindbodyProviderMappings",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateMindbodyProviderMappingsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateMindbodyProviderMappingsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MindbodyServiceListMindbodyProviderMappingsDesc: UnaryMethodDefinitionish = {
  methodName: "ListMindbodyProviderMappings",
  service: MindbodyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListMindbodyProviderMappingsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListMindbodyProviderMappingsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
