import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { addDays, eachDayOfInterval, format, getDay, isSameDay, startOfWeek } from "date-fns";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IconButton from "../../../components/IconButton";
import ResourceListFetch from "../../../components/elements/ResourceListFetch";
import ResourceListRender from "../../../components/elements/ResourceListRender";
import KeyboardArrowLeftIcon from "../../../components/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "../../../components/icons/KeyboardArrowRight";
import * as ReduxDialogs from "../../../redux/features/dialogs";
import { BookingDTO } from "sdk/dist/bookings_pb";
import { bookingStore } from "../../../stores/booking-store";
import { daysOfWeek, splitTime } from "../../../util";
import { breakpoints, colors, hexToRgba } from "../../../util/consts";
import { toDateTz } from "../../../util/timestamp";
import typography from "../../../util/typography";
import { CalendarCard } from "./Card";
import Timeline from "./Timeline";
import { ThirdPartyCards } from "./ThirdPartyCards";

function getMinutesBetweenTimeString(startTimeString, endTimeString) {
  const [startHour, startMinute] = splitTime(startTimeString);
  const [endHour, endMinute] = splitTime(endTimeString);

  return Math.abs(endHour * 60 + endMinute - (startHour * 60 + startMinute));
}

function getHeightFromMinutes(minutes, base) {
  return (minutes * 2 + base).toString() + "px";
}

class Calendar extends Component {
  // quickbook
  handleOpenQuickBook = (e, provider, date) => {
    const { dispatch, openingHours } = this.props;
    // Card styles
    const base = 60; // base dist from top
    const { top, left, right } = e.target.getBoundingClientRect();
    const mouseY = e.clientY - top;
    const cardTop = Math.floor((mouseY - base) / 60) * 60 + 61 + top + 2;

    const cardStyle = {
      top: cardTop,
      left: left + 1,
      width: right - left - 2,
      height: 58
    };

    // get opening hours
    const [openingStartHour, openingStartMinute] = splitTime(openingHours.OpensAt);
    // 60px for every half hour block
    const numThirtyBlocks = Math.floor((mouseY - base) / 60);
    // find the clicked on block
    const timeSinceOpenInMinutes =
      numThirtyBlocks * 30 + openingStartHour * 60 + openingStartMinute;
    const startHour = Math.floor(timeSinceOpenInMinutes / 60).toString();
    const startMinute = timeSinceOpenInMinutes % 60 === 0 ? "00" : "30";
    const startTime =
      (startHour < 10 ? "0" + startHour.toString() : startHour.toString()) +
      ":" +
      startMinute +
      ":00";
    const endHour = Math.floor((timeSinceOpenInMinutes + 30) / 60).toString();
    const endMinute = (timeSinceOpenInMinutes + 30) % 60 === 0 ? "00" : "30";
    const endTime =
      (endHour < 10 ? "0" + endHour.toString() : endHour.toString()) + ":" + endMinute + ":00";
    // create an empty card with the start and end date set
    // create a modal, positioned on the side with the most space, and starting at the top or bottom of the card depending on how far down the card is relative to the screen.

    // position of the card relative to the screen, so it can be positioned absolutely. (includes width and height)
    // start time (Y)
    // selected date
    // provider
    dispatch(
      ReduxDialogs.openQuickBook({ cardStyle, startTime, endTime, date, provider }, () => {
        this.props.refreshCalendar();
      })
    );
  };

  // open a booking for details/edit?
  handleAptCardClicked = (e, booking) => {
    e.stopPropagation();
    if (booking.Type === BookingDTO.Type.BOOKING_TIME_OFF) {
      this.props.dispatch(
        ReduxDialogs.openTimeOff(booking.id, () => {
          this.props.refreshCalendar();
        })
      );
    } else {
      this.props.dispatch(
        ReduxDialogs.openAppointment(booking.id, "", false, () => {
          this.props.refreshCalendar();
        })
      );
    }
  };

  clearProvider = () => {
    bookingStore.deleteAll();
    this.props.history.push("/admin/locs/" + this.props.match.params.lid + "/appointments");
  };

  preventClickThrough = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      numPages,
      currentPage,
      provider,
      providers,
      date,
      day,
      week,
      openingHours,
      prevPage,
      nextPage,
      bookings,
      viewingThirdParty
    } = this.props;

    const dateStr = date && format(date, "yyyy-MM-dd");
    const isIframe = window.location.pathname.includes("/iframe/");

    return (
      <Table>
        <THead>
          <Tr>
            <Th
              onClick={provider ? this.clearProvider : () => {}}
              active={Boolean(provider)}
              isIframe={isIframe}
            >
              <TCell>
                {provider ? (
                  <>
                    <KeyboardArrowLeftIcon size="small" />
                    <HeaderSubtitle>
                      {provider.User.FirstName} {provider.User.LastName}
                    </HeaderSubtitle>
                  </>
                ) : (
                  <>
                    <IconButton mini onClick={prevPage}>
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <TimelineHeaderText>
                      {currentPage}/{numPages}
                    </TimelineHeaderText>
                    <IconButton mini onClick={nextPage}>
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </>
                )}
              </TCell>
            </Th>
            {provider
              ? week.map((dt, i) => (
                  <Th key={i} isIframe={isIframe}>
                    <TCell>
                      <HeaderSubtitle isToday={isSameDay(dt, new Date())}>
                        {format(dt, "EEEE do")}
                      </HeaderSubtitle>
                    </TCell>
                  </Th>
                ))
              : providers.map((p, i) => (
                  <Th key={i} active={Boolean(p)} isIframe={isIframe}>
                    {p && p.IsActive ? (
                      <TCell
                        as={Link}
                        to={"/admin/locs/" + p.Location.ID + "/providers/" + p.User.ID}
                      >
                        <BlankAvatar
                          style={{
                            backgroundImage:
                              "url(" + (Boolean(p.Photo) && p.Photo.DownloadURL) + ")"
                          }}
                        >
                          <AvatarText>{p.User.FirstName[0] + p.User.LastName[0]}</AvatarText>
                        </BlankAvatar>
                        <HeaderSubtitle>
                          {p.User.FirstName} {p.User.LastName}
                        </HeaderSubtitle>
                      </TCell>
                    ) : (
                      <TCell />
                    )}
                  </Th>
                ))}
          </Tr>
        </THead>
        <TBody>
          <Tr>
            <Td>
              <TCell>
                <Timeline date={date} openingHours={openingHours} />
              </TCell>
            </Td>
            {provider
              ? week.map((dt, i) => {
                  const dy = daysOfWeek[getDay(dt)];
                  const weekDateStr = format(dt, "yyyy-MM-dd");
                  return (
                    <Td key={i}>
                      <TCell onClick={(e) => this.handleOpenQuickBook(e, provider, dt)}>
                        {provider.ProviderSchedule[dy].OpensAt &&
                        provider.ProviderSchedule[dy].ClosesAt ? (
                          <>
                            {provider.ProviderSchedule[dy].OpensAt.localeCompare(
                              openingHours.OpensAt
                            ) >= 0 && (
                              <ClosedSection
                                onClick={this.preventClickThrough}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  height: getHeightFromMinutes(
                                    getMinutesBetweenTimeString(
                                      provider.ProviderSchedule[dy].OpensAt,
                                      openingHours.OpensAt
                                    ),
                                    60
                                  )
                                }}
                              />
                            )}
                            {provider.ProviderSchedule[dy].ClosesAt.localeCompare(
                              openingHours.ClosesAt
                            ) <= 0 && (
                              <ClosedSection
                                onClick={this.preventClickThrough}
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  height: getHeightFromMinutes(
                                    getMinutesBetweenTimeString(
                                      provider.ProviderSchedule[dy].ClosesAt,
                                      openingHours.ClosesAt
                                    ),
                                    60
                                  )
                                }}
                              />
                            )}
                            {/* Weekly view */}
                            {!viewingThirdParty && bookings
                              .filter(
                                (b) =>
                                  b.provider.id === provider.User.ID &&
                                  weekDateStr === toDateTz(b.startDatetime).format("YYYY-MM-DD")
                              )
                              .map((bk, j) => (
                                <CalendarCard
                                  key={bk.id + j}
                                  openingHours={openingHours}
                                  startDate={weekDateStr}
                                  endDate={weekDateStr}
                                  onClick={(e) => this.handleAptCardClicked(e, bk)}
                                  booking={bk}
                                />
                              ))}

                              {viewingThirdParty && (
                                <ThirdPartyCards
                                  openingHours={openingHours}
                                  startDate={weekDateStr}
                                  endDate={weekDateStr}
                                  locationId={provider.Location.ID}
                                />
                              )}
                          </>
                        ) : (
                          <ClosedSection onClick={this.preventClickThrough} />
                        )}
                      </TCell>
                    </Td>
                  );
                })
              : providers.map((p, i) => (
                  <Td key={i}>
                    {p && p.IsActive ? (
                      <TCell onClick={(e) => this.handleOpenQuickBook(e, p, date)}>
                        {p.ProviderSchedule[day].OpensAt && p.ProviderSchedule[day].ClosesAt ? (
                          <>
                            {p.ProviderSchedule[day].OpensAt > openingHours.OpensAt && (
                              <ClosedSection
                                onClick={this.preventClickThrough}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  height: getHeightFromMinutes(
                                    getMinutesBetweenTimeString(
                                      p.ProviderSchedule[day].OpensAt,
                                      openingHours.OpensAt
                                    ),
                                    60
                                  )
                                }}
                              />
                            )}
                            {p.ProviderSchedule[day].ClosesAt < openingHours.ClosesAt && (
                              <ClosedSection
                                onClick={this.preventClickThrough}
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  height: getHeightFromMinutes(
                                    getMinutesBetweenTimeString(
                                      p.ProviderSchedule[day].ClosesAt,
                                      openingHours.ClosesAt
                                    ),
                                    60
                                  )
                                }}
                              />
                            )}
                            {/* Daily view */}
                            {!viewingThirdParty && bookings
                              .filter(
                                (b) =>
                                  b.provider.id === p.User.ID &&
                                  dateStr === toDateTz(b.startDatetime).format("YYYY-MM-DD")
                              )
                              .map((bk, i) => (
                                <CalendarCard
                                  key={bk.id}
                                  openingHours={openingHours}
                                  startDate={dateStr}
                                  endDate={dateStr}
                                  onClick={(e) => this.handleAptCardClicked(e, bk)}
                                  booking={bk}
                                />
                              ))}

                              {viewingThirdParty && providers.filter(p => p?.Location !== undefined).map((p) => (
                                <ThirdPartyCards
                                  key={p.Location.ID}
                                  openingHours={openingHours}
                                  startDate={dateStr}
                                  endDate={dateStr}
                                  locationId={p.Location.ID}
                                />
                              ))}
                          </>
                        ) : (
                          <ClosedSection onClick={this.preventClickThrough} />
                        )}
                      </TCell>
                    ) : (
                      <TCell>
                        <ClosedSection onClick={this.preventClickThrough} />
                      </TCell>
                    )}
                  </Td>
                ))}
          </Tr>
        </TBody>
      </Table>
    );
  }
}

class ResourceForCalendar extends Component {
  calendarRef = React.createRef();

  refreshCalendar = () => {
    if (this.calendarRef.current) {
      this.calendarRef.current.getList(true);
    }
  };

  render() {
    const { date, location, provider, list, ...otherProps } = this.props; // eslint-disable-line no-unused-vars

    const day = daysOfWeek[getDay(date)];
    const start = startOfWeek(date);
    const week = eachDayOfInterval({ start: start, end: addDays(start, 6) });

    if (provider) {
      const providerViewContext = {
        Location: provider.Location.ID,
        Provider: provider.User.ID,
        StartDate: format(week[0], "yyyy-MM-dd"),
        EndDate: format(week[week.length - 1], "yyyy-MM-dd"),
        Selector: "Range"
      };
      const providerViewType = "LocationProviderBookingCalendarPage";

      return (
        <>
          <ResourceListFetch
            listctx={providerViewContext}
            type={providerViewType}
            ref={this.calendarRef}
            force
          />
          <ResourceListRender
            listctx={providerViewContext}
            type={providerViewType}
            compSuccess={Calendar}
            forwardedProps={{
              day,
              week,
              date,
              location,
              provider,
              refreshCalendar: this.refreshCalendar,
              ...otherProps
            }}
          />
        </>
      );
    } else {
      const locationViewContext = {
        Location: location.ID,
        Selector: "Single",
        Date: format(date, "yyyy-MM-dd")
      };
      const locationViewType = "LocationBookingCalendarPage";

      return (
        <>
          <ResourceListFetch
            listctx={locationViewContext}
            type={locationViewType}
            ref={this.calendarRef}
            force
          />
          <ResourceListRender
            listctx={locationViewContext}
            type={locationViewType}
            compSuccess={Calendar}
            forwardedProps={{
              day,
              week,
              date,
              location,
              provider,
              refreshCalendar: this.refreshCalendar,
              ...otherProps
            }}
          />
        </>
      );
    }
  }
}

export default connect()(ResourceForCalendar);

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  outline: none;
  height: 100%;
`;

const THead = styled.thead``;

const TBody = styled.tbody``;

const Tr = styled.tr`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
`;

const tbase = css`
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 180px;
  border: none;
  outline: none;
  background: #fff;
`;

const leftcol = css`
  position: sticky;
  left: 0;
  width: 150px;
  min-width: 150px;
  z-index: 5;

  ${breakpoints["phone-only"]} {
    width: 70px;
    min-width: 70px;
  }
`;

const Th = styled.th`
  ${tbase};

  z-index: 4;
  position: sticky;
  top: ${({ isIframe }) => (isIframe ? "5rem" : "9.313rem")};
  background-color: #f4f8f9;
  transition: background-color 0.2s ease;

  ${({ active }) =>
    active
      ? `cursor: pointer;
      &:hover {
      background-color: #dbdfe0;
    }`
      : ""}

  &:first-of-type {
    ${leftcol};
  }
`;

const Td = styled.td`
  ${tbase};
  &:first-of-type {
    ${leftcol};
    z-index: 3;
  }
`;

const TCell = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  border: 1px solid #f4f8f9;
  width: 100%;
  height: 100%;

  text-decoration: none;
`;

const BlankAvatar = styled.div`
  height: 32px;
  min-height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 16px;
  background-color: ${colors.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
`;

const TimelineHeaderText = styled.span`
  ${typography.overline};
  ${typography.bold};
`;

const AvatarText = styled.div`
  ${typography.caption};
  ${typography.bold};
  ${typography.elipse};
  color: ${colors.whiteText.highEmphasis};
  text-transform: uppercase;
`;

const HeaderSubtitle = styled.div`
  ${typography.caption};
  ${typography.bold};
  position: relative;
  // transition: opacity 0.3s ease;

  // &:hover {
  //   opacity: 0.6;
  // }

  &::before {
    content: "";
    display: ${({ isToday }) => (isToday ? "block" : "none")};

    height: 8px;
    width: 8px;
    background-color: ${colors.secondary.main};

    position: absolute;
    left: -14px;
    top: 4px;
    border-radius: 50%;
  }
`;

const ClosedSection = styled.div`
  background-color: ${hexToRgba(colors.primary.main, 0.15)};
  width: 100%;
  height: 100%;
`;
