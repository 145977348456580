import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import ClearIcon from "./../../components/icons/Clear";
import {
  LineItem,
  UpdateLineItemRequest,
  BookingDTO,
  DeleteLineItemRequest
} from "sdk/dist/bookings_pb";
import { colors } from "./../../util/consts";
import { fundTypeToText } from "./../../util/funds";
import { offeringTypeToText } from "./../../util/offerings";
import DropdownSelect from "../DropdownSelect";
import {
  AccordianEdit,
  ButtonDetails,
  ButtonHead,
  NoSpacingIconButton,
  OptionsContainer,
  Separator
} from "../elements/AccordianElements";
import Options from "../icons/Options";
import { FundType } from "sdk/dist/offerings_pb";
import { Select } from "../form/Select";
import { rpc, metadata } from "../../grpc/grpc-legacy";
import { FieldMask } from "google-protobuf/google/protobuf/field_mask_pb";
import { bookingStore } from "./../../stores/booking-store";
import { toastStore } from "./../../stores/toast-store";

interface Props {
  item: LineItem.AsObject;
  booking: BookingDTO.AsObject;
  readOnly?: boolean;
}

@observer
export class BookingLineItem extends React.Component<Props> {
  @observable
  isOpen = false;

  remove = async () => {
    try {
      const req = new DeleteLineItemRequest();
      req.setLineItemId(this.props.item.id);
      const res = await rpc.lineItems.delete(req, metadata());
      // cannot remove the booking from here because what we deleted is only the line item in the boooking
      // so reload the booking to refresh the state and let mobx to re-render the line item component
      await bookingStore.load(this.props.booking.id);
    } catch (err) {
      toastStore.grpcError(err);
    }
  };

  async updateFundType(fundType: FundType) {
    try {
      const req = new UpdateLineItemRequest();
      const mask = new FieldMask();
      const update = new LineItem();
      update.setId(this.props.item.id);
      update.setFundType(fundType);
      mask.addPaths("line_item.fund_type");
      req.setLineItem(update);
      req.setMask(mask);
      const res = await rpc.lineItems.update(req, metadata());
      this.props.item.fundType = fundType;
      await bookingStore.add(res.toObject());
    } catch (err) {
      toastStore.grpcError(err);
    }
  }

  render() {
    const item = this.props.item;
    const offering = this.props.item.offering!;
    return (
      <AccordianEdit>
        <ButtonHead style={{ width: 150 }}>{offering.name}</ButtonHead>
        <Separator />
        <ButtonDetails>{offeringTypeToText(offering.type)}</ButtonDetails>
        <Separator />
        {this.props.readOnly ? (
          <ButtonDetails>{fundTypeToText(item.fundType)}</ButtonDetails>
        ) : (
          <Select
            value={item.fundType}
            values={offering.enabledFundsList
              .filter((fundType) => {
                if (
                  process.env.BOOKLYFE_SERVER_INSTANCE_COUNTRY === "US" &&
                  fundType === FundType.THIRD_PARTY_INVOICE
                )
                  return false;
                return fundType != FundType.DVA_ALLIED;
              })
              .map((fundType) => ({
                label: fundTypeToText(fundType),
                value: fundType
              }))}
            onChange={(option) => this.updateFundType(option.value)}
            style={{ width: 200 }}
          >
            {offering.enabledFundsList.map((fundType) => (
              <option key={fundType} value={fundType}>
                {fundTypeToText(fundType)}
              </option>
            ))}
          </Select>
        )}
        {!this.props.readOnly && (
          <OptionsContainer>
            {!this.isOpen ? (
              <DropdownSelect options={[{ label: "Remove", onClick: this.remove }]}>
                <NoSpacingIconButton>
                  <Options />
                </NoSpacingIconButton>
              </DropdownSelect>
            ) : (
              <NoSpacingIconButton onClick={() => (this.isOpen = !this.isOpen)}>
                <ClearIcon fill={colors.primary.main} />
              </NoSpacingIconButton>
            )}
          </OptionsContainer>
        )}
      </AccordianEdit>
    );
  }
}
