import * as grpcWeb from "grpc-web";
import { IMiddleware } from "./GrpcInterceptor";
import { toastStore } from "../stores/toast-store";

export class ErrorToastMiddleware implements IMiddleware {
  constructor() {}

  onError(id: string, methodDesc: any, error: grpcWeb.RpcError) {
    toastStore.error(error.message ?? "An unexpected server error has occurred.");
  }
  pre() {
    // Do nothing
  }
  post() {
    // Do nothing
  }
}
