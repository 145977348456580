/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";
import { StringValue } from "./google/protobuf/wrappers";

export const protobufPackage = "proto";

/**
 * Currency name codes are defined by ISO 4217 and found in https://www.iso.org/iso-4217-currency-codes.html
 * Add new codes as needed
 */
export enum ISOCurrencyName {
  /** XXX - ISO name where no currency is involved. Used internally as unknown */
  XXX = 0,
  /** AUD - Australia */
  AUD = 1,
  /** USD - United States */
  USD = 2,
  /** CAD - Canada */
  CAD = 3,
  /** EUR - Netherlands, Austria, Finland, Ireland, Belgium, Germany */
  EUR = 4,
  /** GBP - United Kingdom */
  GBP = 5,
  /** DKK - Denmark */
  DKK = 6,
  /** NZD - New Zealand */
  NZD = 7,
  /** SEK - Sweden */
  SEK = 8,
  /** CHF - Switzerland */
  CHF = 9,
  /** SGD - Singapore */
  SGD = 10,
  /** NOK - Norway */
  NOK = 11,
  UNRECOGNIZED = -1,
}

export function iSOCurrencyNameFromJSON(object: any): ISOCurrencyName {
  switch (object) {
    case 0:
    case "XXX":
      return ISOCurrencyName.XXX;
    case 1:
    case "AUD":
      return ISOCurrencyName.AUD;
    case 2:
    case "USD":
      return ISOCurrencyName.USD;
    case 3:
    case "CAD":
      return ISOCurrencyName.CAD;
    case 4:
    case "EUR":
      return ISOCurrencyName.EUR;
    case 5:
    case "GBP":
      return ISOCurrencyName.GBP;
    case 6:
    case "DKK":
      return ISOCurrencyName.DKK;
    case 7:
    case "NZD":
      return ISOCurrencyName.NZD;
    case 8:
    case "SEK":
      return ISOCurrencyName.SEK;
    case 9:
    case "CHF":
      return ISOCurrencyName.CHF;
    case 10:
    case "SGD":
      return ISOCurrencyName.SGD;
    case 11:
    case "NOK":
      return ISOCurrencyName.NOK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ISOCurrencyName.UNRECOGNIZED;
  }
}

export function iSOCurrencyNameToJSON(object: ISOCurrencyName): string {
  switch (object) {
    case ISOCurrencyName.XXX:
      return "XXX";
    case ISOCurrencyName.AUD:
      return "AUD";
    case ISOCurrencyName.USD:
      return "USD";
    case ISOCurrencyName.CAD:
      return "CAD";
    case ISOCurrencyName.EUR:
      return "EUR";
    case ISOCurrencyName.GBP:
      return "GBP";
    case ISOCurrencyName.DKK:
      return "DKK";
    case ISOCurrencyName.NZD:
      return "NZD";
    case ISOCurrencyName.SEK:
      return "SEK";
    case ISOCurrencyName.CHF:
      return "CHF";
    case ISOCurrencyName.SGD:
      return "SGD";
    case ISOCurrencyName.NOK:
      return "NOK";
    case ISOCurrencyName.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface OrgDefCcyRequest {
  orgId: string;
}

export interface OrgDefCcyResponse {
  currency: Currency | undefined;
}

export interface AvailableCcyInInstanceResponse {
  currencies: Currency[];
}

export interface Currency {
  ccyIsoName: ISOCurrencyName;
  ccyNameEnglish: string;
}

export interface GetCurrenciesRequest {
  country: string | undefined;
}

export interface GetCurrenciesResponse {
  currencies: CurrencyDTO[];
}

export interface CurrencyDTO {
  isoName: string;
  name: string;
}

function createBaseOrgDefCcyRequest(): OrgDefCcyRequest {
  return { orgId: "" };
}

export const OrgDefCcyRequest = {
  encode(message: OrgDefCcyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orgId !== "") {
      writer.uint32(10).string(message.orgId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrgDefCcyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrgDefCcyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orgId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrgDefCcyRequest {
    return { orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "" };
  },

  toJSON(message: OrgDefCcyRequest): unknown {
    const obj: any = {};
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrgDefCcyRequest>, I>>(base?: I): OrgDefCcyRequest {
    return OrgDefCcyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrgDefCcyRequest>, I>>(object: I): OrgDefCcyRequest {
    const message = createBaseOrgDefCcyRequest();
    message.orgId = object.orgId ?? "";
    return message;
  },
};

function createBaseOrgDefCcyResponse(): OrgDefCcyResponse {
  return { currency: undefined };
}

export const OrgDefCcyResponse = {
  encode(message: OrgDefCcyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currency !== undefined) {
      Currency.encode(message.currency, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrgDefCcyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrgDefCcyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.currency = Currency.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrgDefCcyResponse {
    return { currency: isSet(object.currency) ? Currency.fromJSON(object.currency) : undefined };
  },

  toJSON(message: OrgDefCcyResponse): unknown {
    const obj: any = {};
    if (message.currency !== undefined) {
      obj.currency = Currency.toJSON(message.currency);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrgDefCcyResponse>, I>>(base?: I): OrgDefCcyResponse {
    return OrgDefCcyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrgDefCcyResponse>, I>>(object: I): OrgDefCcyResponse {
    const message = createBaseOrgDefCcyResponse();
    message.currency = (object.currency !== undefined && object.currency !== null)
      ? Currency.fromPartial(object.currency)
      : undefined;
    return message;
  },
};

function createBaseAvailableCcyInInstanceResponse(): AvailableCcyInInstanceResponse {
  return { currencies: [] };
}

export const AvailableCcyInInstanceResponse = {
  encode(message: AvailableCcyInInstanceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.currencies) {
      Currency.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AvailableCcyInInstanceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvailableCcyInInstanceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.currencies.push(Currency.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AvailableCcyInInstanceResponse {
    return {
      currencies: globalThis.Array.isArray(object?.currencies)
        ? object.currencies.map((e: any) => Currency.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AvailableCcyInInstanceResponse): unknown {
    const obj: any = {};
    if (message.currencies?.length) {
      obj.currencies = message.currencies.map((e) => Currency.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AvailableCcyInInstanceResponse>, I>>(base?: I): AvailableCcyInInstanceResponse {
    return AvailableCcyInInstanceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AvailableCcyInInstanceResponse>, I>>(
    object: I,
  ): AvailableCcyInInstanceResponse {
    const message = createBaseAvailableCcyInInstanceResponse();
    message.currencies = object.currencies?.map((e) => Currency.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCurrency(): Currency {
  return { ccyIsoName: 0, ccyNameEnglish: "" };
}

export const Currency = {
  encode(message: Currency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ccyIsoName !== 0) {
      writer.uint32(8).int32(message.ccyIsoName);
    }
    if (message.ccyNameEnglish !== "") {
      writer.uint32(18).string(message.ccyNameEnglish);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Currency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ccyIsoName = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ccyNameEnglish = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Currency {
    return {
      ccyIsoName: isSet(object.ccyIsoName) ? iSOCurrencyNameFromJSON(object.ccyIsoName) : 0,
      ccyNameEnglish: isSet(object.ccyNameEnglish) ? globalThis.String(object.ccyNameEnglish) : "",
    };
  },

  toJSON(message: Currency): unknown {
    const obj: any = {};
    if (message.ccyIsoName !== 0) {
      obj.ccyIsoName = iSOCurrencyNameToJSON(message.ccyIsoName);
    }
    if (message.ccyNameEnglish !== "") {
      obj.ccyNameEnglish = message.ccyNameEnglish;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Currency>, I>>(base?: I): Currency {
    return Currency.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Currency>, I>>(object: I): Currency {
    const message = createBaseCurrency();
    message.ccyIsoName = object.ccyIsoName ?? 0;
    message.ccyNameEnglish = object.ccyNameEnglish ?? "";
    return message;
  },
};

function createBaseGetCurrenciesRequest(): GetCurrenciesRequest {
  return { country: undefined };
}

export const GetCurrenciesRequest = {
  encode(message: GetCurrenciesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.country !== undefined) {
      StringValue.encode({ value: message.country! }, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCurrenciesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCurrenciesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.country = StringValue.decode(reader, reader.uint32()).value;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCurrenciesRequest {
    return { country: isSet(object.country) ? String(object.country) : undefined };
  },

  toJSON(message: GetCurrenciesRequest): unknown {
    const obj: any = {};
    if (message.country !== undefined) {
      obj.country = message.country;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCurrenciesRequest>, I>>(base?: I): GetCurrenciesRequest {
    return GetCurrenciesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCurrenciesRequest>, I>>(object: I): GetCurrenciesRequest {
    const message = createBaseGetCurrenciesRequest();
    message.country = object.country ?? undefined;
    return message;
  },
};

function createBaseGetCurrenciesResponse(): GetCurrenciesResponse {
  return { currencies: [] };
}

export const GetCurrenciesResponse = {
  encode(message: GetCurrenciesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.currencies) {
      CurrencyDTO.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCurrenciesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCurrenciesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.currencies.push(CurrencyDTO.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCurrenciesResponse {
    return {
      currencies: globalThis.Array.isArray(object?.currencies)
        ? object.currencies.map((e: any) => CurrencyDTO.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetCurrenciesResponse): unknown {
    const obj: any = {};
    if (message.currencies?.length) {
      obj.currencies = message.currencies.map((e) => CurrencyDTO.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCurrenciesResponse>, I>>(base?: I): GetCurrenciesResponse {
    return GetCurrenciesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCurrenciesResponse>, I>>(object: I): GetCurrenciesResponse {
    const message = createBaseGetCurrenciesResponse();
    message.currencies = object.currencies?.map((e) => CurrencyDTO.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCurrencyDTO(): CurrencyDTO {
  return { isoName: "", name: "" };
}

export const CurrencyDTO = {
  encode(message: CurrencyDTO, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isoName !== "") {
      writer.uint32(10).string(message.isoName);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyDTO {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyDTO();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.isoName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CurrencyDTO {
    return {
      isoName: isSet(object.isoName) ? globalThis.String(object.isoName) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: CurrencyDTO): unknown {
    const obj: any = {};
    if (message.isoName !== "") {
      obj.isoName = message.isoName;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CurrencyDTO>, I>>(base?: I): CurrencyDTO {
    return CurrencyDTO.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CurrencyDTO>, I>>(object: I): CurrencyDTO {
    const message = createBaseCurrencyDTO();
    message.isoName = object.isoName ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

export interface CurrenciesService {
  GetOrgDefaultCcy(request: DeepPartial<OrgDefCcyRequest>, metadata?: grpc.Metadata): Promise<OrgDefCcyResponse>;
  GetAvailableCcyByServerInstanceLoc(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<AvailableCcyInInstanceResponse>;
  GetCurrencies(request: DeepPartial<GetCurrenciesRequest>, metadata?: grpc.Metadata): Promise<GetCurrenciesResponse>;
}

export class CurrenciesServiceClientImpl implements CurrenciesService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetOrgDefaultCcy = this.GetOrgDefaultCcy.bind(this);
    this.GetAvailableCcyByServerInstanceLoc = this.GetAvailableCcyByServerInstanceLoc.bind(this);
    this.GetCurrencies = this.GetCurrencies.bind(this);
  }

  GetOrgDefaultCcy(request: DeepPartial<OrgDefCcyRequest>, metadata?: grpc.Metadata): Promise<OrgDefCcyResponse> {
    return this.rpc.unary(CurrenciesServiceGetOrgDefaultCcyDesc, OrgDefCcyRequest.fromPartial(request), metadata);
  }

  GetAvailableCcyByServerInstanceLoc(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<AvailableCcyInInstanceResponse> {
    return this.rpc.unary(
      CurrenciesServiceGetAvailableCcyByServerInstanceLocDesc,
      Empty.fromPartial(request),
      metadata,
    );
  }

  GetCurrencies(request: DeepPartial<GetCurrenciesRequest>, metadata?: grpc.Metadata): Promise<GetCurrenciesResponse> {
    return this.rpc.unary(CurrenciesServiceGetCurrenciesDesc, GetCurrenciesRequest.fromPartial(request), metadata);
  }
}

export const CurrenciesServiceDesc = { serviceName: "proto.CurrenciesService" };

export const CurrenciesServiceGetOrgDefaultCcyDesc: UnaryMethodDefinitionish = {
  methodName: "GetOrgDefaultCcy",
  service: CurrenciesServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return OrgDefCcyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = OrgDefCcyResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CurrenciesServiceGetAvailableCcyByServerInstanceLocDesc: UnaryMethodDefinitionish = {
  methodName: "GetAvailableCcyByServerInstanceLoc",
  service: CurrenciesServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AvailableCcyInInstanceResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CurrenciesServiceGetCurrenciesDesc: UnaryMethodDefinitionish = {
  methodName: "GetCurrencies",
  service: CurrenciesServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCurrenciesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCurrenciesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
