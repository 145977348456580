
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { withFormik } from "formik";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withToastManager } from "react-toast-notifications";
import { compose } from "recompose";
import * as Yup from "yup";
import { toastStore } from '../../../stores/toast-store';
// Utils
import { colors } from "./../../../util/consts";

// Redux
import * as ResourceActions from "../../../redux/features/resources/thunkactions";
import * as GlobalActions from "./../../../redux/features/global/actions";
import apiService from "./../../../redux/services/api";

import { userStore } from "./../../../mobx/stores/UserStore";


// Custom components
import Button from "./../../../components/Button";
import Error from "./../../../components/form/Error";
import FooterContainer from "./../../../components/form/FooterContainer";
import FooterText from "./../../../components/form/FooterText";
import Form from "./../../../components/form/Form";
import FormHeader from "./../../../components/form/FormHeader";
import Input from "./../../../components/form/Input";
import Label from "./../../../components/form/Label";
import MobileInput from "./../../../components/form/MobileInput";
import Section from "./../../../components/form/Section";
import SectionsContainer from "./../../../components/form/SectionsContainer";
import { paymentModalStore } from "./../../../mobx/stores/PaymentModalStore";

import { metadata, rpc } from '../../../grpc/grpc-legacy';
import { CreditCard, ListCreditCardsRequest } from 'sdk/dist/credit_cards_pb';
import EmailIcon from './../../../components/icons/Email';
import MobileIcon from './../../../components/icons/Mobile';


const IS_PHONE_REGEX = /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1})?[-\s\./0-9]*$/; // eslint-disable-line no-useless-escape

let history;

class Login extends Component {

  componentWillMount() {
    history = this.props.history;
  }
  state = {
    emailInput: true
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormHeader title="Sign in to Lyfe" subtitle="Enter either your email or mobile number below" />
        <SectionsContainer>
        <Section>
          <div style={{ textAlignLast: 'center'}}>
        <Button
            css={css`
              width: 150px;
              background-color: ${this.state.emailInput ? colors.secondary.main : colors.primary[300]};
              margin-right: 5px;
            `}
            variant="contained"
            color="secondary"
            type="button"
            onClick={()=>{this.setState({emailInput: true}), values.EmailPhone = ""}}

          >
            <div style={{display: 'flex', justifyContent: 'center'}}><EmailIcon size="small" fill="white" style={{marginRight:'5px'}}/>Email </div>
          </Button>
          <Button
            css={css`
              width: 150px;
              background-color: ${this.state.emailInput ? colors.primary[300] : colors.secondary.main};
              margin-left: 5px;
            `}
            variant="contained"
            type="button"
            onClick={()=>{this.setState({emailInput: false}), values.EmailPhone=""}}
          >
            <div style={{display: 'flex', justifyContent: 'center'}}><MobileIcon size="small" fill="white" style={{marginRight:'5px'}}/>Mobile</div>
          </Button> </div>
          </Section>
          { this.state.emailInput ?
          <Section>
            <Label htmlFor="login-EmailPhone">Email</Label>
            <Input
              id="login-EmailPhone"
              placeholder="example@email.com"
              type="text"
              name="EmailPhone"
              value={values.EmailPhone}
              onBlur={handleBlur}
              onChange={handleChange}
              autoCapitalize="none"
            />
            {!!errors.EmailPhone && touched.EmailPhone && <Error>{errors.EmailPhone}</Error>}
          </Section> :
            <Section>
            <Label htmlFor="login-EmailPhone">Phone</Label>
            <MobileInput
                  id="login-EmailPhone"
                  name="EmailPhone"
                  onCountryChange={(e)=>{values.MobileCountryCode = e}}
                  onPhoneChange={(e)=>{values.EmailPhone = e}}
                 />
            {!!errors.EmailPhone && touched.EmailPhone && <Error>{errors.EmailPhone}</Error>}
          </Section>
          }
          <Section>
            <LabelSpacer>
              <Label htmlFor="login-Password">Password</Label>
              <Label
                htmlFor="login-Password"
                onClick={()=> this.props.setPage("forgotpass", {})}
                style={{ cursor: "pointer", userSelect: "none" }}
              >
                <FooterText>Forgot?</FooterText>
              </Label>
            </LabelSpacer>
            <Input
              id="login-Password"
              placeholder="enter password"
              type="password"
              name="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Password}
            />
            {!!errors.Password && touched.Password && <Error>{errors.Password}</Error>}
          </Section>
        </SectionsContainer>
        <FooterContainer>
          <Button
            css={css`
              width: 150px;
            `}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            Login
          </Button>
        </FooterContainer>
        <FooterContainer
          css={css`
            margin-top: 16px;
          `}
        >
          <FooterText>
            Don't have an account?{" "}
            <span
              css={css`
                text-decoration: none;
                color: ${colors.secondary.main};
                cursor: pointer;
              `}
              onClick={() => this.props.setPage("signup", this.props.pageData)}
            >
              Sign up
            </span>
          </FooterText>
        </FooterContainer>
      </Form>
    );
  }
}

const formikEnhancer = withFormik({
  mapPropsToValues: () => ({
    EmailPhone: "",
    Password: ""
  }),
  validationSchema: Yup.object().shape({
    EmailPhone: Yup.string().required("Please enter your email or mobile number."),
    Password: Yup.string()
      .min(8, "Passwords have an 8 character minimum.")
      .required("Please enter a password.")
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const EmailPhone = values.EmailPhone;
    const usePhone = IS_PHONE_REGEX.test(EmailPhone);
    try {
      const logRes = await apiService.userLogin(
        usePhone ? null : EmailPhone.trim(),
        usePhone ? EmailPhone : null,
        usePhone ? "AU" : null,
        values.Password
      );

      // Get user.
      const resource = { $Metadata: { Type: "User" }, ID: logRes.Payload.UserID };
      const userRes = await props.dispatch(ResourceActions.action(resource, "Fetch", {}));
      // Store user address in userStorage when login
      userStore.setUser({
        ...userStore.user,
        address: userRes.Payload.Address ? userRes.Payload.Address : ""
      });

      const authUser = userRes.Payload;
      if (
        authUser.Email &&
        (!authUser.ValidatedFields || authUser.ValidatedFields.indexOf("Email") < 0)
      ) {
        props.setPage("verify", { user: authUser });
      } else if (
        authUser.MobileNumber &&
        (!authUser.ValidatedFields || authUser.ValidatedFields.indexOf("MobileNumber") < 0)
      ) {
        props.setPage("verify", { user: authUser });
      } else {
        props.closeModal(userRes.Payload);

        // Disable the redirection if props is passed
        // List of scenario where redirection is disabled
        // 1. Booking dialog (where user is not logged in)
        if (props.pageData !== undefined && props.pageData.disableRedirection === true) {
          return;
        } else {
          loginRoleRedirection(logRes.Payload.UserID, props);
        }
      }
    } catch (err) {
      toastStore.error(err.message);
    }
    setSubmitting(false);
  },
  displayName: "LoginForm"
});

async function redirectToPaymentDetails(user) {
  // check if user has set creditCard and Medicare fund
  const req = new ListCreditCardsRequest();
  req.setOwner(user.ID);
  req.setOwnerType(CreditCard.OwnerType.USER);
  const res = await rpc.userCards.list(req, metadata());
  const cards = res.toObject().cardsList;
  paymentModalStore._isCreditCardSet = cards.length !== 0;
  paymentModalStore._isMedicareFundSet = Object.keys(user.FundProperties).length !== 0;

  // open modal if credit card or medicare fund has not been set yet
  paymentModalStore.isOpen =
    !paymentModalStore._isCreditCardSet || !paymentModalStore._isMedicareFundSet;
}

async function loginRoleRedirection(userID, props) {
  const orgRes = await apiService.performRequest({
    method: "get",
    path: `/api/v1/orgs/`
  });
  const appoinRes = await apiService.performRequest({
    method: "get",
    path: `/api/v1/users/` + userID + `/bookings`
  });
  const userRes = await apiService.performRequest({
    method: "get",
    path: `/api/v1/users/` + userID
  });

  // If it has not organization(business)
  if (orgRes.Payload.length === 0) {
    // redirect client to payment details if they have not set payment up
    await redirectToPaymentDetails(userRes.Payload);

    // If it has any appointment, show them
    if (appoinRes.Payload.length > 0) {
      history.push("/users/appointments");
    }

    // If it has one organization check the locations
  } else if (orgRes.Payload.length === 1) {
    const orgID = orgRes.Payload[0].Org.ID;
    const locations = orgRes.Payload[0].Org.Locations;

    // Trigger a global event on login, this will tell the bredcrumbs to render
   props.dispatch(GlobalActions.setUserContext(orgID, ""))
    switch (locations.length) {
      // When no location, show view to add locations
      case 0:
        history.push("/admin/orgs/" + orgID + "/locations");
        break;
      // When one location, show location dashboard
      case 1:
        history.push("/admin/locs/" + locations[0].ID + "/dashboard");
        break;
      // If more than one locations, show them all
      default:
        history.push("/admin/orgs/" + orgID + "/locations");
        break;
    }
  }
}

export default compose(connect(), withToastManager, formikEnhancer, withRouter)(Login);

const LabelSpacer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;
