/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "proto";

export interface Media {
  id: string;
  filename: string;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  downloadUrl: string;
  description: string;
  mime: string;
  type: string;
  status: string;
}

export interface CreateMediaRequest {
  file: Uint8Array;
  filename: string;
  mime: string;
}

export interface UpdateMediaRequest {
  file: Uint8Array;
  filename: string;
  mime: string;
  mediaId: string;
}

function createBaseMedia(): Media {
  return {
    id: "",
    filename: "",
    createdAt: undefined,
    updatedAt: undefined,
    downloadUrl: "",
    description: "",
    mime: "",
    type: "",
    status: "",
  };
}

export const Media = {
  encode(message: Media, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.filename !== "") {
      writer.uint32(18).string(message.filename);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.downloadUrl !== "") {
      writer.uint32(42).string(message.downloadUrl);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.mime !== "") {
      writer.uint32(58).string(message.mime);
    }
    if (message.type !== "") {
      writer.uint32(66).string(message.type);
    }
    if (message.status !== "") {
      writer.uint32(74).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Media {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedia();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filename = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.downloadUrl = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.mime = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.type = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Media {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
      downloadUrl: isSet(object.downloadUrl) ? globalThis.String(object.downloadUrl) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      mime: isSet(object.mime) ? globalThis.String(object.mime) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
    };
  },

  toJSON(message: Media): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    if (message.downloadUrl !== "") {
      obj.downloadUrl = message.downloadUrl;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.mime !== "") {
      obj.mime = message.mime;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Media>, I>>(base?: I): Media {
    return Media.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Media>, I>>(object: I): Media {
    const message = createBaseMedia();
    message.id = object.id ?? "";
    message.filename = object.filename ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.downloadUrl = object.downloadUrl ?? "";
    message.description = object.description ?? "";
    message.mime = object.mime ?? "";
    message.type = object.type ?? "";
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseCreateMediaRequest(): CreateMediaRequest {
  return { file: new Uint8Array(0), filename: "", mime: "" };
}

export const CreateMediaRequest = {
  encode(message: CreateMediaRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.file.length !== 0) {
      writer.uint32(10).bytes(message.file);
    }
    if (message.filename !== "") {
      writer.uint32(18).string(message.filename);
    }
    if (message.mime !== "") {
      writer.uint32(26).string(message.mime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateMediaRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMediaRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.file = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filename = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mime = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateMediaRequest {
    return {
      file: isSet(object.file) ? bytesFromBase64(object.file) : new Uint8Array(0),
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
      mime: isSet(object.mime) ? globalThis.String(object.mime) : "",
    };
  },

  toJSON(message: CreateMediaRequest): unknown {
    const obj: any = {};
    if (message.file.length !== 0) {
      obj.file = base64FromBytes(message.file);
    }
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.mime !== "") {
      obj.mime = message.mime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateMediaRequest>, I>>(base?: I): CreateMediaRequest {
    return CreateMediaRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateMediaRequest>, I>>(object: I): CreateMediaRequest {
    const message = createBaseCreateMediaRequest();
    message.file = object.file ?? new Uint8Array(0);
    message.filename = object.filename ?? "";
    message.mime = object.mime ?? "";
    return message;
  },
};

function createBaseUpdateMediaRequest(): UpdateMediaRequest {
  return { file: new Uint8Array(0), filename: "", mime: "", mediaId: "" };
}

export const UpdateMediaRequest = {
  encode(message: UpdateMediaRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.file.length !== 0) {
      writer.uint32(10).bytes(message.file);
    }
    if (message.filename !== "") {
      writer.uint32(18).string(message.filename);
    }
    if (message.mime !== "") {
      writer.uint32(26).string(message.mime);
    }
    if (message.mediaId !== "") {
      writer.uint32(34).string(message.mediaId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMediaRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMediaRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.file = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filename = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mime = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.mediaId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMediaRequest {
    return {
      file: isSet(object.file) ? bytesFromBase64(object.file) : new Uint8Array(0),
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
      mime: isSet(object.mime) ? globalThis.String(object.mime) : "",
      mediaId: isSet(object.mediaId) ? globalThis.String(object.mediaId) : "",
    };
  },

  toJSON(message: UpdateMediaRequest): unknown {
    const obj: any = {};
    if (message.file.length !== 0) {
      obj.file = base64FromBytes(message.file);
    }
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.mime !== "") {
      obj.mime = message.mime;
    }
    if (message.mediaId !== "") {
      obj.mediaId = message.mediaId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateMediaRequest>, I>>(base?: I): UpdateMediaRequest {
    return UpdateMediaRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateMediaRequest>, I>>(object: I): UpdateMediaRequest {
    const message = createBaseUpdateMediaRequest();
    message.file = object.file ?? new Uint8Array(0);
    message.filename = object.filename ?? "";
    message.mime = object.mime ?? "";
    message.mediaId = object.mediaId ?? "";
    return message;
  },
};

export interface MediaService {
  Create(request: DeepPartial<CreateMediaRequest>, metadata?: grpc.Metadata): Promise<Media>;
  Update(request: DeepPartial<UpdateMediaRequest>, metadata?: grpc.Metadata): Promise<Media>;
}

export class MediaServiceClientImpl implements MediaService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Create = this.Create.bind(this);
    this.Update = this.Update.bind(this);
  }

  Create(request: DeepPartial<CreateMediaRequest>, metadata?: grpc.Metadata): Promise<Media> {
    return this.rpc.unary(MediaServiceCreateDesc, CreateMediaRequest.fromPartial(request), metadata);
  }

  Update(request: DeepPartial<UpdateMediaRequest>, metadata?: grpc.Metadata): Promise<Media> {
    return this.rpc.unary(MediaServiceUpdateDesc, UpdateMediaRequest.fromPartial(request), metadata);
  }
}

export const MediaServiceDesc = { serviceName: "proto.MediaService" };

export const MediaServiceCreateDesc: UnaryMethodDefinitionish = {
  methodName: "Create",
  service: MediaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateMediaRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Media.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MediaServiceUpdateDesc: UnaryMethodDefinitionish = {
  methodName: "Update",
  service: MediaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateMediaRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Media.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

function bytesFromBase64(b64: string): Uint8Array {
  if (globalThis.Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (globalThis.Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
