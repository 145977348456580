/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import * as _m0 from "protobufjs/minimal";
import { ApplicationType, applicationTypeFromJSON, applicationTypeToJSON } from "./applications_and_tokens";
import { Empty } from "./google/protobuf/empty";

export const protobufPackage = "proto";

export interface TokenAvailableRequest {
  type: ApplicationType;
  orgId: string;
  clientId: string;
}

export interface TokenAvailableResponse {
  isAvailable: boolean;
  token: string;
  clientPhysitrack: ClientPhysitrack | undefined;
  signInAndPushClientUrl: string;
}

export interface ClientPhysitrack {
  id: number;
  externalId: string;
  firstName: string;
  lastName: string;
  yearOfBirth: number;
  gender: string;
  email: string;
  mobilePhone: string;
  ssn: string;
  lastSeen: string;
  currentAccessCode: string;
  programEndDate: string;
  selfRegistered: boolean;
}

export interface GetClientDetailsRequest {
  clientId: string;
  orgId: string;
}

export interface GetClientDetailsResponse {
  clients: ClientPhysitrack[];
}

export interface ClientTelehealthData {
  accessCode: string;
  ssoLink: string;
}

export interface UpdateClientDetailsRequest {
  phystrackData: ClientPhysitrack | undefined;
  orgId: string;
}

function createBaseTokenAvailableRequest(): TokenAvailableRequest {
  return { type: 0, orgId: "", clientId: "" };
}

export const TokenAvailableRequest = {
  encode(message: TokenAvailableRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.orgId !== "") {
      writer.uint32(18).string(message.orgId);
    }
    if (message.clientId !== "") {
      writer.uint32(26).string(message.clientId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TokenAvailableRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTokenAvailableRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TokenAvailableRequest {
    return {
      type: isSet(object.type) ? applicationTypeFromJSON(object.type) : 0,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
    };
  },

  toJSON(message: TokenAvailableRequest): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = applicationTypeToJSON(message.type);
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TokenAvailableRequest>, I>>(base?: I): TokenAvailableRequest {
    return TokenAvailableRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TokenAvailableRequest>, I>>(object: I): TokenAvailableRequest {
    const message = createBaseTokenAvailableRequest();
    message.type = object.type ?? 0;
    message.orgId = object.orgId ?? "";
    message.clientId = object.clientId ?? "";
    return message;
  },
};

function createBaseTokenAvailableResponse(): TokenAvailableResponse {
  return { isAvailable: false, token: "", clientPhysitrack: undefined, signInAndPushClientUrl: "" };
}

export const TokenAvailableResponse = {
  encode(message: TokenAvailableResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isAvailable === true) {
      writer.uint32(8).bool(message.isAvailable);
    }
    if (message.token !== "") {
      writer.uint32(18).string(message.token);
    }
    if (message.clientPhysitrack !== undefined) {
      ClientPhysitrack.encode(message.clientPhysitrack, writer.uint32(26).fork()).ldelim();
    }
    if (message.signInAndPushClientUrl !== "") {
      writer.uint32(34).string(message.signInAndPushClientUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TokenAvailableResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTokenAvailableResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isAvailable = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.token = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientPhysitrack = ClientPhysitrack.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.signInAndPushClientUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TokenAvailableResponse {
    return {
      isAvailable: isSet(object.isAvailable) ? globalThis.Boolean(object.isAvailable) : false,
      token: isSet(object.token) ? globalThis.String(object.token) : "",
      clientPhysitrack: isSet(object.clientPhysitrack) ? ClientPhysitrack.fromJSON(object.clientPhysitrack) : undefined,
      signInAndPushClientUrl: isSet(object.signInAndPushClientUrl)
        ? globalThis.String(object.signInAndPushClientUrl)
        : "",
    };
  },

  toJSON(message: TokenAvailableResponse): unknown {
    const obj: any = {};
    if (message.isAvailable === true) {
      obj.isAvailable = message.isAvailable;
    }
    if (message.token !== "") {
      obj.token = message.token;
    }
    if (message.clientPhysitrack !== undefined) {
      obj.clientPhysitrack = ClientPhysitrack.toJSON(message.clientPhysitrack);
    }
    if (message.signInAndPushClientUrl !== "") {
      obj.signInAndPushClientUrl = message.signInAndPushClientUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TokenAvailableResponse>, I>>(base?: I): TokenAvailableResponse {
    return TokenAvailableResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TokenAvailableResponse>, I>>(object: I): TokenAvailableResponse {
    const message = createBaseTokenAvailableResponse();
    message.isAvailable = object.isAvailable ?? false;
    message.token = object.token ?? "";
    message.clientPhysitrack = (object.clientPhysitrack !== undefined && object.clientPhysitrack !== null)
      ? ClientPhysitrack.fromPartial(object.clientPhysitrack)
      : undefined;
    message.signInAndPushClientUrl = object.signInAndPushClientUrl ?? "";
    return message;
  },
};

function createBaseClientPhysitrack(): ClientPhysitrack {
  return {
    id: 0,
    externalId: "",
    firstName: "",
    lastName: "",
    yearOfBirth: 0,
    gender: "",
    email: "",
    mobilePhone: "",
    ssn: "",
    lastSeen: "",
    currentAccessCode: "",
    programEndDate: "",
    selfRegistered: false,
  };
}

export const ClientPhysitrack = {
  encode(message: ClientPhysitrack, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.externalId !== "") {
      writer.uint32(18).string(message.externalId);
    }
    if (message.firstName !== "") {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(34).string(message.lastName);
    }
    if (message.yearOfBirth !== 0) {
      writer.uint32(40).int32(message.yearOfBirth);
    }
    if (message.gender !== "") {
      writer.uint32(50).string(message.gender);
    }
    if (message.email !== "") {
      writer.uint32(58).string(message.email);
    }
    if (message.mobilePhone !== "") {
      writer.uint32(66).string(message.mobilePhone);
    }
    if (message.ssn !== "") {
      writer.uint32(74).string(message.ssn);
    }
    if (message.lastSeen !== "") {
      writer.uint32(82).string(message.lastSeen);
    }
    if (message.currentAccessCode !== "") {
      writer.uint32(90).string(message.currentAccessCode);
    }
    if (message.programEndDate !== "") {
      writer.uint32(98).string(message.programEndDate);
    }
    if (message.selfRegistered === true) {
      writer.uint32(104).bool(message.selfRegistered);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientPhysitrack {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientPhysitrack();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.externalId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.yearOfBirth = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.gender = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.email = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.mobilePhone = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.ssn = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lastSeen = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.currentAccessCode = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.programEndDate = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.selfRegistered = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClientPhysitrack {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      externalId: isSet(object.externalId) ? globalThis.String(object.externalId) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      yearOfBirth: isSet(object.yearOfBirth) ? globalThis.Number(object.yearOfBirth) : 0,
      gender: isSet(object.gender) ? globalThis.String(object.gender) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      mobilePhone: isSet(object.mobilePhone) ? globalThis.String(object.mobilePhone) : "",
      ssn: isSet(object.ssn) ? globalThis.String(object.ssn) : "",
      lastSeen: isSet(object.lastSeen) ? globalThis.String(object.lastSeen) : "",
      currentAccessCode: isSet(object.currentAccessCode) ? globalThis.String(object.currentAccessCode) : "",
      programEndDate: isSet(object.programEndDate) ? globalThis.String(object.programEndDate) : "",
      selfRegistered: isSet(object.selfRegistered) ? globalThis.Boolean(object.selfRegistered) : false,
    };
  },

  toJSON(message: ClientPhysitrack): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.externalId !== "") {
      obj.externalId = message.externalId;
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.yearOfBirth !== 0) {
      obj.yearOfBirth = Math.round(message.yearOfBirth);
    }
    if (message.gender !== "") {
      obj.gender = message.gender;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.mobilePhone !== "") {
      obj.mobilePhone = message.mobilePhone;
    }
    if (message.ssn !== "") {
      obj.ssn = message.ssn;
    }
    if (message.lastSeen !== "") {
      obj.lastSeen = message.lastSeen;
    }
    if (message.currentAccessCode !== "") {
      obj.currentAccessCode = message.currentAccessCode;
    }
    if (message.programEndDate !== "") {
      obj.programEndDate = message.programEndDate;
    }
    if (message.selfRegistered === true) {
      obj.selfRegistered = message.selfRegistered;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClientPhysitrack>, I>>(base?: I): ClientPhysitrack {
    return ClientPhysitrack.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientPhysitrack>, I>>(object: I): ClientPhysitrack {
    const message = createBaseClientPhysitrack();
    message.id = object.id ?? 0;
    message.externalId = object.externalId ?? "";
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.yearOfBirth = object.yearOfBirth ?? 0;
    message.gender = object.gender ?? "";
    message.email = object.email ?? "";
    message.mobilePhone = object.mobilePhone ?? "";
    message.ssn = object.ssn ?? "";
    message.lastSeen = object.lastSeen ?? "";
    message.currentAccessCode = object.currentAccessCode ?? "";
    message.programEndDate = object.programEndDate ?? "";
    message.selfRegistered = object.selfRegistered ?? false;
    return message;
  },
};

function createBaseGetClientDetailsRequest(): GetClientDetailsRequest {
  return { clientId: "", orgId: "" };
}

export const GetClientDetailsRequest = {
  encode(message: GetClientDetailsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientId !== "") {
      writer.uint32(10).string(message.clientId);
    }
    if (message.orgId !== "") {
      writer.uint32(18).string(message.orgId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetClientDetailsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetClientDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetClientDetailsRequest {
    return {
      clientId: isSet(object.clientId) ? globalThis.String(object.clientId) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
    };
  },

  toJSON(message: GetClientDetailsRequest): unknown {
    const obj: any = {};
    if (message.clientId !== "") {
      obj.clientId = message.clientId;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetClientDetailsRequest>, I>>(base?: I): GetClientDetailsRequest {
    return GetClientDetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetClientDetailsRequest>, I>>(object: I): GetClientDetailsRequest {
    const message = createBaseGetClientDetailsRequest();
    message.clientId = object.clientId ?? "";
    message.orgId = object.orgId ?? "";
    return message;
  },
};

function createBaseGetClientDetailsResponse(): GetClientDetailsResponse {
  return { clients: [] };
}

export const GetClientDetailsResponse = {
  encode(message: GetClientDetailsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.clients) {
      ClientPhysitrack.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetClientDetailsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetClientDetailsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clients.push(ClientPhysitrack.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetClientDetailsResponse {
    return {
      clients: globalThis.Array.isArray(object?.clients)
        ? object.clients.map((e: any) => ClientPhysitrack.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetClientDetailsResponse): unknown {
    const obj: any = {};
    if (message.clients?.length) {
      obj.clients = message.clients.map((e) => ClientPhysitrack.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetClientDetailsResponse>, I>>(base?: I): GetClientDetailsResponse {
    return GetClientDetailsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetClientDetailsResponse>, I>>(object: I): GetClientDetailsResponse {
    const message = createBaseGetClientDetailsResponse();
    message.clients = object.clients?.map((e) => ClientPhysitrack.fromPartial(e)) || [];
    return message;
  },
};

function createBaseClientTelehealthData(): ClientTelehealthData {
  return { accessCode: "", ssoLink: "" };
}

export const ClientTelehealthData = {
  encode(message: ClientTelehealthData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accessCode !== "") {
      writer.uint32(10).string(message.accessCode);
    }
    if (message.ssoLink !== "") {
      writer.uint32(18).string(message.ssoLink);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientTelehealthData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientTelehealthData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accessCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ssoLink = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClientTelehealthData {
    return {
      accessCode: isSet(object.accessCode) ? globalThis.String(object.accessCode) : "",
      ssoLink: isSet(object.ssoLink) ? globalThis.String(object.ssoLink) : "",
    };
  },

  toJSON(message: ClientTelehealthData): unknown {
    const obj: any = {};
    if (message.accessCode !== "") {
      obj.accessCode = message.accessCode;
    }
    if (message.ssoLink !== "") {
      obj.ssoLink = message.ssoLink;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClientTelehealthData>, I>>(base?: I): ClientTelehealthData {
    return ClientTelehealthData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientTelehealthData>, I>>(object: I): ClientTelehealthData {
    const message = createBaseClientTelehealthData();
    message.accessCode = object.accessCode ?? "";
    message.ssoLink = object.ssoLink ?? "";
    return message;
  },
};

function createBaseUpdateClientDetailsRequest(): UpdateClientDetailsRequest {
  return { phystrackData: undefined, orgId: "" };
}

export const UpdateClientDetailsRequest = {
  encode(message: UpdateClientDetailsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.phystrackData !== undefined) {
      ClientPhysitrack.encode(message.phystrackData, writer.uint32(10).fork()).ldelim();
    }
    if (message.orgId !== "") {
      writer.uint32(18).string(message.orgId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateClientDetailsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateClientDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phystrackData = ClientPhysitrack.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateClientDetailsRequest {
    return {
      phystrackData: isSet(object.phystrackData) ? ClientPhysitrack.fromJSON(object.phystrackData) : undefined,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
    };
  },

  toJSON(message: UpdateClientDetailsRequest): unknown {
    const obj: any = {};
    if (message.phystrackData !== undefined) {
      obj.phystrackData = ClientPhysitrack.toJSON(message.phystrackData);
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateClientDetailsRequest>, I>>(base?: I): UpdateClientDetailsRequest {
    return UpdateClientDetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateClientDetailsRequest>, I>>(object: I): UpdateClientDetailsRequest {
    const message = createBaseUpdateClientDetailsRequest();
    message.phystrackData = (object.phystrackData !== undefined && object.phystrackData !== null)
      ? ClientPhysitrack.fromPartial(object.phystrackData)
      : undefined;
    message.orgId = object.orgId ?? "";
    return message;
  },
};

/** Create/Get integration auth apps */
export interface PhysitrackApiV1 {
  TokenAvailable(
    request: DeepPartial<TokenAvailableRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TokenAvailableResponse>;
}

export class PhysitrackApiV1ClientImpl implements PhysitrackApiV1 {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.TokenAvailable = this.TokenAvailable.bind(this);
  }

  TokenAvailable(
    request: DeepPartial<TokenAvailableRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TokenAvailableResponse> {
    return this.rpc.unary(PhysitrackApiV1TokenAvailableDesc, TokenAvailableRequest.fromPartial(request), metadata);
  }
}

export const PhysitrackApiV1Desc = { serviceName: "proto.PhysitrackApiV1" };

export const PhysitrackApiV1TokenAvailableDesc: UnaryMethodDefinitionish = {
  methodName: "TokenAvailable",
  service: PhysitrackApiV1Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return TokenAvailableRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TokenAvailableResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export interface PhysitrackApiV2 {
  GetClientsDetails(
    request: DeepPartial<GetClientDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetClientDetailsResponse>;
  UpdateClientsDetails(request: DeepPartial<UpdateClientDetailsRequest>, metadata?: grpc.Metadata): Promise<Empty>;
}

export class PhysitrackApiV2ClientImpl implements PhysitrackApiV2 {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetClientsDetails = this.GetClientsDetails.bind(this);
    this.UpdateClientsDetails = this.UpdateClientsDetails.bind(this);
  }

  GetClientsDetails(
    request: DeepPartial<GetClientDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetClientDetailsResponse> {
    return this.rpc.unary(PhysitrackApiV2GetClientsDetailsDesc, GetClientDetailsRequest.fromPartial(request), metadata);
  }

  UpdateClientsDetails(request: DeepPartial<UpdateClientDetailsRequest>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(
      PhysitrackApiV2UpdateClientsDetailsDesc,
      UpdateClientDetailsRequest.fromPartial(request),
      metadata,
    );
  }
}

export const PhysitrackApiV2Desc = { serviceName: "proto.PhysitrackApiV2" };

export const PhysitrackApiV2GetClientsDetailsDesc: UnaryMethodDefinitionish = {
  methodName: "GetClientsDetails",
  service: PhysitrackApiV2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetClientDetailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetClientDetailsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PhysitrackApiV2UpdateClientsDetailsDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateClientsDetails",
  service: PhysitrackApiV2Desc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateClientDetailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
