import { EntityStore } from "./entity-store";
import { rpc, metadata } from "../grpc/grpc-legacy";
import {
  ListThirdPartyInvoiceTemplateRequest,
  ThirdPartyInvoiceTemplate
} from "sdk/dist/third_party_invoice_templates_pb";

class TPITemplateStore extends EntityStore<ThirdPartyInvoiceTemplate.AsObject> {
  async loadByClientId(clientId: string) {
    const req = new ListThirdPartyInvoiceTemplateRequest();
    req.setClientId(clientId);
    const res = await rpc.thirdPartyInvoiceTemplate.list(req, metadata());
    res.toObject().templatesList.map((template) => this.add(template));
  }

  getByClientId(clientId: string): Array<ThirdPartyInvoiceTemplate.AsObject> {
    let templateArray = new Array<ThirdPartyInvoiceTemplate.AsObject>();
    this.all().map((template) => {
      if (template.clientId === clientId) {
        templateArray.push(template);
      }
    });
    return templateArray;
  }
}

export const tpiTemplateStore = new TPITemplateStore();
